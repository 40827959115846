import { Button, Stack } from '@mui/material'
import { genHeader } from './navConfig'

export const CommonNavigation = () => {
  return (
    <Stack direction="row">
      {genHeader.map((item) => (
        <Button key={item.path} disableRipple href={item.path}>
          {item.title}
        </Button>
      ))}
    </Stack>
  )
}
