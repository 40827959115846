import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AlertBox } from '../../../../../components/AlertBox'
import { AppDispatch } from '../../../../../store'
import { GrantMatchActions } from '../../../../../store/grantMatcher'
import { SnackbarActions } from '../../../../../store/snackbar'
import { Outcome } from '../../../../../types/Grants'
import { GrantOutcomeFeedbackForm } from './GrantOutcomeFeedbackForm'

interface GrantOutcomeConfirmationModalProps {
  open: boolean
  setOpen: Function
  outcome: Outcome
  refId: string
  cb: Function
  grantTitle?: string
}

const ModalTypes = {
  win: {
    title: 'You got the grant?',
    description: (grantTitle: string, optionalFunction: Function) =>
      `Did you get the grant ${grantTitle}?`,
  },
  loss: {
    title: "You didn't get the grant?",
    description: (feedback: string, setFeedback: Function) => (
      <GrantOutcomeFeedbackForm feedback={feedback} setFeedback={setFeedback} />
    ),
  },
  'N/A': {
    title: 'Reset grant outcome?',
    description: () => 'Are you sure you want to reset the grant outcome to N/A?',
  },
}

export const GrantOutcomeConfirmationModal: FC<GrantOutcomeConfirmationModalProps> = ({
  cb,
  open,
  refId,
  setOpen,
  outcome,
  grantTitle,
}) => {
  const { id = '' } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const [feedback, setFeedback] = useState('')

  const modalConfig = ModalTypes[outcome]

  const title = modalConfig.title

  const description =
    outcome === 'loss'
      ? modalConfig.description(feedback, setFeedback)
      : modalConfig.description(grantTitle || '', () => {})

  const handleUpdateGrantOutcome = async () => {
    const resultAction = await dispatch(
      GrantMatchActions.updateMatchedTechGrantAsync({
        params: { techId: id, refId },
        body: { outcome, feedback },
      }),
    )

    if (GrantMatchActions.updateMatchedTechGrantAsync.fulfilled.match(resultAction)) {
      const message = outcome === 'win' ? 'Conratulations!!' : 'grant outcome updated'
      dispatch(SnackbarActions.showSnackbar({ message, options: { variant: 'success' } }))
    }

    cb()

    setOpen(false)
    setFeedback('')
  }

  return (
    <AlertBox
      open={open}
      setOpen={setOpen}
      title={title}
      description={description}
      confirmAction={handleUpdateGrantOutcome}
      denyAction={() => setOpen(false)}
      confirmButtonProps={{ label: 'Confirm', color: 'primary' }}
      denyButtonProps={{ label: 'Cancel' }}
    />
  )
}
