import { Box, Stack, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

export const LabeledText: FC<{ label?: string; value: string }> = ({ label, value }) => {
  const theme = useTheme()

  return (
    <Stack direction="row" alignItems="center" gap=".4rem" my=".2rem">
      {label && (
        <Typography variant="body1" color={theme.palette.grey[600]}>
          {label}:
        </Typography>
      )}
      <Typography
        variant="body1"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Box component="span" fontWeight={700} fontSize="1rem" color="secondary.main">
          {value}
        </Box>
      </Typography>
    </Stack>
  )
}
