import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { GrantApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { IGrant } from '../../../types/Grants/Grant'
import { IPagedResponse } from '../../../types/Requests'
import { normalizeError } from '../../../utils'
import { GrantsInitialState } from '../grants.slice'

export const fetchQuickSearchGrantsAsync = createAsyncThunk(
  'grantMatcher/fetchQuickSearchGrants',
  async ({ body, options }: { body: any; options?: RequestConfig }, { rejectWithValue }) => {
    try {
      const req = await GrantApi.fetchSearchedGrants(body, options)
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'searching grants')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchQuickSearchGrantsAsyncBuilder = (
  builder: ActionReducerMapBuilder<GrantsInitialState>,
) => {
  builder.addCase(
    fetchQuickSearchGrantsAsync.fulfilled.type,
    (state, action: PayloadAction<IPagedResponse<IGrant[]>>) => {
      const { items, ...rest } = action.payload

      state.data = items
      state.paging.totalRows = rest.totalRows
      state.paging.rows = rest.rows
      state.submitting = false
    },
  )
  builder.addCase(
    fetchQuickSearchGrantsAsync.rejected.type,
    (state, action: PayloadAction<string>) => {
      state.submitting = false
      state.error = action.payload
    },
  )
  builder.addCase(fetchQuickSearchGrantsAsync.pending.type, (state) => {
    state.submitting = true
    state.error = ''
  })
}
