import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AuthApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { AuthInitialState } from '../userAuth.slice'

export const requestResetPasswordAsync = createAsyncThunk(
  'auth/resetPasswordToken',
  async ({ email }: { email: string }, { rejectWithValue }) => {
    try {
      const req = await AuthApi.passwordResetRequest({ email })
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'requesting rest password')
      return rejectWithValue(normalized)
    }
  },
)

export const requestResetPasswordAsyncBuilder = (
  builder: ActionReducerMapBuilder<AuthInitialState>,
) => {
  builder.addCase(requestResetPasswordAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(requestResetPasswordAsync.fulfilled, (state, action: PayloadAction<string>) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(requestResetPasswordAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
