import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { UsersApi } from '../../../services'
import { ICurrentUserCompany } from '../../../types'
import { normalizeError } from '../../../utils'
import { CurrentUserInitialState } from '../currentUser.slice'

export const fetchCurrentUserCompanyAsync = createAsyncThunk(
  'user/fetchCompany',
  async (_, { rejectWithValue }) => {
    try {
      const company = await UsersApi.fetchCompany()

      const response = (company as AxiosResponse<any, any>).data
      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching user company info')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchCurrentUserCompanyAsyncBuilder = (
  builder: ActionReducerMapBuilder<CurrentUserInitialState>,
) => {
  builder.addCase(fetchCurrentUserCompanyAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(
    fetchCurrentUserCompanyAsync.fulfilled,
    (state, action: PayloadAction<ICurrentUserCompany>) => {
      state.loading = false
      state.success = true
      state.data.company = action.payload
    },
  )
  builder.addCase(fetchCurrentUserCompanyAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
