import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { UsersApi } from '../../../services'
import { IRegsitrationProgress } from '../../../types'
import { normalizeError } from '../../../utils'
import { RegistrationProgressInitialState } from '../registrationProgress.slice'

export const fetchRegistrationProgressAsync = createAsyncThunk(
  'users/registrationProgress',
  async (_, { rejectWithValue }) => {
    try {
      const user = await UsersApi.fetchRegistrationProgress()

      const response = (user as AxiosResponse<any, any>).data
      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching registration progress')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchRegistrationProgressAsyncBuilder = (
  builder: ActionReducerMapBuilder<RegistrationProgressInitialState>,
) => {
  builder.addCase(fetchRegistrationProgressAsync.pending, (state, action) => {
    state.loading = true
    state.error = ''
  })
  builder.addCase(
    fetchRegistrationProgressAsync.fulfilled,
    (state, action: PayloadAction<IRegsitrationProgress>) => {
      state.loading = false
      state.data = action.payload
    },
  )
  builder.addCase(fetchRegistrationProgressAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.error = action.payload
  })
}
