import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { TechnologiesApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { TechnologiesInitialState } from '../technologies.slice'

export const deleteTechnologyAsync = createAsyncThunk(
  'technologies/deleteTechnology',
  async ({ techId }: { techId: string }, { rejectWithValue }) => {
    try {
      const req = await TechnologiesApi.deleteTechnology(techId)
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'deleting technology')
      return rejectWithValue(normalized)
    }
  },
)

export const deleteTechnologyAsyncBuilder = (
  builder: ActionReducerMapBuilder<TechnologiesInitialState>,
) => {
  builder.addCase(deleteTechnologyAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(deleteTechnologyAsync.fulfilled, (state, action) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(deleteTechnologyAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = true
    state.error = action.payload
  })
}
