import { FC } from 'react'
import { CustomModal } from '../../../../../../../components/customModal'
import { EditCompanyInfoForm } from './EditCompanyInfoForm'

interface EditCompanyInfoModalProps {
  open: boolean
  setOpen: Function
}

export const EditCompanyInfoModal: FC<EditCompanyInfoModalProps> = ({ open, setOpen }) => {
  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title="Company info"
      closeButtonAction={() => setOpen(false)}
    >
      <EditCompanyInfoForm handleModalClose={() => setOpen(false)} />
    </CustomModal>
  )
}
