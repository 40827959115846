import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const TopicNumberIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="one 1" clipPath="url(#clip0_3813_15381)">
        <g id="Layer 2">
          <g id="Number">
            <g id="1">
              <path
                id="Vector"
                d="M39.9023 18.0937C39.668 14.6937 39.1734 11.3405 37.9297 8.13975C37.3125 6.55069 36.5477 5.04365 35.2281 3.89834C34.0477 2.87412 32.6562 2.22412 31.193 1.71866C30.3906 1.34053 29.5234 1.13897 28.6625 0.937405C25.1438 0.131155 21.5781 -0.12197 17.9742 0.0522488C15.0453 0.192874 12.1586 0.585061 9.35938 1.48428C7.78828 1.98662 6.28437 2.63506 4.97422 3.67178C3.90625 4.50772 3.17422 5.60694 2.5875 6.81162C1.60156 8.83819 1.03359 10.9952 0.633594 13.203C0.266949 15.2112 0.0551281 17.2445 0 19.2851C0 19.3726 0.03125 23.4452 0.078125 24.0351C0.33125 27.264 0.835937 30.4343 2.21875 33.4054C2.36641 33.7226 2.51016 34.0444 2.73984 34.3155C3.43125 35.5921 4.46406 36.5155 5.72422 37.2062C7.71172 38.2999 9.86484 38.8827 12.0766 39.3038C13.7417 39.6168 15.425 39.8232 17.1164 39.9218C19.2638 40.0393 21.4166 40.0164 23.5609 39.853C26.575 39.6038 29.5266 39.0882 32.3891 38.0616C35.5141 36.9405 37.4781 34.7952 38.4875 31.6765C39.2789 29.2327 39.7094 26.7312 39.8875 24.1765C40.0325 22.1516 40.0374 20.1192 39.9023 18.0937Z"
                fill="#FF9F40"
              />
              <path
                id="Vector_2"
                d="M39.9016 18.0932C39.6672 14.6932 39.1727 11.34 37.9289 8.13926C37.3117 6.5502 36.5469 5.04316 35.2273 3.89785C35.0435 3.73848 34.8555 3.58796 34.6633 3.44629C34.6836 3.90879 34.6937 4.37415 34.6937 4.84238C34.6937 21.1447 22.4281 34.3596 7.29297 34.3596C5.67156 34.3614 4.05364 34.2094 2.46094 33.9057C2.53941 34.0523 2.63232 34.1908 2.73828 34.3189C3.42969 35.5955 4.4625 36.5189 5.72266 37.2096C7.71016 38.3033 9.86328 38.8861 12.075 39.3072C13.7404 39.6189 15.4241 39.824 17.1156 39.9213C19.263 40.0388 21.4158 40.0159 23.5602 39.8525C26.5742 39.6033 29.5258 39.0877 32.3883 38.0611C35.5133 36.94 37.4773 34.7947 38.4867 31.676C39.2781 29.2322 39.7086 26.7307 39.8867 24.176C40.0317 22.1511 40.0366 20.1187 39.9016 18.0932Z"
                fill="#F49138"
              />
              <g id="Group">
                <path
                  id="Vector_3"
                  d="M18.5711 28.9268V14.8791C18.3445 14.9783 18.0313 15.1135 17.5969 15.2955C16.3836 15.8049 16.118 15.901 16.018 15.9275L15.5273 16.0572V12.9572L15.7555 12.8525C15.9344 12.7705 16.5289 12.4752 17.5219 11.9752C18.8383 11.3111 19.0844 11.1713 19.1266 11.1416L19.2258 11.0752H19.3484H22.3172V28.9268H18.5711Z"
                  fill="#FCFCFC"
                />
                <path
                  id="1_2"
                  d="M17.6977 12.3238C16.6987 12.8275 16.1055 13.1223 15.918 13.2082V15.552C16.0352 15.5217 16.5445 15.3171 17.4461 14.9379C18.3477 14.5587 18.8529 14.3342 18.9617 14.2645V28.5363H21.9242V11.4629H19.3461C19.2461 11.5332 18.6966 11.8202 17.6977 12.3238Z"
                  fill="#FCFCFC"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3813_15381">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
