import { Menu, MenuProps, styled } from '@mui/material'

export const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0.5rem',
    marginTop: 4,
    minWidth: 200,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      py: 4,
    },
  },
}))
