import { Box, Button, Stack, SxProps, Typography, useTheme } from '@mui/material'
import { FC, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../../../store'
import { CurrentUserActions, CurrentUserSelector } from '../../../../../../store/currentUser'
import { SnackbarActions } from '../../../../../../store/snackbar'
import { UploadContainer } from '../../../MyAccout.styles'

interface UploadAvatarFormProps {
  setOpen: Function
}

const footerButtonStyles: SxProps = {
  py: 1,
  px: 2,
  height: '45px',
  color: 'white',
  fontWeight: 500,
  fontSize: '0.875rem',
  borderRadius: '0.5rem',
  textTransform: 'capitalize',
}

export const UploadAvatarForm: FC<UploadAvatarFormProps> = ({ setOpen }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [avatar, setAvatar] = useState<any>()

  const loading = useSelector(CurrentUserSelector.isLoading)

  const handleAvatarChange = (file: File) => {
    setAvatar(file)
  }

  const handleUploadAvatar = async () => {
    const formData = new FormData()

    formData.append('file', avatar)

    const resultAction = await dispatch(
      CurrentUserActions.updateCurrentUserAvatarAsync({ file: formData }),
    )

    if (CurrentUserActions.updateCurrentUserAvatarAsync.fulfilled.match(resultAction)) {
      SnackbarActions.showSnackbar({
        message: 'Avatar Uploaded successfully!',
        options: { variant: 'success' },
      })
      setOpen(false)
    }
  }

  return (
    <Box>
      <Box my={3}>
        <Button
          variant="text"
          color="error"
          sx={{ textTransform: 'capitalize', borderRadius: '0.5rem', my: 1 }}
        >
          Remove Avatar
        </Button>
        <Box my={1}>
          <Typography fontSize="0.875rem" fontWeight={500} color="secondary.light" mb={1}>
            Upload avatar
          </Typography>

          <FileUploader
            multiple={false}
            name="avatar"
            handleChange={handleAvatarChange}
            types={['png', 'jpeg', 'jpg']}
          >
            <UploadContainer>
              {avatar && avatar.name ? (
                <Typography
                  textAlign="center"
                  color="secondary.light"
                  fontSize="0.875rem"
                  fontWeight={500}
                >
                  {avatar.name}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign="center"
                    color="secondary.light"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    Drop your avatar here,
                  </Typography>
                  <Typography
                    textAlign="center"
                    color="primary"
                    fontSize="0.875rem"
                    fontWeight={500}
                  >
                    Browse
                  </Typography>
                </>
              )}
            </UploadContainer>
          </FileUploader>
        </Box>
      </Box>

      <Stack justifyContent="flex-end" direction="row" gap="1rem" mt={1}>
        <Button
          variant="contained"
          sx={{
            background: theme.palette.primary.main,
            ...footerButtonStyles,
          }}
          onClick={handleUploadAvatar}
          disableElevation
          disabled={!avatar || loading}
        >
          Save changes
        </Button>
      </Stack>
    </Box>
  )
}
