import { FC } from 'react'
import { CustomModal } from '../../../../components/customModal'
import { AddTechnologyMembersForm } from './AddTechnologyMemberForm'

interface AddTechnologyMembersModelProps {
  open: boolean
  setOpen: Function
}

export const AddTechnologyMembersModal: FC<AddTechnologyMembersModelProps> = ({
  open,
  setOpen,
}) => {
  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title="Invite user"
      closeButtonAction={() => setOpen(false)}
    >
      <AddTechnologyMembersForm showModal={setOpen} />
    </CustomModal>
  )
}
