import { ArrowDropDownRounded, CloseRounded } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, MouseEvent, useState } from 'react'
import { filterContainerStyles, menuStyles } from './searchFilters.styles'

interface PhaseFilterProps {
  handlePhaseChange: Function
  selectedPhases: string[]
}

const phases = ['Phase I', 'Phase II', 'BOTH']

//TODO: this function needs to be refactored, the props should be handled through redux
export const PhaseFilter: FC<PhaseFilterProps> = ({ handlePhaseChange, selectedPhases }) => {
  const theme = useTheme()
  const [filteredPhases, setFilteredPhases] = useState<string[]>(selectedPhases)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    handlePhaseChange(filteredPhases)
  }

  const handleFilterChange = (selectedPhase: string) => {
    const isSelected = filteredPhases.includes(selectedPhase)

    if (!isSelected) {
      setFilteredPhases([...filteredPhases, selectedPhase])
    } else {
      setFilteredPhases(filteredPhases.filter((phase) => phase !== selectedPhase))
    }
  }

  const handleResetFilters = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setFilteredPhases([])
    handlePhaseChange([])
  }

  const filterTitle = (
    <>
      {!filteredPhases.length ? (
        <>
          <Typography
            color="secondary"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="1rem"
            ml={1}
          >
            All Phases
          </Typography>

          <ArrowDropDownRounded sx={{ fontSize: 28, color: 'secondary' }} />
        </>
      ) : (
        <>
          <Typography
            color="secondary"
            fontWeight={theme.typography.fontWeightBold}
            fontSize="1rem"
            mx={1}
          >
            {filteredPhases.length}
            {filteredPhases.length > 1 ? ' Phases' : ' Phase'}
          </Typography>
          <IconButton onClick={handleResetFilters} sx={{ p: 0.1 }}>
            <CloseRounded sx={{ fontSize: 22, color: 'secondary.light' }} />
          </IconButton>
        </>
      )}
    </>
  )

  return (
    <Box>
      <Box
        component="div"
        sx={{
          ...filterContainerStyles,
          background: filteredPhases.length ? theme.palette.grey[300] : 'white',
          border: `1px solid ${theme.palette.grey[300]}`,
        }}
        onClick={handleClick}
      >
        {filterTitle}
      </Box>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} elevation={0} sx={menuStyles}>
        {phases.map((phase: any) => (
          <Box
            key={phase}
            component="div"
            sx={{
              py: 1,
              px: 2,
              '&:hover': {
                cursor: 'pointer',
                background: theme.palette.grey[100],
              },
            }}
          >
            <FormControlLabel
              control={<Checkbox />}
              checked={filteredPhases.includes(phase)}
              label={`${phase}`}
              onChange={() => handleFilterChange(phase)}
            />
          </Box>
        ))}
      </Menu>
    </Box>
  )
}
