import { Box, Chip, Paper, Stack, Typography, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'

interface CompanyMembersListItemProps {
  name: string
  email: string
  role: string
  technologyCount: number
  actionMenu: ReactNode
}

const containerStyles = {
  p: '0.8rem',
  borderRadius: '.5rem',
  boxShadow: '0px 1px 3px 0px rgba(28,28,28,0.2)',
}

export const CompanyMembersListItem: FC<CompanyMembersListItemProps> = ({
  name,
  email,
  role,
  technologyCount,
  actionMenu,
}) => {
  const theme = useTheme()

  return (
    <>
      <Paper elevation={0} component="div" sx={containerStyles}>
        <Stack gap={0.5} direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h5"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            color="secondary"
            fontWeight={700}
            fontSize="1rem"
          >
            <Box component="span">{name}</Box>
          </Typography>

          {actionMenu}
        </Stack>

        <Typography
          mb={1.2}
          variant="h5"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          color="secondary"
          fontWeight={500}
          fontSize="1rem"
        >
          <Box component="span">{email}</Box>
        </Typography>

        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="body2" color={theme.palette.grey[700]} mb={0.6}>
              Technology count
            </Typography>
            <Typography variant="h5" color="secondary" fontSize="1rem" fontWeight={700}>
              {technologyCount}
            </Typography>
          </Box>

          <Chip
            color="primary"
            variant="outlined"
            sx={{
              my: '.2rem',
              padding: '8px 4px',
              borderRadius: '24px',
              alignSelf: 'flex-end',
              border: 'transparent',
              backgroundColor: 'primary.light',
            }}
            label={
              <Typography variant="body1" sx={{ color: 'primary.main', fontSize: '0.875rem' }}>
                {role}
              </Typography>
            }
          />
        </Stack>
      </Paper>
    </>
  )
}
