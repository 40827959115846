import { FC } from 'react'
import { CustomModal } from '../../../../../../../components/customModal'
import { UpdateMemberRoleForm } from './UpdateMemberRoleForm'

interface AddCompanyMembersModalProps {
  open: boolean
  setOpen: Function
  selectedMember: { memberId: string; memberName: string; currentRole: string }
}

export const UpdateMemberRoleModal: FC<AddCompanyMembersModalProps> = ({
  open,
  setOpen,
  selectedMember,
}) => {
  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title="Change user's role"
      closeButtonAction={() => setOpen(false)}
    >
      <UpdateMemberRoleForm
        handleModalClose={() => setOpen(false)}
        selectedMember={selectedMember}
      />
    </CustomModal>
  )
}
