import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import { IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../../components/table'
import { AgenciesSelectors } from '../../../../store/agencies'
import { IPaging } from '../../../../store/technology'
import { MatchedGrantStatus } from '../../../../types/Grants'
import { GrantOutcome } from './GrantOutcome'
import { GrantsTableChip } from './GrantsTableChip'
import { LikeDislikeWidget } from './LikeDislikeWidget'
import { PhaseTag } from './PhaseTag'
import { TrackGrantStatus } from './TrackGrantStatus'

const Table_Headers = [
  'Grant',
  'Agency',
  'Next Close Date',
  'Phase',
  'Outcome',
  'Grant Link',
  'Grant Status',
  '',
]

const headerCells = Table_Headers.map((header, index) => ({
  content: (
    <Typography key={index} color="secondary" fontWeight={700} fontSize="0.9rem">
      {header}
    </Typography>
  ),
}))

interface ITableData {
  _id?: string
  refId: string
  topicId: string
  title: string
  topicTitle: string
  agency: string
  close_date: string
  phase: string | string[]
  link: string
  status: string
  like: number
  progressStatus: string
  outcome: 'win' | 'loss' | 'N/A'
  rowAction: ReactElement
}

interface MatchedGrantsProps {
  tableData: ITableData[]
  pageUpAction: () => void
  pageDownAction: () => void
  handleSlider: Function
  handleGrantOutcomeChange: Function
  fetchGrants: Function
  emptyComponent: ReactElement
  paging: IPaging
  selectedTab?: MatchedGrantStatus
}

export const GrantsTable: FC<MatchedGrantsProps> = ({
  tableData,
  pageUpAction,
  pageDownAction,
  handleSlider,
  fetchGrants,
  emptyComponent,
  paging,
  handleGrantOutcomeChange,
  selectedTab,
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const currentPage = Math.ceil((paging.skip + 1) / paging.limit)
  const pages = Math.ceil(paging.totalRows / paging.limit)

  const agencies: any = tableData.map((row) => row.agency)
  const agencyDetails: any = useSelector((state) =>
    AgenciesSelectors.makeAgenciesDetail(state, agencies),
  )

  const filteredHeader = isSmallScreen
    ? headerCells.slice(0, 2)
    : headerCells.filter((_, index) => {
        if (index === 4 || index === 6) return selectedTab === 'selected'
        return true
      })

  const rows: any = tableData.map((rowCell) => {
    const cells = [
      {
        content: (
          <Typography
            color="secondary"
            fontWeight={theme.typography.fontWeightBold}
            fontSize="0.875rem"
          >
            {rowCell.title || rowCell.topicTitle}
          </Typography>
        ),
        cellProps: {
          sx: { maxWidth: 400 },
        },
      },
      {
        content: (
          <GrantsTableChip
            value={agencyDetails[rowCell.agency]?.acronym}
            textColor={agencyDetails[rowCell.agency]?.textColor}
            backgroundColor={agencyDetails[rowCell.agency]?.backgroundColor}
          />
        ),
      },
      {
        content: (
          <Typography
            color="secondary.light"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="0.875rem"
          >
            {rowCell.close_date}
          </Typography>
        ),
        cellProps: {
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
      },
      {
        content: <PhaseTag value={rowCell.phase} />,
        cellProps: {
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
      },
      selectedTab === 'selected' && {
        content: rowCell.outcome && (
          <GrantOutcome
            refId={rowCell.refId}
            grantTitle={rowCell.title}
            currentOutcome={rowCell.outcome}
            handleGrantOutcomeChange={handleGrantOutcomeChange}
          />
        ),
        cellProps: {
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
      },
      {
        content: (
          <Typography
            color="primary"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="0.875rem"
          >
            Learn More
          </Typography>
        ),
        cellProps: {
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
      },
      selectedTab === 'selected' && {
        content: rowCell.progressStatus && (
          <TrackGrantStatus
            refId={rowCell.refId}
            progressStatus={rowCell.progressStatus}
            cb={fetchGrants}
          />
        ),
        cellProps: {
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
      },
      {
        content: <LikeDislikeWidget like={rowCell.like} refId={rowCell.refId} cb={fetchGrants} />,
        cellProps: {
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
      },
    ].filter(Boolean) // This filters out the false cells

    return {
      rowProps: {
        onClick: () => handleSlider(rowCell.topicId),
        sx: { '&:hover': { cursor: 'pointer', background: theme.palette.grey[100] } },
      },
      cells,
    }
  })

  return (
    <Stack
      sx={{
        my: '1rem',
        padding: '1.5rem',
        minHeight: '380px',
        borderRadius: '8px',
        background: 'white',
      }}
      justifyContent="space-between"
    >
      {!!rows.length ? (
        <>
          <Table
            containerProps={{
              sx: {
                boxShadow: 'none',
                background: 'transparent',
              },
            }}
            head={{
              containerProps: {
                sx: { borderRadius: '8px', background: theme.palette.grey[200], width: '100%' },
              },
              rows: [{ cells: filteredHeader || [] }],
            }}
            body={{ rows }}
          />

          <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="space-between">
            <Typography
              color="secondary"
              fontSize="0.875rem"
              fontWeight={theme.typography.fontWeightBold}
            >
              Page {currentPage} of {pages}
            </Typography>
            <Stack direction="row" alignItems="center" gap={1}>
              <IconButton onClick={pageDownAction} disabled={currentPage === 1}>
                <ArrowBackIosRounded sx={{ fontSize: '0.9rem' }} />
              </IconButton>
              <Typography
                color="secondary"
                fontSize="0.875rem"
                fontWeight={700}
                sx={{
                  minWidth: '24px',
                  padding: '4px 8px',
                  borderRadius: '12px',
                  textAlign: 'center',
                  background: theme.palette.grey[200],
                }}
              >
                {currentPage}
              </Typography>
              <IconButton onClick={pageUpAction} disabled={currentPage === pages}>
                <ArrowForwardIosRounded sx={{ fontSize: '0.9rem' }} />
              </IconButton>
            </Stack>
          </Stack>
        </>
      ) : (
        emptyComponent
      )}
    </Stack>
  )
}
