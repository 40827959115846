import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { FC, ReactNode, useMemo } from 'react'
import components from './components'
import palette from './palette'
import typography from './typography'
interface ThemeProviderProps {
  children: ReactNode
}

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const themeOptions = useMemo(
    () => ({
      typography,
      palette,
      components,
    }),
    [],
  )

  const theme = createTheme(themeOptions)

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}

export default ThemeProvider
