import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AuthApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { AuthInitialState } from '../userAuth.slice'

export const resetPasswordAsync = createAsyncThunk(
  'auth/resetPassword',
  async (
    { email, token, newPassword }: { email: string; token: string; newPassword: string },
    { rejectWithValue },
  ) => {
    try {
      const req = await AuthApi.PasswordReset({ email, token, newPassword })
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'reseting password')
      return rejectWithValue(normalized)
    }
  },
)

export const resetPasswordAsyncBuilder = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  builder.addCase(resetPasswordAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(resetPasswordAsync.fulfilled, (state, action: PayloadAction<string>) => {
    state.loading = false
  })
  builder.addCase(resetPasswordAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
