import { CircularProgress, Stack, Typography, useTheme } from '@mui/material'

export const StripeCheckoutLoader = () => {
  const theme = useTheme()

  return (
    <Stack alignItems="center" justifyContent="center" gap={3} mt={4}>
      <CircularProgress size="6rem" />

      <Typography
        color="#0A1420"
        fontSize="1.75rem"
        textAlign="center"
        fontWeight={theme.typography.fontWeightMedium}
      >
        Please wait while we are finalizing your payment
      </Typography>
    </Stack>
  )
}
