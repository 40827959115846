import { Navigate, Route, Routes } from 'react-router-dom'
import { Company } from './Company'
import { MyAccount } from './MyAccount'

export const UserProfile = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="./myAccount" />} />
      <Route path="myAccount" element={<MyAccount />} />
      <Route path="company/*" element={<Company />} />
      <Route path="*" element={<Navigate replace to="./myAccount" />} />
    </Routes>
  )
}
