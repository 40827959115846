import { Button, useTheme } from '@mui/material'
import { CsvBuilder } from 'filefy'
import moment from 'moment'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ExportIcon } from '../../../../components/icons'
import { AppDispatch, GrantsActions } from '../../../../store'
import { SnackbarActions } from '../../../../store/snackbar'
import { TechGrantSelectors } from '../../../../store/technology'
import { MatchedGrantStatus } from '../../../../types/Grants'

export const ExportGrantsButton: FC<{ selectedTab: MatchedGrantStatus }> = ({ selectedTab }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [exporting, setExporting] = useState(false)

  const grantsToExport = useSelector((state) =>
    TechGrantSelectors.makeExportGrants(state, selectedTab),
  )

  const grantIds = useSelector((state) => TechGrantSelectors.makeGrantsIds(state, selectedTab))

  const generateGrantLink = async (grantIds: string[]) => {
    try {
      const body = { grantIds }

      const resultAction = await dispatch(GrantsActions.createShareableGrantsAsync(body))

      if (GrantsActions.createShareableGrantsAsync.rejected.match(resultAction)) {
        const errorMessage = resultAction.payload as any
        throw new Error(errorMessage)
      }

      return resultAction.payload as string[]
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /*
    mapping grant id to url for later faster extraction
   { granId : generatedUrl }
  */
  const mapGrantLinksToGrantIds = (grantLinks: string[]) => {
    const grantIdToUrl: { [key: string]: string } = {}

    grantLinks.forEach((link) => {
      const url = new URL(link)
      const id = url.pathname.split('/').pop()

      if (id) {
        grantIdToUrl[id] = link
      }
    })

    return grantIdToUrl
  }

  const exportGrantsToCsv = async () => {
    try {
      setExporting(true)
      if (!grantsToExport) return

      const { rows, columns } = grantsToExport

      const grantLinks = await generateGrantLink(grantIds)
      const mappedIdUrl = mapGrantLinksToGrantIds(grantLinks)

      const _idColumnIndex = columns.indexOf('_id')
      if (_idColumnIndex === -1) {
        throw new Error('_id column not found')
      }

      columns.push('Grant links')
      rows.forEach((row) => {
        const grantId = row[_idColumnIndex]
        const url = mappedIdUrl[grantId] || ''
        row.push(url)
      })

      const builder = new CsvBuilder(
        `GrantMatch_${selectedTab}_${moment().format('YYYY-MM-DD')}.csv`,
      )

      builder.setColumns(columns).addRows(rows).exportFile()

      dispatch(
        SnackbarActions.showSnackbar({
          message: 'CSV file exported!!!',
          options: { variant: 'success' },
        }),
      )
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message || 'CSV file exporting error',
        }),
      )
    } finally {
      setExporting(false)
    }
  }

  return (
    <Button
      variant="contained"
      disableElevation
      disabled={!grantsToExport || exporting}
      startIcon={<ExportIcon />}
      sx={{
        position: 'absolute',
        transform: 'translateY(calc(-100% - 0px))',
        right: 0,
        zIndex: 1,
        width: '170px',
        borderRadius: 2,
        textTransform: 'none',
        padding: '0.5rem 1rem',
        marginBottom: 0,
        [theme.breakpoints.down('md')]: {
          top: '100%',
          transform: 'translateY(calc(100% - 25px))',
        },
      }}
      onClick={exportGrantsToCsv}
    >
      Export as CSV
    </Button>
  )
}
