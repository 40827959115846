import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const LinkExpireIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50.6665 50.6665H67.3332V57.3332H57.3332V67.3332H50.6665V50.6665ZM17.3332 17.3332H0.666504V10.6665H10.6665V0.666504H17.3332V17.3332ZM55.2132 45.7848L50.4988 41.0708L55.2132 36.3568C61.7218 29.8482 61.7218 19.2954 55.2132 12.7866C48.7042 6.2779 38.1515 6.2779 31.6428 12.7866L26.9288 17.5007L22.2147 12.7866L26.9288 8.0726C36.0408 -1.03963 50.8148 -1.03963 59.9272 8.0726C69.0392 17.1848 69.0392 31.9588 59.9272 41.0708L55.2132 45.7848ZM45.7848 55.2132L41.0708 59.9272C31.9588 69.0392 17.1848 69.0392 8.0726 59.9272C-1.03963 50.8148 -1.03963 36.0408 8.0726 26.9288L12.7866 22.2147L17.5007 26.9288L12.7866 31.6428C6.2779 38.1515 6.2779 48.7042 12.7866 55.2132C19.2954 61.7218 29.8482 61.7218 36.3568 55.2132L41.0708 50.4988L45.7848 55.2132ZM43.4278 19.8577L48.1418 24.5717L24.5717 48.1418L19.8577 43.4278L43.4278 19.8577Z"
        fill="#ED1112"
      />
    </SvgIcon>
  )
}
