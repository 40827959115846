import { Box, SxProps } from '@mui/material'
import { FC, useEffect, useRef } from 'react'

interface DropdownProps {
  open: boolean
  onClose: Function
  children: React.ReactNode
}

const menuStyles: SxProps = {
  mt: 1,
  py: 1,
  position: 'absolute',
  width: '100%',
  borderRadius: '0.5rem',
  zIndex: 3,
  background: 'white',
  boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
              rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
}

export const DropdownMenu: FC<DropdownProps> = ({ open, onClose, children }) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        onClose()
      }
    }
    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [onClose])

  return (
    <>
      {open ? (
        <Box component="div" sx={menuStyles} ref={dropdownRef}>
          {children}
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}
