import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const getTechnologies = (state: RootState) => state.technologies.data
const getTechnologiesWithoutPaging = (state: RootState) => state.technologies.data.items || []
const loading = (state: RootState) => state.technologies.loading
const error = (state: RootState) => state.technologies.error
const newTechnology = (state: RootState) => state.technologies.newTechnology

const makeTechnologies = createSelector(getTechnologies, (technologies) => technologies.items || [])
const makeTechnologyNames = createSelector(makeTechnologies, (technologies) => {
  return technologies.map((technology) => ({ name: technology.title, _id: technology._id }))
})

const getTechnologyById = createSelector(
  getTechnologiesWithoutPaging,
  (_: any, id: string) => id,
  (technology, id) => {
    return technology.find((x) => x._id === id)
  },
)

export const TechnologiesSelectors = {
  loading,
  makeTechnologies,
  makeTechnologyNames,
  newTechnology,
  getTechnologyById,
  error,
}
