import { Check } from '@mui/icons-material'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'

interface PasswordRequirementProps {
  password: string
}

const requirements = [
  { key: 'lowercase', label: 'one lowercase character' },
  { key: 'uppercase', label: 'one uppercase character' },
  { key: 'number', label: 'one number' },
  { key: 'special', label: 'one special character' },
  { key: 'minLength', label: '8 characters minimum' },
]

export const PasswordRequirement: FC<PasswordRequirementProps> = ({ password }) => {
  const theme = useTheme()

  const [passwordStatus, setPasswordStatus] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    minLength: false,
    special: false,
  })

  useEffect(() => {
    const newPasswordStatus = {
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[~!@#$%^&*()_+{}[\]:;<>,.?~/\\/-]/.test(password),
      minLength: password.length >= 8,
    }

    setPasswordStatus(newPasswordStatus)
  }, [password])

  return (
    <Box>
      {requirements.map(({ key, label }) => {
        const checked = passwordStatus[key as keyof typeof passwordStatus]

        return (
          <Stack key={key} direction="row" gap={2} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 20,
                height: 20,
                background: checked ? theme.palette.primary.main : theme.palette.grey[200],
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {checked && <Check sx={{ fontSize: '1rem', color: 'white' }} />}
            </Box>
            <Typography
              fontWeight={theme.typography.fontWeightMedium}
              fontSize="0.875rem"
              color="secondary.light"
            >
              {label}
            </Typography>
          </Stack>
        )
      })}
    </Box>
  )
}
