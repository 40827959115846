import { TableSortLabel, Typography } from '@mui/material'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../../../store'
import { SolicitationsActions, SolicitationsSelector } from '../../../../../../store/solicitations'

const SORTABLE_HEADERS = ['Topics', 'Open date', 'Close date']

interface TableHeaderCellProps {
  header: string
}

export const TableHeaderCell: FC<TableHeaderCellProps> = ({ header }) => {
  const dispatch = useDispatch<AppDispatch>()

  const filter = useSelector(SolicitationsSelector.getFilters)

  const { sort } = filter
  const sortedHeader = Object.keys(sort)[0]
  const sortedOrder = sort[sortedHeader as keyof typeof sort]

  const sortDirection = sortedOrder === 1 ? 'asc' : 'desc'

  const isSortable = SORTABLE_HEADERS.includes(header)

  const handlSort = (newSortHeader: string) => {
    const newSortOrder = sortedHeader === newSortHeader ? sortedOrder * -1 : 1
    dispatch(
      SolicitationsActions.setSolicitationsFilter({ sort: { [newSortHeader]: newSortOrder } }),
    )
  }

  return (
    <>
      {isSortable ? (
        <TableSortLabel
          active={header === sortedHeader}
          direction={header === sortedHeader ? sortDirection : 'asc'}
          onClick={() => handlSort(header)}
        >
          <Typography
            fontWeight={700}
            fontSize="0.9rem"
            color="secondary"
            sx={{ cursor: 'pointer' }}
          >
            {header}
          </Typography>
        </TableSortLabel>
      ) : (
        <Typography fontWeight={700} fontSize="0.9rem" color="secondary">
          {header}
        </Typography>
      )}
    </>
  )
}
