import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'

interface NavItemStyledProps extends BoxProps {
  isActive: boolean
}

interface NavTextStyleProps extends TypographyProps {
  isActive: boolean
}

export const NavItemStyle = styled(({ isActive, ...rest }: NavItemStyledProps) => (
  <Box {...rest} />
))<{ isActive: boolean }>(({ theme, isActive }) => ({
  borderBottom: isActive ? `2px solid ${theme.palette.secondary.main}` : '2px solid transparent',
  height: '100%',
  width: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border 0.3s',
  '&:hover': {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    cursor: 'pointer',
  },
}))

export const NavTextStyle = styled(({ isActive, ...rest }: NavTextStyleProps) => (
  <Typography {...rest} />
))<{ isActive: boolean }>(({ theme, isActive }) => ({
  fontWeight: isActive ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium,
  fontSize: '1rem',
  width: '120px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: isActive ? theme.palette.secondary.main : theme.palette.grey[500],
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}))
