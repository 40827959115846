import { FC } from 'react'
import { useSelector } from 'react-redux'
import { GrantsSelector } from '../../../../store'
import { GrantsList, GrantsLoader } from './subComponents'

export const SearchResults: FC = () => {
  const isLoading = useSelector(GrantsSelector.getSubmitting)

  return <>{isLoading ? <GrantsLoader /> : <GrantsList />}</>
}
