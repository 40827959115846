import { Add } from '@mui/icons-material'
import { Box, Button, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../store'
import { CurrentUserActions } from '../../../../../store/currentUser'
import {
  AddCompanyMembersModal,
  CompanyMembersList,
  CompanyMembersTable,
  MemberDeleteAlertModal,
  UpdateMemberRoleModal,
} from './subcomponents'

const addButtonStyles: SxProps = {
  width: '140px',
  fontSize: '0.875rem',
  borderRadius: '8px',
  textTransform: 'none',
  padding: '.5rem 1rem',
}

export const CompanyMembers = () => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()
  const showMembersInListForm = useMediaQuery(theme.breakpoints.down(769))

  const [showMembersModal, setShowMembersModal] = useState(false)
  const [showRoleModal, setShowRoleModal] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [selectedMember, setSelectedMember] = useState({
    memberId: '',
    memberName: '',
    currentRole: '',
  })

  useEffect(() => {
    dispatch(CurrentUserActions.fetchCurrentUserCompanyMembersAsync())
  }, [])

  const handleChangeRole = (memberId: string, currentRole: string) => {
    setSelectedMember({ memberName: '', memberId, currentRole })
    setShowRoleModal(true)
  }

  const handleRemoveUser = (memberId: string, memberName: string, currentRole: string) => {
    setSelectedMember({ memberId, memberName, currentRole })
    setShowDeleteAlert(true)
  }

  return (
    <Box my={4} width="100%">
      <AddCompanyMembersModal open={showMembersModal} setOpen={setShowMembersModal} />
      <UpdateMemberRoleModal
        open={showRoleModal}
        setOpen={setShowRoleModal}
        selectedMember={selectedMember}
      />
      <MemberDeleteAlertModal
        open={showDeleteAlert}
        setOpen={setShowDeleteAlert}
        selectedMember={selectedMember}
      />

      <Box>
        <Typography
          fontSize="1.5rem"
          fontWeight={theme.typography.fontWeightBold}
          color="secondary.main"
          mb={1}
        >
          Members
        </Typography>
        <Typography
          fontSize="1rem"
          fontWeight={theme.typography.fontWeightMedium}
          color="secondary.light"
        >
          Manage your members
        </Typography>
      </Box>

      <Stack
        sx={{
          my: '1rem',
          padding: '1.5rem',
          borderRadius: '8px',
          background: 'white',
          [theme.breakpoints.down('sm')]: {
            p: '1rem',
          },
        }}
        justifyContent="space-between"
      >
        <Stack mb={3} direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            fontSize="1.2rem"
            fontWeight={theme.typography.fontWeightBold}
            color="secondary"
          >
            Members
          </Typography>

          <Button
            variant="contained"
            sx={addButtonStyles}
            disableElevation={true}
            startIcon={<Add sx={{ color: 'white' }} />}
            onClick={() => setShowMembersModal(true)}
          >
            Add member
          </Button>
        </Stack>

        {showMembersInListForm ? (
          <CompanyMembersList
            handleChangeRole={handleChangeRole}
            handleRemoveUser={handleRemoveUser}
          />
        ) : (
          <CompanyMembersTable
            handleChangeRole={handleChangeRole}
            handleRemoveUser={handleRemoveUser}
          />
        )}
      </Stack>
    </Box>
  )
}
