import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { JSX } from 'react/jsx-runtime'

export const GrantDescription: FC<{ description: string | object }> = ({ description }) => {
  const splitDescription = (description: string) => {
    const d = description.split('\n')
    let currEleChildren: JSX.Element[] = []
    const reduced = d.reduce((acc, curr) => {
      const bullet_rgx = /^[\u2022,\u2023,\u25E6,\u2043,\u2219]\s/
      const bulletmatch = curr.match(bullet_rgx)
      if (bulletmatch) {
        const str = curr.replace(bullet_rgx, '')
        currEleChildren.push(<ListItem color="secondary.light">{str}</ListItem>)
      } else {
        if (currEleChildren.length) {
          acc.push(<List>{currEleChildren}</List>)
          currEleChildren = []
        }

        acc.push(
          <Typography
            variant="body1"
            color="secondary.medium"
            sx={{
              width: '100%',
              whiteSpace: 'break-spaces',
              my: '8px',
            }}
          >
            {curr.trim()}
          </Typography>,
        )
      }
      return acc
    }, [] as any)
    return reduced
  }
  if (typeof description === 'object') {
    return (
      <Box my={3}>
        {Object.entries(description).map(([key, value]) => (
          <Stack mb={2} gap={0.5} key={key}>
            <Typography fontSize="1rem" fontWeight={700} color="secondary.main">
              {key}
            </Typography>
            <Typography fontSize="0.875rem" color="secondary.light" whiteSpace="break-spaces">
              {value}
            </Typography>
          </Stack>
        ))}
      </Box>
    )
  } else {
    return <Box my={3}>{splitDescription(description)}</Box>
  }
}
