import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { UsersApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { CurrentUserInitialState } from '../currentUser.slice'

export const updateCurrentUserCompanyMemberRoleAsync = createAsyncThunk(
  'users/company/updateMemberRole',
  async ({ id, role }: { id: string; role: string }, { rejectWithValue }) => {
    try {
      await UsersApi.updateCurrentUserCompanyMemberRole(id, { role })
      return
    } catch (error: any) {
      const normalized = normalizeError(error, 'updating company member role')
      return rejectWithValue(normalized)
    }
  },
)

export const updateCurrentUserCompanyMemberRoleAsyncBuilder = (
  builder: ActionReducerMapBuilder<CurrentUserInitialState>,
) => {
  builder.addCase(updateCurrentUserCompanyMemberRoleAsync.pending, (state) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(updateCurrentUserCompanyMemberRoleAsync.fulfilled, (state, action) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(
    updateCurrentUserCompanyMemberRoleAsync.rejected,
    (state, action: PayloadAction<any>) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    },
  )
}
