import { ThumbDownRounded, ThumbUpRounded } from '@mui/icons-material'
import { Button, Stack, SxProps } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../../../store'
import { GrantMatchActions } from '../../../store/grantMatcher'

const btnStyles: SxProps = {
  flexGrow: 1,
  flexBasis: 0,
  minWidth: 100,
  borderRadius: '8px',
  padding: '0.9rem 0',
  textTransform: 'none',
}

const Likes = [-1, 0, 1]

export const LikeDislikeWidget: FC<{
  like: number | undefined
  refId: string | undefined
}> = ({ like, refId }) => {
  const { id = '' } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { enqueueSnackbar } = useSnackbar()

  const [currentLikedValue, setCurrentLikedValue] = useState(like)

  useEffect(() => {
    setCurrentLikedValue(like)
  }, [like])

  const likeDislikeAction = async (newLikedValue: number, status: string) => {
    if (
      typeof currentLikedValue === 'number' &&
      Likes.includes(currentLikedValue) &&
      typeof refId === 'string'
    ) {
      const bodyPayload = {
        like: 0,
        status: 'recommended',
      }

      if (currentLikedValue !== newLikedValue) {
        bodyPayload.like = newLikedValue
        bodyPayload.status = status
      }

      setCurrentLikedValue(bodyPayload.like)

      const likedAction = await dispatch(
        GrantMatchActions.updateMatchedTechGrantAsync({
          params: { techId: id, refId },
          body: bodyPayload,
        }) as any,
      )

      if (GrantMatchActions.updateMatchedTechGrantAsync.rejected.match(likedAction)) {
        setCurrentLikedValue(like)
      }
    } else {
      enqueueSnackbar('invalid like or grantId', { variant: 'error', autoHideDuration: 3000 })
    }
  }

  return (
    <Stack direction="row" gap={2} flexBasis={0} flexGrow={1} minWidth={220}>
      <Button
        fullWidth
        color="error"
        sx={btnStyles}
        disableElevation={true}
        startIcon={<ThumbDownRounded />}
        onClick={() => likeDislikeAction(-1, 'rejected')}
        variant={currentLikedValue === -1 ? 'contained' : 'outlined'}
      >
        Not relevant
      </Button>
      <Button
        fullWidth
        color="primary"
        sx={btnStyles}
        disableElevation={true}
        startIcon={<ThumbUpRounded />}
        onClick={() => likeDislikeAction(1, 'selected')}
        variant={currentLikedValue === 1 ? 'contained' : 'outlined'}
      >
        Add to tech
      </Button>
    </Stack>
  )
}
