import { InfoOutlined } from '@mui/icons-material'
import { Box, Button, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { CreateTechnologySelectors } from '../../../store/createTechnologyForm'

const TechnologySchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
})

interface FormValues {
  title: string
  description: string
}

interface TechnoloFormProps {
  action: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void | Promise<any>
}

export const TechnologyForm: FC<TechnoloFormProps> = ({ action }) => {
  const theme = useTheme()
  const title = useSelector(CreateTechnologySelectors.getTitle)
  const description = useSelector(CreateTechnologySelectors.getDescription)

  const formik = useFormik({
    initialValues: { title, description },
    validationSchema: TechnologySchema,
    onSubmit: action,
  })
  const { errors, touched, getFieldProps } = formik

  const handleTextFieldFocus = () => {
    const tooltips = document.querySelectorAll('.MuiTooltip-tooltip')
    tooltips.forEach((tooltip) => {
      tooltip.setAttribute('style', 'display: none')
    })
  }

  return (
    <FormikProvider value={formik}>
      <Typography
        variant="h4"
        color="secondary.main"
        fontSize="24px"
        fontWeight={theme.typography.fontWeightBold}
        mt="3rem"
      >
        Add Project
      </Typography>
      <Stack marginTop="1.5rem">
        <Typography component="h5" fontWeight={theme.typography.fontWeightMedium}>
          Briefly describe your project below so that we can quickly match it to open grant
          opportunities. This process will only take a few moments.
        </Typography>
        <Typography fontWeight={theme.typography.fontWeightMedium} marginTop="1rem" component="h5">
          Once we have matched the project, we will automatically match the Project with new,
          relevant opportunities as they become available.
        </Typography>
      </Stack>
      <Form autoComplete="off" noValidate>
        <Box my={6}>
          <Box display="flex" alignItems="center" marginBottom="2rem">
            <Typography fontSize="1.5rem" fontWeight={theme.typography.fontWeightMedium}>
              Title
            </Typography>
            <Tooltip
              title="This is the name you use to track your project. It is not included in the search, so feel free to use any type of internal classifier here."
              placement="top-start"
            >
              <InfoOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
            </Tooltip>
          </Box>
          <TextField
            fullWidth
            variant="standard"
            placeholder="Type your answer here"
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(touched.title && errors.title)}
            {...getFieldProps('title')}
            onFocus={handleTextFieldFocus}
          />
        </Box>

        <Box my={6}>
          <Box display="flex" alignItems="center" marginBottom="2rem">
            <Typography fontSize="1.5rem" fontWeight={theme.typography.fontWeightMedium}>
              Briefly describe your project
            </Typography>
            <Tooltip
              title="This is the basis of our project search. Describe your project in plain english. If you have an abstract or other descriptions handy, feel free to paste them in here."
              placement="top-start"
            >
              <InfoOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
            </Tooltip>
          </Box>
          <TextField
            variant="standard"
            multiline
            maxRows={8}
            fullWidth
            placeholder="Type your answer here"
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(touched.description && errors.description)}
            {...getFieldProps('description')}
            onFocus={handleTextFieldFocus}
          />
        </Box>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          size="medium"
          disabled={Object.keys(errors).length > 0}
          sx={{
            fontSize: '1.2rem',
            fontWeight: 700,
            borderRadius: '0.5rem',
            textTransform: 'capitalize',
            float: 'right',
            maxWidth: '160px',
            height: '60px',
            margin: '2rem 0',
          }}
          type="submit"
        >
          Continue
        </Button>
      </Form>
    </FormikProvider>
  )
}
