import { CameraAltRounded } from '@mui/icons-material'
import { Avatar, Box, Button, IconButton, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../../../../store'
import { AuthActions, AuthSelector } from '../../../../../store/auth'
import { CurrentUserSelector } from '../../../../../store/currentUser'
import { SnackbarActions } from '../../../../../store/snackbar'
import { UploadAvatarModal } from './UploadAvatarModal/UploadAvatarModal'

const buttonStyles = {
  fontSize: '0.875rem',
  fontWeight: 500,
  textTransform: 'capitalize',
  borderRadius: '0.5rem',
  padding: '0.5rem 1rem',
}

const iconButtonStyles = {
  position: 'absolute',
  bottom: '-5px',
  right: '0',
  background: 'white',
  padding: 0.5,
  '&:hover': {
    background: 'white',
  },
}

export const UserInfo = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpen] = useState(false)

  const {
    firstName,
    lastName = '',
    email,
    avatar = '',
  } = useSelector(CurrentUserSelector.userDetail)
  const userInitials = useSelector(CurrentUserSelector.userInitials)
  const userRole = useSelector(AuthSelector.userRole)
  const userRoleInCompany = useSelector(AuthSelector.userCompanyRole)

  const handleRequestResetPassword = async () => {
    const resultAction = await dispatch(AuthActions.requestResetPasswordAsync({ email }))

    if (AuthActions.requestResetPasswordAsync.fulfilled.match(resultAction)) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: 'Email for password reset is sent check your email please',
          options: { variant: 'success' },
        }),
      )
    }
  }

  return (
    <Box>
      <UploadAvatarModal open={open} setOpen={setOpen} />
      <Stack direction="row" alignItems="center" gap={2} my={3}>
        <Box sx={{ position: 'relative', width: 60, height: 60, borderRadius: 30 }}>
          <Avatar sx={{ width: '100%', height: '100%' }} src={avatar}>
            {userInitials}
          </Avatar>
          {avatar && (
            <IconButton sx={iconButtonStyles} size="small" onClick={() => setOpen(true)}>
              <CameraAltRounded color="primary" sx={{ fontSize: '0.8rem' }} />
            </IconButton>
          )}
        </Box>
        <Box>
          <Typography fontSize="1rem" fontWeight={700} color="secondary.main">
            {`${firstName} ${lastName}`}
          </Typography>
          <Typography fontSize="0.875rem" fontWeight={500} color="secondary.light">
            {email}
          </Typography>
        </Box>
      </Stack>
      <Stack direction="row" gap={2}>
        {(userRole === 'ORG_ADMIN' || userRoleInCompany === 'COMPANY_ADMIN') && (
          <Button
            variant="contained"
            sx={buttonStyles}
            onClick={() => navigate('/profile/company')}
            disableElevation
          >
            Company's profile
          </Button>
        )}

        <Button
          sx={{
            background: theme.palette.grey[200],
            color: theme.palette.grey[900],
            ...buttonStyles,
          }}
          disableElevation
          onClick={handleRequestResetPassword}
        >
          Reset Password
        </Button>
      </Stack>
    </Box>
  )
}
