import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { WelcomeHeader } from '../headers'

export const WelcomeLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
      }}
    >
      <WelcomeHeader />
      <Box
        component="div"
        sx={{
          overflowY: 'auto',
          padding: '40px 24px 24px 24px',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}
