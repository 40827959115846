import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { AuthActions, AuthSelector } from '../store/auth' // Import your selector
import decodeToken from '../utils/jwtUtil' // Import the utility function

const withAuth = <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> => {
  return (props: P) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const token = useSelector(AuthSelector.authToken)
    const isAuthenticated = useSelector(AuthSelector.hasToken)

    if (!isAuthenticated) {
      return <Navigate to={'/login'} state={{ from: `${location.pathname}${location.search}` }} />
    }
    const decodedToken: any = decodeToken(token)

    if (decodedToken.exp * 1000 < Date.now()) {
      dispatch(AuthActions.logout())
      return <Navigate to={'/login'} state={{ from: `${location.pathname}${location.search}` }} />
    }

    return <WrappedComponent {...props} />
  }
}

export default withAuth
