import { createSlice } from '@reduxjs/toolkit'

interface QuestionAnswer {
  question: string
  answer: string
}

export interface MetaInitialState {
  usaStates: { name: string; abbreviation: string }[]
  faq: QuestionAnswer[]
}

const usaStates = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
]

const faq = [
  {
    question: 'How long does it take to search for grants?',
    answer:
      'About 3-5 minutes. You first enter a plain text description of your technology/project. Then we parse the most relevant keywords and use that as the search. From there, we ask a series of quick Yes/No style questions to help find your initial batch of grants.',
  },
  {
    question: 'Where does the grant data come from?',
    answer:
      'We get grant data directly from each agency is FOA/NOFO or website. This alleviates our reliance on infrequently updated sources like SBIR.gov.',
  },
  {
    question: 'Do I need to pay for each technology team?',
    answer:
      'No, absolutely not. You only need to pay once for your entire company. From there you can as many technologies/projects as you like. We match grants directly to these, so each project has the most relevant, clear results.',
  },
  {
    question:
      'Will employees have to see all the technologies or projects for our company, even the ones they are not working on?',
    answer:
      'Nope. When you make a technology, your team members and employees will only see the technologies they have been added to. This can be as many or as little as you had like.',
  },
]

const initialState: MetaInitialState = {
  usaStates: usaStates,
  faq: faq,
}

const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {},
})

export const metaActions = {
  ...metaSlice.actions,
}

export const metaReducer = metaSlice.reducer
