import { AddCircleRounded } from '@mui/icons-material'
import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { CurrentUserSelector } from '../../../../store/currentUser'
import { TechnologySelectors } from '../../../../store/technology'
import { AddTechnologyMembersModal } from '../AddTechnologyMemberModal'
import { UserDetailModel } from './UserDetailModel'

const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    borderRadius: '8px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}))

const avatarStyles = {
  width: 32,
  height: 32,
  fontSize: '0.9rem',
  backgroundColor: '#f56a00',
  '&:hover': { cursor: 'pointer' },
}

const ToolTipContent: FC<{ name: string; role: string }> = ({ name = '', role = '' }) => {
  return (
    <Stack alignItems="center">
      <Typography fontSize="0.875rem" fontWeight={700} color="secondary.main">
        {name}
      </Typography>
      <Typography fontSize="0.875rem" fontWeight={500} color="secondary.light">
        {role}
      </Typography>
    </Stack>
  )
}

export const Members = () => {
  const [userId, setUserId] = useState('')
  const [showModel, setShowModel] = useState(false)
  const [showDetailModel, setShowDetailModel] = useState(false)
  const theme = useTheme()

  const { email: currentUserEmail = '' } = useSelector(CurrentUserSelector.userDetail)
  const technologyMembers = useSelector(TechnologySelectors.makeTechnologyMembers)
  const maxAvatarsToShow = technologyMembers.length

  return (
    <Box sx={{ float: { xs: 'left', sm: 'right' } }}>
      <AddTechnologyMembersModal open={showModel} setOpen={setShowModel} />
      <UserDetailModel open={showDetailModel} setOpen={setShowDetailModel} userId={userId} />

      <Stack direction="row" alignItems="center">
        <Typography fontSize="1rem" fontWeight={theme.typography.fontWeightBold} color="secondary">
          Members
        </Typography>
        <IconButton size="small" onClick={() => setShowModel(true)}>
          <AddCircleRounded sx={{ color: 'primary.main' }} />
        </IconButton>
      </Stack>

      <AvatarGroup max={maxAvatarsToShow} sx={{ justifyContent: 'flex-end' }}>
        {technologyMembers &&
          technologyMembers
            .filter((member: any) => member.email !== currentUserEmail)
            .map((member: any) => (
              <StyledToolTip
                arrow
                key={member._id}
                title={<ToolTipContent name={member.firstName} role={member.role} />}
              >
                <Avatar
                  component="div"
                  sx={avatarStyles}
                  src={member.avatar}
                  onClick={() => {
                    setUserId(member._id)
                    setShowDetailModel(true)
                  }}
                >
                  {member.firstName[0]}
                </Avatar>
              </StyledToolTip>
            ))}
      </AvatarGroup>
    </Box>
  )
}
