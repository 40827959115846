import { Link } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EmptyMatchedStateIcon } from '../../../../components/icons'
import { RightSlider } from '../../../../components/sliders'
import { AppDispatch } from '../../../../store'
import { GrantMatchActions } from '../../../../store/grantMatcher'
import { TechGrantsActions, TechGrantSelectors } from '../../../../store/technology'
import { Outcome } from '../../../../types/Grants'
import { EmptyState } from './EmptyState'
import { GrantOutcomeConfirmationModal } from './GrantOutcome'
import { GrantsTable } from './GrantsTable'
import { TechnologyGrant } from './TechnologyGrant'

interface MatchedGrantsProps {
  technologyId: string
}

export const MatchedGrants: React.FC<MatchedGrantsProps> = ({ technologyId }) => {
  const dispatch = useDispatch<AppDispatch>()

  const [showGrantOutomeModal, setShowGrantOutcomeModal] = useState(false)
  const [showGrantDetail, setShowGrantDetail] = useState<boolean>(false)
  const [selectedGrant, setSelectedGrant] = useState({
    topicId: '',
    refId: '',
    grantTitle: '',
    outcome: 'N/A',
  })

  const paging = useSelector(TechGrantSelectors.makeMatchedGrantsPaging)

  const fetchMatchedGrants = useCallback(async () => {
    await dispatch(
      TechGrantsActions.fetchGrantsAsync({
        id: technologyId,
        status: 'recommended',
        options: {
          params: {
            $includes: 'grant,solicitation',
            $limit: paging.limit,
            $skip: paging.skip,
            $sort: `createdAt -1`,
          },
        },
      }),
    )
  }, [paging, technologyId, dispatch])

  useEffect(() => {
    fetchMatchedGrants()
  }, [fetchMatchedGrants])

  const grants = useSelector((state) => TechGrantSelectors.makeGrantsSummary(state, 'recommended'))

  const handlePageUp = async () => {
    dispatch(
      TechGrantsActions.setPaging({ skip: paging.skip + paging.limit, grantType: 'recommended' }),
    )
  }

  const handlePageDown = async () => {
    const nextVal = paging.skip - paging.limit
    dispatch(
      TechGrantsActions.setPaging({ skip: nextVal < 0 ? 0 : nextVal, grantType: 'recommended' }),
    )
  }

  const handleOpenDrawer = (topicId: string) => {
    setSelectedGrant({ ...selectedGrant, topicId })
    setShowGrantDetail(true)
  }

  const handleCloseDrawer = () => {
    setShowGrantDetail(false)
    fetchMatchedGrants()
    dispatch(GrantMatchActions.resetGrantData())
  }

  const handleGrantOutcomeChange = (outcome: Outcome, refId: string, grantTitle: string) => {
    setSelectedGrant({ ...selectedGrant, outcome, refId, grantTitle })
    setShowGrantOutcomeModal(true)
  }

  return (
    <>
      <RightSlider
        open={showGrantDetail}
        setOpen={setShowGrantDetail}
        closeButtonAction={handleCloseDrawer}
      >
        <TechnologyGrant technologyId={technologyId} matchedTopicId={selectedGrant.topicId} />
      </RightSlider>

      <GrantOutcomeConfirmationModal
        cb={fetchMatchedGrants}
        refId={selectedGrant.refId}
        open={showGrantOutomeModal}
        setOpen={setShowGrantOutcomeModal}
        grantTitle={selectedGrant.grantTitle}
        outcome={selectedGrant.outcome as Outcome}
      />

      <GrantsTable
        paging={paging}
        tableData={grants as any}
        pageUpAction={handlePageUp}
        pageDownAction={handlePageDown}
        handleSlider={handleOpenDrawer}
        fetchGrants={fetchMatchedGrants}
        handleGrantOutcomeChange={handleGrantOutcomeChange}
        emptyComponent={
          <EmptyState
            message={
              <>
                You currently don't have any new matched recommendations. Please check back later
                for new matches, or <Link href="/browse">browse</Link> open grants now!
              </>
            }
            icon={EmptyMatchedStateIcon}
          />
        }
      />
    </>
  )
}
