import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { AppDispatch } from '../../../store'
import { EmailVerificationActions, EmailVerificationSelector } from '../../../store/auth'
import { SnackbarActions } from '../../../store/snackbar'

export const ResendEmailVerification = () => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const loading = useSelector(EmailVerificationSelector.isLoading)
  const requestError = useSelector(EmailVerificationSelector.error)

  useEffect(() => {
    if (requestError) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: requestError,
        }),
      )
    }
    return () => {
      dispatch(EmailVerificationActions.reset)
    }
  }, [requestError, dispatch])

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup
      .object()
      .shape({ email: yup.string().email('Invalid Email Address').required('Email is required!') }),
    onSubmit: async ({ email }) => {
      await dispatch(EmailVerificationActions.requestResendEmailAsync({ email }))
    },
  })

  const { errors, touched, handleSubmit, getFieldProps } = formik

  return (
    <Box maxWidth="md" margin="auto">
      <FormikProvider value={formik}>
        <Stack alignItems="center" width="100%">
          <Card sx={{ width: '100%', display: 'flex', p: 3 }}>
            {loading && (
              <Stack width="100%" alignItems="center">
                <CircularProgress size="6rem" />
              </Stack>
            )}

            {!loading && (
              <>
                <Stack mb={2} width="100%">
                  <Box mb={4}>
                    <Typography
                      mb={1}
                      variant="h5"
                      color="secondary"
                      fontWeight={theme.typography.fontWeightBold}
                    >
                      Send email verification request
                    </Typography>

                    <Typography
                      fontSize="1rem"
                      color="secondary.light"
                      fontWeight={theme.typography.fontWeightMedium}
                    >
                      Please enter your email below to send another email verification request.
                    </Typography>
                  </Box>

                  <Form onSubmit={handleSubmit}>
                    <Box mb={4}>
                      <TextField
                        fullWidth
                        required
                        label="Email"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={(touched.email && errors.email) || ''}
                      />
                    </Box>

                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          fontWeight: 500,
                          fontSize: '1rem',
                          textTransform: 'capitalize',
                          padding: '0.5rem 1.375rem',
                        }}
                        disableElevation={true}
                        disabled={!touched.email || 'email' in errors}
                      >
                        Send Email
                      </Button>
                    </Stack>
                  </Form>
                </Stack>
              </>
            )}
          </Card>
        </Stack>
      </FormikProvider>
    </Box>
  )
}
