import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { AuthSelector } from '../../../../store/auth'

export const SubscriptionTrialCountDown = () => {
  // const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(true)
  // const dispatch = useDispatch<AppDispatch>()
  // const userRole = useSelector(AuthSelector.userRole)

  const trialDates: any = useSelector(AuthSelector.trialDates)

  // const handleManageSubscription = async () => {
  //   try {
  //     if (window.gtag) {
  //       window.gtag('event', 'click', {
  //         event_category: 'Button',
  //         event_label: 'Subscribe',
  //       })
  //     }

  //     const resultAction = await dispatch(SubscriptionActions.createStripePortalSessionAsync())

  //     if (SubscriptionActions.createStripePortalSessionAsync.rejected.match(resultAction)) {
  //       const errorMessage = resultAction.payload as any
  //       throw new Error(errorMessage)
  //     }

  //     if (SubscriptionActions.createStripePortalSessionAsync.fulfilled.match(resultAction)) {
  //       const url = resultAction.payload as any
  //       window.open(url, '_blank')
  //     }
  //   } catch (error: any) {
  //     enqueueSnackbar(error.message || 'An error occurred while fetching stripe url', {
  //       variant: 'error',
  //       autoHideDuration: 3000,
  //     })
  //   }
  // }

  const handleCloseClick = () => {
    setIsOpen(false)
  }

  const calculateRemainingDays = () => {
    if (!trialDates) {
      return 0
    }

    const currentDate = new Date()
    const endDate = new Date(trialDates?.trialEndDate * 1000)

    const differenceMs = endDate.getTime() - currentDate.getTime()
    const remainingDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24))
    return remainingDays > 0 ? remainingDays : 0
  }

  const remainingDays = calculateRemainingDays()
  const remainingDaysText = remainingDays === 1 ? 'day' : 'days'

  const formatDate = (date: any) => {
    const month = date.toLocaleString('en-US', { month: 'long' })
    const day = date.getDate().toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${month} ${day}, ${year}`
  }

  const formattedEndDate = trialDates?.trialEndDate
    ? formatDate(new Date(trialDates.trialEndDate * 1000))
    : ''

  return isOpen && trialDates && remainingDays > 0 ? (
    <Box
      display="flex"
      alignItems="center"
      p={1}
      px={4}
      justifyContent="space-between"
      bgcolor="#FFF9E8"
    >
      <Box alignItems="center">
        <Typography variant="body1" color="#8E6F16">
          Your free trial ends on {formattedEndDate}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="body1" fontWeight={700} color="#8E6F16">
          {remainingDays} {remainingDaysText} left
        </Typography>
        {/* {userRole === 'ORG_ADMIN' && (
          <Button
            variant="contained"
            onClick={handleManageSubscription}
            sx={{
              px: 2,
              backgroundColor: '#B76D17',
              textTransform: 'capitalize',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#a36013',
              },
            }}
            disableElevation={true}
          >
            Subscribe
          </Button>
        )} */}

        <IconButton onClick={handleCloseClick}>
          <CloseIcon sx={{ color: '#8E6F16', fontSize: 'medium' }} />
        </IconButton>
      </Box>
    </Box>
  ) : null
}
