import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  createCompanyMembersAsync,
  createCompanyMembersAsyncBuilder,
} from './asyncRequests/createCompanyMemberAsync'

interface ICompany {
  _id: string
  name: string
  address: string
  description?: string
}

interface ITeamMember {
  email: string
}

export enum CompanySteps {
  PersonalInfo,
  ChooseSubscription,
  CompanyInfo,
  InviteTeamMember,
}

export interface CompanyInitialState {
  data: ICompany
  teamMembers: ITeamMember[]
  loading: boolean
  error: string | null
  success: boolean
  stageStatuses: { [key: string]: boolean }
  step: CompanySteps
}

const initialState: CompanyInitialState = {
  data: {} as ICompany,
  teamMembers: [],
  loading: false,
  error: null,
  success: false,
  step: CompanySteps.PersonalInfo,
  stageStatuses: {
    [CompanySteps.PersonalInfo]: false,
    [CompanySteps.ChooseSubscription]: false,
    [CompanySteps.CompanyInfo]: false,
    [CompanySteps.InviteTeamMember]: false,
  },
}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateStep: (state, action: PayloadAction<CompanySteps>) => {
      const currentStep = state.step
      const newStep = action.payload

      state.stageStatuses[currentStep] = true

      state.step = newStep
    },
    incrementStage: (state) => {
      const enumLength = Object.keys(CompanySteps).filter((key) => isNaN(Number(key))).length
      if (state.step < enumLength - 1) {
        state.stageStatuses[state.step] = true
        state.step = state.step + 1
      }
    },
    decrementStage: (state) => {
      if (state.step > 0) {
        state.step = state.step - 1
      }
    },
    resetSteps: (state) => {
      state.step = CompanySteps.PersonalInfo
    },
  },
  extraReducers: (builder) => {
    createCompanyMembersAsyncBuilder(builder)
  },
})

export const CompanyActions = {
  ...companySlice.actions,
  createCompanyMembersAsync,
}

export const CompanyReducer = companySlice.reducer
