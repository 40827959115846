import { Add } from '@mui/icons-material'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const AddTechnologyButton = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  return (
    <Button
      variant="contained"
      disableElevation={true}
      startIcon={<Add sx={{ color: 'white' }} />}
      sx={{
        width: '200px',
        fontSize: '1rem',
        borderRadius: '8px',
        textTransform: 'none',
        padding: '.5rem 1rem',
        [theme.breakpoints.down('sm')]: {
          width: '90px',
        },
      }}
      onClick={() => navigate('/addTechnology')}
    >
      {isSmallScreen ? 'Add' : 'Add project'}
    </Button>
  )
}
