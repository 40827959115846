import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { AppDispatch } from '../../../../../../../store'
import { CurrentUserActions } from '../../../../../../../store/currentUser'
import { SnackbarActions } from '../../../../../../../store/snackbar'

interface UpdateMemberRoleFormProps {
  handleModalClose: () => void
  selectedMember: { memberId: string; memberName: string; currentRole: string }
}

const UpdateMemberRoleFormSchema = Yup.object().shape({
  role: Yup.string().required('role is required'),
})

export const UpdateMemberRoleForm: FC<UpdateMemberRoleFormProps> = ({
  handleModalClose,
  selectedMember,
}) => {
  const dispatch = useDispatch<AppDispatch>()

  const formik = useFormik({
    initialValues: {
      role: selectedMember.currentRole || '',
    },
    enableReinitialize: true,
    validationSchema: UpdateMemberRoleFormSchema,
    onSubmit: async ({ role }) => {
      try {
        const roleAction = await dispatch(
          CurrentUserActions.updateCurrentUserCompanyMemberRoleAsync({
            id: selectedMember.memberId,
            role,
          }),
        )

        if (
          CurrentUserActions.updateCurrentUserCompanyMemberRoleAsync.fulfilled.match(roleAction)
        ) {
          dispatch(CurrentUserActions.fetchCurrentUserCompanyMembersAsync())
          dispatch(
            SnackbarActions.showSnackbar({
              message: 'user role has been updated',
              options: { variant: 'success' },
            }),
          )
          handleModalClose()
        }
      } catch (error: any) {
        dispatch(SnackbarActions.showSnackbar({ message: error.message }))
      }
    },
  })

  const { errors, touched, handleSubmit, getFieldProps } = formik

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="role-label">User's role</InputLabel>
            <Select
              labelId="role-label"
              label="User's role"
              {...getFieldProps('role')}
              error={Boolean(touched.role && errors.role)}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="member">Member</MenuItem>
            </Select>
          </FormControl>

          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              variant="contained"
              disableElevation={true}
              type="submit"
              sx={{
                height: '40px',
                fontSize: '14px',
                textTransform: 'none',
                borderRadius: '0.5rem',
              }}
            >
              Save changes
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  )
}
