import { Box, Stack, Typography, useTheme } from '@mui/material'
import { FC, ReactElement } from 'react'

interface ResetPasswordResponseLayoutProps {
  logo: ReactElement
  heading: string
  subHeading: string
  actionButton: ReactElement
}

export const ResetPasswordResponseLayout: FC<ResetPasswordResponseLayoutProps> = ({
  logo,
  heading,
  subHeading,
  actionButton,
}) => {
  const theme = useTheme()
  return (
    <Stack alignItems="center" py={4}>
      <Box
        sx={{
          maxWidth: '400px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {logo}
        <Typography
          color="secondary"
          fontSize="1.5rem"
          fontWeight={theme.typography.fontWeightBold}
        >
          {heading}
        </Typography>
        <Typography
          color="secondary.light"
          fontSize="1rem"
          fontWeight={theme.typography.fontWeightMedium}
          textAlign="center"
        >
          {subHeading}
        </Typography>
        {actionButton}
      </Box>
    </Stack>
  )
}
