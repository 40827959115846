import { createSelector } from 'reselect'
import { RootState } from '../store'

const loading = (state: RootState) => state.companyPreferences.loading
const error = (state: RootState) => state.companyPreferences.error
const getCompanyPreferences = (state: RootState) => state.companyPreferences.data

const makeGrantsPreferences = createSelector(getCompanyPreferences, (companyPreferences) => {
  if (companyPreferences && companyPreferences.grants) {
    return companyPreferences.grants
  }
  return null
})

const makeGrantsProgressStatuses = createSelector(makeGrantsPreferences, (grantPreferences) => {
  if (grantPreferences) {
    return grantPreferences.progressStatuses || []
  }
  return []
})

export const companyPreferencesSelector = {
  error,
  loading,
  makeGrantsPreferences,
  makeGrantsProgressStatuses,
}
