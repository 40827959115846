import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Table } from '../../../../../../components/table'
import { SolicitationsSelector } from '../../../../../../store/solicitations'
import { AgencyTag } from '../AgencyTag'
import { TableHeaderCell } from './TableHeaderCell'

const Table_Headers = ['Solicitation', 'Topics', 'Agency', 'Program', 'Open date', 'Close date']

export const SolicitationsTable = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const solicitations = useSelector(SolicitationsSelector.makeSolicitations)

  const handleClick = (id: any) => {
    navigate(`/solicitations/${id}`)
  }

  const headerCells = Table_Headers.map((header, index) => ({
    content: <TableHeaderCell key={index} header={header} />,
    cellProps: {
      sx: {
        display:
          isSmallScreen &&
          (header === 'Agency' ||
            header === 'Program' ||
            header === 'Open date' ||
            header === 'Close date')
            ? 'none'
            : 'table-cell',
      },
    },
  }))

  const rows =
    solicitations &&
    solicitations.map((rowCell) => {
      return {
        rowProps: {
          onClick: () => handleClick(rowCell._id),
          sx: { '&:hover': { cursor: 'pointer', background: theme.palette.grey[100] } },
        },
        cells: [
          {
            content: (
              <Typography
                color="secondary"
                fontWeight={theme.typography.fontWeightBold}
                fontSize="0.875rem"
              >
                {rowCell.solicitationTitle}
              </Typography>
            ),
            cellProps: {
              sx: { maxWidth: 400 },
            },
          },

          {
            content: (
              <Typography
                color="secondary.light"
                fontWeight={theme.typography.fontWeightMedium}
                fontSize="0.875rem"
              >
                {rowCell.topicCount}
              </Typography>
            ),
          },
          {
            content: (
              <AgencyTag
                value={rowCell.agency.acronym}
                textColor={rowCell.agency.textColor}
                backgroundColor={rowCell.agency.backgroundColor}
              />
            ),
            cellProps: {
              sx: { display: { xs: 'none', sm: 'table-cell' } },
            },
          },
          {
            content: (
              <Typography
                color="secondary.light"
                fontWeight={theme.typography.fontWeightMedium}
                fontSize="0.875rem"
              >
                {rowCell.program}
              </Typography>
            ),
            cellProps: {
              sx: { display: { xs: 'none', sm: 'table-cell' } },
            },
          },
          {
            content: (
              <Typography
                color="secondary.light"
                fontWeight={theme.typography.fontWeightMedium}
                fontSize="0.875rem"
              >
                {rowCell.openDate}
              </Typography>
            ),
            cellProps: {
              sx: { display: { xs: 'none', sm: 'table-cell' } },
            },
          },
          {
            content: (
              <Typography
                color="secondary.light"
                fontWeight={theme.typography.fontWeightMedium}
                fontSize="0.875rem"
              >
                {rowCell.closeDate}
              </Typography>
            ),
            cellProps: {
              sx: { display: { xs: 'none', sm: 'table-cell' } },
            },
          },
        ],
      }
    })

  return (
    <>
      {!!rows.length && (
        <Table
          containerProps={{
            sx: {
              boxShadow: 'none',
              background: 'transparent',
            },
          }}
          head={{
            containerProps: {
              sx: { borderRadius: '8px', background: theme.palette.grey[200], width: '100%' },
            },
            rows: [{ cells: headerCells || [] }],
          }}
          body={{ rows }}
        />
      )}
    </>
  )
}
