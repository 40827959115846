import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AuthApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { AuthInitialState } from '../userAuth.slice'

interface MicrosoftAuthCodePayload {
  authCode: string
}

export const microsoftAuthAsync = createAsyncThunk(
  'microsoft/tokens',
  async ({ authCode }: MicrosoftAuthCodePayload, { rejectWithValue }) => {
    try {
      const req = await AuthApi.microsoftAuthWithToken({ authCode })
      const response = (req as AxiosResponse).data

      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching microsoft token')
      return rejectWithValue(normalized)
    }
  },
)

export const microsoftAuthAsyncBuilder = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  builder
    .addCase(microsoftAuthAsync.pending, (state, action) => {
      state.loading = true
      state.success = false
      state.error = ''
    })
    .addCase(microsoftAuthAsync.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
      state.success = true
    })
    .addCase(microsoftAuthAsync.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
}
