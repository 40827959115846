import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { TechnologiesApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { MatchedGrantStatus } from '../../../types/Grants'
import { IPagedMatchedGrantsResponse } from '../../../types/Requests'
import { normalizeError } from '../../../utils'
import { TechGrantsInitialState } from '../grants.slice'

export const fetchGrantsAsync = createAsyncThunk(
  'technology/matchedgrants/fetch',
  async (
    { id, status, options }: { id: string; status: MatchedGrantStatus; options?: RequestConfig },
    { rejectWithValue },
  ) => {
    try {
      const $filter = JSON.stringify({ status })

      if (options) {
        options.params = { ...options?.params, $filter }
      }

      const res = await TechnologiesApi.fetchMatchedGrants(id, options)
      const data = (res as AxiosResponse<any, any>).data

      return { data, status }
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching matched grants')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchTechGrantsBuilder = (
  builder: ActionReducerMapBuilder<TechGrantsInitialState>,
) => {
  builder.addCase(fetchGrantsAsync.pending, (state, action) => {
    state.loading = true
    state.error = ''
  })
  builder.addCase(
    fetchGrantsAsync.fulfilled,
    (
      state,
      action: PayloadAction<{ data: IPagedMatchedGrantsResponse; status: MatchedGrantStatus }>,
    ) => {
      const { data, status } = action.payload
      state.data[status].grants = (data as any).items
      state.data[status].paging.totalRows = data.totalRows
      state.data[status].paging.rows = data.rows
      state.loading = false
    },
  )
  builder.addCase(fetchGrantsAsync.rejected, (state, action: PayloadAction<any>) => {
    state.error = action.payload
    state.loading = false
  })
}
