import CancelIcon from '@mui/icons-material/Cancel'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CompanyActions } from '../../../../store/company'

export const StripeCheckoutError = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleBack = () => {
    dispatch(CompanyActions.updateStep(1))
    navigate('/steps')
  }
  return (
    <Box textAlign="center" mt={4}>
      <CancelIcon sx={{ fontSize: 80, color: 'error.main' }} />
      <Typography variant="h5" fontWeight={theme.typography.fontWeightBold} mt={2}>
        We are sorry, an error occurred.
      </Typography>
      <Typography variant="body1" mt={1}>
        We are unable to process the payment, please make sure your card is funded and try again.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          mt: 2,
          px: 2,
          textTransform: 'none',
          fontWeight: theme.typography.fontWeightBold,
          borderRadius: '12px',
        }}
        onClick={handleBack}
      >
        Retry Payment
      </Button>
    </Box>
  )
}
