import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { SolicitationsApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { ISolicitation } from '../../../types/Grants'
import { normalizeError } from '../../../utils'
import { SolicitationsInitialState } from '../solicitations.slice'

export const fetchSolicitationAsync = createAsyncThunk(
  'solicitations/fetchSolicitationAsync',
  async ({ id, options }: { id: string; options?: RequestConfig }, { rejectWithValue }) => {
    try {
      const res = await SolicitationsApi.fetchSolicitation(id, {
        params: {
          $includes: 'grant,agency',
        },
        ...options,
      })
      return (res as AxiosResponse).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching solicitation')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchSolicitationAsyncBuilder = (
  builder: ActionReducerMapBuilder<SolicitationsInitialState>,
) => {
  builder.addCase(fetchSolicitationAsync.pending, (state) => {
    state.solicitation.error = ''
    state.solicitation.fetching = true
  })
  builder.addCase(
    fetchSolicitationAsync.fulfilled,
    (state, action: PayloadAction<ISolicitation>) => {
      state.solicitation.fetching = false
      state.solicitation.data = action.payload
    },
  )
  builder.addCase(fetchSolicitationAsync.rejected, (state, action: PayloadAction<any>) => {
    state.solicitation.fetching = false
    state.solicitation.error = action.payload
  })
}
