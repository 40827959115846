const GREY = {
  0: '#FFF',
  100: '#F8FAFC',
  200: '#F1F5F9',
  300: '#E2E8F0',
  400: '#C4CDD5',
  500: '#94A3B8',
  600: '#475569',
  700: '#334155',
  800: '#1E293B',
  900: '#100A37',
}

const YELLOW = {
  50: '#FFF9E8',
  100: '#FFF2CD',
  200: '#FFFECB4',
  300: '#FFE598',
  400: '#FFD864',
  500: '#FFCB32',
  600: '#FFBF05',
  700: '#BF8F01',
  800: '#805F01',
  900: '#403003',
}

const RED = {
  50: '#FEF5F6',
  100: '#FFEAEB',
  200: '#FBC1C2',
  300: '#F89897',
  400: '#F46E6F',
  500: '#F24546',
  600: '#F03031',
  700: '#ED1112',
  800: '#BD0E0F',
  900: '#7E090A',
}

const GREEN = {
  50: '#E6F8EF',
  100: '#CCF1DE',
  200: '#9DE5BE',
  300: '#99E3BA',
  400: '#67D599',
  500: '#35C778',
  600: '#08C15B',
  700: '#028B3D',
  800: '#015D2B',
  900: '#012E16',
}

const BLUE = {
  50: '#EDEFFD',
  100: '#C9CEF8',
  200: '#A4AEF3',
  300: '#808EEF',
  400: '#5C6DEA',
  500: '#5C6DEB',
  600: '#4A5DE8',
  700: '#172AB5',
  800: '#132397',
  900: '#0F1C79',
}

const PRIMARY = {
  light: '#CCEEDC',
  main: '#00A94F',
  contrastText: GREY[0],
}

const SECONDARY = {
  lighter: GREY[300],
  light: GREY[600],
  main: GREY[900],
  contrastText: GREY[0],
}

const INFO = {
  lighter: BLUE[50],
  light: BLUE[300],
  main: BLUE[600],
  dark: BLUE[800],
  darker: BLUE[900],
  contrastText: GREY[0],
}

const SUCCESS = {
  lighter: GREEN[50],
  light: GREEN[300],
  main: GREEN[600],
  dark: GREEN[800],
  darker: GREEN[900],
  contrastText: GREY[0],
}

const WARNING = {
  lighter: YELLOW[50],
  light: YELLOW[300],
  main: YELLOW[600],
  dark: YELLOW[800],
  darker: YELLOW[900],
  contrastText: GREY[0],
}

const ERROR = {
  lighter: RED[50],
  light: RED[300],
  main: RED[600],
  dark: RED[800],
  darker: RED[900],
  contrastText: GREY[0],
}

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  yellow: YELLOW,
  red: RED,
  green: GREEN,
  blue: BLUE,
  divider: GREY[300],
  background: { paper: GREY[0], default: GREY[100], neutral: GREY[200] },
}

export default palette
