import { Stack, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { EmptyStateIcon2 } from '../../../components/icons'
import { AddTechnologyButton } from './AddTechnologyButton'

export const EmptyComponent = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <Stack alignItems="center" justifyContent="center" gap={1}>
      <img src={EmptyStateIcon2} alt="empty-icon" width={200} />
      <Typography
        color="secondary.light"
        fontSize="1rem"
        fontWeight={theme.typography.fontWeightMedium}
        textAlign="center"
        mt={2}
      >
        Oops! You don’t have any technology at the moment.
      </Typography>
      <Stack direction="row" alignItems="center" gap={1} mb={1}>
        <Typography
          component="div"
          fontSize="1rem"
          color="primary"
          fontWeight={theme.typography.fontWeightMedium}
          borderBottom={`1px solid ${theme.palette.primary.main}`}
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/addTechnology')}
        >
          add project
        </Typography>
        <Typography color="secondary.light" fontSize="1rem" fontWeight={500} textAlign="center">
          to get started
        </Typography>
      </Stack>

      <AddTechnologyButton />
    </Stack>
  )
}
