import { LinkOffRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ResetPasswordResponseLayout } from './ResetPasswordResponseLayout'

export const ResetPasswordError = () => {
  const navigate = useNavigate()
  const handleRequestLinkClick = () => {
    navigate('/requestresetpassword')
  }
  return (
    <ResetPasswordResponseLayout
      logo={<LinkOffRounded sx={{ color: 'secondary.light', fontSize: '8rem' }} />}
      heading="Link Expired"
      subHeading="The reset password link is expired. You can request for another link"
      actionButton={
        <Button
          variant="contained"
          sx={{
            fontSize: '1rem',
            textTransform: 'capitalize',
            padding: '0.5rem 1.375rem',
            fontWeight: 500,
            minWidth: '100px',
            mt: 1,
          }}
          disableElevation
          onClick={handleRequestLinkClick}
        >
          Request new link
        </Button>
      }
    />
  )
}
