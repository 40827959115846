import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const AgencyIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.75 24.5C18.75 18.9863 23.2363 14.5 28.75 14.5C31.1144 14.5 33.2862 15.3294 35 16.7063V10.75C35 9.36937 33.8806 8.25 32.5 8.25H5C3.61937 8.25 2.5 9.36937 2.5 10.75V30.75C2.5 32.1306 3.61937 33.25 5 33.25H23.9375C20.8494 31.5488 18.75 28.2688 18.75 24.5Z"
        fill="#FA6450"
      />
      <path
        d="M5 30.75V10.75C5 9.36937 6.11937 8.25 7.5 8.25H5C3.61937 8.25 2.5 9.36937 2.5 10.75V30.75C2.5 32.1306 3.61937 33.25 5 33.25H7.5C6.11937 33.25 5 32.1306 5 30.75Z"
        fill="#DC4632"
      />
      <path
        d="M28.75 14.5C31.1144 14.5 33.2862 15.3294 35 16.7063V10.75C35 9.36937 33.8806 8.25 32.5 8.25H5C3.61937 8.25 2.5 9.36937 2.5 10.75V17C2.5 20.4519 5.29813 23.25 8.75 23.25H18.8369C19.455 18.325 23.66 14.5 28.75 14.5Z"
        fill="#BE2814"
      />
      <path
        d="M12.5 4.5V8.25H15V5.75H22.5V8.25H25V4.5C25 3.80937 24.4406 3.25 23.75 3.25H13.75C13.0594 3.25 12.5 3.80937 12.5 4.5Z"
        fill="#505050"
      />
      <path
        d="M15 4.5C15 3.80937 15.5594 3.25 16.25 3.25H13.75C13.0594 3.25 12.5 3.80937 12.5 4.5V8.25H15V5.75V4.5Z"
        fill="#3C3C3C"
      />
      <path d="M7.5 20.75H10V25.75H7.5V20.75Z" fill="#FAB400" />
      <path
        d="M28.75 38.25C29.4406 38.25 30 37.6906 30 37V32H27.5V37C27.5 37.6906 28.0594 38.25 28.75 38.25Z"
        fill="#505050"
      />
      <path
        d="M28.75 37V32H27.5V37C27.5 37.6906 28.0594 38.25 28.75 38.25C28.9787 38.25 29.1906 38.1837 29.375 38.0769C29.0031 37.86 28.75 37.4612 28.75 37Z"
        fill="#3C3C3C"
      />
      <path d="M27.5 34.5H30V35.75H27.5V34.5Z" fill="#FAB400" />
      <path d="M27.5 34.5H28.75V35.75H27.5V34.5Z" fill="#DC9600" />
      <path
        d="M28.75 33.25C33.5825 33.25 37.5 29.3325 37.5 24.5C37.5 19.6675 33.5825 15.75 28.75 15.75C23.9175 15.75 20 19.6675 20 24.5C20 29.3325 23.9175 33.25 28.75 33.25Z"
        fill="#505050"
      />
      <path
        d="M21.25 24.5C21.25 19.8863 24.8419 16.1044 29.375 15.7819C29.1675 15.7669 28.9612 15.75 28.75 15.75C23.925 15.75 20 19.675 20 24.5C20 29.325 23.925 33.25 28.75 33.25C28.9612 33.25 29.1675 33.2331 29.375 33.2181C24.8419 32.8956 21.25 29.1137 21.25 24.5Z"
        fill="#3C3C3C"
      />
      <path
        d="M28.75 32C24.6081 32 21.25 28.6419 21.25 24.5C21.25 20.3581 24.6081 17 28.75 17C32.8919 17 36.25 20.3581 36.25 24.5C36.25 28.6419 32.8919 32 28.75 32Z"
        fill="#F0F0F0"
      />
      <path
        d="M32.5 30.75C32.5 28.6788 30.8212 27 28.75 27C26.6788 27 25 28.6788 25 30.75V30.9881C26.1038 31.6281 27.3825 32 28.75 32C30.1175 32 31.3962 31.6281 32.5 30.9881V30.75Z"
        fill="#64C8F0"
      />
      <path
        d="M28.75 27C26.6788 27 25 28.6788 25 30.75V30.9881C25.7594 31.4281 26.6019 31.7362 27.5 31.8875V30.75C27.5 29.1181 28.5444 27.7331 30 27.2181C29.6088 27.0794 29.1888 27 28.75 27Z"
        fill="#46AAD2"
      />
      <path
        d="M28.75 27C26.6788 27 25 25.3212 25 23.25C25 21.1788 26.6788 19.5 28.75 19.5C30.8212 19.5 32.5 21.1788 32.5 23.25C32.5 25.3212 30.8212 27 28.75 27Z"
        fill="#F0C8A0"
      />
      <path
        d="M25 23.25C25 21.1788 26.6788 19.5 28.75 19.5C30.8212 19.5 32.5 21.1788 32.5 23.25H25Z"
        fill="#FAB400"
      />
      <path
        d="M27.5 23.25C27.5 21.6181 28.5444 20.2331 30 19.7181C29.6088 19.5794 29.1888 19.5 28.75 19.5C26.6788 19.5 25 21.1788 25 23.25C25 25.3212 26.6788 27 28.75 27C29.1888 27 29.6088 26.9206 30 26.7819C28.5444 26.2669 27.5 24.8819 27.5 23.25Z"
        fill="#D2AA82"
      />
      <path
        d="M30 19.7181C29.6088 19.5794 29.1888 19.5 28.75 19.5C26.6788 19.5 25 21.1788 25 23.25H27.5C27.5 21.6181 28.5444 20.2331 30 19.7181Z"
        fill="#DC9600"
      />
    </SvgIcon>
  )
}
