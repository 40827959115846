import * as yup from 'yup'

export const PasswordValidationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%*^?&+=-])[A-Za-z\d~!@#$%*^?&+=-]{8,}$/,
      'Password at least include one uppercase letter, one lowercase, one number, and one special character (~!@#$%*^&+=)',
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('newPassword'), ''], 'Password must match'),
})
