import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { UsersApi } from '../../../services'
import { ICompanyPreferences } from '../../../types/CurrentUser'
import { normalizeError } from '../../../utils'
import { CompanyPreferencesInitialState } from '../companyPreferences.slice'

export const fetchCompanyPreferencesAsync = createAsyncThunk(
  'company/preferences',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UsersApi.fetchCompanyPreferences()
      return (response as AxiosResponse<ICompanyPreferences>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching company preferences')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchCompanyPreferencesAsyncBuilder = (
  builder: ActionReducerMapBuilder<CompanyPreferencesInitialState>,
) => {
  builder.addCase(fetchCompanyPreferencesAsync.pending, (state) => {
    state.loading = true
    state.error = null
  })
  builder.addCase(
    fetchCompanyPreferencesAsync.fulfilled,
    (state, action: PayloadAction<ICompanyPreferences>) => {
      state.loading = false
      state.data = action.payload
    },
  )
  builder.addCase(fetchCompanyPreferencesAsync.rejected, (state, action) => {
    state.loading = false
    state.error = action.payload as string
  })
}
