import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const agencies = (state: RootState) => state.agencies.data || []
const loading = (state: RootState) => state.agencies.loading
const error = (state: RootState) => state.agencies.error

const getDefaultAgency = (state: RootState) => ({
  title: 'N/A',
  acronym: 'N/A',
  backgroundColor: '#e0e0e0',
  textColor: '#424242',
})

const makeAgencies = createSelector(agencies, (agencies) => agencies)
const makeAgenciesDetail = createSelector(
  agencies,
  (_: any, agencyTitle: string | string[]) => agencyTitle,
  (agencies, agencyTitle) => {
    const agencyTitles: any[] = Array.isArray(agencyTitle) ? agencyTitle : [agencyTitle]

    const agencyDetails = agencyTitles.reduce((_agencies, agencyTitle) => {
      const defaultAgency = {
        title: agencyTitle,
        acronym: 'N/A',
        backgroundColor: '#e0e0e0',
        textColor: '#424242',
      }

      const agency = agencies.find((a: any) => a.title === agencyTitle) || defaultAgency

      return { ..._agencies, [agencyTitle]: agency }
    }, {})

    return agencyDetails
  },
)

const makeUniqueAgenciesAcronym = createSelector(agencies, (_agencies) => {
  if (!_agencies) return []

  const acronyms = _agencies.reduce((acc: string[], curr) => {
    const exists = acc.some((acronym) => acronym === curr.acronym)
    if (!exists) {
      acc.push(curr.acronym)
    }
    return acc
  }, [])

  return acronyms
})

export const AgenciesSelectors = {
  agencies,
  loading,
  error,
  getDefaultAgency,
  makeAgencies,
  makeAgenciesDetail,
  makeUniqueAgenciesAcronym,
}
