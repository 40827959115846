import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { TechnologiesApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { TechnologiesInitialState } from '../technologies.slice'

export const createTechnologyGrantAsync = createAsyncThunk(
  'technologies/createGrant',
  async ({ techId, body }: { techId: string; body: any }, { rejectWithValue }) => {
    try {
      const req = await TechnologiesApi.createTechnologyGrant(techId, body)
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'creating technology grant')
      return rejectWithValue(normalized)
    }
  },
)

export const createTechnologyGrantAsyncBuilder = (
  builder: ActionReducerMapBuilder<TechnologiesInitialState>,
) => {
  builder.addCase(createTechnologyGrantAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(createTechnologyGrantAsync.fulfilled, (state, action) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(createTechnologyGrantAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
