import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { TechnologiesApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { ITechnology, TechnologiesInitialState } from '../technologies.slice'

interface IAddTechnologyBody {
  title: string
  description: string
  keywords?: string[]
  selectedKeywords?: string[]
}

export const addTechnologyAsync = createAsyncThunk(
  'user/technologies/addTechnology',
  async (
    { title, description, keywords, selectedKeywords }: IAddTechnologyBody,
    { rejectWithValue },
  ) => {
    try {
      const res = await TechnologiesApi.createTechnology({
        title,
        description,
        keywords,
        selectedKeywords,
      })
      return (res as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'adding technology')
      return rejectWithValue(normalized)
    }
  },
)

export const addTechnologyAsyncBuilder = (
  builder: ActionReducerMapBuilder<TechnologiesInitialState>,
) => {
  builder.addCase(addTechnologyAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(addTechnologyAsync.fulfilled, (state, action: PayloadAction<ITechnology>) => {
    state.loading = false
    state.success = true
    state.newTechnology = action.payload
  })
  builder.addCase(addTechnologyAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
