import { DeleteRounded, ModeEditRounded, MoreVertRounded } from '@mui/icons-material'
import {
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteTechnologyAlertBox } from './DeleteTechnologyAlertBox'

interface TechnologyCardProps {
  technologyId: string
  title: string
  status: boolean
  totalGrants: number
  cb: Function
}

const containerStyles = {
  borderRadius: '.5rem',
  padding: '1rem',
  '&:hover': {
    cursor: 'pointer',
    boxShadow: '0px 1px 5px 0px rgba(28,28,28,0.2)',
  },
}

const menuStyles = {
  '& .MuiPaper-root': {
    mr: 1,
    minWidth: 200,
    maxWidth: 400,
    borderRadius: '0.5rem',
    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
                 rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
  },
}

const menuItemStyles: SxProps = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
}

export const TechnologyCard: FC<TechnologyCardProps> = ({
  title,
  status,
  totalGrants,
  technologyId,
  cb,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <DeleteTechnologyAlertBox
        open={showDeleteAlert}
        setOpen={setShowDeleteAlert}
        technologyId={technologyId}
        cb={cb}
      />
      <Paper
        elevation={0}
        component="div"
        sx={containerStyles}
        onClick={(e) => {
          !open && navigate(`/technologies/${technologyId}`)
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={0.5}
          mb={0.8}
        >
          <Typography
            variant="h5"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            color="secondary"
            fontWeight={700}
            fontSize="1rem"
          >
            <Box component="span">{title}</Box>
          </Typography>

          <Box>
            <IconButton onClick={handleClick} color="secondary" data-testid="menu-button">
              <MoreVertRounded />
            </IconButton>

            <Menu
              open={open}
              elevation={0}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={menuStyles}
            >
              <MenuItem disableRipple sx={menuItemStyles}>
                <ModeEditRounded fontSize="small" />
                <Typography color="secondary.light" fontWeight={500} fontSize="0.875rem">
                  Edit
                </Typography>
              </MenuItem>
              <MenuItem
                disableRipple
                sx={menuItemStyles}
                onClick={() => {
                  handleClose()
                  setShowDeleteAlert(true)
                }}
                data-testid="delete-item"
              >
                <DeleteRounded color="error" fontSize="small" />
                <Typography color="error" fontWeight={500} fontSize="0.875rem">
                  Delete technology
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="body2" color={theme.palette.grey[700]} mb={0.6}>
              Number of grants
            </Typography>
            <Typography variant="h5" color="secondary" fontSize="1rem" fontWeight={700}>
              {totalGrants}
            </Typography>
          </Box>

          <Chip
            color="primary"
            variant="outlined"
            sx={{
              my: '.2rem',
              padding: '8px 4px',
              borderRadius: '24px',
              alignSelf: 'flex-end',
              border: 'transparent',
              backgroundColor: status ? 'primary.light' : 'error.light',
            }}
            label={
              <Typography
                variant="body1"
                sx={{ color: status ? 'primary.main' : 'error.main', fontSize: '0.875rem' }}
              >
                {status ? 'Active' : 'Complete'}
              </Typography>
            }
          />
        </Stack>
      </Paper>
    </>
  )
}
