import { FC } from 'react'
import { CustomModal } from '../../../../../../components/customModal'
import { UploadAvatarForm } from './UploadAvatarForm'

interface UploadAvatarModalProps {
  open: boolean
  setOpen: Function
}

export const UploadAvatarModal: FC<UploadAvatarModalProps> = ({ open, setOpen }) => {
  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title="Edit avatar"
      closeButtonAction={() => setOpen(false)}
    >
      <UploadAvatarForm setOpen={setOpen} />
    </CustomModal>
  )
}
