import { ArrowDropDownRounded, CloseRounded } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, GrantsActions } from '../../../../../store'
import { AgenciesActions, AgenciesSelectors } from '../../../../../store/agencies'
import { SolicitationsActions } from '../../../../../store/solicitations'
import { filterContainerStyles, menuStyles } from './searchFilters.styles'

interface AgencyFilterProps {
  selectedAgencies: string[]
}

export const AgencyFilter: FC<AgencyFilterProps> = ({ selectedAgencies }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filteredAgencies, setFilteredAgencies] = useState<string[]>(selectedAgencies)

  const agenciesAcronyms = useSelector(AgenciesSelectors.makeUniqueAgenciesAcronym)

  const open = Boolean(anchorEl)

  useEffect(() => {
    dispatch(AgenciesActions.fetchAgenciesAsync())
  }, [dispatch])

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    dispatch(GrantsActions.setFilters({ agencies: filteredAgencies }))
    dispatch(GrantsActions.resetPaging())
    dispatch(SolicitationsActions.resetSolicitationsPaging())
  }

  const handleFilterChange = (acronym: string) => {
    const isSelected = filteredAgencies.includes(acronym)

    if (!isSelected) {
      setFilteredAgencies([...filteredAgencies, acronym])
    } else {
      setFilteredAgencies(filteredAgencies.filter((item) => item !== acronym))
    }
  }

  const handleResetFilters = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setFilteredAgencies([])
    dispatch(GrantsActions.setFilters({ agencies: [] }))
  }

  const filterTitle = (
    <>
      {!filteredAgencies.length ? (
        <>
          <Typography color="secondary" fontWeight={500} fontSize="1rem" ml={1}>
            All Agencies
          </Typography>

          <ArrowDropDownRounded sx={{ fontSize: 28, color: 'secondary' }} />
        </>
      ) : (
        <>
          <Typography color="secondary" fontWeight={500} fontSize="1rem" mx={1}>
            {filteredAgencies.length}
            {filteredAgencies.length > 1 ? ' agencies' : ' agency'}
          </Typography>
          <IconButton onClick={handleResetFilters} sx={{ p: 0.1 }}>
            <CloseRounded sx={{ fontSize: 22, color: 'secondary.light' }} />
          </IconButton>
        </>
      )}
    </>
  )

  return (
    <Box>
      <Box
        component="div"
        sx={{
          ...filterContainerStyles,
          background: filteredAgencies.length ? theme.palette.grey[300] : 'white',
          border: `1px solid ${theme.palette.grey[300]}`,
        }}
        onClick={handleClick}
      >
        {filterTitle}
      </Box>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} elevation={0} sx={menuStyles}>
        {agenciesAcronyms.map((acronym: string) => (
          <Box
            key={acronym}
            component="div"
            sx={{
              py: 1,
              px: 2,
              '&:hover': {
                background: theme.palette.grey[100],
                cursor: 'pointer',
              },
            }}
          >
            <FormControlLabel
              value={acronym}
              label={acronym}
              control={<Checkbox />}
              checked={filteredAgencies.includes(acronym)}
              onChange={() => handleFilterChange(acronym)}
            />
          </Box>
        ))}
      </Menu>
    </Box>
  )
}
