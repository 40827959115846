import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { TechnologiesApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { normalizeError } from '../../../utils'
import { TechnologyInitialState } from '../technology.slice'

export const fetchTechnologyAsync = createAsyncThunk(
  'technology/fetch',
  async (
    {
      id,
      options,
    }: {
      id: string
      options?: RequestConfig
    },
    { rejectWithValue },
  ) => {
    try {
      const res = await TechnologiesApi.fetchTechnology(id, options)
      return (res as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching technology')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchTechnologyAsyncBuilder = (
  builder: ActionReducerMapBuilder<TechnologyInitialState>,
) => {
  builder.addCase(fetchTechnologyAsync.pending, (state, action) => {
    state.loading = true
    state.error = ''
  })
  builder.addCase(fetchTechnologyAsync.fulfilled, (state, action) => {
    state.data = action.payload
    state.loading = false
  })
  builder.addCase(fetchTechnologyAsync.rejected, (state, action: PayloadAction<any>) => {
    state.error = action.payload
    state.loading = false
  })
}
