import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

interface EmailListProps {
  email: string
  onDelete: () => void
}

export const EmailList: FC<EmailListProps> = ({ email, onDelete }) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      alignItems="center"
      mb={2}
      borderBottom={`1px solid ${theme.palette.divider}`}
    >
      <Typography flexGrow={1} mr={2}>
        {email}
      </Typography>
      <IconButton onClick={onDelete}>
        <DeleteIcon sx={{ color: 'error.dark' }} />
      </IconButton>
    </Box>
  )
}
