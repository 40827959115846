import { AxiosAPI } from './AxiosApi'

class CompanyApi extends AxiosAPI {
  public createCompanyMembers = async (companyId: string, body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.company.addCompanyMember(companyId), body, options)
  }
  public fetchCompanyMembers = async (companyId: string, options = {}) => {
    return this.GET(this.apiSchema.GET.company.companyMembers(companyId), options)
  }
}

export default new CompanyApi()
