import { Button, Card, Stack } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { LinkExpireIcon } from '../../../../components/icons'
import { AppDispatch } from '../../../../store'
import { EmailVerificationActions } from '../../../../store/auth/resendEmailVerification.slice'
import { SnackbarActions } from '../../../../store/snackbar'
import { ValidationError } from '../../../../utils/helper'
import { EmailAuthResponseLayout } from './EmailAuthResponseLayout'

export const EmailAuthError = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')

  const requestResendEmail = async () => {
    try {
      if (!email) {
        throw new ValidationError('invalid email')
      }

      const resultAction = await dispatch(
        EmailVerificationActions.requestResendEmailAsync({ email }),
      )

      if (EmailVerificationActions.requestResendEmailAsync.fulfilled.match(resultAction)) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: 'email successfully resend.',
            options: { variant: 'success' },
          }),
        )
      }
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message,
          options: { variant: 'success' },
        }),
      )
    }
  }

  return (
    <Stack alignItems="center">
      <Card elevation={3} style={{ padding: '16px', minHeight: 'auto', minWidth: '35%' }}>
        <EmailAuthResponseLayout
          logo={<LinkExpireIcon sx={{ fontSize: '5rem' }} />}
          heading="Link Expired!"
          subHeading="Your Email Validation Was Successful"
          additionalSubHead="This link has expired. Please request a new one or try again."
          actionButtons={[
            <Button
              key="newLink"
              variant="contained"
              sx={{
                fontSize: '1rem',
                borderRadius: '8px',
                textTransform: 'capitalize',
                padding: '0.5rem 1.375rem',
                fontWeight: 500,
                minWidth: '380px',
                mt: 1,
              }}
              disableElevation
              onClick={requestResendEmail}
            >
              Request for a new link
            </Button>,
          ]}
        />
      </Card>
    </Stack>
  )
}
