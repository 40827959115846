import { Chip, SxProps, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

interface TagProps {
  value: string | string[]
  textColor?: string
  backgroundColor?: string
  fontSize?: string
  chipStyles?: SxProps
}

export const AgencyTag: FC<TagProps> = ({
  value,
  textColor,
  backgroundColor,
  fontSize,
  chipStyles,
}) => {
  const theme = useTheme()
  const chipDefaultColor = backgroundColor || theme.palette.grey[200]

  return (
    <Chip
      variant="outlined"
      sx={{
        backgroundColor: chipDefaultColor,
        borderRadius: '24px',
        border: 'transparent',
        my: '.2rem',
        width: 'min-content',
        ...chipStyles,
        '& .MuiChip-label': {
          padding: 0,
        },
      }}
      label={
        <Typography
          variant="body1"
          sx={{
            color: textColor,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: fontSize || '13px',
          }}
        >
          {value}
        </Typography>
      }
    />
  )
}
