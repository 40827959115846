import { Box } from '@mui/material'
import { useState } from 'react'
import { ResetPasswordForm } from './ResetPasswordForm'
import { ResetPasswordError, ResetPasswordSuccess } from './ResponseMessage'

export const ResetPassword = () => {
  const [currentPage, setCurrentPage] = useState('form')

  return (
    <Box>
      {currentPage === 'form' && <ResetPasswordForm setCurrentPage={setCurrentPage} />}

      {currentPage === 'success' && <ResetPasswordSuccess />}

      {currentPage === 'linkExpired' && <ResetPasswordError />}
    </Box>
  )
}
