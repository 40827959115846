import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICompanyPreferences } from '../../types'
import { fetchCompanyPreferencesAsync, fetchCompanyPreferencesAsyncBuilder } from './asyncRequests'

export interface CompanyPreferencesInitialState {
  data: ICompanyPreferences | null
  loading: boolean
  error: string | null
}

const initialState: CompanyPreferencesInitialState = {
  data: {} as ICompanyPreferences,
  loading: false,
  error: null,
}

const companyPreferencesSlice = createSlice({
  name: 'companyPreferences',
  initialState,
  reducers: {
    setCompanyPreferences: (state, action: PayloadAction<ICompanyPreferences>) => {
      state.data = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    fetchCompanyPreferencesAsyncBuilder(builder)
  },
})

export const companyPreferencesActions = {
  ...companyPreferencesSlice.actions,
  fetchCompanyPreferencesAsync,
}

export const companyPreferencesReducer = companyPreferencesSlice.reducer
