import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { AuthActions } from '../auth'
import { CurrentUserActions } from '../currentUser'

export const AuthMiddleware = createListenerMiddleware()

AuthMiddleware.startListening({
  actionCreator: AuthActions.logout,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(CurrentUserActions.resetCurrentUser())
  },
})

AuthMiddleware.startListening({
  matcher: isAnyOf(
    AuthActions.userLoginAsync.fulfilled,
    AuthActions.googleLoginAsync.fulfilled,
    AuthActions.microsoftAuthAsync.fulfilled,
    AuthActions.linkedInAuthAsync.fulfilled,
    AuthActions.refreshJwtAsync.fulfilled,
    CurrentUserActions.updateCurrentUserProfileAsync.fulfilled,
    CurrentUserActions.updateCurrentUserAvatarAsync.fulfilled,
  ),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(CurrentUserActions.fetchCurrentUserAsync())
  },
})
