import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { TechnologiesApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { normalizeError } from '../../../utils'

export const fetchMatchedGrantAsync = createAsyncThunk(
  'technology/matchedgrant/fetch',
  async (
    { id, refId, options }: { id: string; refId: string; options?: RequestConfig },
    { rejectWithValue },
  ) => {
    try {
      const encodedRefId = encodeURIComponent(refId)
      const res = await TechnologiesApi.fetchMatchedGrant(id, encodedRefId)
      return (res as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching matched grant')
      return rejectWithValue(normalized)
    }
  },
)
