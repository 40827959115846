import { createAsyncThunk } from '@reduxjs/toolkit'

export const userLogoutAsync = createAsyncThunk(
  'user/logoutUser',
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem('authToken')

      return true
    } catch (error: any) {
      return rejectWithValue(error.response?.data)
    }
  },
)
