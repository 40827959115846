import { Grid } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { FormsSelector } from '../../store/forms'
import { SearchForm, SearchResults } from './subComponents'
import { Solicitations } from './subComponents/Solicitations'

export const Search: FC = () => {
  const formId = 'grantQuery'
  const formValues = useSelector((state: RootState) => FormsSelector.getFormValues(state, formId))

  const { technology = '' } = formValues

  return (
    <Grid container height="min-content">
      <Grid item xs={12}>
        <SearchForm />
      </Grid>
      <Grid item xs={12}>
        {technology ? <SearchResults /> : <Solicitations />}
      </Grid>
    </Grid>
  )
}
