import {
  Box,
  ButtonProps,
  Stack,
  StackProps,
  SxProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material'
import { FC, isValidElement, ReactNode } from 'react'
import { CustomButton } from '../CustomButton'
import { CustomModal } from '../customModal'

const footerButtonStyles: SxProps = {
  py: 1,
  px: 2,
  height: '40px',
  color: 'white',
  fontWeight: 500,
  fontSize: '0.875rem',
  textTransform: 'none',
  borderRadius: '0.5rem',
}

interface CustomButtonProps extends ButtonProps {
  label?: string | ReactNode
  children?: ReactNode
}

interface AlertBoxProps {
  open: boolean
  setOpen: Function
  confirmAction?: () => void
  denyAction?: () => void
  hideDefaultActionButtons?: boolean
  title?: string | ReactNode
  titleProps?: TypographyProps
  titleSx?: SxProps
  description?: string | ReactNode
  descriptionProps?: TypographyProps
  descriptionSx?: SxProps
  denyButtonProps?: CustomButtonProps
  confirmButtonProps?: CustomButtonProps
  denyButtonSx?: SxProps
  confirmButtonSx?: SxProps
  actionContainerProps?: StackProps
  customActionButtons?: ReactNode
}

export const AlertBox: FC<AlertBoxProps> = ({
  open,
  setOpen,
  title,
  description,
  titleProps,
  descriptionProps,
  denyAction,
  denyButtonProps,
  denyButtonSx,
  confirmButtonProps,
  confirmAction,
  confirmButtonSx,
  actionContainerProps,
  customActionButtons,
  hideDefaultActionButtons,
}) => {
  const theme = useTheme()

  return (
    <CustomModal open={open} setOpen={setOpen} closeButtonAction={() => setOpen(false)}>
      <Box>
        {title && isValidElement(title)
          ? title
          : title && (
              <Typography
                mb={1}
                color="secondary"
                fontWeight={700}
                fontSize="1.3rem"
                {...(titleProps && titleProps)}
              >
                {title}
              </Typography>
            )}

        {description && isValidElement(description)
          ? description
          : description && (
              <Typography
                fontWeight={500}
                fontSize="1rem"
                color="secondary.light"
                {...(descriptionProps && descriptionProps)}
              >
                {description}
              </Typography>
            )}

        {customActionButtons && customActionButtons}

        {!hideDefaultActionButtons && !customActionButtons && (
          <Stack
            mt={4}
            gap="1rem"
            direction="row"
            justifyContent="flex-end"
            {...(actionContainerProps && actionContainerProps)}
          >
            <CustomButton
              disableElevation
              variant="contained"
              sx={{
                ...footerButtonStyles,
                width: '100px',
                color: 'secondary.main',
                background: theme.palette.grey[300],
                '&:hover': {
                  background: theme.palette.grey[400],
                },
                ...(denyButtonSx || {}),
              }}
              onClick={denyAction}
              {...(denyButtonProps && denyButtonProps)}
            />

            <CustomButton
              color="error"
              disableElevation
              variant="contained"
              onClick={confirmAction}
              sx={{ ...footerButtonStyles, ...(confirmButtonSx || {}) }}
              {...(confirmButtonProps && confirmButtonProps)}
            />
          </Stack>
        )}
      </Box>
    </CustomModal>
  )
}
