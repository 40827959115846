import { InputAdornment, TextField, Typography, useTheme } from '@mui/material'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../../../store'
import { GrantMatchActions } from '../../../store/grantMatcher'
import { SnackbarActions } from '../../../store/snackbar'

interface FundingAmountFormProps {
  matchedGrantId: string
  fundingAmount: string
}

export const FundingAmountForm: FC<FundingAmountFormProps> = ({
  fundingAmount,
  matchedGrantId,
}) => {
  const theme = useTheme()
  const { id = '' } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const [amount, setAmount] = useState(fundingAmount)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit(amount)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value.trim() === '') {
      setAmount('')
      return
    }

    if (/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value)) {
      setAmount(value)
    }
  }

  const handleSubmit = async (amount: string) => {
    try {
      if (amount === fundingAmount) {
        return
      }

      const resultAction = await dispatch(
        GrantMatchActions.updateMatchedTechGrantAsync({
          params: { techId: id, refId: matchedGrantId },
          body: { fundingAmount: amount },
        }),
      )

      if (GrantMatchActions.updateMatchedTechGrantAsync.fulfilled.match(resultAction)) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: 'funding amount updated!',
            options: { variant: 'success' },
          }),
        )
      }
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message,
        }),
      )
    }
  }

  return (
    <>
      <Typography
        mb={2}
        color="secondary"
        fontSize="0.875rem"
        fontWeight={theme.typography.fontWeightBold}
      >
        Input your desired funding amount
      </Typography>
      <TextField
        fullWidth
        type="tel"
        variant="outlined"
        label="Funding Amount"
        value={amount}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography>USD</Typography>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}
