import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const MicrosoftIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Microsoft">
        <path id="Vector" d="M11.3684 0H0V11.3684H11.3684V0Z" fill="#F25022" />
        <path id="Vector_2" d="M11.3684 12.6313H0V23.9998H11.3684V12.6313Z" fill="#00A4EF" />
        <path id="Vector_3" d="M23.9993 0H12.6309V11.3684H23.9993V0Z" fill="#7FBA00" />
        <path id="Vector_4" d="M23.9993 12.6313H12.6309V23.9998H23.9993V12.6313Z" fill="#FFB900" />
      </g>
    </SvgIcon>
  )
}
