import { DeleteRounded, ModeEditRounded, MoreVertRounded } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, SxProps, Typography, useTheme } from '@mui/material'
import { FC, MouseEvent, useState } from 'react'

const menuStyles: SxProps = {
  '& .MuiPaper-root': {
    mr: 1,
    minWidth: 200,
    maxWidth: 400,
    borderRadius: '0.5rem',
    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
                   rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
  },
}

const menuItemStyles: SxProps = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
}

interface MemberActionMenuProps {
  handleChangeRole: () => void
  handleRemoveUser: () => void
}

export const MemberActionMenu: FC<MemberActionMenuProps> = ({
  handleChangeRole,
  handleRemoveUser,
}) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton onClick={handleClick} color="secondary">
        <MoreVertRounded />
      </IconButton>

      <Menu
        open={open}
        elevation={0}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={menuStyles}
      >
        <MenuItem
          disableRipple
          sx={menuItemStyles}
          onClick={() => {
            handleChangeRole()
            handleClose()
          }}
        >
          <ModeEditRounded fontSize="small" sx={{ color: 'secondary.light' }} />
          <Typography
            color="secondary.light"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="0.875rem"
          >
            Change role
          </Typography>
        </MenuItem>
        <MenuItem
          disableRipple
          sx={menuItemStyles}
          onClick={() => {
            handleRemoveUser()
            handleClose()
          }}
        >
          <DeleteRounded color="error" fontSize="small" />
          <Typography
            color="error"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="0.875rem"
          >
            Remove user
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
