import { Box, Card } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppDispatch } from '../../../store'
import { AuthActions, AuthSelector } from '../../../store/auth'
import { SnackbarActions } from '../../../store/snackbar'
import { AuthLoader } from '../subComponents'
import { SignUpForm } from './SignUpForm'
import { SignUpSuccess } from './subComponents/SignUpSuccess'

export const SignUp = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [isSuccess, setSuccess] = useState<Boolean>(false)
  const [searchParams] = useSearchParams()
  const authCode: any = searchParams.get('code')
  const provider = useSelector(AuthSelector.provider)
  const signupError = useSelector(AuthSelector.error)
  const setSuccessState = (value: boolean) => {
    setSuccess(value)
  }
  const loading = useSelector(AuthSelector.isLoading)

  const getGoogleAccessToken = async () => {
    try {
      const actionResult = await dispatch(AuthActions.googleSignupTokenAsync({ authCode }))

      if (AuthActions.googleSignupTokenAsync.fulfilled.match(actionResult)) {
        const accessToken = actionResult.payload.access_token
        const options = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }

        const signupAction = await dispatch(AuthActions.googleSignupAsync(options))

        if (AuthActions.googleSignupAsync.fulfilled.match(signupAction)) {
          localStorage.setItem('accessToken', accessToken)
          dispatch(AuthActions.setAccessToken(accessToken))
          navigate('/login')
        }
      }
      if (AuthActions.googleSignupTokenAsync.rejected.match(actionResult)) {
        dispatch(AuthActions.logout())
        navigate('/register')
      }
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message,
        }),
      )
    }
  }

  const getLinkedinAccessToken = async () => {
    try {
      const actionResult = await dispatch(AuthActions.linkedinSignupTokenAsync({ authCode }))

      if (AuthActions.linkedinSignupTokenAsync.fulfilled.match(actionResult)) {
        const accessToken = actionResult.payload.access_token

        const options = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }

        const signupAction = await dispatch(AuthActions.linkedinSignupAsync(options))

        if (AuthActions.linkedinSignupAsync.fulfilled.match(signupAction)) {
          localStorage.setItem('accessToken', accessToken)
          navigate('/login')
        }
      }
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message,
        }),
      )
    }
  }

  const getMicrosoftAccessToken = async () => {
    try {
      const actionResult = await dispatch(AuthActions.microsoftSignupTokenAsync({ authCode }))

      if (AuthActions.microsoftSignupTokenAsync.fulfilled.match(actionResult)) {
        const accessToken = actionResult.payload.access_token

        const options = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }

        const signupAction = await dispatch(AuthActions.microsoftSignupAsync(options))

        if (AuthActions.microsoftSignupAsync.fulfilled.match(signupAction)) {
          localStorage.setItem('accessToken', accessToken)
          navigate('/login')
        }
      }
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message,
        }),
      )
    }
  }

  useEffect(() => {
    if (signupError) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: signupError,
        }),
      )
    }
  }, [signupError, dispatch])

  useEffect(() => {
    if (authCode && provider) {
      switch (provider) {
        case 'google':
          getGoogleAccessToken()
          break
        case 'linkedin':
          getLinkedinAccessToken()
          break
        case 'microsoft':
          getMicrosoftAccessToken()
          break
        default:
          break
      }
    }
  }, [authCode, provider])

  if (isSuccess) {
    return <SignUpSuccess />
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" mt="0.3rem">
      {loading ? (
        <AuthLoader message=" Please be patient while we are setting up your account" />
      ) : (
        <Card sx={{ maxWidth: '480px', p: 4, width: '100%', boxSizing: 'border-box' }}>
          <SignUpForm onSuccess={setSuccessState} />
        </Card>
      )}
    </Box>
  )
}
