import { Box, Chip, Menu, MenuItem, SxProps, Typography } from '@mui/material'
import { FC, MouseEvent, useState } from 'react'
import { Outcome } from '../../../../../types/Grants'

const menuSx: SxProps = {
  '& .MuiPaper-root': {
    mt: 0.5,
    width: 80,
    borderRadius: '0.5rem',
    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px,
                rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
                rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
                rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
  },
}

const Outcomes = {
  win: {
    textColor: '#015D2B',
    backgroundColor: '#E6F8EF',
  },
  loss: {
    textColor: '#BD0E0F',
    backgroundColor: '#FEF5F6',
  },
  'N/A': {
    textColor: '',
    backgroundColor: '',
  },
}

interface GrantOutcomeProps {
  refId: string
  grantTitle: string
  currentOutcome: Outcome
  handleGrantOutcomeChange?: Function
}

export const GrantOutcome: FC<GrantOutcomeProps> = ({
  refId,
  grantTitle,
  currentOutcome,
  handleGrantOutcomeChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    const currentTarget = event.currentTarget
    setAnchorEl(() => currentTarget)
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleUpdateGrantOutcome = async (event: MouseEvent<HTMLElement>, outcome: Outcome) => {
    handleGrantOutcomeChange && handleGrantOutcomeChange(outcome, refId, grantTitle)
    handleClose(event)
  }

  return (
    <Box>
      <Box onClick={handleClick}>
        {currentOutcome && (
          <Chip
            size="small"
            sx={{
              minWidth: '100%',
              borderRadius: '8px',
              background: Outcomes[currentOutcome].backgroundColor,
            }}
            label={
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  color: Outcomes[currentOutcome].textColor,
                  textTransform: 'capitalize',
                }}
              >
                {currentOutcome}
              </Typography>
            }
          />
        )}
      </Box>

      <Menu
        open={open}
        sx={menuSx}
        elevation={0}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {Object.entries(Outcomes).map(([key, value]) => {
          return (
            key !== currentOutcome && (
              <MenuItem
                key={key}
                disableRipple
                onClick={(e) => {
                  handleUpdateGrantOutcome(e, key as Outcome)
                }}
                sx={{ py: 0.5, px: 1, display: 'flex', justifyContent: 'center' }}
              >
                <Chip
                  size="small"
                  sx={{
                    height: 25,
                    width: '100%',
                    borderRadius: '5px',
                    background: value.backgroundColor,
                  }}
                  label={
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        color: value.textColor,
                        textTransform: 'capitalize',
                      }}
                    >
                      {key}
                    </Typography>
                  }
                />
              </MenuItem>
            )
          )
        })}
      </Menu>
    </Box>
  )
}
