import { Stack, Typography } from '@mui/material'

export const MemberInvalidSub = () => {
  return (
    <Stack alignItems="center" width="100%" gap={2}>
      <Typography fontSize="1.2rem" textAlign="center" mt={4}>
        You don't have any active subscription.Please contact your administrator
      </Typography>
    </Stack>
  )
}
