import { ArcElement, Chart as ChartJS, ChartOptions, Legend, Tooltip } from 'chart.js'
import { useSelector } from 'react-redux'
import { DoughnutChart } from '../../../../../../components/DoughnutChart'
import { MetricsSelector } from '../../../../../../store/TechnologyMetrics'

ChartJS.register(ArcElement, Tooltip, Legend)

export const GrantsWinLossChart = () => {
  const winPercentage = useSelector(MetricsSelector.selectWinPercentage)
  const lossPercentage = useSelector(MetricsSelector.selectLossPercentage)

  const isEmpty = winPercentage === 0 && lossPercentage === 0

  const data = [
    {
      label: 'Wins',
      value: winPercentage,
      color: '#00A94F',
    },
    {
      label: 'Losses',
      value: lossPercentage,
      color: '#F46E6F',
    },
  ]

  const finalData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: isEmpty ? [1, 1] : data.map((item) => Math.round(item.value)),
        backgroundColor: isEmpty ? '#F1F5F9' : data.map((item) => item.color),
        borderColor: isEmpty ? '#F1F5F9' : data.map((item) => item.color),
        borderWidth: 1,
      },
    ],
  }

  const options: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          padding: 20,
          generateLabels: (chart) => {
            const data = chart.data
            if (data.labels && data.datasets.length) {
              return data.labels.map((label, index) => {
                const dataset: any = data.datasets[0]
                const backgroundColor = isEmpty
                  ? index === 0
                    ? '#00A94F'
                    : '#F46E6F'
                  : (dataset.backgroundColor[index] as string)
                return {
                  text: isEmpty ? `${label}` : `${label} - ${dataset.data[index]}%`,
                  fillStyle: backgroundColor,
                  strokeStyle: backgroundColor,
                  lineWidth: 1,
                  hidden: isEmpty
                    ? false
                    : isNaN(dataset.data[index] as number) || (dataset.data[index] as number) === 0,
                  index: index,
                }
              })
            }
            return []
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.label || ''
            const value = tooltipItem.raw as number
            return isEmpty ? `${label}` : `${label}: ${value}%`
          },
        },
      },
    },
    cutout: '60%',
    responsive: true,
    maintainAspectRatio: false,
  }

  return <DoughnutChart data={finalData} options={options} title="Wins and Losses chart" />
}
