import { createSlice } from '@reduxjs/toolkit'
import {
  addTechnologyAsync,
  addTechnologyAsyncBuilder,
  createTechnologyGrantAsync,
  createTechnologyGrantAsyncBuilder,
  deleteTechnologyAsync,
  deleteTechnologyAsyncBuilder,
  fetchTechnologiesAsyncBuilder,
  fetchUserTechnologiesAsync,
  updateTechnologiesAsync,
  updateTechnologiesAsyncBuilder,
} from './asyncRequests'

export interface ITechnology {
  _id: string
  title: string
  description: string
  active: boolean
  organization: string
  keywords: string[]
  matchedGrants_count?: number
  matchedGrants?: any[]
}

export interface ITechnologyWithPagination {
  totalRows: number
  rows: number
  limit: number
  skip: number
  items: ITechnology[]
}

export interface TechnologiesInitialState {
  data: ITechnologyWithPagination
  newTechnology: ITechnology
  loading: boolean
  success: boolean
  error: string
}

const initialState: TechnologiesInitialState = {
  data: {} as ITechnologyWithPagination,
  newTechnology: {} as ITechnology,
  loading: false,
  success: false,
  error: '',
}

const TechnologiesSlice = createSlice({
  name: 'technologies',
  initialState,
  reducers: {
    resetNewTechnology: (state) => {
      state.newTechnology = {} as ITechnology
    },
    resetTechnologyError: (state) => {
      state.error = ''
    },
  },
  extraReducers: (builder) => {
    fetchTechnologiesAsyncBuilder(builder)
    addTechnologyAsyncBuilder(builder)
    createTechnologyGrantAsyncBuilder(builder)
    deleteTechnologyAsyncBuilder(builder)
    updateTechnologiesAsyncBuilder(builder)
  },
})

export const TechnologiesActions = {
  ...TechnologiesSlice.actions,
  fetchUserTechnologiesAsync,
  addTechnologyAsync,
  createTechnologyGrantAsync,
  deleteTechnologyAsync,
  updateTechnologiesAsync,
}

export const TechnologiesReducer = TechnologiesSlice.reducer
