import { RootState } from '../store'

const isLoading = (state: RootState) => state.emailVerify.loading
const isSuccess = (state: RootState) => state.emailVerify.success
const isError = (state: RootState) => !state.emailVerify.error
const error = (state: RootState) => state.emailVerify.error

export const EmailVerificationSelector = {
  isLoading,
  isSuccess,
  isError,
  error,
}
