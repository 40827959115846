import { Card, Stack } from '@mui/material'
import { LinkExpireIcon } from '../../../../../../components/icons'
import { VerificationResponseLayout } from './VerificationResponseLayout'

export const VerificationError = () => {
  return (
    <Stack alignItems="center">
      <Card elevation={3} style={{ padding: '16px', minHeight: 'auto', minWidth: '35%' }}>
        <VerificationResponseLayout
          logo={<LinkExpireIcon sx={{ fontSize: '5rem' }} />}
          heading="Invite Verification Error"
          subHeading="We are unable to verify the invitation at this moment.Please contact your company admin"
        />
      </Card>
    </Stack>
  )
}
