import { AppBar, Box, Button, Chip, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

export const Footer: FC = () => {
  const theme = useTheme()

  return (
    <AppBar
      color="transparent"
      elevation={0}
      position="static"
      sx={{ bottom: 0, textAlign: 'center', padding: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box display="flex" flexWrap="nowrap">
          <Button
            variant="text"
            sx={{ textTransform: 'capitalize', color: 'black', px: 1 }}
            href={'/aboutus'}
          >
            About Us
          </Button>
          <Button variant="text" sx={{ textTransform: 'capitalize', color: 'black' }} href={'#'}>
            Contact Us
          </Button>
          <Button
            variant="text"
            sx={{ textTransform: 'capitalize', color: 'black' }}
            href={'/privacy'}
          >
            Privacy Policy
          </Button>
          <Button
            variant="text"
            sx={{ textTransform: 'capitalize', color: 'black' }}
            href={'/termsofuse'}
          >
            Terms of Use
          </Button>
          <Button
            variant="text"
            sx={{ textTransform: 'capitalize', color: 'black' }}
            href={'/useragreement'}
          >
            User Agreement
          </Button>
        </Box>
        <Chip
          label="Finding Grants Sucks. We made it awesome"
          sx={{ fontWeight: theme.typography.fontWeightBold }}
          clickable={false}
        />
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        >
          © 2024 Catalyzing Concepts LLC
        </Typography>
      </Box>
    </AppBar>
  )
}
