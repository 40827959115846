import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  addTechnologyMemberAsync,
  addTechnologyMemberAsyncBuilder,
} from './asyncRequests/addTechnologyMember'
import {
  fetchTechnologyAsync,
  fetchTechnologyAsyncBuilder,
} from './asyncRequests/fetchTechnologyAsync'

export interface IUser {
  _id: string
  firstName: string
  lastName: string
  email?: string
  phone?: string
  avatar?: string
}

export interface IData {
  _id: string
  title: string
  description: string
  active: boolean
  users: IUser[]
  selectedKeywords: string[]
}

export interface TechnologyInitialState {
  data: IData
  loading: boolean
  error: string
}

const initialState: TechnologyInitialState = {
  data: {
    _id: '',
    title: '',
    description: '',
    active: false,
    users: [],
    selectedKeywords: [],
  },
  loading: false,
  error: '',
}

const TechnologySlice = createSlice({
  name: 'technology',
  initialState,
  reducers: {
    resetTechnologyData: (state) => {
      state.data = initialState.data
    },
    updateSelectedKeywords: (state, action: PayloadAction<string[]>) => {
      state.data.selectedKeywords = action.payload
    },
  },
  extraReducers: (builder) => {
    fetchTechnologyAsyncBuilder(builder)
    addTechnologyMemberAsyncBuilder(builder)
  },
})

export const TechnologyActions = {
  ...TechnologySlice.actions,
  fetchTechnologyAsync,
  addTechnologyMemberAsync,
}

export const TechnologyReducer = TechnologySlice.reducer
