import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { SolicitationsApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { IGrant } from '../../../types/Grants'
import { IPagedResponse } from '../../../types/Requests'
import { normalizeError } from '../../../utils'
import { SolicitationsInitialState } from '../solicitations.slice'

export const fetchSolicitationGrantsAsync = createAsyncThunk(
  'solicitations/fetchSolicitationGrantsAsync',
  async (
    { solicitationNumber, options }: { solicitationNumber: string; options?: RequestConfig },
    { rejectWithValue },
  ) => {
    try {
      const res = await SolicitationsApi.fetchGrants(solicitationNumber, options)
      return (res as AxiosResponse).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching solicitation grants')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchSolicitationGrantsAsyncBuilder = (
  builder: ActionReducerMapBuilder<SolicitationsInitialState>,
) => {
  builder.addCase(fetchSolicitationGrantsAsync.pending, (state) => {
    state.grants.error = ''
    state.grants.fetching = true
  })
  builder.addCase(
    fetchSolicitationGrantsAsync.fulfilled,
    (state, action: PayloadAction<IPagedResponse<IGrant[]>>) => {
      const { items, ...rest } = action.payload

      state.grants.data = items
      state.grants.paging.rows = rest.rows
      state.grants.paging.totalRows = rest.totalRows
      state.grants.fetching = false
    },
  )
  builder.addCase(fetchSolicitationGrantsAsync.rejected, (state, action: PayloadAction<any>) => {
    state.grants.fetching = false
    state.grants.error = action.payload
  })
}
