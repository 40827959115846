import { createSlice } from '@reduxjs/toolkit'
import { IStripeProduct, IStripeSubscription } from '../../types'
import {
  createStripePortalSessionAsync,
  createStripePortalSessionAsyncBuilder,
  fetchStripeProductsAsync,
  fetchStripeProductsAsyncBuilder,
} from './asyncRequests'

export interface ISubscriptionInitialState {
  data: IStripeSubscription
  loading: boolean
  success: boolean
  error: string
}

const initialState: ISubscriptionInitialState = {
  data: { products: [] as IStripeProduct[] } as IStripeSubscription,
  loading: false,
  success: false,
  error: '',
}

export const SubscriptionSlice = createSlice({
  name: 'subscriptionSlice',
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.error = ''
    },
  },
  extraReducers: (builder) => {
    fetchStripeProductsAsyncBuilder(builder)
    createStripePortalSessionAsyncBuilder(builder)
  },
})

export const SubscriptionActions = {
  ...SubscriptionSlice.actions,
  fetchStripeProductsAsync,
  createStripePortalSessionAsync,
}

export const SubscriptionReducer = SubscriptionSlice.reducer
