import { createSlice } from '@reduxjs/toolkit'
import { IAddress, ICurrentUser, ICurrentUserCompany, ICurrentUserCompanyMember } from '../../types'

import {
  addCurrentUserCompanyMembersAsync,
  addCurrentUserCompanyMembersAsyncBuilder,
  fetchCurrentUserAsync,
  fetchCurrentUserAsyncBuilder,
  fetchCurrentUserCompanyAsync,
  fetchCurrentUserCompanyAsyncBuilder,
  fetchCurrentUserCompanyMembersAsync,
  fetchCurrentUserCompanyMembersAsyncBuilder,
  removeCurrentUserCompanyMemberAsync,
  removeCurrentUserCompanyMemberAsyncBuilder,
  updateCurrentUserAvatarAsync,
  updateCurrentUserAvatarAsyncBuilder,
  updateCurrentUserCompanyAsync,
  updateCurrentUserCompanyAsyncBuilder,
  updateCurrentUserCompanyMemberRoleAsync,
  updateCurrentUserCompanyMemberRoleAsyncBuilder,
  updateCurrentUserProfileAsync,
  updateCurrentUserProfileAsyncBuilder,
  userLogoutAsync,
} from './asyncRequests'

export interface CurrentUserInitialState {
  data: {
    user: ICurrentUser
    company: ICurrentUserCompany
    companyMembers: ICurrentUserCompanyMember[]
    address?: IAddress
    isTempPassword: boolean
  }
  error: string
  isLoggedIn: boolean
  success: boolean
  loading: boolean
}

const initialState: CurrentUserInitialState = {
  data: {
    user: {} as ICurrentUser,
    company: {} as ICurrentUserCompany,
    companyMembers: [] as ICurrentUserCompanyMember[],
    isTempPassword: false,
  },
  error: '',
  isLoggedIn: false,
  success: false,
  loading: false,
}

export const CurrentUserSlice = createSlice({
  name: 'currentUserSlice',
  initialState,
  reducers: {
    resetUser: (state, action) => {
      state.data.user = initialState.data.user
    },
    resetCurrentUser: () => {
      return initialState
    },
    resetError: (state) => {
      state.error = ''
    },
  },
  extraReducers: (builder) => {
    fetchCurrentUserAsyncBuilder(builder)
    updateCurrentUserProfileAsyncBuilder(builder)
    updateCurrentUserAvatarAsyncBuilder(builder)
    updateCurrentUserCompanyAsyncBuilder(builder)
    addCurrentUserCompanyMembersAsyncBuilder(builder)
    fetchCurrentUserCompanyAsyncBuilder(builder)
    fetchCurrentUserCompanyMembersAsyncBuilder(builder)
    updateCurrentUserCompanyMemberRoleAsyncBuilder(builder)
    removeCurrentUserCompanyMemberAsyncBuilder(builder)
  },
})

export const CurrentUserActions = {
  ...CurrentUserSlice.actions,
  fetchCurrentUserAsync,
  userLogoutAsync,
  updateCurrentUserProfileAsync,
  updateCurrentUserAvatarAsync,
  updateCurrentUserCompanyAsync,
  addCurrentUserCompanyMembersAsync,
  fetchCurrentUserCompanyAsync,
  fetchCurrentUserCompanyMembersAsync,
  updateCurrentUserCompanyMemberRoleAsync,
  removeCurrentUserCompanyMemberAsync,
}

export const CurrentUserReducer = CurrentUserSlice.reducer
