import { Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../../store'
import { AuthSelector } from '../../../../../store/auth'
import { CurrentUserActions, CurrentUserSelector } from '../../../../../store/currentUser'
import { EditCompanyInfoModal, ManageSubscription } from './subcomponents'

const buttonStyles = {
  fontSize: '0.875rem',
  fontWeight: 500,
  textTransform: 'capitalize',
  borderRadius: '0.5rem',
  padding: '0.5rem 1rem',
}

export const CompanyInfo = () => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false)

  const userRole = useSelector(AuthSelector.userRole)
  const { name = '', description = '' } = useSelector(CurrentUserSelector.companyDetail)

  const companyAddress = useSelector(CurrentUserSelector.makeCompanyAddress)

  useEffect(() => {
    dispatch(CurrentUserActions.fetchCurrentUserCompanyAsync())
  }, [])

  return (
    <Grid container spacing={4} height="min-content">
      <EditCompanyInfoModal open={showEditCompanyModal} setOpen={setShowEditCompanyModal} />

      <Grid item xs={12} md={6}>
        <Typography
          fontSize="1.5rem"
          fontWeight={theme.typography.fontWeightMedium}
          color="secondary.main"
        >
          {name}
        </Typography>

        <Typography
          fontSize="1rem"
          fontWeight={theme.typography.fontWeightMedium}
          color="secondary.light"
          my={2}
        >
          {description}
        </Typography>

        <Stack direction="row" gap={2}>
          {userRole === 'ORG_ADMIN' && <ManageSubscription />}

          <Button
            sx={{
              background: theme.palette.grey[200],
              color: theme.palette.grey[900],
              ...buttonStyles,
            }}
            disableElevation
            onClick={() => setShowEditCompanyModal(true)}
          >
            Edit company profile
          </Button>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Typography
          fontSize="0.875rem"
          fontWeight={theme.typography.fontWeightMedium}
          color="secondary.light"
          mb={1}
        >
          Address
        </Typography>
        <Typography
          fontSize="1rem"
          fontWeight={theme.typography.fontWeightBold}
          color="secondary.main"
        >
          {companyAddress || 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  )
}
