import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { PublicApi } from '../../../services'
import { IGrant } from '../../../types/Grants'
import { normalizeError } from '../../../utils'
import { PublicInitialState } from '../public.slice'

export const fetchPulicGrantAsync = createAsyncThunk(
  'public/grants',
  async ({ grantId, accessKey }: { grantId: string; accessKey: string }, { rejectWithValue }) => {
    try {
      const res = await PublicApi.fetchPublicGrant(grantId, accessKey, {
        params: { $includes: 'solicitation,agency' },
      })
      return (res as AxiosResponse).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching public grant')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchPulicGrantAsyncBuilder = (
  builder: ActionReducerMapBuilder<PublicInitialState>,
) => {
  builder.addCase(fetchPulicGrantAsync.pending, (state) => {
    state.grant.error = ''
    state.grant.fetching = true
  })
  builder.addCase(fetchPulicGrantAsync.fulfilled, (state, action: PayloadAction<IGrant>) => {
    state.grant.fetching = false
    state.grant.data = action.payload
  })
  builder.addCase(fetchPulicGrantAsync.rejected, (state, action: PayloadAction<any>) => {
    state.grant.fetching = false
    state.grant.error = action.payload
  })
}
