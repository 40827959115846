import { AppBar, Stack, useTheme } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { GrantMatch } from '../icons'
import { AccountPopover } from './AccountPopover'

export const InvalidSubHeader = () => {
  const theme = useTheme()

  return (
    <AppBar color="transparent" position="relative" elevation={0}>
      <Stack
        spacing={1}
        height="60px"
        direction="row"
        alignItems="center"
        component="header"
        sx={{
          background: 'white',
          padding: '0 1.5rem',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Stack direction="row">
          <NavLink to={'/'}>
            <GrantMatch sx={{ fontSize: '7rem' }} />
          </NavLink>
        </Stack>

        <Stack direction="row" gap={2} alignItems="center">
          <AccountPopover />
        </Stack>
      </Stack>
    </AppBar>
  )
}
