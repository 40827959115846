import { Stack } from '@mui/material'
import { FC } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { navConfig } from './navConfig'
import { NavItem } from './NavItem'

export const Navigation: FC = () => {
  const { pathname } = useLocation()

  const match = (path: string) => (path ? !!matchPath({ path, end: true }, pathname) : false)

  return (
    <Stack alignItems="center" direction="row" gap="1.2rem" height="100%">
      {navConfig.map(({ path, title }, index) => (
        <NavItem key={path} title={title} path={path} active={match(path)} />
      ))}
    </Stack>
  )
}
