import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { UsersApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { normalizeError } from '../../../utils'
import { CurrentUserInitialState } from '../currentUser.slice'

export const addCurrentUserCompanyMembersAsync = createAsyncThunk(
  'user/company/addMembers',
  async ({ body, options }: { body: string[]; options?: RequestConfig }, { rejectWithValue }) => {
    try {
      await UsersApi.addCompanyMembers(body, options)
      return
    } catch (error: any) {
      const normalized = normalizeError(error, 'adding members to company')
      return rejectWithValue(normalized)
    }
  },
)

export const addCurrentUserCompanyMembersAsyncBuilder = (
  builder: ActionReducerMapBuilder<CurrentUserInitialState>,
) => {
  builder.addCase(addCurrentUserCompanyMembersAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(addCurrentUserCompanyMembersAsync.fulfilled, (state, action) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(
    addCurrentUserCompanyMembersAsync.rejected,
    (state, action: PayloadAction<any>) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    },
  )
}
