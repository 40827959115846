import { FC } from 'react'
import { CustomModal } from '../../../../../../../components/customModal'
import { AddCompanyMembersForm } from './AddCompanyMembersForm'

interface AddCompanyMembersModalProps {
  open: boolean
  setOpen: Function
}

export const AddCompanyMembersModal: FC<AddCompanyMembersModalProps> = ({ open, setOpen }) => {
  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title="Add members"
      closeButtonAction={() => setOpen(false)}
    >
      <AddCompanyMembersForm handleModalClose={() => setOpen(false)} />
    </CustomModal>
  )
}
