import { FilterListRounded } from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

interface TechnologyResultProps {
  deadlineSort: number
  handleDeadlineSort: Function
}

const filterContainerStyles = {
  minWidth: '100px',
  height: '42px',
  borderRadius: '42px',
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: '0 0.5rem',
}

export const DeadlineFilter: FC<TechnologyResultProps> = ({ deadlineSort, handleDeadlineSort }) => {
  const theme = useTheme()

  const handleDeadlineChange = () => {
    const newSort = deadlineSort === 1 ? -1 : 1
    handleDeadlineSort(newSort)
  }

  return (
    <Box>
      <Box
        component="div"
        sx={{ ...filterContainerStyles, border: `1px solid ${theme.palette.grey[300]}` }}
        onClick={handleDeadlineChange}
      >
        <Typography color="secondary" fontWeight={500} fontSize="1rem" mx={1}>
          Deadline :
        </Typography>
        <Typography color="secondary" fontWeight={700} fontSize="1rem" mr={1}>
          {deadlineSort === 1 ? 'Ascending' : 'Descending'}
        </Typography>
        <FilterListRounded sx={{ fontSize: 20, color: 'secondary' }} />
      </Box>
    </Box>
  )
}
