import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BaseVariant, SnackbarAction, SnackbarOrigin } from 'notistack'
import { v4 as uuid4 } from 'uuid'

interface SnackbarOptions {
  key: string
  variant: BaseVariant
  autoHideDuration: number
  anchorOrigin: SnackbarOrigin
  persist?: boolean
  action?: SnackbarAction
}

interface SnackbarQueue {
  message: string
  options: SnackbarOptions
}

interface SnackbarPayload {
  message: string
  options?: Partial<SnackbarOptions>
}

interface SnackbarInitialState {
  snackbarQueue: SnackbarQueue[]
}

const initialState: SnackbarInitialState = {
  snackbarQueue: [] as SnackbarQueue[],
}

const SnackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<SnackbarPayload>) => {
      const { payload } = action

      const {
        key = uuid4(),
        variant = 'error',
        autoHideDuration = 3000,
        anchorOrigin = { horizontal: 'center', vertical: 'top' },
        ...rest
      } = payload?.options || {}

      const snackbarOptions: SnackbarQueue = {
        message: payload.message,
        options: {
          key,
          variant,
          anchorOrigin,
          autoHideDuration,
          ...rest,
        },
      }

      state.snackbarQueue.push(snackbarOptions)
    },
    removeSnackbar: (state, action: PayloadAction<string>) => {
      const key = action.payload
      state.snackbarQueue = state.snackbarQueue.filter((snackbar) => snackbar.options.key !== key)
    },
  },
})

export const SnackbarActions = {
  ...SnackbarSlice.actions,
}

export const SnackbarReducer = SnackbarSlice.reducer
