import { createSlice } from '@reduxjs/toolkit'
import { IGrant } from '../../types/Grants'
import { fetchPulicGrantAsync, fetchPulicGrantAsyncBuilder } from './asyncRequest'

export interface PublicInitialState {
  grant: {
    data: IGrant
    fetching: boolean
    error: string
  }
}

const initialState: PublicInitialState = {
  grant: {
    data: {} as IGrant,
    fetching: false,
    error: '',
  },
}

const PublicSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {
    resetGrantError: (state) => {
      state.grant.error = ''
    },
  },
  extraReducers: (builder) => {
    fetchPulicGrantAsyncBuilder(builder)
  },
})

export const PublicActions = {
  ...PublicSlice.actions,
  fetchPulicGrantAsync,
}

export const PublicReducer = PublicSlice.reducer
