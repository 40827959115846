import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthSelector } from '../../../store/auth'
import { CompanyProfile } from './CompanyProfile'

export const Company = () => {
  const userRole = useSelector(AuthSelector.userRole)
  const userRoleInCompany = useSelector(AuthSelector.userCompanyRole)

  return (
    <Routes>
      <Route
        path="/"
        element={
          userRole === 'ORG_ADMIN' || userRoleInCompany === 'COMPANY_ADMIN' ? (
            <CompanyProfile />
          ) : (
            <Navigate to="/home" />
          )
        }
      />
    </Routes>
  )
}
