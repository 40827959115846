import { Box, Paper, Stack, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { AgencyTag } from '../AgencyTag'

interface SolicitationsCardProps {
  title: string
  agency: { [key: string]: string }
  openDate: string
  closeDate: string
  grantCount: number
}

export const SolicitationsCard: FC<SolicitationsCardProps> = ({
  title,
  agency,
  openDate,
  closeDate,
  grantCount,
}) => {
  const theme = useTheme()

  return (
    <Paper
      elevation={0}
      component="div"
      sx={{
        p: '16px',
        cursor: 'pointer',
        borderRadius: '8px',
        background: theme.palette.grey[100],
      }}
    >
      <Stack gap={0.8} direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h5"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          color="secondary"
          fontWeight={700}
          fontSize="1rem"
        >
          <Box component="span">{title}</Box>
        </Typography>

        <AgencyTag
          value={agency.acronym}
          textColor={agency.textColor}
          backgroundColor={agency.backgroundColor}
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between" mt={3}>
        <Stack gap={3} direction="row">
          <Stack gap={1}>
            <Typography variant="body2" color="secondary.light">
              Open date
            </Typography>
            <Typography
              color="secondary"
              fontSize="0.9rem"
              fontWeight={theme.typography.fontWeightBold}
            >
              {openDate}
            </Typography>
          </Stack>

          <Stack gap={1}>
            <Typography variant="body2" color="secondary.light">
              Close date
            </Typography>
            <Typography
              color="secondary"
              fontSize="0.9rem"
              fontWeight={theme.typography.fontWeightBold}
            >
              {closeDate}
            </Typography>
          </Stack>
        </Stack>

        <Stack alignItems="flex-end" gap={1}>
          <Typography variant="body2" fontSize="0.9rem" color="secondary.light">
            Topic
          </Typography>
          <Typography
            color="secondary"
            fontSize="0.9rem"
            fontWeight={theme.typography.fontWeightBold}
          >
            {grantCount}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  )
}
