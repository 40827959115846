import { SxProps } from '@mui/material'

export const filterContainerStyles: SxProps = {
  minWidth: '100px',
  height: '44px',
  borderRadius: '44px',
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: '0 0.5rem',
}

export const menuStyles: SxProps = {
  '& .MuiPaper-root': {
    ml: 1,
    mt: 1,
    minWidth: 160,
    maxWidth: 400,
    borderRadius: '0.5rem',
    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
                  rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
  },
}
