const components = {
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '24px',

        /*
        *         backgroundColor: theme.palette.grey[200],
        borderRadius: '24px',
        border: 'transparent',
        padding: '8px 4px',
        my: '.2rem',
        width: 'min-content',
        * */
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        padding: '16px',
        boxShadow: 'none',
      },
    },
  },
  MuiInput: {
    defaultProps: {
      inputProps: {
        sx: {
          '&::placeholder': {
            opacity: 1,
            fontSize: '1.2rem',
            color: '#E2E8F0',
          },
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
}

export default components
