import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AlertBox } from '../../../../components/AlertBox'
import { AppDispatch } from '../../../../store'
import { SnackbarActions } from '../../../../store/snackbar'
import { TechnologiesActions, TechnologiesSelectors } from '../../../../store/technologies'

interface DeleteTechnologyAlertBoxProps {
  open: boolean
  setOpen: Function
  technologyId: string
}

export const DeleteTechnologyAlertBox: FC<DeleteTechnologyAlertBoxProps> = ({
  open,
  setOpen,
  technologyId,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const loading = useSelector(TechnologiesSelectors.loading)

  const handleDeleteTechnology = async () => {
    if (!technologyId) {
      return
    }

    const resultAction = await dispatch(
      TechnologiesActions.deleteTechnologyAsync({ techId: technologyId }),
    )

    if (TechnologiesActions.deleteTechnologyAsync.fulfilled.match(resultAction)) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: 'Project has been deleted successfully!',
          options: { variant: 'success' },
        }),
      )
      navigate('/home', { replace: true })
    }
  }

  return (
    <AlertBox
      open={open}
      setOpen={setOpen}
      title="Delete project?"
      description="Are you sure you want to delete this technology?"
      confirmAction={handleDeleteTechnology}
      denyAction={() => setOpen(false)}
      confirmButtonProps={{ disabled: loading, label: 'Delete technology' }}
      denyButtonProps={{ disabled: loading, label: 'Cancel' }}
    />
  )
}
