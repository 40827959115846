import { createSlice } from '@reduxjs/toolkit'
import { IRegsitrationProgress } from '../../types'
import {
  fetchRegistrationProgressAsync,
  fetchRegistrationProgressAsyncBuilder,
  updateRegistrationProgressAsync,
  updateRegistrationProgressAsyncBuilder,
} from './asyncRequests'

export interface RegistrationProgressInitialState {
  data: IRegsitrationProgress
  loading: boolean
  error: string
}

const initialState: RegistrationProgressInitialState = {
  data: {} as IRegsitrationProgress,
  loading: false,
  error: '',
}

const RegistrationProgressSlice = createSlice({
  name: 'registrationProgress',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchRegistrationProgressAsyncBuilder(builder)
    updateRegistrationProgressAsyncBuilder(builder)
  },
})

export const RegistrationProgressActions = {
  ...RegistrationProgressSlice.actions,
  fetchRegistrationProgressAsync,
  updateRegistrationProgressAsync,
}

export const RegistrationProgressReducer = RegistrationProgressSlice.reducer
