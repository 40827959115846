import { AxiosAPI } from './AxiosApi'

class AuthApi extends AxiosAPI {
  public login = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.login(), body, options)
  }

  public RegisterUser = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.registerUser(), body, options)
  }

  public googleSignupAccessToken = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.googleSignupToken(), body, options)
  }

  public googleSignup = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.googleSignup(), body, options)
  }

  public linkedinSignupAccessToken = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.linkedinSignupToken(), body, options)
  }

  public linkedinSignup = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.linkedinSignup(), body, options)
  }

  public microsoftSignupAccessToken = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.microsoftSignupToken(), body, options)
  }

  public microsoftSignup = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.microsoftSignup(), body, options)
  }

  public linkedinAuthWithToken = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.linkedinAuthToken(), body, options)
  }

  public loginWithLinkedin = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.loginLinkedin(), body, options)
  }

  public googleAuthWithToken = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.googleAuthToken(), body, options)
  }

  public loginWithGoogle = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.loginGoogle(), body, options)
  }

  public microsoftAuthWithToken = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.microsoftAuthToken(), body, options)
  }

  public loginWithMicrosoft = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.loginMicrosoft(), body, options)
  }

  public passwordResetRequest = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.requestResetPassword(), body, options)
  }

  public PasswordReset = async (body: any, options = {}) => {
    return await this.PUT(this.apiSchema.PUT.auth.resetPassword(), body, options)
  }

  public verifyEmail = async (body: any, options = {}) => {
    return await this.PUT(this.apiSchema.PUT.auth.verifyEmail(), body, options)
  }

  public requestResendEmail = async (body: any, options = {}) => {
    return await this.POST(this.apiSchema.POST.auth.requestResendEmail(), body, options)
  }

  public verifyCompanyInvitation = async (body: any, options = {}) => {
    return await this.PUT(this.apiSchema.PUT.auth.verifyCompanyInvitation(), body, options)
  }

  public refreshJwt = async (options = {}) => {
    return await this.GET(this.apiSchema.GET.auth.refreshJwt(), options)
  }
}

export default new AuthApi()
