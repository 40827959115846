import { Box, Button, Grid, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { AppDispatch } from '../../../../../../../store'
import { CurrentUserActions, CurrentUserSelector } from '../../../../../../../store/currentUser'
import { MetaSelectors } from '../../../../../../../store/meta/meta.selector'
import { SnackbarActions } from '../../../../../../../store/snackbar'
import { ICompanyAddress } from '../../../../../../../types'

const EditCompanyInfoSchema = Yup.object().shape({
  name: Yup.string().required('Company name is required'),
  description: Yup.string(),
  address1: Yup.string().required('state is requried'),
  state: Yup.string().required('state is requried'),
  zip: Yup.string().required('zip code is requried'),
  city: Yup.string().required('city  is requried'),
})

export const EditCompanyInfoForm: FC<{ handleModalClose: () => void }> = ({ handleModalClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()
  const {
    name = '',
    description = '',
    address = {} as ICompanyAddress,
  } = useSelector(CurrentUserSelector.companyDetail)
  const { address1 = '', address2 = '', city = '', state = '', zip = '' } = address

  const states = useSelector(MetaSelectors.getUsaStatesNameAbbreviation)

  const formik = useFormik({
    initialValues: {
      name: name,
      description: description,
      address1: address1,
      address2: address2,
      state: state,
      zip: zip,
      city: city,
    },
    enableReinitialize: true,
    validationSchema: EditCompanyInfoSchema,
    onSubmit: async (values) => {
      try {
        const { name, description, ...address } = values

        const resultAction = await dispatch(
          CurrentUserActions.updateCurrentUserCompanyAsync({ name, description, address }) as any,
        )

        if (CurrentUserActions.updateCurrentUserCompanyAsync.fulfilled.match(resultAction)) {
          dispatch(
            SnackbarActions.showSnackbar({
              message: 'Company information successfully updated!',
              options: { variant: 'success' },
            }),
          )

          dispatch(CurrentUserActions.fetchCurrentUserCompanyAsync())
          handleModalClose()
        }
      } catch (error: any) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: error.message,
          }),
        )
      }
    },
  })

  const { errors, touched, handleSubmit, getFieldProps } = formik

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Typography
                fontSize="0.875rem"
                color="secondary"
                fontWeight={theme.typography.fontWeightMedium}
                mb={1}
              >
                Company name
              </Typography>

              <TextField
                fullWidth
                size="small"
                variant="outlined"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={(touched.name && errors.name) || ''}
                InputProps={{
                  sx: {
                    fontSize: '0.875rem',
                    borderRadius: '0.5rem',
                  },
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Typography
                fontSize="0.875rem"
                color="secondary"
                fontWeight={theme.typography.fontWeightMedium}
                mb={1}
              >
                Address 1
              </Typography>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                InputProps={{
                  sx: {
                    fontSize: '0.875rem',
                    borderRadius: '0.5rem',
                  },
                }}
                {...getFieldProps('address1')}
                error={Boolean(touched.address1 && errors.address1)}
                helperText={(touched.address1 && errors.address1) || ''}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Typography
                fontSize="0.875rem"
                color="secondary"
                fontWeight={theme.typography.fontWeightMedium}
                mb={1}
              >
                Address 2
              </Typography>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                InputProps={{
                  sx: {
                    fontSize: '0.875rem',
                    borderRadius: '0.5rem',
                  },
                }}
                {...getFieldProps('address2')}
                error={Boolean(touched.address2 && errors.address2)}
                helperText={(touched.address2 && errors.address2) || ''}
              />
            </Grid>

            <Grid item container xs={12} spacing={1.2}>
              <Grid item xs={4}>
                <Typography
                  fontSize="0.875rem"
                  color="secondary"
                  fontWeight={theme.typography.fontWeightMedium}
                  mb={1}
                >
                  State
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{
                    fontSize: '0.875rem',
                    borderRadius: '0.5rem',
                  }}
                  {...getFieldProps('state')}
                  error={Boolean(touched.state && errors.state)}
                >
                  {states &&
                    states.map((state) => (
                      <MenuItem key={state.abbreviation} value={state.abbreviation}>
                        {state.name} - {state.abbreviation}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  fontSize="0.875rem"
                  color="secondary"
                  fontWeight={theme.typography.fontWeightMedium}
                  mb={1}
                >
                  City
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputProps={{
                    sx: {
                      fontSize: '0.875rem',
                      borderRadius: '0.5rem',
                    },
                  }}
                  {...getFieldProps('city')}
                  error={Boolean(touched.city && errors.city)}
                  helperText={(touched.city && errors.city) || ''}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography
                  fontSize="0.875rem"
                  color="secondary"
                  fontWeight={theme.typography.fontWeightMedium}
                  mb={1}
                >
                  Zip
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputProps={{
                    sx: {
                      fontSize: '0.875rem',
                      borderRadius: '0.5rem',
                    },
                  }}
                  {...getFieldProps('zip')}
                  error={Boolean(touched.zip && errors.zip)}
                  helperText={(touched.zip && errors.zip) || ''}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontSize="0.875rem"
                color="secondary"
                fontWeight={theme.typography.fontWeightMedium}
                mb={1}
              >
                Description
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Enter description here"
                InputProps={{
                  sx: {
                    p: 1,
                    fontSize: '0.875rem',
                    borderRadius: '0.5rem',
                  },
                }}
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={(touched.description && errors.description) || ''}
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={2.5}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                height: '40px',
                minWidth: '100px',
                fontSize: '14px',
                textTransform: 'none',
                borderRadius: '0.5rem',
              }}
              disableElevation={true}
            >
              Save
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  )
}
