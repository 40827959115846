import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { GrantDetail } from '../../components/GrantDetail'
import { AppDispatch } from '../../store'
import { PublicActions, PublicSelector } from '../../store/public'
import { SnackbarActions } from '../../store/snackbar'

export const PublicGrant = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch<AppDispatch>()

  const { id: grantId = '' } = useParams<{ id: string }>()
  const accessKey: any = searchParams.get('key')

  const grant = useSelector(PublicSelector.getGrant)
  const grantError = useSelector(PublicSelector.getGrantError)

  useEffect(() => {
    if (grantError) {
      dispatch(SnackbarActions.showSnackbar({ message: grantError }))
    }

    return () => {
      dispatch(PublicActions.resetGrantError())
    }
  }, [grantError])

  useEffect(() => {
    const fetchGrant = async () => {
      try {
        await dispatch(PublicActions.fetchPulicGrantAsync({ grantId, accessKey }))
      } catch (error: any) {
        dispatch(SnackbarActions.showSnackbar({ message: error.message }))
      }
    }

    if (grantId && accessKey) {
      fetchGrant()
    }
  }, [dispatch, grantId, accessKey])

  return <GrantDetail grant={grant} />
}
