import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { UsersApi } from '../../../services'
import { AppDispatch } from '../../../store'
import { CurrentUserActions } from '../../../store/currentUser'
import { SnackbarActions } from '../../../store/snackbar'
// TODO: create a helper method for the password validation
const passwordResetSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Password is required'),
  newPassword: Yup.string()
    .required('New Password is required')
    .min(8, 'New Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%*^?&+=-])[A-Za-z\d~!@#$%*^?&+=-]{8,}$/,
      'Password at least include one uppercase letter, one lowercase, one number, and one special character (~!@#$%*^&+=)',
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .test((val, ctx) => {
      return val === ctx.parent.newPassword
    }),
})

export const ResetCurrentUserPassword = () => {
  const [state, setState] = useState({
    newPassword: false,
    currentPassword: false,
    confirmPassword: false,
  })
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const handleShowPassword = (key: string) => {
    setState({
      ...state,
      [key]: !(state as any)[key],
    })
  }
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: passwordResetSchema,
    onSubmit: async (
      { currentPassword, newPassword, confirmPassword },
      { setFieldValue, setFieldError, setTouched, resetForm },
    ) => {
      try {
        const request = await UsersApi.resetCurrentUserPassword({
          currentPassword,
          confirmPassword,
          newPassword,
        })
        dispatch(CurrentUserActions.fetchCurrentUserAsync)
        navigate('/home')
      } catch (err) {
        dispatch(SnackbarActions.showSnackbar({ message: 'err.message' }))
        resetForm()
      }
    },
  })
  useEffect(() => {
    console.log(formik.isValid, formik.dirty)
  }, [formik.isValid, formik.dirty])
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ flexGrow: 1 }}
    >
      <div>{formik.isValid}</div>
      <div>{formik.dirty}</div>
      <FormikProvider value={formik}>
        <Form style={{ minWidth: '400px', maxWidth: '500px', width: '500px' }} noValidate>
          <InputLabel htmlFor="currentPassword" sx={{ my: '8px' }}>
            Current Password
          </InputLabel>
          <TextField
            type={state.currentPassword ? 'text' : 'password'}
            id="currentPassword"
            sx={{ width: '100%' }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currentPassword}
            error={Object.hasOwnProperty.call(formik.errors, 'currentPassword')}
            helperText={formik.errors.currentPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleShowPassword('currentPassword')} edge="end">
                    {state.currentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <InputLabel htmlFor="newPassword" sx={{ my: '8px' }}>
            New Password
          </InputLabel>
          <TextField
            type={state.newPassword ? 'text' : 'password'}
            id="newPassword"
            sx={{ width: '100%' }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            error={Object.hasOwnProperty.call(formik.errors, 'newPassword')}
            helperText={formik.errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleShowPassword('newPassword')} edge="end">
                    {state.newPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <InputLabel htmlFor="confirmPassword" sx={{ my: '8px' }}>
            Confirm Password
          </InputLabel>
          <TextField
            type={state.confirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            sx={{ width: '100%' }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            error={Object.hasOwnProperty.call(formik.errors, 'confirmPassword')}
            helperText={formik.errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleShowPassword('confirmPassword')} edge="end">
                    {state.confirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <Stack direction="row">
            <Button
              variant="contained"
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
              sx={{ my: '8px', width: '100%' }}
            >
              Reset Password
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Box>
  )
}
