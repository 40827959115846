import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { TechnologiesApi } from '../../../services'
import { IMatchedGrant } from '../../../types/Grants'
import { normalizeError } from '../../../utils'
import { GrantMatchInitialState } from '../grantMatcher.slice'

export const fetchFilteredTechGrantsAsync = createAsyncThunk(
  'grantMatcher/fetchFilteredTechGrants',
  async (
    {
      _id,
      agencies,
      statuses,
      phases,
      deadline = 1,
    }: {
      _id: string
      agencies?: string[]
      statuses?: string[]
      phases?: string[]
      deadline?: number
    },
    { rejectWithValue },
  ) => {
    try {
      const agencyFilter =
        agencies && agencies?.length ? { 'solicitation.agency_abbr': { $in: agencies } } : {}

      const statusFilter =
        statuses && statuses?.length ? { 'solicitation.current_status': { $in: statuses } } : {}

      const phaseFilter = phases && phases?.length ? { 'solicitation.phase': { $in: phases } } : {}

      const $postFilter = JSON.stringify({ ...agencyFilter, ...statusFilter, ...phaseFilter })

      const $sort = `solicitation.close_date ${deadline}`

      const options = {
        params: {
          $includes: 'grant,solicitation',
          $sort,
          $postFilter,
          $paging: false,
        },
      }

      const req = await TechnologiesApi.fetchFilteredtechGrants(_id, options)
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching technology grants')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchFilteredTechGrantsAsyncBuilder = (
  builder: ActionReducerMapBuilder<GrantMatchInitialState>,
) => {
  builder.addCase(fetchFilteredTechGrantsAsync.pending, (state, action) => {
    state.grants.fetching = true
    state.grants.error = ''
  })
  builder.addCase(
    fetchFilteredTechGrantsAsync.fulfilled,
    (state, action: PayloadAction<IMatchedGrant[]>) => {
      state.grants.fetching = false
      state.grants.data = action.payload
    },
  )
  builder.addCase(fetchFilteredTechGrantsAsync.rejected, (state, action: PayloadAction<any>) => {
    state.grants.fetching = false
    state.grants.error = action.payload
  })
}
