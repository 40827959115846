import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { FormikHelpers, useFormik } from 'formik'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { GoogleIcon, LinkedInIcon, MicrosoftIcon } from '../../../components/icons'
import { AppDispatch, RootState } from '../../../store'
import { AuthActions } from '../../../store/auth'
import { FormsSelector } from '../../../store/forms'
import { SnackbarActions } from '../../../store/snackbar'

const iconStyle = {
  width: 60,
  height: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #E2E8F0',
  borderRadius: 1,
  margin: '0 8px',
}

export const LoginForm: FC<{ onLogin: () => void }> = ({ onLogin }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const { state } = useLocation()
  const formId = 'invitationEmail'
  const { email = '' } = useSelector((state: RootState) =>
    FormsSelector.getFormValues(state, formId),
  )

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email is not valid').required('Email is required'),
    password: Yup.string().required('Password is required'),
  })
  const url =
    (window.env && window.env.API_URL !== '__API_URL__' && window.env.API_URL) ||
    process.env.REACT_APP_API_URL
  const handleGoogleLogin = () => {
    localStorage.setItem('authProvider', 'google')
    window.location.href = `${url}/v1/auth/google`
  }

  const handleMicrosoftLogin = () => {
    localStorage.setItem('authProvider', 'microsoft')
    window.location.href = `${url}/v1/auth/microsoft`
  }

  const handleLinkedinLogin = () => {
    localStorage.setItem('authProvider', 'linkedin')
    window.location.href = `${url}/v1/auth/linkedin`
  }
  const handleForgotPasswordClick = () => {
    navigate('/forgotpassword')
  }

  const onSubmit = async (
    { email, password }: { email: string; password: string },
    _: FormikHelpers<{ email: string; password: string }>,
  ) => {
    try {
      const resultAction = await dispatch(AuthActions.userLoginAsync({ email, password }))
      if (AuthActions.userLoginAsync.fulfilled.match(resultAction)) {
        localStorage.setItem('authToken', resultAction.payload.token)
        onLogin()
      }
      if (AuthActions.userLoginAsync.rejected.match(resultAction)) {
        resetPasswordField()
        dispatch(SnackbarActions.showSnackbar({ message: resultAction.payload as string }))
      }
    } catch (error: any) {
      resetPasswordField()
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    }
  }

  const formik = useFormik({
    initialValues: {
      email: email as string,
      password: '',
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: onSubmit,
  })
  const { errors, touched, handleSubmit, getFieldProps, setTouched, setFieldValue } = formik

  const resetPasswordField = () => {
    setTouched({ password: false })
    setFieldValue('password', '')
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <>
      <Typography
        variant="h5"
        fontWeight={theme.typography.fontWeightMedium}
        fontSize={{ xs: '1.1rem', sm: '1.5rem' }}
        mb="1.5rem"
      >
        Sign in to find the perfect grant
      </Typography>
      <form autoComplete="off" noValidate>
        <Stack spacing={1}>
          <Box width="100%">
            <TextField
              type="email"
              label="Email"
              variant="outlined"
              fullWidth
              required
              autoFocus
              InputLabelProps={{ shrink: true }}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={(touched.email && errors.email) || ' '}
            />

            <TextField
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={(touched.password && errors.password) || ' '}
            />

            <Box display="flex" alignItems="center" justifyContent="right">
              <Typography
                color="primary.main"
                fontSize={{ xs: '14px', sm: 'medium' }}
                fontWeight={theme.typography.fontWeightMedium}
                sx={{
                  cursor: 'pointer',
                  mb: 3,
                }}
                onClick={handleForgotPasswordClick}
              >
                Forgot Password?
              </Typography>
            </Box>
          </Box>
          <Box width="100%">
            <Button
              variant="contained"
              type="button"
              disableElevation
              fullWidth
              onClick={() => handleSubmit()}
              sx={{
                height: '50px',
                fontSize: { xs: '0.857rem', sm: '1rem' },
                textTransform: 'none',
                fontWeight: 500,
                mb: 2,
              }}
            >
              Sign in
            </Button>

            <Typography
              sx={{
                textAlign: 'center',
                fontWeight: theme.typography.fontWeightBold,
                mb: 2,
              }}
            >
              Or
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" gap={1}>
            <IconButton sx={iconStyle} aria-label="Google" onClick={handleGoogleLogin}>
              <GoogleIcon />
            </IconButton>
            <IconButton sx={iconStyle} aria-label="Microsoft" onClick={handleMicrosoftLogin}>
              <MicrosoftIcon />
            </IconButton>
            <IconButton sx={iconStyle} aria-label="LinkedIn" onClick={handleLinkedinLogin}>
              <LinkedInIcon />
            </IconButton>
          </Box>
          {/*
          <Box textAlign="center" py={2}>
            <Typography
              component="span"
              fontSize="14px"
              fontWeight={theme.typography.fontWeightMedium}
              color="secondary.light"
            >
              No account?
            </Typography>
            <Button
              variant="text"
              sx={{
                textTransform: 'none',
                fontSize: { xs: '14px', sm: '0.9375rem' },
                fontWeight: 'bold',
              }}
              onClick={() => navigate('/register')}
            >
              Create account
            </Button>
          </Box>
          */}
        </Stack>
      </form>
    </>
  )
}
