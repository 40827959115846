import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const LinkedInIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group">
        <path
          id="Vector"
          d="M0 1.76065C0 0.815509 0.794438 0.0483398 1.77375 0.0483398H22.2262C23.2059 0.0483398 24 0.815509 24 1.76065V22.2403C24 23.1857 23.2059 23.9523 22.2262 23.9523H1.77375C0.794531 23.9523 0 23.1858 0 22.2406V1.76037V1.76065Z"
          fill="#006699"
        />
        <path
          id="Vector_2"
          d="M7.2933 20.0524V9.29076H3.70192V20.0524H7.29367H7.2933ZM5.49836 7.82169C6.75048 7.82169 7.53002 6.99532 7.53002 5.96259C7.50658 4.90633 6.75048 4.10303 5.52217 4.10303C4.29302 4.10303 3.49023 4.90633 3.49023 5.9625C3.49023 6.99522 4.26948 7.82159 5.47483 7.82159H5.49808L5.49836 7.82169ZM9.28117 20.0524H12.8723V14.0433C12.8723 13.7221 12.8957 13.4 12.9906 13.1706C13.2501 12.5277 13.841 11.8622 14.8333 11.8622C16.1325 11.8622 16.6525 12.849 16.6525 14.2958V20.0524H20.2435V13.882C20.2435 10.5766 18.472 9.03846 16.1093 9.03846C14.1721 9.03846 13.3213 10.1168 12.8486 10.8513H12.8725V9.29113H9.28136C9.32823 10.3007 9.28108 20.0528 9.28108 20.0528L9.28117 20.0524Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  )
}
