import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EmptyRejectedStateIcon } from '../../../../components/icons'
import { RightSlider } from '../../../../components/sliders'
import { AppDispatch } from '../../../../store'
import { GrantMatchActions } from '../../../../store/grantMatcher'
import { TechGrantsActions, TechGrantSelectors } from '../../../../store/technology'
import { Outcome } from '../../../../types/Grants'
import { EmptyState } from './EmptyState'
import { GrantOutcomeConfirmationModal } from './GrantOutcome'
import { GrantsTable } from './GrantsTable'
import { TechnologyGrant } from './TechnologyGrant'

interface RejectedGrantsProps {
  technologyId: string
}

export const RejectedGrants: React.FC<RejectedGrantsProps> = ({ technologyId }) => {
  const dispatch = useDispatch<AppDispatch>()

  const [showGrantOutomeModal, setShowGrantOutcomeModal] = useState(false)
  const [showGrantDetail, setShowGrantDetail] = useState<boolean>(false)
  const [selectedGrant, setSelectedGrant] = useState({
    topicId: '',
    refId: '',
    grantTitle: '',
    outcome: 'N/A',
  })

  const paging = useSelector(TechGrantSelectors.makeRejectedGrantsPaging)
  const grants = useSelector((state) => TechGrantSelectors.makeGrantsSummary(state, 'rejected'))

  const fetchRejectedGrants = useCallback(async () => {
    await dispatch(
      TechGrantsActions.fetchGrantsAsync({
        id: technologyId,
        status: 'rejected',
        options: {
          params: {
            $includes: 'grant,solicitation',
            $limit: paging.limit,
            $skip: paging.skip,
            $sort: `createdAt -1`,
          },
        },
      }),
    )
  }, [paging, technologyId, dispatch])

  useEffect(() => {
    fetchRejectedGrants()
  }, [fetchRejectedGrants])

  const handlePageUp = async () => {
    dispatch(
      TechGrantsActions.setPaging({ skip: paging.skip + paging.limit, grantType: 'rejected' }),
    )
  }

  const handlePageDown = async () => {
    const nextVal = paging.skip - paging.limit
    dispatch(
      TechGrantsActions.setPaging({ skip: nextVal < 0 ? 0 : nextVal, grantType: 'rejected' }),
    )
  }

  const handleOpenDrawer = (topicId: string) => {
    setSelectedGrant({ ...selectedGrant, topicId })
    setShowGrantDetail(true)
  }

  const handleCloseDrawer = () => {
    setShowGrantDetail(false)
    fetchRejectedGrants()
    dispatch(GrantMatchActions.resetGrantData())
  }

  const handleGrantOutcomeChange = (outcome: Outcome, refId: string, grantTitle: string) => {
    setSelectedGrant({ ...selectedGrant, outcome, refId, grantTitle })
    setShowGrantOutcomeModal(true)
  }

  return (
    <>
      <RightSlider
        open={showGrantDetail}
        setOpen={setShowGrantDetail}
        closeButtonAction={handleCloseDrawer}
      >
        <TechnologyGrant technologyId={technologyId} matchedTopicId={selectedGrant.topicId} />
      </RightSlider>

      <GrantOutcomeConfirmationModal
        cb={fetchRejectedGrants}
        refId={selectedGrant.refId}
        open={showGrantOutomeModal}
        setOpen={setShowGrantOutcomeModal}
        grantTitle={selectedGrant.grantTitle}
        outcome={selectedGrant.outcome as Outcome}
      />

      <GrantsTable
        paging={paging}
        tableData={grants as any}
        pageUpAction={handlePageUp}
        pageDownAction={handlePageDown}
        handleSlider={handleOpenDrawer}
        fetchGrants={fetchRejectedGrants}
        handleGrantOutcomeChange={handleGrantOutcomeChange}
        emptyComponent={
          <EmptyState message="Your reject grants are shown here" icon={EmptyRejectedStateIcon} />
        }
      />
    </>
  )
}
