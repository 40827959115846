import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const getForms = (state: RootState) => state.forms

const getFormValues = createSelector(
  getForms,
  (state: RootState, id: string) => id,
  (forms, id) => forms[id] || {},
)
export const FormsSelector = {
  getFormValues,
  getForms,
}
