import { ArrowBackRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const BackToTechnologiesButton = () => {
  const navigate = useNavigate()

  return (
    <Button
      startIcon={<ArrowBackRounded />}
      variant="text"
      sx={{
        color: '#0A1420',
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'none',
        mb: 2,
      }}
      onClick={() => navigate('/')}
    >
      Back to projects
    </Button>
  )
}
