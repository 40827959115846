import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { UsersApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { CurrentUserInitialState } from '../currentUser.slice'

export const removeCurrentUserCompanyMemberAsync = createAsyncThunk(
  'users/company/removeMember',
  async ({ id, currentRole }: { id: string; currentRole: string }, { rejectWithValue }) => {
    try {
      await UsersApi.removeCurrentUserCompanyMember(id, { currentRole })
      return
    } catch (error: any) {
      const normalized = normalizeError(error, 'removing member from company')
      return rejectWithValue(normalized)
    }
  },
)

export const removeCurrentUserCompanyMemberAsyncBuilder = (
  builder: ActionReducerMapBuilder<CurrentUserInitialState>,
) => {
  builder.addCase(removeCurrentUserCompanyMemberAsync.pending, (state) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(removeCurrentUserCompanyMemberAsync.fulfilled, (state, action) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(
    removeCurrentUserCompanyMemberAsync.rejected,
    (state, action: PayloadAction<any>) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    },
  )
}
