import { Box, Stack, Typography, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'

interface LoadingScreenProps {
  message: string
  loader: ReactNode
}

export const LoadingScreen: FC<LoadingScreenProps> = ({ message, loader }) => {
  const theme = useTheme()
  return (
    <Stack alignItems="center" justifyContent="center" gap={2}>
      <Box maxHeight={350} maxWidth={350} overflow="hidden">
        {loader}
      </Box>
      <Typography
        fontSize="1.75rem"
        fontWeight={theme.typography.fontWeightMedium}
        color="#0A1420"
        textAlign="center"
      >
        {message}
      </Typography>
    </Stack>
  )
}
