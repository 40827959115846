import { Paper, Table as MuiTable, TableContainer } from '@mui/material'
import { FC } from 'react'
import { TableBody } from './tableBody'
import { TableFooter } from './tableFooter'
import { TableHeader } from './tableHeader'
import { ITableProps } from './tableTypes'

export const Table: FC<ITableProps> = ({ containerProps, tableProps, head, body, footer }) => {
  return (
    <TableContainer component={Paper} {...containerProps}>
      <MuiTable {...tableProps}>
        {head && <TableHeader {...head} />}
        <TableBody {...body} />
        {footer && <TableFooter {...footer} />}
      </MuiTable>
    </TableContainer>
  )
}
