import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AuthApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { normalizeError } from '../../../utils'
import { AuthInitialState } from '../userAuth.slice'

export const googleSignupAsync = createAsyncThunk(
  'google/signup',
  async (options: RequestConfig, { rejectWithValue }) => {
    try {
      await AuthApi.googleSignup({}, options)
      return
    } catch (error: any) {
      const normalized = normalizeError(error, 'google signup')
      return rejectWithValue(normalized)
    }
  },
)

export const googleSignupAsyncBuilder = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  builder
    .addCase(googleSignupAsync.pending, (state) => {
      state.loading = true
      state.success = false
      state.error = ''
    })
    .addCase(googleSignupAsync.fulfilled, (state, action) => {
      state.loading = false
      state.success = true
    })
    .addCase(googleSignupAsync.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
}
