import { Card, CircularProgress, Typography } from '@mui/material'
import { FC } from 'react'

export const AuthLoader: FC<{ message: string }> = ({ message }) => {
  return (
    <Card
      sx={{
        py: 4,
        gap: 4,
        width: '100%',
        maxWidth: '480px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress size={60} />
      <Typography fontSize="1rem" color="secondary.main">
        {message}
      </Typography>
    </Card>
  )
}
