import { Box, Card, Stack, Typography } from '@mui/material'

export const EmailAuthPendingResponse = () => {
  return (
    <Stack alignItems="center" width="100%">
      <Card elevation={3} sx={{ padding: '16px', width: '25rem', height: '11rem' }}>
        <Box sx={{ marginTop: '70px', px: '18px' }}>
          <Typography color="secondary" fontSize="1.5rem" fontWeight={700}>
            Please wait!
          </Typography>
          <Typography color="secondary.light" fontSize="1rem" fontWeight={500}>
            Please wait while we verify email on our end
          </Typography>
        </Box>
      </Card>
    </Stack>
  )
}
