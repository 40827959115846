import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { UsersApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { CurrentUserInitialState } from '../currentUser.slice'

export interface ICompanyAddress {
  address1: string
  address2: string
  city: string
  state: string
  zip: string
}

export interface ICompanyInfo {
  name: string
  address: ICompanyAddress
  description?: string
}

export const updateCurrentUserCompanyAsync = createAsyncThunk(
  'users/updateCurrentUserCompany',
  async (data: Partial<ICompanyInfo>, { rejectWithValue }) => {
    try {
      await UsersApi.updateCurrentUserCompany(data)
      return
    } catch (error: any) {
      const normalized = normalizeError(error, 'updating user company')
      return rejectWithValue(normalized)
    }
  },
)

export const updateCurrentUserCompanyAsyncBuilder = (
  builder: ActionReducerMapBuilder<CurrentUserInitialState>,
) => {
  builder.addCase(updateCurrentUserCompanyAsync.pending, (state) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(updateCurrentUserCompanyAsync.fulfilled, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(updateCurrentUserCompanyAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
