import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { UsersApi } from '../../../services'
import { ICurrentUser } from '../../../types'
import { normalizeError } from '../../../utils'
import { CurrentUserInitialState } from '../currentUser.slice'

export const updateCurrentUserProfileAsync = createAsyncThunk(
  'users/updateUser',
  async (
    { firstName, lastName, phone }: { firstName: string; lastName: string; phone: string },
    { rejectWithValue },
  ) => {
    try {
      const user = await UsersApi.updateCurrentUser({ firstName, lastName, phone })

      const response = (user as AxiosResponse<any, any>).data
      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'updating user profile')
      return rejectWithValue(normalized)
    }
  },
)

export const updateCurrentUserProfileAsyncBuilder = (
  builder: ActionReducerMapBuilder<CurrentUserInitialState>,
) => {
  builder.addCase(updateCurrentUserProfileAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(
    updateCurrentUserProfileAsync.fulfilled,
    (state, action: PayloadAction<ICurrentUser>) => {
      state.loading = false
      state.success = true
      state.data.user = action.payload
    },
  )
  builder.addCase(updateCurrentUserProfileAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
