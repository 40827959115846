import { Card, CircularProgress, Stack, Typography, useTheme } from '@mui/material'

export const VerificationPending = () => {
  const theme = useTheme()
  return (
    <Stack alignItems="center" width="100%">
      <Card elevation={3}>
        <Stack p={2} alignItems="center" gap={2}>
          <CircularProgress />
          <Typography
            color="secondary.light"
            fontSize="1rem"
            fontWeight={theme.typography.fontWeightMedium}
            textAlign="center"
          >
            Please wait while we verify the invitation at our end
          </Typography>
        </Stack>
      </Card>
    </Stack>
  )
}
