import { Box, Button, TextField, useTheme } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { AppDispatch } from '../../../../../../../store'
import { CurrentUserActions } from '../../../../../../../store/currentUser'
import { SnackbarActions } from '../../../../../../../store/snackbar'
import { ValidationError } from '../../../../../../../utils/helper'
import { EmailList } from './EmailList'

const AddCompanyMembersFormSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid'),
})

export const AddCompanyMembersForm: FC<{ handleModalClose: () => void }> = ({
  handleModalClose,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [emails, setEmails] = useState<string[]>([])

  const handleDeleteEmail = (_email: string) => {
    const filteredEmails = emails.filter((email) => email !== _email)
    setEmails(filteredEmails)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: AddCompanyMembersFormSchema,
    onSubmit: async ({ email }, { setFieldValue, setFieldError, setTouched }) => {
      try {
        if (email) {
          const emailExists = emails.some((_email) => _email === email)

          if (emailExists) {
            setFieldError('email', 'emial is already in list')
            return
          }

          setEmails((prev) => [...prev, email])
          setFieldValue('email', '')
          setTouched({ email: false })
        }
      } catch (error: any) {}
    },
  })

  const handleAddMembers = async () => {
    try {
      if (values.email) {
        throw new ValidationError('please add the current email in list or remove it')
      }

      if (!emails.length) {
        throw new ValidationError('Invalid inputs in add members')
      }

      const inviteAction = await dispatch(
        CurrentUserActions.addCurrentUserCompanyMembersAsync({
          body: emails,
        }),
      )

      if (CurrentUserActions.addCurrentUserCompanyMembersAsync.fulfilled.match(inviteAction)) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: 'invite emails have sent successfully',
            options: { variant: 'success' },
          }),
        )
      }

      handleModalClose()
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message,
        }),
      )
    }
  }

  const { errors, touched, handleSubmit, getFieldProps, values } = formik

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <EmailList emails={emails} handleDelete={handleDeleteEmail} />

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            required
            fullWidth
            type="email"
            label="Email"
            variant="outlined"
            {...getFieldProps('email')}
            InputLabelProps={{ shrink: true }}
            error={Boolean(touched.email && errors.email)}
            helperText={(touched.email && errors.email) || ' '}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" variant="text" sx={{ textTransform: 'none' }}>
              Add email
            </Button>
          </Box>
        </Form>
      </FormikProvider>

      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          variant="contained"
          disableElevation={true}
          sx={{
            height: '40px',
            fontSize: '14px',
            textTransform: 'none',
            borderRadius: '0.5rem',
          }}
          onClick={handleAddMembers}
        >
          Send invite
        </Button>
      </Box>
    </Box>
  )
}
