import { Close } from '@mui/icons-material'
import { Box, BoxProps, Drawer, DrawerProps, SxProps, useTheme } from '@mui/material'
import { FC, MouseEventHandler, ReactNode } from 'react'

interface RightSliderProps {
  open: boolean
  setOpen: Function
  children: ReactNode
  drawerProps?: DrawerProps
  drawerSx?: SxProps
  outerBoxProps?: BoxProps
  outerBoxSx?: SxProps
  contentContainerProps?: BoxProps
  contentContainerSx?: SxProps
  hideDefaultCloseButton?: boolean
  onCloseCallback?: Function
  closeIcon?: ReactNode
  closeButtonProps?: BoxProps
  closeButtonSx?: SxProps
  enableBackdropClick?: boolean
  closeButtonAction: MouseEventHandler<HTMLDivElement>
}

const closeButtonStyles: SxProps = {
  mt: 3,
  width: 30,
  height: 28,
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
}

export const RightSlider: FC<RightSliderProps> = ({
  open,
  setOpen,
  children,
  drawerProps,
  drawerSx,
  outerBoxProps,
  outerBoxSx,
  closeIcon,
  contentContainerProps,
  contentContainerSx,
  closeButtonProps,
  closeButtonSx,
  closeButtonAction,
  enableBackdropClick,
  onCloseCallback,
  hideDefaultCloseButton,
}) => {
  const theme = useTheme()

  const afterSliderClose = () => {
    onCloseCallback && onCloseCallback()
  }

  const handleSliderClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (enableBackdropClick || hideDefaultCloseButton) {
      setOpen(false)
      afterSliderClose()
    }

    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false)
      afterSliderClose()
    }
  }

  return (
    <Drawer
      open={open}
      elevation={0}
      anchor="right"
      onClose={handleSliderClose}
      sx={{
        '.MuiDrawer-paper': {
          overflow: 'hidden',
          background: 'transparent',
        },
        ...(drawerSx || {}),
      }}
      {...(drawerProps || {})}
    >
      <Box
        sx={{
          gap: 2,
          display: 'flex',
          height: '100%',
          width: { xs: '90vw', lg: '75vw' },
          ...(outerBoxSx || {}),
        }}
        {...(outerBoxProps || {})}
      >
        {!hideDefaultCloseButton && (
          <Box
            onClick={closeButtonAction}
            sx={{ ...closeButtonStyles, ...(closeButtonSx || {}) }}
            {...(closeButtonProps || {})}
          >
            {closeIcon ? closeIcon : <Close sx={{ fontSize: 18 }} />}
          </Box>
        )}

        <Box
          sx={{
            py: 2,
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            background: theme.palette.grey[100],
            ...(contentContainerSx || {}),
          }}
          {...(contentContainerProps && contentContainerProps)}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  )
}
