import {
  DesktopDatePicker,
  DesktopDatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Moment } from 'moment'
import { FC } from 'react'

interface CustomdatePickerProps extends DesktopDatePickerProps<Moment | null> {
  onSelect: Function
}

export const CustomDatePicker: FC<CustomdatePickerProps> = ({ onSelect, ...rest }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        closeOnSelect
        onChange={(newValue) => onSelect(newValue)}
        sx={{
          '& fieldset': { display: 'none' },
        }}
        slotProps={{
          textField: {
            style: {
              width: 'min-content',
              padding: 0,
              margin: 0,
            },
          },
        }}
        {...rest}
      />
    </LocalizationProvider>
  )
}
