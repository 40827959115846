import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AuthSelector } from '../../store/auth'
import { CurrentUserSelector } from '../../store/currentUser'
import { RegistrationProgressSelectors } from '../../store/registrationProgress'
import { AuthHeader } from '../headers'

import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Footer } from './Footer'

export const RegistrationStepsLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const redirect = location.pathname === '/'

  const userRole = useSelector(AuthSelector.userRole)
  const validSub = useSelector(AuthSelector.isSubscriptionValid)
  const userInitials = useSelector(CurrentUserSelector.userInitials)

  const {
    overallStatus = '',
    profile = '',
    company = '',
    subscription = '',
    members = '',
  } = useSelector(RegistrationProgressSelectors.progressSteps)

  useEffect(() => {
    if (overallStatus === 'complete') {
      return navigate('/home', { replace: true })
    }

    if (userRole === 'ORG_MEMBER' && !userInitials && profile !== 'complete') {
      navigate('/steps', { replace: true })
    } else if (
      userRole !== 'ORG_MEMBER' &&
      ((!userInitials && profile !== 'complete') ||
        (!validSub && subscription !== 'complete') ||
        company !== 'complete' ||
        members !== 'complete')
    ) {
      navigate('/steps', { replace: true })
    }
  }, [userRole, userInitials, validSub, profile, subscription, members])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
      }}
    >
      <AuthHeader />
      <Box
        component="div"
        flexGrow={1}
        sx={{
          overflowY: 'auto',
          padding: '40px 24px 24px 24px',
        }}
      >
        {redirect && <Navigate to={'/steps'} />}
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}
