import { AddCircleRounded, AutoAwesome, CheckRounded, SaveRounded } from '@mui/icons-material'
import { Box, Button, Fab, Grid, Stack, SxProps, Typography, useTheme } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { EmptyRejectedStateIcon } from '../../components/icons'
import { SkeletonLoader } from '../../components/Loaders'
import { RightSlider } from '../../components/sliders'
import { AppDispatch, RootState } from '../../store'
import { CreateTechnologyActions } from '../../store/createTechnologyForm'
import { GrantMatchActions, GrantMatcherSelector } from '../../store/grantMatcher'
import { SnackbarActions } from '../../store/snackbar'
import {
  AgencyFilter,
  DeadlineFilter,
  EmptyState,
  GrantCard,
  GrantFilter,
  TechnologyGrant,
} from './subComponents'

const containerSx: SxProps = {
  overflowY: 'auto',
  margin: '-40px -24px -24px -24px',
  width: 'calc(100% + 48px)',
}

const bottomButtonBoxSx: SxProps = {
  p: 2,
  borderRadius: '8px',
  background: 'white',
  width: 'fit-content',
  transform: 'translateY(-50%)',
  filter: 'drop-shadow(0 10px 4px #f0ebeb)',
}

const bottomButtonSx: SxProps = {
  my: 'auto',
  minWidth: '190px',
  fontSize: '1.125rem',
  textTransform: 'none',
  color: 'secondary.main',
}

const fabSx: SxProps = {
  position: 'fixed',
  right: 40,
  bottom: 20,
  textTransform: 'none',
}

export const TechnologyGrants = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const gridRef = useRef<HTMLDivElement | null>(null)

  const { id = '' } = useParams()

  const [deadlineSort, setDeadlineSort] = useState<number>(1)
  const [selectedAgencies, setSelectedAgencies] = useState<string[]>([])
  const [selectedGrantType, setSelectedGrantType] = useState<string>('all')
  const [showGrantDetail, setShowGrantDetail] = useState<boolean>(false)
  const [isScrollBottom, setIsScrollBottom] = useState(false)
  const [selectedGrant, setSelectedGrant] = useState('')

  const fetchingGrants = useSelector(GrantMatcherSelector.getGrantsFetching)

  const grants = useSelector((state: RootState) =>
    GrantMatcherSelector.makeGrantsResponses(state, selectedGrantType),
  )
  const matchedGrantsCount = useSelector(GrantMatcherSelector.matchedGrantsCount)
  const grantsError = useSelector(GrantMatcherSelector.getGrantsError)

  useEffect(() => {
    if (grantsError) {
      dispatch(SnackbarActions.showSnackbar({ message: grantsError }))
    }

    return () => {
      dispatch(GrantMatchActions.resetGrantsError())
    }
  }, [grantsError])

  useEffect(() => {
    const container = gridRef.current

    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const fetchGrants = useCallback(
    async (agencies: string[], deadline: number) => {
      if (id) {
        await dispatch(
          GrantMatchActions.fetchFilteredTechGrantsAsync({
            _id: id,
            agencies,
            deadline,
          }) as any,
        )
      }
    },
    [id],
  )

  useEffect(() => {
    fetchGrants(selectedAgencies, deadlineSort)
  }, [fetchGrants, selectedAgencies, deadlineSort])

  const handleScroll = () => {
    const container = gridRef.current

    if (container) {
      const isAtBottom = container.scrollTop + container.clientHeight === container.scrollHeight

      setIsScrollBottom(isAtBottom)
    }
  }

  const handleBack = (path: string) => {
    dispatch(CreateTechnologyActions.resetState())
    navigate(path)
  }

  const handleAgencyFilter = (agencies: string[]) => {
    setSelectedAgencies(agencies)
    fetchGrants(agencies, deadlineSort)
  }

  const handleDeadlineFilter = (deadline: number) => {
    setDeadlineSort(deadline)
    fetchGrants(selectedAgencies, deadline)
  }

  const handleSliderClose = () => {
    setShowGrantDetail(false)
    fetchGrants(selectedAgencies, deadlineSort)
    dispatch(GrantMatchActions.resetGrantData())
  }

  return (
    <Grid ref={gridRef} px={2} container justifyContent="center" sx={containerSx}>
      <RightSlider
        open={showGrantDetail}
        setOpen={setShowGrantDetail}
        closeButtonAction={handleSliderClose}
      >
        <TechnologyGrant technologyId={id} matchedTopicId={selectedGrant} />
      </RightSlider>

      <Grid container item xs={12} md={8} justifyContent="center" sx={{ my: 4 }}>
        <Grid
          container
          item
          xs={12}
          sx={{ pt: 4, pb: 2 }}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography fontSize="1.5rem" fontWeight={700} mx={{ xs: 1 }}>
              Here are your grant results
            </Typography>
            <AutoAwesome sx={{ color: 'warning.light' }} />
          </Stack>

          <Button
            variant="text"
            startIcon={<AddCircleRounded />}
            sx={{
              fontSize: '0.8125rem',
              fontWeight: 700,
              textTransform: 'none',
              borderRadius: '1rem',
              px: 2,
              [theme.breakpoints.down('sm')]: {
                mt: 2,
              },
            }}
            onClick={() => handleBack('/addTechnology')}
          >
            Add new Project
          </Button>
        </Grid>

        <Grid item xs={12} my={2} mx={{ xs: 1 }}>
          <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
            <AgencyFilter handleFilteredAgencies={handleAgencyFilter} />
            <GrantFilter
              selectedGrantType={selectedGrantType}
              setSelectedGrantType={setSelectedGrantType}
            />
            <DeadlineFilter handleDeadlineSort={handleDeadlineFilter} deadlineSort={deadlineSort} />
          </Stack>
        </Grid>

        {!fetchingGrants && !grants.length && (
          <EmptyState
            icon={EmptyRejectedStateIcon}
            message={`We couldn't find any technologies to match that search.
                     Please adjust your technology description to try again`}
          />
        )}

        {fetchingGrants && <SkeletonLoader count={8} />}

        <Grid item xs={12} position="relative">
          {!fetchingGrants &&
            grants.map((item: any, index: number) => {
              const { agency, dueDate, _id, phaseValue, topicId, like, status } = item

              return (
                <Box
                  component="div"
                  key={_id}
                  mt={4}
                  mx={{ xs: 1 }}
                  onClick={() => {
                    setSelectedGrant(topicId)
                    setShowGrantDetail(true)
                  }}
                >
                  <GrantCard
                    grant={item}
                    like={like}
                    status={status}
                    refId={_id}
                    agency={agency}
                    deadline={dueDate}
                    phase={phaseValue}
                  />
                </Box>
              )
            })}

          <Stack alignItems="center" height={100} width="100%">
            {isScrollBottom && !!grants.length && (
              <Box sx={bottomButtonBoxSx} onClick={() => handleBack('/home')}>
                <Button
                  variant="contained"
                  disableElevation={true}
                  sx={{
                    ...bottomButtonSx,
                    background: theme.palette.grey[300],
                    '&:hover': {
                      background: theme.palette.grey[400],
                    },
                  }}
                >
                  Save for later
                </Button>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>

      {!fetchingGrants && !isScrollBottom && !!grants.length && (
        <Fab
          sx={fabSx}
          size="medium"
          color="secondary"
          variant="extended"
          onClick={() => handleBack('/home')}
        >
          {!matchedGrantsCount ? (
            <>
              <CheckRounded />
              All Done
            </>
          ) : (
            <>
              <SaveRounded />
              Save for later
            </>
          )}
        </Fab>
      )}
    </Grid>
  )
}
