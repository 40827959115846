import { Add, Close, Delete, SaveOutlined } from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, TextField, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../../../../../store'
import { AuthSelector } from '../../../../../store/auth'
import { SnackbarActions } from '../../../../../store/snackbar'
import { TechnologiesActions } from '../../../../../store/technologies'
import { TechnologyActions, TechnologySelectors } from '../../../../../store/technology'
import { DeleteTechnologyAlertBox } from '../../DeleteTechnologyAlertBox'

export const TechnologySettings = () => {
  const theme = useTheme()
  const params = useParams()
  const technologyId = params.id || ''
  const dispatch = useDispatch<AppDispatch>()

  const [newTitle, setNewTitle] = useState('')
  const [newDescription, setNewDescription] = useState('')
  const [showAddTagField, setShowAddTagField] = useState(false)
  const [newTag, setNewTag] = useState('')
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  const userId = useSelector(AuthSelector.userId)

  const selectedKeywords = useSelector(TechnologySelectors.makeSelectedKeywords)
  const loading = useSelector(TechnologySelectors.loading)
  const { title = '', description = '' } = useSelector(TechnologySelectors.getTechnology)

  const handleAddTagButtonClick = () => {
    setShowAddTagField(true)
  }

  const fetchTechnology = async () => {
    if (technologyId) {
      await dispatch(
        TechnologyActions.fetchTechnologyAsync({
          id: technologyId,
          options: {
            params: {
              $includes: 'users',
            },
          },
        }),
      )
    }
  }

  const handleSaveSettings = async () => {
    if (!technologyId || loading) {
      return
    }
    const updateAction = await dispatch(
      TechnologiesActions.updateTechnologiesAsync({
        techId: technologyId,
        body: { title: newTitle, description: newDescription, selectedKeywords },
      }),
    )

    if (TechnologiesActions.updateTechnologiesAsync.fulfilled.match(updateAction)) {
      await fetchTechnology()
      dispatch(
        SnackbarActions.showSnackbar({
          message: 'Project settings Updated!!!',
          options: { variant: 'success' },
        }),
      )
    }
  }

  const handleAddKeyword = () => {
    const trimmedKeyword = newTag.trim()

    if (trimmedKeyword && !selectedKeywords.includes(trimmedKeyword)) {
      const updatedKeywords = [...selectedKeywords, trimmedKeyword]

      dispatch(TechnologyActions.updateSelectedKeywords(updatedKeywords))

      setNewTag('')
      setShowAddTagField(false)
    }
  }

  const handleDeleteKeyword = (keyword: string) => {
    const updatedKeywords: string[] = selectedKeywords.filter((k) => k !== keyword)
    dispatch(TechnologyActions.updateSelectedKeywords(updatedKeywords))
  }

  useEffect(() => {
    if (userId) {
      dispatch(TechnologiesActions.fetchUserTechnologiesAsync({ userId }))
    }
  }, [userId, dispatch])

  useEffect(() => {
    setNewTitle(title)
    setNewDescription(description)
  }, [title, description])

  return (
    <>
      <DeleteTechnologyAlertBox
        open={showDeleteAlert}
        technologyId={technologyId}
        setOpen={setShowDeleteAlert}
      />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        px={{ xs: 2, sm: 6 }}
        mt={3}
      >
        <Box width="100%" mb={{ xs: '100%', sm: '70px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              mb: 3,
            }}
          >
            <Typography variant="h5" fontWeight={theme.typography.fontWeightMedium}>
              Technology settings
            </Typography>
            <Button
              disableElevation
              variant="contained"
              startIcon={<SaveOutlined />}
              sx={{ textTransform: 'capitalize', borderRadius: '8px' }}
              onClick={handleSaveSettings}
            >
              Save
            </Button>
          </Box>
          <Box gap={2} display="flex" justifyContent="space-between" flexDirection="column">
            <TextField
              type="text"
              size="medium"
              label="Technology title"
              value={newTitle}
              variant="outlined"
              onChange={(e) => setNewTitle(e.target.value)}
              InputProps={{
                endAdornment: loading && <CircularProgress size={14} sx={{ ml: 1 }} />,
              }}
              sx={{
                width: '100%',
                '& fieldset': { borderRadius: '0.3rem' },
                '& .MuiOutlinedInput-input': {
                  color: 'secondary.main',
                  fontSize: '1rem',
                  fontWeight: 500,
                  p: 2,
                },
              }}
            />
            <TextField
              type="text"
              size="medium"
              label="Technology description"
              value={newDescription}
              variant="outlined"
              multiline
              maxRows={4}
              onChange={(e) => setNewDescription(e.target.value)}
              InputProps={{
                endAdornment: loading && <CircularProgress size={14} sx={{ ml: 1 }} />,
              }}
              sx={{
                width: '100%',
                '& fieldset': { borderRadius: '0.3rem' },
                '& .MuiOutlinedInput-input': {
                  color: 'secondary.main',
                  fontSize: '1rem',
                  fontWeight: 500,
                },
              }}
            />
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" mt={5}>
            {selectedKeywords.map((keyword, index) => (
              <Chip
                key={index}
                label={keyword}
                component="div"
                variant="outlined"
                onDelete={() => handleDeleteKeyword(keyword)}
                deleteIcon={<Close />}
                sx={{
                  backgroundColor: 'success.light',
                  borderColor: 'success.main',
                  color: 'success.dark',
                  padding: '20px 10px',
                  fontWeight: theme.typography.fontWeightMedium,
                  m: 1,
                  '& .MuiChip-deleteIcon': {
                    display: 'none',
                  },
                  '&:hover .MuiChip-deleteIcon': {
                    display: 'block',
                  },
                }}
              />
            ))}
          </Box>
          <Box display="flex" justifyContent="flex-start" width="100%" mt={2}>
            {showAddTagField ? (
              <TextField
                type="text"
                size="medium"
                label="Add keyword"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddKeyword()
                  }
                }}
                variant="outlined"
                sx={{ width: '100%' }}
              />
            ) : (
              <Button
                startIcon={<Add />}
                onClick={handleAddTagButtonClick}
                variant="outlined"
                color="primary"
                size="small"
                sx={{
                  border: 'none',
                  textTransform: 'capitalize',
                  '&:hover': {
                    border: 'none',
                  },
                }}
              >
                Add keyword
              </Button>
            )}
          </Box>
        </Box>
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" mt="auto">
          <Button
            variant="outlined"
            color="error"
            startIcon={<Delete />}
            disabled={loading}
            onClick={() => setShowDeleteAlert(true)}
            sx={{
              border: 'none',
              textTransform: 'capitalize',
              '&:hover': {
                border: 'none',
              },
            }}
          >
            Delete technology
          </Button>
        </Box>
      </Box>
    </>
  )
}
