import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AuthApi } from '../../../services'
import { IAuth } from '../../../types'
import { normalizeError } from '../../../utils'
import decodeToken from '../../../utils/jwtUtil'
import { AuthInitialState } from '../userAuth.slice'

export const userSignUpAsync = createAsyncThunk(
  'auth/register',
  async (
    {
      email,
      password,
      recaptchaToken,
    }: { email: string; password: string; recaptchaToken: string },
    { rejectWithValue },
  ) => {
    try {
      const req = await AuthApi.RegisterUser({ email, password, recaptchaToken })
      const response = (req as AxiosResponse<any, any>).data
      localStorage.setItem('authToken', response.token)
      const payload = decodeToken(response.token)
      return { ...response.data, ...payload, token: response.token }
    } catch (error: any) {
      const normalized = normalizeError(error, 'signup')
      return rejectWithValue(normalized)
    }
  },
)

export const userSignUpAsyncBuilder = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  builder.addCase(userSignUpAsync.pending, (state) => {
    state.loading = true
    state.error = ''
  })
  builder.addCase(
    userSignUpAsync.fulfilled,
    (state, action: PayloadAction<IAuth & { token: string }>) => {
      const { token, ...rest } = action.payload
      state.isLoggedIn = true
      state.loading = false
      state.success = true
      state.data = rest
    },
  )
  builder.addCase(userSignUpAsync.rejected, (state, action: PayloadAction<any>) => {
    state.isLoggedIn = false
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
