import { Box, Button, TextField, Typography, useTheme } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { AppDispatch } from '../../../../../store'
import { CurrentUserActions } from '../../../../../store/currentUser'
import {
  RegistrationProgressActions,
  RegistrationProgressSelectors,
} from '../../../../../store/registrationProgress'
import { SnackbarActions } from '../../../../../store/snackbar'
import { EmailList } from './subComponent/EmailList'

interface InviteProps {
  handleNext: () => void
}

const InviteMemberSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid'),
})

export const InviteTeamMember: FC<InviteProps> = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [emails, setEmails] = useState<string[]>([])

  const { members = '' } = useSelector(RegistrationProgressSelectors.progressSteps)

  useEffect(() => {
    if (members === 'complete') {
      navigate('/completed', { replace: true })
    }
  }, [members])

  const handleDeleteEmail = (_email: string) => {
    const filteredEmails = emails.filter((email) => email !== _email)
    setEmails(filteredEmails)
  }

  const handleNext = async () => {
    await dispatch(
      RegistrationProgressActions.updateRegistrationProgressAsync({
        steps: { members: 'complete' },
      }),
    )
    navigate('/completed', { replace: true })
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: InviteMemberSchema,
    onSubmit: async ({ email }, { setFieldValue, setFieldError, setTouched }) => {
      try {
        if (email) {
          const emailExists = emails.some((_email) => _email === email)

          if (emailExists) {
            setFieldError('email', 'email is already in list')
            return
          }

          setEmails((prev) => [...prev, email])
          setFieldValue('email', '')
          setTouched({ email: false })
        }
      } catch (error: any) {}
    },
  })

  const { errors, touched, handleSubmit, getFieldProps, values } = formik

  const handleAddMembers = async () => {
    try {
      if (values.email) {
        throw new Error('please add the current email in list or remove it')
      }

      if (!emails.length) {
        throw new Error('Invalid inputs in invite members')
      }

      const reqPayload = {
        body: emails,
      }

      const inviteAction = await dispatch(
        CurrentUserActions.addCurrentUserCompanyMembersAsync(reqPayload),
      )

      if (CurrentUserActions.addCurrentUserCompanyMembersAsync.rejected.match(inviteAction)) {
        const errorMessage = inviteAction.payload as any
        throw new Error(errorMessage.message)
      }

      dispatch(
        SnackbarActions.showSnackbar({
          message: 'members have been invited!',
          options: { variant: 'success' },
        }),
      )

      await dispatch(
        RegistrationProgressActions.updateRegistrationProgressAsync({
          steps: { members: 'complete' },
        }),
      )

      navigate('/completed', { replace: true })
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message || 'An error occurred. Please try again.',
        }),
      )
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          mb: 2,
        }}
      >
        <Typography variant="h6" fontWeight={theme.typography.fontWeightBold}>
          Invite team member
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleNext}
          sx={{ border: 'none', textTransform: 'none' }}
        >
          Skip
        </Button>
      </Box>

      {emails.map((email) => (
        <EmailList key={email} email={email} onDelete={() => handleDeleteEmail(email)} />
      ))}

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            required
            fullWidth
            type="email"
            label="Email"
            variant="outlined"
            {...getFieldProps('email')}
            InputLabelProps={{ shrink: true }}
            error={Boolean(touched.email && errors.email)}
            helperText={(touched.email && errors.email) || ' '}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" variant="text" sx={{ textTransform: 'none' }}>
              Add email
            </Button>
          </Box>
        </Form>
      </FormikProvider>

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddMembers}
          sx={{
            height: '40px',
            fontWeight: 700,
            fontSize: '14px',
            textTransform: 'none',
          }}
        >
          Save and continue
        </Button>
      </Box>
    </Box>
  )
}
