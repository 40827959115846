import { useDroppable, UseDroppableArguments } from '@dnd-kit/core'
import { Stack, SxProps } from '@mui/material'
import { FC, ReactNode } from 'react'

interface DroppableProps extends UseDroppableArguments {
  children: ReactNode
  droppableSx?: SxProps
}

export const Droppable: FC<DroppableProps> = ({ children, droppableSx, ...droppableArgs }) => {
  const { setNodeRef } = useDroppable(droppableArgs)

  const style = {
    gap: 2,
    ...(droppableSx && droppableSx),
  }

  return (
    <Stack ref={setNodeRef} sx={style}>
      {children}
    </Stack>
  )
}
