import { Button, SxProps } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../../../../store'
import { SnackbarActions } from '../../../../../../../store/snackbar'
import { SubscriptionActions, SubscriptionSelectors } from '../../../../../../../store/subscription'

const buttonStyles: SxProps = {
  fontSize: '0.875rem',
  fontWeight: 500,
  textTransform: 'capitalize',
  borderRadius: '0.5rem',
  padding: '0.5rem 1rem',
}

export const ManageSubscription = () => {
  const dispatch = useDispatch<AppDispatch>()

  const loading = useSelector(SubscriptionSelectors.loading)
  const stripeSessionError = useSelector(SubscriptionSelectors.error)

  useEffect(() => {
    if (stripeSessionError) {
      SnackbarActions.showSnackbar({ message: stripeSessionError })
    }

    return () => {
      dispatch(SubscriptionActions.resetErrors())
    }
  }, [stripeSessionError])

  const handleManageSubscription = async () => {
    try {
      const resultAction = await dispatch(SubscriptionActions.createStripePortalSessionAsync())

      if (SubscriptionActions.createStripePortalSessionAsync.fulfilled.match(resultAction)) {
        const url = resultAction.payload as any
        window.open(url, '_blank')
      }
    } catch (error: any) {
      SnackbarActions.showSnackbar({ message: error.message })
    }
  }

  return (
    <Button
      disableElevation
      sx={buttonStyles}
      disabled={loading}
      variant="contained"
      onClick={handleManageSubscription}
    >
      Manage Subscription
    </Button>
  )
}
