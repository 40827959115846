import { Box, Button, Chip, Grid, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../store'
import {
  CreateTechnologyActions,
  CreateTechnologySelectors,
} from '../../../store/createTechnologyForm'

interface KeywordsFormProps {
  action: () => void | Promise<any>
}

export const KeywordsForm: FC<KeywordsFormProps> = ({ action }) => {
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme()

  const keywords = useSelector(CreateTechnologySelectors.makeKeywords)
  const selectedKeywords = useSelector(CreateTechnologySelectors.makeSelectedKeywords)

  const handleSubmitKeywords = async () => {
    action()
  }

  const handleSetSelectedKeywords = (key: any) => {
    dispatch(CreateTechnologyActions.setSelectedKeyWords(key))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        minHeight: '100%',
        paddingY: { xs: 3, sm: 0 },
      }}
    >
      <Typography
        color="secondary.light"
        fontSize="1rem"
        fontWeight={theme.typography.fontWeightMedium}
        my={4}
        display={{ xs: 'none', sm: 'block' }}
      >
        Below, you'll find a list of keywords related to the description you provided. Please feel
        free to choose the tags that best align with your specific needs.
      </Typography>
      <Grid container direction="row" spacing={3}>
        {keywords.map((key: string) => (
          <Grid item key={key}>
            <Chip
              label={key}
              component="div"
              onClick={() => handleSetSelectedKeywords(key)}
              sx={{
                padding: '20px 10px',
                border: '1px solid',
                fontWeight: 'fontWeightMedium',
                color: selectedKeywords.includes(key) ? 'success.dark' : 'grey.500',
                borderColor: selectedKeywords.includes(key) ? 'success.main' : 'grey.400',
                backgroundColor: selectedKeywords.includes(key) ? 'success.light' : 'grey.200',
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            size="medium"
            disabled={selectedKeywords.length === 0}
            sx={{
              fontSize: '1rem',
              fontWeight: theme.typography.fontWeightMedium,
              borderRadius: '0.3rem',
              textTransform: 'capitalize',
              float: 'right',
              maxWidth: '160px',
              height: '50px',
              margin: '3rem 0',
              paddingX: '1.5rem',
            }}
            type="button"
            onClick={handleSubmitKeywords}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
