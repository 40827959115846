import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { TechnologiesApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { normalizeError } from '../../../utils'
import { ITechnologyWithPagination, TechnologiesInitialState } from '../technologies.slice'

export const fetchUserTechnologiesAsync = createAsyncThunk(
  'technologies/user',
  async ({ userId, options }: { userId: string; options?: RequestConfig }, { rejectWithValue }) => {
    try {
      const res = await TechnologiesApi.fetchUserTechnologies(userId, options)
      return (res as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching technologies')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchTechnologiesAsyncBuilder = (
  builder: ActionReducerMapBuilder<TechnologiesInitialState>,
) => {
  builder.addCase(fetchUserTechnologiesAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(
    fetchUserTechnologiesAsync.fulfilled,
    (state, action: PayloadAction<ITechnologyWithPagination>) => {
      state.loading = false
      state.success = true
      state.data = action.payload
    },
  )
  builder.addCase(fetchUserTechnologiesAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
