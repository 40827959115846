import { createSlice } from '@reduxjs/toolkit'
import { IAuth } from '../../types'
import {
  googleAuthAsync,
  googleAuthAsyncBuilder,
  googleLoginAsync,
  googleLoginAsyncBuilder,
  googleSignupAsync,
  googleSignupAsyncBuilder,
  googleSignupTokenAsync,
  googleSignupTokensAsyncBuilder,
  linkedInAuthAsync,
  linkedInAuthAsyncBuilder,
  linkedinLoginAsync,
  linkedinLoginAsyncBuilder,
  linkedinSignupAsync,
  linkedinSignupAsyncBuilder,
  linkedinSignupTokenAsync,
  linkedinSignupTokenAsyncBuilder,
  microsoftAuthAsync,
  microsoftAuthAsyncBuilder,
  microsoftLoginAsync,
  microsoftLoginAsyncBuilder,
  microsoftSignupAsync,
  microsoftSignupAsyncBuilder,
  microsoftSignupTokenAsync,
  microsoftSignupTokenAsyncBuilder,
  refreshJwtAsync,
  refreshJwtAsyncBuilder,
  requestResetPasswordAsync,
  requestResetPasswordAsyncBuilder,
  resetPasswordAsync,
  resetPasswordAsyncBuilder,
  userLoginAsync,
  userLoginAsyncBuilder,
  userSignUpAsync,
  userSignUpAsyncBuilder,
  verifyCompanyInvitationAsync,
  verifyCompanyInvitationAsyncBuilder,
  verifyEmailAsync,
  verifyEmailAsyncBuilder,
} from './asyncRequests'

export interface AuthInitialState {
  data: IAuth
  loading?: boolean
  success?: boolean
  error?: string
  provider?: string
  token?: string
  accessToken?: string
  isLoggedIn?: boolean
}

const initialState: AuthInitialState = {
  data: {} as IAuth,
  loading: false,
  success: false,
  error: '',
  accessToken: '',
  provider: '',
  token: '',
  isLoggedIn: false,
}

export const UserAuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    logout: () => {
      localStorage.removeItem('authToken')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('authProvider')
      return initialState
    },
    resetError: (state) => {
      state.error = initialState.error
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
  },
  extraReducers: (builder) => {
    userLoginAsyncBuilder(builder)
    requestResetPasswordAsyncBuilder(builder)
    resetPasswordAsyncBuilder(builder)
    verifyEmailAsyncBuilder(builder)
    linkedInAuthAsyncBuilder(builder)
    linkedinLoginAsyncBuilder(builder)
    googleLoginAsyncBuilder(builder)
    googleAuthAsyncBuilder(builder)
    microsoftAuthAsyncBuilder(builder)
    microsoftLoginAsyncBuilder(builder)
    userSignUpAsyncBuilder(builder)
    googleSignupAsyncBuilder(builder)
    googleSignupTokensAsyncBuilder(builder)
    linkedinSignupAsyncBuilder(builder)
    linkedinSignupTokenAsyncBuilder(builder)
    microsoftSignupAsyncBuilder(builder)
    microsoftSignupTokenAsyncBuilder(builder)
    verifyCompanyInvitationAsyncBuilder(builder)
    refreshJwtAsyncBuilder(builder)
  },
})

export const AuthActions = {
  ...UserAuthSlice.actions,
  googleAuthAsync,
  googleLoginAsync,
  googleSignupAsync,
  googleSignupTokenAsync,
  linkedInAuthAsync,
  linkedinLoginAsync,
  linkedinSignupAsync,
  linkedinSignupTokenAsync,
  microsoftAuthAsync,
  microsoftLoginAsync,
  microsoftSignupAsync,
  microsoftSignupTokenAsync,
  refreshJwtAsync,
  requestResetPasswordAsync,
  resetPasswordAsync,
  userLoginAsync,
  userSignUpAsync,
  verifyCompanyInvitationAsync,
  verifyEmailAsync,
}

export const AuthReducer = UserAuthSlice.reducer
