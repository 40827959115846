import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { UsersApi } from '../../../services'
import { ICurrentUser } from '../../../types'
import { normalizeError } from '../../../utils'
import { UserAuthSlice } from '../../auth'
import { CurrentUserInitialState, CurrentUserSlice } from '../currentUser.slice'

export const fetchCurrentUserAsync = createAsyncThunk(
  'users/fetchCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const user = await UsersApi.fetchCurrentUser()
      return (user as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching user profile')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchCurrentUserAsyncBuilder = (
  builder: ActionReducerMapBuilder<CurrentUserInitialState>,
) => {
  builder.addCase(fetchCurrentUserAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(fetchCurrentUserAsync.fulfilled, (state, action: PayloadAction<ICurrentUser>) => {
    state.loading = false
    state.success = true
    state.data.user = action.payload
    state.isLoggedIn = true
  })
  builder.addCase(fetchCurrentUserAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
    CurrentUserSlice.caseReducers.resetUser(state, action)
    UserAuthSlice.caseReducers.logout()
  })
}
