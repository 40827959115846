import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { UsersApi } from '../../../services'
import { IRegsitrationProgress } from '../../../types'
import { normalizeError } from '../../../utils'
import { RegistrationProgressInitialState } from '../registrationProgress.slice'

export const updateRegistrationProgressAsync = createAsyncThunk(
  'users/updateregistrationProgress',
  async (steps: any, { rejectWithValue }) => {
    try {
      const user = await UsersApi.updateRegistrationProgress(steps)

      const response = (user as AxiosResponse<any, any>).data
      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'updating registration progress')
      return rejectWithValue(normalized)
    }
  },
)

export const updateRegistrationProgressAsyncBuilder = (
  builder: ActionReducerMapBuilder<RegistrationProgressInitialState>,
) => {
  builder.addCase(updateRegistrationProgressAsync.pending, (state, action) => {
    state.loading = true
    state.error = ''
  })
  builder.addCase(
    updateRegistrationProgressAsync.fulfilled,
    (state, action: PayloadAction<IRegsitrationProgress>) => {
      state.loading = false
      state.data = action.payload
    },
  )
  builder.addCase(updateRegistrationProgressAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.error = action.payload
  })
}
