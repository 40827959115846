import { createSlice } from '@reduxjs/toolkit'
import { IMatchedGrant, MatchedGrantStatus } from '../../types/Grants'
import { fetchMatchedGrantAsync } from './asyncRequests'
import { fetchGrantsAsync, fetchTechGrantsBuilder } from './asyncRequests/fetchGrantsAsync'

export interface IPaging {
  rows: number
  limit: number
  skip: number
  totalRows: number
  paging: boolean
}

interface IGrantsData {
  grants: IMatchedGrant[]
  paging: IPaging
}

export interface TechGrantsInitialState {
  data: { selected: IGrantsData; recommended: IGrantsData; rejected: IGrantsData }
  loading: boolean
  error: string
}

export const initialGrantsData: IGrantsData = {
  grants: [],
  paging: {
    rows: 0,
    totalRows: 0,
    limit: 25,
    skip: 0,
    paging: true,
  },
}

const initialState: TechGrantsInitialState = {
  data: {
    selected: initialGrantsData,
    recommended: initialGrantsData,
    rejected: initialGrantsData,
  },
  loading: false,
  error: '',
}

const TechGrantsSlice = createSlice({
  name: 'technology',
  initialState,
  reducers: {
    setPaging: (state, action) => {
      const grantType = action.payload.grantType as MatchedGrantStatus
      const currentPaging = state.data[grantType].paging

      const {
        limit = currentPaging.limit,
        skip = currentPaging.skip,
        paging = currentPaging.paging,
      } = action.payload

      state.data[grantType].paging = { ...currentPaging, skip, limit, paging }
    },

    setProgressStatus: (state, action) => {
      const { matchedGrantId, progressStatus } = action.payload
      const savedGrants = state.data['selected'].grants

      state.data['selected'].grants = savedGrants.map((grant) => {
        if (grant._id === matchedGrantId) {
          return { ...grant, progressStatus }
        }
        return grant
      })
    },

    resetData: (state) => {
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    fetchTechGrantsBuilder(builder)
  },
})

export const TechGrantsActions = {
  ...TechGrantsSlice.actions,
  fetchGrantsAsync,
  fetchMatchedGrantAsync,
}

export const TechGrantsReducer = TechGrantsSlice.reducer
