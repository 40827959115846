import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

interface EmailListProps {
  emails: string[]
  handleDelete: (email: string) => void
}

export const EmailList: FC<EmailListProps> = ({ emails, handleDelete }) => {
  const theme = useTheme()
  return (
    <>
      {emails &&
        emails.map((email) => (
          <Box
            key={email}
            display="flex"
            alignItems="center"
            mb={2}
            borderBottom={`1px solid ${theme.palette.divider}`}
          >
            <Typography flexGrow={1} mr={2}>
              {email}
            </Typography>
            <IconButton onClick={() => handleDelete(email)}>
              <DeleteIcon sx={{ color: 'error.dark' }} />
            </IconButton>
          </Box>
        ))}
    </>
  )
}
