import { CheckCircleOutlineRounded } from '@mui/icons-material'
import { Button, Card, Stack, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { EmailAuthResponseLayout } from './EmailAuthResponseLayout'

export const EmailAuthSuccess = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')

  return (
    <Stack alignItems="center">
      <Card elevation={3} style={{ padding: '16px', minHeight: 'auto', minWidth: '35%' }}>
        <EmailAuthResponseLayout
          logo={<CheckCircleOutlineRounded sx={{ color: 'primary.main', fontSize: '5rem' }} />}
          heading="Congratulations!"
          additionalSubHead={
            <Typography variant="body1">
              You have successfully validated the following email address:{' '}
              <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                {email}
              </Typography>
            </Typography>
          }
          actionButtons={[
            <Button
              key="continueLogin"
              variant="contained"
              sx={{
                fontSize: '1rem',
                borderRadius: '8px',
                textTransform: 'none',
                padding: '0.5rem 1.375rem',
                fontWeight: 500,
                width: '380px',
                mt: 1,
              }}
              onClick={() => navigate('/login')}
              disableElevation
            >
              Let's finish this setup
            </Button>,
          ]}
        />
      </Card>
    </Stack>
  )
}
