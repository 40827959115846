import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const getTitle = (state: RootState) => state.createTechnologyForm.technology.title
const getDescription = (state: RootState) => state.createTechnologyForm.technology.description
const getTechnology = (state: RootState) => state.createTechnologyForm.technology
const getSubmitting = (state: RootState) => state.createTechnologyForm.submitting
const getKeywords = (state: RootState) => state.createTechnologyForm.keywords
const error = (state: RootState) => state.createTechnologyForm.error

const makeKeywords = createSelector(getKeywords, (keywords) => [...new Set(keywords)])

const getSelectedKeywords = (state: RootState) => state.createTechnologyForm.selectedKeywords
const makeSelectedKeywords = createSelector(getSelectedKeywords, (keywords) => keywords)

const getStage = (state: RootState) => state.createTechnologyForm.stage
const getErrorMessage = (state: RootState) => state.createTechnologyForm.error
const getStageStatuses = (state: RootState) => state.createTechnologyForm.stageStatuses

const getKeywordsByTechnologyDescription = createSelector(
  [getDescription, getKeywords],
  (description, keywords) => {
    const filteredKeywords = keywords.filter((keyword) => description.includes(keyword))
    return filteredKeywords
  },
)

export const CreateTechnologySelectors = {
  getTitle,
  getDescription,
  getSubmitting,
  getStage,
  getErrorMessage,
  getTechnology,
  getStageStatuses,
  makeKeywords,
  getKeywords,
  makeSelectedKeywords,
  error,
  getKeywordsByTechnologyDescription,
}
