import { Box, Button, Card, Stack, TextField, Typography, useTheme } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { AppDispatch } from '../../../../store'
import { AuthActions, AuthSelector } from '../../../../store/auth'
import { SnackbarActions } from '../../../../store/snackbar'

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').required('Email is required'),
})

export const ForgotPassword = () => {
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme()

  const authError = useSelector(AuthSelector.error)

  useEffect(() => {
    if (authError) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: authError,
        }),
      )
    }

    return () => {
      dispatch(AuthActions.resetError())
    }
  }, [authError])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async ({ email }) => {
      try {
        const resultAction = await dispatch(AuthActions.requestResetPasswordAsync({ email }))

        if (AuthActions.requestResetPasswordAsync.fulfilled.match(resultAction)) {
          dispatch(
            SnackbarActions.showSnackbar({
              message: 'Password reset link sent to your email!',
              options: { variant: 'success' },
            }),
          )
        }
      } catch (error: any) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: error.message,
          }),
        )
      }
    },
  })

  const { errors, touched, handleSubmit, getFieldProps } = formik

  return (
    <Stack alignItems="center" width="100%">
      <Card
        elevation={3}
        sx={{ padding: '16px', width: { xs: 'auto', sm: '27rem' }, height: 'auto' }}
      >
        <Box sx={{ px: '18px', py: '18px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Typography
                color="secondary"
                fontSize="1.5rem"
                fontWeight={theme.typography.fontWeightBold}
                marginBottom={1}
              >
                Forgot password?
              </Typography>
              <Typography
                color="secondary.light"
                fontSize="0.9rem"
                fontWeight={theme.typography.fontWeightMedium}
                marginBottom={3}
              >
                Input your email address for us to send you a reset link
              </Typography>
              <TextField
                type="email"
                variant="outlined"
                fullWidth
                required
                autoFocus
                InputLabelProps={{ shrink: true }}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={(touched.email && errors.email) || ' '}
              />
              <Button
                fullWidth
                disableElevation={true}
                color="primary"
                variant="contained"
                size="medium"
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  borderRadius: '0.3rem',
                  textTransform: 'none',
                  float: 'right',
                  maxWidth: '100px',
                  height: '45px',
                  margin: '1rem 0',
                  padding: '8px 20px',
                }}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </FormikProvider>
        </Box>
      </Card>
    </Stack>
  )
}
