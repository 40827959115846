import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { GrantMatchApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { ICreateTechnologyFormState } from '../createTechnology.slice'

export const createMatchedGrantsAsync = createAsyncThunk(
  'createTechnologyForm/createMatchedGrants',
  async (body: { _id: string; keywords: string[]; query: string }, { rejectWithValue }) => {
    try {
      const req = await GrantMatchApi.createMatchedGrants(body)
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'creating matched grants')
      return rejectWithValue(normalized)
    }
  },
)

export const createMatchedGrantsAsyncBuilder = (
  builder: ActionReducerMapBuilder<ICreateTechnologyFormState>,
) => {
  builder.addCase(createMatchedGrantsAsync.pending, (state) => {
    state.submitting = true
    state.error = ''
  })
  builder.addCase(createMatchedGrantsAsync.fulfilled, (state, action) => {
    state.keywords = action.payload.keywords || []
    state.submitting = false
  })
  builder.addCase(createMatchedGrantsAsync.rejected, (state, action: PayloadAction<any>) => {
    state.submitting = false
    state.error = action.payload
  })
}
