import { Close } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Grid,
  Modal,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { TechnologySelectors } from '../../../../store/technology'
import { formatPhoneNumber } from '../../../../utils'

interface AddTechnologyMembersModelProps {
  open: boolean
  setOpen: Function
  userId: string
}

const modalContainerStyles: SxProps = {
  width: 500,
  minHeight: 290,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

const modalContainerSmStyles: SxProps = {
  bottom: 0,
  position: 'absolute',
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}

const closeButtonStyles: SxProps = {
  mr: 1,
  width: 30,
  height: 30,
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
}

export const UserDetailModel: FC<AddTechnologyMembersModelProps> = ({ open, setOpen, userId }) => {
  const theme = useTheme()
  const isXs = useMediaQuery('(max-width:600px)')

  const member = useSelector((state) => TechnologySelectors.makeTechnologyMember(state, userId))

  const sx = isXs ? modalContainerSmStyles : modalContainerStyles

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false)
    }
  }

  return (
    <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
      <Box sx={sx}>
        <Box mb={3} display="flex" justifyContent="flex-end" width="100%">
          <Box
            component="div"
            sx={closeButtonStyles}
            onClick={() => {
              setOpen(false)
            }}
          >
            <Close sx={{ fontSize: 18 }} />
          </Box>
        </Box>

        <Box
          sx={{
            p: 3,
            background: 'white',
            borderRadius: '0.5rem',
            [theme.breakpoints.down('sm')]: {
              borderRadius: '1rem 1rem 0 0',
            },
          }}
        >
          <Typography color="secondary" fontWeight={700} fontSize="1.3rem">
            Member Detail
          </Typography>

          {member && (
            <Grid container mt={2}>
              <Grid container item xs={2} mr={1}>
                <Avatar sx={{ width: 60, height: 60, background: '#E2E2E2' }} src={member.avatar}>
                  {member.firstName[0]}
                </Avatar>
              </Grid>

              <Grid item container xs={9} spacing={2}>
                <Grid item xs={12}>
                  <Typography fontSize="0.875rem" fontWeight={500} color="secondary.light">
                    Full name
                  </Typography>
                  <Typography
                    fontSize="0.9rem"
                    fontWeight={700}
                    color="secondary"
                  >{`${member.firstName} ${member.lastName}`}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    fontSize="0.875rem"
                    fontWeight={theme.typography.fontWeightMedium}
                    color="secondary.light"
                  >
                    Email
                  </Typography>
                  <Typography
                    fontSize="0.9rem"
                    fontWeight={theme.typography.fontWeightBold}
                    color="secondary"
                  >
                    {member.email}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    fontSize="0.875rem"
                    fontWeight={theme.typography.fontWeightMedium}
                    color="secondary.light"
                  >
                    Phone
                  </Typography>
                  <Typography
                    fontSize="0.9rem"
                    fontWeight={theme.typography.fontWeightBold}
                    color="secondary"
                  >
                    {formatPhoneNumber(member.phone) || '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
