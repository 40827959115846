import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SolicitationsSelector } from '../../../../../../store/solicitations'
import { SolicitationsCard } from './SolicitationsCard'

export const SolicitationsGrid = () => {
  const navigate = useNavigate()
  const solicitations = useSelector(SolicitationsSelector.makeSolicitations)

  return (
    <Grid container spacing={3}>
      {solicitations &&
        solicitations.map(({ _id, solicitationTitle, agency, openDate, closeDate, topicCount }) => (
          <Grid key={_id} item xs={12} md={6} onClick={() => navigate(`/solicitations/${_id}`)}>
            <SolicitationsCard
              title={solicitationTitle}
              agency={agency}
              openDate={openDate}
              closeDate={closeDate}
              grantCount={topicCount}
            />
          </Grid>
        ))}
    </Grid>
  )
}
