import { Diversity3Rounded, LoginRounded, MenuRounded } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, SxProps, Typography, useTheme } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const menuSx: SxProps = {
  '& .MuiPaper-root': {
    mt: 0.5,
    minWidth: 200,
    height: 'min-content',
    borderRadius: '0.5rem',
    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
  },
}

export const WelcomeNavPopover = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        [theme.breakpoints.up(768)]: {
          display: 'none',
        },
      }}
    >
      <IconButton
        onClick={handleClick}
        sx={{ background: open ? theme.palette.grey[400] : 'transparent' }}
      >
        <MenuRounded sx={{ fontSize: 28, color: 'secondary.main' }} />
      </IconButton>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={menuSx}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={0}
      >
        <MenuItem
          onClick={() => {
            navigate('/login')
            handleClose()
          }}
          sx={{
            gap: '20px',
          }}
          disableRipple
        >
          <LoginRounded fontSize="medium" />
          <Typography
            color="secondary.light"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="0.875rem"
          >
            Login
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/aboutus')
            handleClose()
          }}
          sx={{
            gap: '20px',
          }}
          disableRipple
        >
          <Diversity3Rounded fontSize="medium" sx={{ color: 'primary.dark' }} />
          <Typography
            color="primary.dark"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="0.875rem"
          >
            About Us
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
