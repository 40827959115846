import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AuthApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { AuthInitialState } from '../userAuth.slice'

export const verifyCompanyInvitationAsync = createAsyncThunk(
  'auth/verifyCompanyInvitation',
  async ({ email }: { email: string }, { rejectWithValue }) => {
    try {
      const user = await AuthApi.verifyCompanyInvitation({ email })

      const response = (user as AxiosResponse<any, any>).data

      if (!response) {
        throw new Error('unable to find the invitation detail')
      }
      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'verifying company invitation')
      return rejectWithValue(normalized)
    }
  },
)

export const verifyCompanyInvitationAsyncBuilder = (
  builder: ActionReducerMapBuilder<AuthInitialState>,
) => {
  builder.addCase(verifyCompanyInvitationAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(verifyCompanyInvitationAsync.fulfilled, (state, action) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(verifyCompanyInvitationAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
