import { Box, OutlinedTextFieldProps, styled, TextField } from '@mui/material'

interface StyledTextFieldProps extends OutlinedTextFieldProps {
  editable: boolean
}

export const StyledTextField = styled(({ editable, ...rest }: StyledTextFieldProps) => (
  <TextField {...rest} />
))<{ editable: boolean }>(({ theme, editable }) => ({
  '& fieldset': { border: !editable ? 'none' : '', borderRadius: '0.6rem' },
  '& .MuiOutlinedInput-input': {
    color: 'secondary.main',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightMedium,
    padding: !editable ? 0 : '',
  },
}))

export const UploadContainer = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1.2rem 2.5rem',
  borderRadius: '8px',
  border: `1px dashed ${theme.palette.grey[300]}`,
  gap: '0.3rem',
  '&:hover': {
    cursor: 'pointer',
    borderColor: theme.palette.primary.main,
    transition: 'border-color .1s ease-out',
  },
}))
