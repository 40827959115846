import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { GrantApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { normalizeError } from '../../../utils'
import { GrantsInitialState } from '../grants.slice'

export const createShareableGrantAsync = createAsyncThunk(
  'grants/createShareableGrantAsync',
  async (
    { grantId, options }: { grantId: string; options?: RequestConfig },
    { rejectWithValue },
  ) => {
    try {
      const res = await GrantApi.createShareableGrant(grantId, options)
      const data = (res as AxiosResponse<any, any>).data

      return data
    } catch (error: any) {
      const normalized = normalizeError(error, 'creating grant shareable URL')
      return rejectWithValue(normalized)
    }
  },
)

export const createShareableGrantAsyncBuilder = (
  builder: ActionReducerMapBuilder<GrantsInitialState>,
) => {
  builder.addCase(createShareableGrantAsync.pending, (state) => {
    state.submitting = true
    state.error = ''
  })
  builder.addCase(createShareableGrantAsync.fulfilled, (state) => {
    state.submitting = false
  })
  builder.addCase(createShareableGrantAsync.rejected, (state, action: PayloadAction<any>) => {
    state.error = action.payload
    state.submitting = false
  })
}
