import { Box, Grid, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../store'
import { CurrentUserActions, CurrentUserSelector } from '../../../store/currentUser'
import { UserInfo, UserProfileForm } from './subComponents'

export const MyAccount = () => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch<AppDispatch>()
  const userError = useSelector(CurrentUserSelector.error)

  useEffect(() => {
    if (userError) {
      enqueueSnackbar(userError, {
        variant: 'error',
        autoHideDuration: 3000,
      })
    }

    return () => {
      dispatch(CurrentUserActions.resetError())
    }
  }, [userError])

  return (
    <Box component="div" px={2} width="100%">
      <Stack gap="0.5rem">
        <Typography fontSize="1.5rem" fontWeight={500} color="secondary.main">
          My Account
        </Typography>
        <Typography fontSize="1rem" fontWeight={500} color="secondary.light">
          Welcome to your user profile!
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <UserInfo />
        </Grid>

        <Grid item xs={12} sm={6} md={8}>
          <UserProfileForm />
        </Grid>
      </Grid>
    </Box>
  )
}
