import { AxiosAPI } from './AxiosApi'

class GrantMatchApi extends AxiosAPI {
  public fetchKeywords = async (body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.grantMatch.keywords(), body, options)
  }
  public fetchRecommendations = async (body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.grantMatch.recommendations(), body, options)
  }
  public createMatchedGrants = async (body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.grantMatch.grants(), body, options)
  }
  public fetchGrants = async (body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.grantMatch.grants(), body, options)
  }
}

export default new GrantMatchApi()
