import { Grid, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SnackbarActions } from '../../../../../store/snackbar'
import { MetricsActions, MetricsSelector } from '../../../../../store/TechnologyMetrics'
import { GrantsActivity, GrantsCountChart, GrantsWinLossChart } from './subComponents'

export const TechnologyMetrics: React.FC = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const params = useParams()
  const techId = params.id || ''
  const won = useSelector(MetricsSelector.selectWon)
  const loss = useSelector(MetricsSelector.selectLoss)
  const applied = useSelector(MetricsSelector.getAppliedGrants)

  useEffect(() => {
    const fetchMatrices = async () => {
      try {
        await dispatch(
          MetricsActions.fetchTechnologyMetricsAsync({
            techId: techId,
          }) as any,
        )
      } catch (error: any) {
        dispatch(SnackbarActions.showSnackbar({ message: error.message }))
      }
    }

    if (techId) {
      fetchMatrices()
    }
  }, [])

  return (
    <Grid container spacing={3} px={4}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight={theme.typography.fontWeightBold}
          gutterBottom
          px={2}
          mt={2}
        >
          Metrics
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <GrantsActivity won={won} loss={loss} applied={applied} />
      </Grid>
      <Grid item xs={12}>
        <GrantsWinLossChart />
      </Grid>
      <Grid item xs={12}>
        <GrantsCountChart />
      </Grid>
    </Grid>
  )
}
