import { ArrowDropDownRounded, CloseRounded } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, MouseEvent, useState } from 'react'
import { filterContainerStyles, menuStyles } from './searchFilters.styles'

interface StatusFilterProps {
  handleStatusChange: Function
  selectedStatuses: string[]
}

const statuses = [
  {
    label: 'Open',
    value: 'open',
  },
  {
    label: 'Future',
    value: 'forecasted',
  },
]

export const StatusFilter: FC<StatusFilterProps> = ({ handleStatusChange, selectedStatuses }) => {
  const theme = useTheme()

  const [filteredStatus, setFilteredStatus] = useState<string[]>(selectedStatuses)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    handleStatusChange(filteredStatus)
  }

  const handleFilterChange = (status: string) => {
    const isSelected = filteredStatus.includes(status)

    if (!isSelected) {
      setFilteredStatus([...filteredStatus, status])
    } else {
      setFilteredStatus(filteredStatus.filter((phase) => phase !== status))
    }
  }

  const handleResetFilters = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setFilteredStatus([])
    handleStatusChange([])
  }

  const filterTitle = (
    <>
      {!filteredStatus.length ? (
        <>
          <Typography
            color="secondary"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="1rem"
            ml={1}
          >
            Status
          </Typography>

          <ArrowDropDownRounded sx={{ fontSize: 28, color: 'secondary' }} />
        </>
      ) : (
        <>
          <Typography
            color="secondary"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="1rem"
            mx={1}
          >
            {filteredStatus.length}
            {filteredStatus.length > 1 ? ' Statuses' : ' Status'}
          </Typography>
          <IconButton onClick={handleResetFilters} sx={{ p: 0.1 }}>
            <CloseRounded sx={{ fontSize: 22, color: 'secondary.light' }} />
          </IconButton>
        </>
      )}
    </>
  )

  return (
    <Box>
      <Box
        component="div"
        sx={{
          ...filterContainerStyles,
          background: filteredStatus.length ? theme.palette.grey[300] : 'white',
          border: `1px solid ${theme.palette.grey[300]}`,
        }}
        onClick={handleClick}
      >
        {filterTitle}
      </Box>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} elevation={0} sx={menuStyles}>
        {statuses.map(({ label, value }) => (
          <Box
            key={label}
            component="div"
            sx={{
              py: 1,
              px: 2,
              '&:hover': {
                cursor: 'pointer',
                background: theme.palette.grey[100],
              },
            }}
          >
            <FormControlLabel
              control={<Checkbox />}
              checked={filteredStatus.includes(value)}
              label={label}
              onChange={() => handleFilterChange(value)}
            />
          </Box>
        ))}
      </Menu>
    </Box>
  )
}
