import { CheckCircleOutlineRounded } from '@mui/icons-material'
import { Button, Card, Stack } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UsersApi } from '../../../../../../services'
import { VerificationResponseLayout } from './VerificationResponseLayout'

export const VerificationSuccess: FC<{ email: string | null }> = ({ email }) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [path, setPath] = useState('/register')

  useEffect(() => {
    const checkUserExists = async () => {
      try {
        const encodedEmail = encodeURIComponent(email as string)
        const user = await UsersApi.fetchUser(encodedEmail)

        if (user) {
          setPath('/login')
        }
      } catch (error: any) {}
      setLoading(false)
    }

    if (email) {
      checkUserExists()
    }
  }, [email])

  return (
    <Stack alignItems="center">
      <Card elevation={3} style={{ padding: '16px', minHeight: 'auto', minWidth: '35%' }}>
        <VerificationResponseLayout
          logo={<CheckCircleOutlineRounded sx={{ color: 'primary.main', fontSize: '5rem' }} />}
          heading="Congratulations!"
          subHeading="Your Invite for the GrantMatch has been verified successfully."
          actionButton={[
            <Button
              key="accountSetup"
              variant="contained"
              sx={{
                mt: 1,
                width: '380px',
                fontWeight: 500,
                fontSize: '1rem',
                borderRadius: '8px',
                textTransform: 'none',
                padding: '0.5rem 1.375rem',
              }}
              onClick={() => navigate(path, { replace: true })}
              disableElevation
              disabled={loading}
            >
              Let's get started
            </Button>,
          ]}
        />
      </Card>
    </Stack>
  )
}
