import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { SubscriptionApi } from '../../../services'
import { StripeCheckoutError, StripeCheckoutSuccess } from '../StripeCheckout/subComponents'

export const StripePaymentRedirect = () => {
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const sessionId = urlParams.get('session_id') || ''

  const [status, setStatus] = useState('')

  useEffect(() => {
    const checkSessionStatus = async () => {
      try {
        const res = await SubscriptionApi.fetchStripeCheckoutSessionStatus({
          params: {
            sessionId,
          },
        })
        const data = (res as AxiosResponse<any, any>).data
        setStatus(data.status)
      } catch (error) {
        setStatus('error')
      }
    }

    if (sessionId) {
      checkSessionStatus()
    }
  }, [sessionId])

  return (
    <>
      {status === 'open' && <Navigate to="/steps" />}
      {status === 'complete' && <StripeCheckoutSuccess />}
      {status === 'error' && <StripeCheckoutError />}
    </>
  )
}
