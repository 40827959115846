import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { TechnologiesApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { MetricsInitialState } from '../metrics.slice'

export const fetchTechnologyMetricsAsync = createAsyncThunk(
  'technologies/matchedGrants/metrics',
  async ({ techId }: { techId: string }, { rejectWithValue }) => {
    try {
      const res = await TechnologiesApi.fetchTechnologyMetrics(techId)
      return (res as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching technologies matrices')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchTechnologyMetricsAsyncBuilder = (
  builder: ActionReducerMapBuilder<MetricsInitialState>,
) => {
  builder.addCase(fetchTechnologyMetricsAsync.pending, (state) => {
    state.loading = true
    state.error = false
  })
  builder.addCase(fetchTechnologyMetricsAsync.fulfilled, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.error = false
    state.data = action.payload
  })
  builder.addCase(fetchTechnologyMetricsAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.error = action.payload
  })
}
