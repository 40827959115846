import { Chip as MuiChip, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

export const GrantsTableChip: FC<{
  value: string
  textColor?: string
  backgroundColor?: string
}> = ({ value, textColor, backgroundColor }) => {
  const theme = useTheme()
  const chipDefaultColor = backgroundColor || theme.palette.grey[200]
  return (
    <MuiChip
      variant="outlined"
      sx={{
        backgroundColor: chipDefaultColor,
        borderRadius: '24px',
        border: 'transparent',
        my: '.2rem',
        width: 'min-content',
        '& .MuiChip-label': {
          padding: 1,
        },
      }}
      label={
        <Typography variant="body1" sx={{ color: textColor, fontWeight: 500, fontSize: '13px' }}>
          {value}
        </Typography>
      }
    />
  )
}
