import { Box, Button, Chip, Divider, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const WelcomePageFooter = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem',
        width: '100%',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontWeight: 'bold',
          fontSize: '20px',
          padding: '12px 30px',
          borderRadius: '50px',
          textTransform: 'capitalize',
        }}
        onClick={() => navigate('/register')}
      >
        Fund Your Tech
      </Button>
      <Typography
        variant="h5"
        sx={{
          marginTop: '1rem',
          textAlign: 'center',
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        Go from idea to income with a better grant strategy.
      </Typography>
      <Divider sx={{ width: { xs: '100%', md: '80%' }, marginTop: '4rem', marginBottom: '1rem' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: { xs: '0.8rem', md: '50px' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Button
          variant="text"
          key={'aboutus'}
          sx={{ textTransform: 'capitalize', color: 'black' }}
          href={'/aboutus'}
        >
          About Us
        </Button>
        <Button
          variant="text"
          key={'contactus'}
          sx={{ textTransform: 'capitalize', color: 'black' }}
          href={'#'}
        >
          Contact Us
        </Button>
        <Button
          variant="text"
          key={'privacy'}
          sx={{ textTransform: 'capitalize', color: 'black' }}
          href={'/privacy'}
        >
          Privacy Policy
        </Button>
        <Button
          variant="text"
          key={'terms'}
          sx={{ textTransform: 'capitalize', color: 'black' }}
          href={'/termsofuse'}
        >
          Terms of Use
        </Button>
        <Button
          variant="text"
          key={'useragreement'}
          sx={{ textTransform: 'capitalize', color: 'black' }}
          href={'/useragreement'}
        >
          User Agreement
        </Button>
      </Box>
      <Chip
        label="Finding Grants Sucks. We made it awesome"
        sx={{ marginTop: '2rem', fontWeight: theme.typography.fontWeightBold }}
      />
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ marginTop: '2rem', fontWeight: theme.typography.fontWeightBold }}
      >
        © 2024 Catalyzing Concepts LLC
      </Typography>
    </Box>
  )
}
