import { Box } from '@mui/material'
import { FC, PropsWithChildren, useEffect } from 'react'
import { BaseHeader } from '../headers'

import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import withAuth from '../../hoc/withAuth'
import { AppDispatch } from '../../store'
import { AuthSelector } from '../../store/auth'
import { CurrentUserSelector } from '../../store/currentUser'
import { RegistrationProgressActions } from '../../store/registrationProgress'
import { SubscriptionTrialCountDown } from '../../views/Subscription/StripeCheckout/subComponents/SubscriptionTrialCountDown'
import { Footer } from './Footer'

interface BaseLayoutProps extends PropsWithChildren {
  header?: JSX.Element
}

const BaseLayoutContainer: FC<BaseLayoutProps> = ({ children, header }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()

  const isLoggedIn = useSelector(AuthSelector.isLoggedIn)
  const validSub = useSelector(AuthSelector.isSubscriptionValid)
  const tempPassword = useSelector(CurrentUserSelector.isTempPassword)

  useEffect(() => {
    const state = {
      state: {
        from: `${location.pathname}${location.search}`,
      },
    }
    if (!isLoggedIn) {
      navigate('/login', state)
    }
    if (isLoggedIn && tempPassword) {
      navigate('/resetuserpassword', state)
    }
  }, [isLoggedIn, tempPassword])

  const handleCheckProfileProgress = async () => {
    const req = await dispatch(RegistrationProgressActions.fetchRegistrationProgressAsync())
    if (RegistrationProgressActions.fetchRegistrationProgressAsync.fulfilled.match(req)) {
      const { steps } = req.payload
      if (steps.overallStatus !== 'complete') {
        navigate(`/steps`)
      }
    }
  }

  useEffect(() => {
    handleCheckProfileProgress()
  }, [])

  if (!validSub) {
    return <Navigate to="/invalidSubscription" />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
      }}
    >
      <BaseHeader />
      <SubscriptionTrialCountDown />
      <Box
        component="div"
        sx={{
          overflowY: 'auto',
          display: 'flex',
          flexGrow: 1,
          padding: '40px 24px 24px 24px',
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}

export const BaseLayout = withAuth(BaseLayoutContainer)
