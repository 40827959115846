import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { AuthApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { normalizeError } from '../../../utils'
import decodeToken from '../../../utils/jwtUtil'
import { AuthInitialState } from '../userAuth.slice'

export const microsoftLoginAsync = createAsyncThunk(
  'microsoft/login',
  async (options: RequestConfig, { rejectWithValue }) => {
    try {
      const req = await AuthApi.loginWithMicrosoft({}, options)
      const response = (req as AxiosResponse).data

      localStorage.setItem('authToken', response.token)

      const payload = decodeToken(response.token)

      return { ...response.data, ...payload, token: response.token }
    } catch (error: any) {
      const normalized = normalizeError(error, 'microsoft login')
      return rejectWithValue(normalized)
    }
  },
)

export const microsoftLoginAsyncBuilder = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  builder
    .addCase(microsoftLoginAsync.pending, (state) => {
      state.loading = true
      state.success = false
      state.error = ''
    })
    .addCase(microsoftLoginAsync.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
      state.success = true
    })
    .addCase(microsoftLoginAsync.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
}
