import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const DeadlineIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.3934 5C24.3934 5 26.2684 10 23.1434 11.25C20.5059 7.8225 22.2778 0.625 13.7684 0.625C13.7684 0.625 18.1572 5.85875 14.7103 12.2706C12.749 10.3331 12.8065 8.39562 12.8065 8.39562C10.379 10.3949 8.46293 12.944 7.21721 15.8317C5.97149 18.7193 5.43198 21.8622 5.6434 25C5.6434 28.8125 7.15791 32.4688 9.85374 35.1647C12.5496 37.8605 16.2059 39.375 20.0184 39.375C23.8309 39.375 27.4872 37.8605 30.1831 35.1647C32.8789 32.4688 34.3934 28.8125 34.3934 25C34.4219 21.1139 33.5308 17.2762 31.7928 13.8003C30.0549 10.3244 27.5194 7.30886 24.3934 5Z"
        fill="#FF786E"
      />
      <path
        d="M20.9556 39.3413C24.5974 39.1032 28.0125 37.4889 30.5079 34.8256C33.0033 32.1624 34.3923 28.6497 34.3931 25C34.4134 21.6287 33.7452 18.2887 32.4295 15.1847C31.1137 12.0806 29.1781 9.27787 26.7412 6.94813C26.7512 7.01938 26.7606 7.09125 26.7693 7.16438C26.8301 7.67683 26.7817 8.19636 26.6273 8.68876C26.4729 9.18116 26.2159 9.63528 25.8733 10.0212C25.5308 10.4072 25.1104 10.7162 24.6398 10.928C24.1692 11.1398 23.6591 11.2495 23.1431 11.25C20.7362 8.125 21.9999 1.85625 15.7681 0.785629C16.7647 2.13607 17.4329 3.70019 17.7196 5.35389C18.0064 7.0076 17.9037 8.70537 17.4199 10.3125C17.2336 10.8837 16.8708 11.381 16.3838 11.7329C15.8968 12.0847 15.3107 12.273 14.7099 12.2706C13.998 11.5866 13.4479 10.7521 13.0999 9.82813C11.1607 11.8264 9.66206 14.2091 8.70061 16.8223C7.73917 19.4356 7.33632 22.2214 7.51806 25C7.51886 28.6497 8.90784 32.1624 11.4033 34.8256C13.8987 37.4889 17.3137 39.1032 20.9556 39.3413Z"
        fill="#FF5050"
      />
      <path
        d="M11.2687 5C9.88804 5 8.76867 3.60062 8.76867 1.875C8.77069 1.69715 8.78489 1.51966 8.81117 1.34375C8.21312 1.5828 7.70578 2.00452 7.36147 2.54882C7.01716 3.09311 6.85345 3.7322 6.89367 4.375C6.89367 6.10062 8.01304 7.5 9.39367 7.5C10.6287 7.5 11.648 6.375 11.8512 4.90625C11.6626 4.96578 11.4664 4.99736 11.2687 5Z"
        fill="#FF786E"
      />
      <path
        d="M8.80122 2.28375C8.36604 2.89361 8.13561 3.62583 8.1431 4.375C8.10542 5.00968 8.26551 5.64043 8.60133 6.18031C8.93714 6.72018 9.43214 7.14262 10.0181 7.38938C10.5234 7.18562 10.9623 6.8456 11.2858 6.4072C11.6094 5.96879 11.8049 5.44919 11.8506 4.90625C11.6621 4.96578 11.4658 4.99737 11.2681 5C9.99997 5 8.9631 3.8125 8.80122 2.28375Z"
        fill="#FF5050"
      />
      <path
        d="M20 33.75C24.8325 33.75 28.75 29.8325 28.75 25C28.75 20.1675 24.8325 16.25 20 16.25C15.1675 16.25 11.25 20.1675 11.25 25C11.25 29.8325 15.1675 33.75 20 33.75Z"
        fill="#E6F0FF"
      />
      <path
        d="M21.25 17.5C19.5951 17.5004 17.9742 17.97 16.5755 18.8545C15.1768 19.7389 14.0576 21.0019 13.3478 22.4968C12.6379 23.9917 12.3666 25.6573 12.5652 27.3002C12.7638 28.9432 13.4243 30.4961 14.47 31.7787C16.1496 33.1469 18.277 33.8433 20.4406 33.7333C22.6041 33.6233 24.6499 32.7146 26.182 31.183C27.7142 29.6515 28.6237 27.6061 28.7346 25.4426C28.8455 23.2791 28.1499 21.1514 26.7825 19.4712C25.2221 18.1936 23.2667 17.4969 21.25 17.5Z"
        fill="#F5FAFF"
      />
      <path
        d="M20.625 24.375H19.375V20C19.375 19.8342 19.4408 19.6753 19.5581 19.5581C19.6753 19.4408 19.8342 19.375 20 19.375C20.1658 19.375 20.3247 19.4408 20.4419 19.5581C20.5592 19.6753 20.625 19.8342 20.625 20V24.375Z"
        fill="#3F4D56"
      />
      <path
        d="M24.375 25.6419H20.625V24.3919H24.375C24.5408 24.3919 24.6997 24.4577 24.8169 24.5749C24.9342 24.6922 25 24.8511 25 25.0169C25 25.1826 24.9342 25.3416 24.8169 25.4588C24.6997 25.576 24.5408 25.6419 24.375 25.6419Z"
        fill="#3F4D56"
      />
      <path
        d="M20 18.125C19.8342 18.125 19.6753 18.0592 19.5581 17.9419C19.4408 17.8247 19.375 17.6658 19.375 17.5V15.625H20.625V17.5C20.625 17.6658 20.5592 17.8247 20.4419 17.9419C20.3247 18.0592 20.1658 18.125 20 18.125Z"
        fill="#3F4D56"
      />
      <path
        d="M20.625 34.375H19.375V32.5C19.375 32.3342 19.4408 32.1753 19.5581 32.0581C19.6753 31.9408 19.8342 31.875 20 31.875C20.1658 31.875 20.3247 31.9408 20.4419 32.0581C20.5592 32.1753 20.625 32.3342 20.625 32.5V34.375Z"
        fill="#3F4D56"
      />
      <path
        d="M15.709 18.8175L14.7715 17.1925L15.854 16.5675L16.7915 18.1925C16.8744 18.3361 16.8968 18.5066 16.8539 18.6668C16.811 18.8269 16.7063 18.9634 16.5627 19.0463C16.4192 19.1291 16.2486 19.1516 16.0885 19.1087C15.9284 19.0658 15.7919 18.9611 15.709 18.8175Z"
        fill="#3F4D56"
      />
      <path
        d="M24.1462 33.4313L23.2087 31.8063C23.1259 31.6627 23.1034 31.4921 23.1463 31.332C23.1892 31.1719 23.2939 31.0354 23.4375 30.9525C23.581 30.8696 23.7516 30.8472 23.9117 30.8901C24.0718 30.933 24.2084 31.0377 24.2912 31.1813L25.2287 32.8063L24.1462 33.4313Z"
        fill="#3F4D56"
      />
      <path
        d="M13.1924 21.7912L11.5674 20.8537L12.1924 19.7712L13.8174 20.7087C13.9609 20.7916 14.0657 20.9281 14.1086 21.0882C14.1515 21.2483 14.129 21.4189 14.0461 21.5625C13.9633 21.706 13.8267 21.8108 13.6666 21.8537C13.5065 21.8966 13.3359 21.8741 13.1924 21.7912Z"
        fill="#3F4D56"
      />
      <path
        d="M27.8066 30.2288L26.1816 29.2913C26.0381 29.2084 25.9333 29.0719 25.8904 28.9118C25.8475 28.7516 25.87 28.5811 25.9529 28.4375C26.0358 28.294 26.1723 28.1892 26.3324 28.1463C26.4925 28.1034 26.6631 28.1259 26.8066 28.2088L28.4316 29.1463L27.8066 30.2288Z"
        fill="#3F4D56"
      />
      <path
        d="M12.5 25.625H10.625V24.375H12.5C12.6658 24.375 12.8247 24.4408 12.9419 24.5581C13.0592 24.6753 13.125 24.8342 13.125 25C13.125 25.1658 13.0592 25.3247 12.9419 25.4419C12.8247 25.5592 12.6658 25.625 12.5 25.625Z"
        fill="#3F4D56"
      />
      <path
        d="M29.375 25.625H27.5C27.3342 25.625 27.1753 25.5592 27.0581 25.4419C26.9408 25.3247 26.875 25.1658 26.875 25C26.875 24.8342 26.9408 24.6753 27.0581 24.5581C27.1753 24.4408 27.3342 24.375 27.5 24.375H29.375V25.625Z"
        fill="#3F4D56"
      />
      <path
        d="M12.1934 30.2288L11.5684 29.1463L13.1934 28.2088C13.3369 28.1259 13.5075 28.1034 13.6676 28.1463C13.8277 28.1892 13.9642 28.294 14.0471 28.4375C14.13 28.5811 14.1524 28.7516 14.1095 28.9118C14.0666 29.0719 13.9619 29.2084 13.8184 29.2913L12.1934 30.2288Z"
        fill="#3F4D56"
      />
      <path
        d="M25.9539 21.5625C25.871 21.4189 25.8485 21.2483 25.8914 21.0882C25.9343 20.9281 26.0391 20.7916 26.1826 20.7087L27.8076 19.7712L28.4326 20.8537L26.8076 21.7912C26.664 21.8741 26.4935 21.8966 26.3334 21.8537C26.1732 21.8108 26.0367 21.706 25.9539 21.5625Z"
        fill="#3F4D56"
      />
      <path
        d="M15.854 33.4313L14.7715 32.8063L15.709 31.1813C15.7919 31.0377 15.9284 30.933 16.0885 30.8901C16.2486 30.8472 16.4192 30.8696 16.5627 30.9525C16.7063 31.0354 16.811 31.1719 16.8539 31.332C16.8968 31.4921 16.8744 31.6627 16.7915 31.8063L15.854 33.4313Z"
        fill="#3F4D56"
      />
      <path
        d="M23.4375 19.0463C23.2939 18.9634 23.1892 18.8269 23.1463 18.6668C23.1034 18.5066 23.1259 18.3361 23.2087 18.1925L24.1462 16.5675L25.2287 17.1925L24.2912 18.8175C24.2084 18.9611 24.0718 19.0658 23.9117 19.1087C23.7516 19.1516 23.581 19.1291 23.4375 19.0463Z"
        fill="#3F4D56"
      />
      <path
        d="M20 13.75C17.775 13.75 15.5999 14.4098 13.7498 15.646C11.8998 16.8821 10.4578 18.6391 9.60636 20.6948C8.75487 22.7505 8.53209 25.0125 8.96617 27.1948C9.40025 29.3771 10.4717 31.3816 12.0451 32.955C13.6184 34.5283 15.623 35.5998 17.8052 36.0338C19.9875 36.4679 22.2495 36.2451 24.3052 35.3936C26.3609 34.5422 28.1179 33.1002 29.354 31.2502C30.5902 29.4001 31.25 27.225 31.25 25C31.25 22.0163 30.0647 19.1548 27.955 17.045C25.8452 14.9353 22.9837 13.75 20 13.75ZM20 33.75C18.2694 33.75 16.5777 33.2368 15.1388 32.2754C13.6998 31.3139 12.5783 29.9473 11.9161 28.3485C11.2538 26.7496 11.0805 24.9903 11.4181 23.293C11.7558 21.5956 12.5891 20.0365 13.8128 18.8128C15.0365 17.5891 16.5956 16.7557 18.293 16.4181C19.9903 16.0805 21.7496 16.2538 23.3485 16.9161C24.9473 17.5783 26.3139 18.6998 27.2754 20.1388C28.2368 21.5777 28.75 23.2694 28.75 25C28.75 27.3206 27.8281 29.5462 26.1872 31.1872C24.5462 32.8281 22.3206 33.75 20 33.75Z"
        fill="#FFD246"
      />
      <path
        d="M21.2498 15C19.1001 14.9985 16.9952 15.6133 15.1843 16.7716C13.3735 17.9299 11.9327 19.5831 11.0327 21.5353C10.1327 23.4874 9.8113 25.6567 10.1065 27.7859C10.4018 29.9152 11.3013 31.9151 12.6985 33.5488C14.8487 35.3903 17.6146 36.3526 20.4435 36.2436C23.2724 36.1346 25.956 34.9622 27.9582 32.9607C29.9603 30.9592 31.1335 28.276 31.2434 25.4471C31.3533 22.6182 30.3918 19.8521 28.551 17.7013C26.5187 15.9565 23.9283 14.9981 21.2498 15ZM19.9998 33.75C18.2692 33.75 16.5774 33.2368 15.1385 32.2754C13.6996 31.3139 12.5781 29.9473 11.9158 28.3485C11.2535 26.7496 11.0803 24.9903 11.4179 23.293C11.7555 21.5956 12.5889 20.0365 13.8126 18.8128C15.0363 17.5891 16.5954 16.7558 18.2927 16.4181C19.99 16.0805 21.7494 16.2538 23.3482 16.9161C24.9471 17.5783 26.3137 18.6998 27.2751 20.1388C28.2366 21.5777 28.7498 23.2694 28.7498 25C28.7498 27.3206 27.8279 29.5462 26.1869 31.1872C24.546 32.8281 22.3204 33.75 19.9998 33.75Z"
        fill="#FFBE37"
      />
      <path
        d="M20 26.5625C20.8629 26.5625 21.5625 25.8629 21.5625 25C21.5625 24.1371 20.8629 23.4375 20 23.4375C19.1371 23.4375 18.4375 24.1371 18.4375 25C18.4375 25.8629 19.1371 26.5625 20 26.5625Z"
        fill="#5A6978"
      />
    </SvgIcon>
  )
}
