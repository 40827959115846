import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertBox } from '../../../components/AlertBox'
import { AppDispatch } from '../../../store'
import { SnackbarActions } from '../../../store/snackbar'
import { TechnologiesActions, TechnologiesSelectors } from '../../../store/technologies'
import { ValidationError } from '../../../utils/helper'

interface DeleteTechnologyAlertBoxProps {
  open: boolean
  setOpen: Function
  technologyId: string
  cb: Function
}

export const DeleteTechnologyAlertBox: FC<DeleteTechnologyAlertBoxProps> = ({
  open,
  setOpen,
  technologyId,
  cb,
}) => {
  const dispatch = useDispatch<AppDispatch>()

  const loading = useSelector(TechnologiesSelectors.loading)

  const handleDeleteTechnology = async () => {
    try {
      if (!technologyId) {
        throw new ValidationError('invalid project id')
      }

      const resultAction = await dispatch(
        TechnologiesActions.deleteTechnologyAsync({ techId: technologyId }),
      )

      if (TechnologiesActions.deleteTechnologyAsync.fulfilled.match(resultAction)) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: 'Project has been deleted successfully!',
            options: { variant: 'success' },
          }),
        )
        setOpen(false)
        cb()
      }
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message,
        }),
      )
    }
  }

  return (
    <AlertBox
      open={open}
      setOpen={setOpen}
      title="Delete project?"
      description="Are you sure you want to delete this project?"
      confirmAction={handleDeleteTechnology}
      denyAction={() => setOpen(false)}
      confirmButtonProps={{ disabled: loading, label: 'Delete project' }}
      denyButtonProps={{ disabled: loading, label: 'Cancel' }}
    />
  )
}
