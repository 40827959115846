import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavItemStyle, NavTextStyle } from './navItem.styled'

interface NavItemProps {
  title: string
  path: string
  active: boolean
}

export const NavItem: FC<NavItemProps> = ({ title, path, active }) => {
  const navigate = useNavigate()
  return (
    <NavItemStyle onClick={() => navigate(path)} isActive={active}>
      <NavTextStyle isActive={active}>{title}</NavTextStyle>
    </NavItemStyle>
  )
}
