import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const getUsaStates = (state: RootState) => state.meta.usaStates
const getQuestionAnswers = (state: RootState) => state.meta.faq

const getUsaStatesNameAbbreviation = createSelector([getUsaStates], (usaStates) =>
  usaStates.map((state) => ({ name: state.name, abbreviation: state.abbreviation })),
)

const questionsAnswers = createSelector([getQuestionAnswers], (usaStates) =>
  usaStates.map((items) => ({ question: items.question, answer: items.answer })),
)

export const MetaSelectors = {
  getUsaStatesNameAbbreviation,
  questionsAnswers,
}
