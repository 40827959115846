import { ArrowDropDownRounded } from '@mui/icons-material'
import { Box, Menu, SxProps, Typography, useTheme } from '@mui/material'
import { FC, MouseEvent, useState } from 'react'

interface GrantFilterProps {
  selectedGrantType: string
  setSelectedGrantType: Function
}

type GrantTypes = {
  [key: string]: string
}

const filterContainerStyles: SxProps = {
  minWidth: '175px',
  height: '44px',
  borderRadius: '44px',
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  padding: '0 0.5rem',
}

const menuStyles: SxProps = {
  '& .MuiPaper-root': {
    ml: 1,
    mt: 1,
    minWidth: 160,
    maxWidth: 400,
    borderRadius: '0.5rem',
    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
                rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
  },
}

const Grant_Types: GrantTypes = {
  all: 'Matched Grants',
  selected: 'Saved Grants',
  rejected: 'Rejected Grants',
}

export const GrantFilter: FC<GrantFilterProps> = ({ selectedGrantType, setSelectedGrantType }) => {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleGrantTypeChange = (type: string) => {
    setSelectedGrantType(type)
    setAnchorEl(null)
  }

  return (
    <Box>
      <Box
        component="div"
        sx={{
          ...filterContainerStyles,
          border: `1px solid ${theme.palette.grey[300]}`,
        }}
        onClick={handleClick}
      >
        <Typography color="secondary" fontWeight={700} fontSize="1rem" ml={1}>
          {Grant_Types[selectedGrantType]}
        </Typography>
        <ArrowDropDownRounded sx={{ fontSize: 28, color: 'secondary' }} />
      </Box>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} elevation={0} sx={menuStyles}>
        {Object.keys(Grant_Types).map((value) => {
          const label = Grant_Types[value]

          return (
            <Box
              key={label}
              component="div"
              sx={{
                py: 1,
                px: 2,
                '&:hover': {
                  background: theme.palette.grey[100],
                  cursor: 'pointer',
                },
              }}
              onClick={() => handleGrantTypeChange(value)}
            >
              <Typography>{label}</Typography>
            </Box>
          )
        })}
      </Menu>
    </Box>
  )
}
