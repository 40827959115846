import { ArrowDropDownRounded } from '@mui/icons-material'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { Avatar, Box, MenuItem, Typography, useTheme } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../../store'
import { AuthActions, AuthSelector } from '../../../store/auth'
import { CurrentUserSelector } from '../../../store/currentUser'
import { BuildingIcon, ProfileIcon } from '../../icons'
import { StyledMenu } from './AccountPopover.styled'

export const AccountPopover = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const { firstName = '', avatar = '' } = useSelector(CurrentUserSelector.userDetail)
  const userInitials = useSelector(CurrentUserSelector.userInitials)
  const userRole = useSelector(AuthSelector.userRole)
  const userRoleInCompany = useSelector(AuthSelector.userCompanyRole)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          component="div"
          onClick={handleClick}
          sx={{
            height: '38px',
            padding: '4px',
            cursor: 'pointer',
            minWidth: '100px',
            borderRadius: '40px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            background: open ? theme.palette.grey[400] : theme.palette.grey[200],
            [theme.breakpoints.down(768)]: {
              minWidth: 0,
              gap: 0,
            },
          }}
        >
          <Avatar sx={{ width: 32, height: 32, fontSize: '1rem' }} src={avatar}>
            {userInitials}
          </Avatar>
          <Typography
            color="secondary"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="1rem"
            sx={{
              [theme.breakpoints.down(768)]: {
                display: 'none',
              },
            }}
          >
            {firstName}
          </Typography>
          <ArrowDropDownRounded sx={{ fontSize: 28 }} />
        </Box>
      </Box>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/profile/myAccount')
            handleClose()
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 0,
          }}
          disableRipple
        >
          <ProfileIcon fontSize="large" sx={{ mt: '15px' }} />
          <Typography
            color="secondary.light"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="0.875rem"
            ml={1}
          >
            View profile
          </Typography>
        </MenuItem>

        {(userRole === 'ORG_ADMIN' || userRoleInCompany === 'COMPANY_ADMIN') && (
          <MenuItem
            onClick={() => {
              navigate('/profile/company')
              handleClose()
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: { xs: 0, sm: 1 },
            }}
            disableRipple
          >
            <BuildingIcon sx={{ color: 'secondary.light' }} />
            <Typography
              color="secondary.light"
              fontWeight={theme.typography.fontWeightMedium}
              fontSize="0.875rem"
              ml={2}
            >
              Company profile
            </Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            dispatch(AuthActions.logout())
            handleClose()
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '15px',
          }}
          disableRipple
        >
          <LogoutOutlinedIcon fontSize="small" sx={{ color: 'error.main' }} />
          <Typography
            color="error.main"
            fontWeight={theme.typography.fontWeightMedium}
            fontSize="0.875rem"
            ml={1}
          >
            Logout
          </Typography>
        </MenuItem>
      </StyledMenu>
    </Box>
  )
}
