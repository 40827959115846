import { AddCircleRounded } from '@mui/icons-material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Button, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RightSlider } from '../../../../components/sliders'
import { AppDispatch } from '../../../../store'
import { SolicitationsActions, SolicitationsSelector } from '../../../../store/solicitations'
import { AddToTechnologyModal } from './AddToTechnologyModal'
import { Grant } from './Grant'
import { GrantCard } from './GrantCard'
import { Pagination } from './Pagination'

const addTechBtnStyles = {
  fontWeight: 700,
  fontSize: '0.8125rem',
  borderRadius: '0.5rem',
  textTransform: 'capitalize',
}

export const Grants = () => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))

  const [selectedGrantId, setSelectedGrantId] = useState('')
  const [showModel, setShowModel] = useState(false)
  const [openSlider, setOpenSlider] = useState(false)

  const grants = useSelector(SolicitationsSelector.makeGrants)
  const paging = useSelector(SolicitationsSelector.getGrantsPaging)
  const solicitationNumber = useSelector(SolicitationsSelector.makeSolicitationNumber)

  const { limit, skip } = paging

  useEffect(() => {
    if (solicitationNumber) {
      const options = {
        params: {
          $limit: limit,
          $skip: skip,
        },
      }

      dispatch(SolicitationsActions.fetchSolicitationGrantsAsync({ solicitationNumber, options }))
    }
  }, [limit, skip, solicitationNumber])

  const handleAddToTechnology = async (e: MouseEvent<HTMLButtonElement>, grantId: string) => {
    e.stopPropagation()
    setSelectedGrantId(grantId)
    setShowModel(true)
  }

  const handleSlider = (grantId: string) => {
    setSelectedGrantId(grantId)
    setOpenSlider(true)
  }

  return (
    <>
      <AddToTechnologyModal open={showModel} setOpen={setShowModel} grantId={selectedGrantId} />

      <RightSlider
        open={openSlider}
        setOpen={setOpenSlider}
        closeButtonAction={() => setOpenSlider(false)}
      >
        <Grant grantId={selectedGrantId} />
      </RightSlider>

      <Grid container spacing={3} my={3}>
        {grants &&
          grants.map((item) => {
            const { grantTitle, solicitationNumber, agency, closeDate, _id, phase } = item

            const AddToTechnologyButton = xs ? (
              <IconButton onClick={(e) => handleAddToTechnology(e, _id)}>
                <AddCircleRounded color="primary" />
              </IconButton>
            ) : (
              <Button
                sx={addTechBtnStyles}
                startIcon={<AddOutlinedIcon />}
                onClick={(e) => handleAddToTechnology(e, _id)}
              >
                Add To Project
              </Button>
            )

            return (
              <Grid item key={_id} xs={12} md={6} onClick={() => handleSlider(_id)}>
                <GrantCard
                  grantTitle={grantTitle}
                  phase={phase}
                  agency={agency}
                  deadline={closeDate}
                  addToTechBtn={AddToTechnologyButton}
                  solicitationNumber={solicitationNumber}
                />
              </Grid>
            )
          })}

        {grants && !!grants.length && (
          <Grid item xs={12}>
            <Pagination />
          </Grid>
        )}
      </Grid>
    </>
  )
}
