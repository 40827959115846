import { BorderColorRounded } from '@mui/icons-material'
import { Box, Button, Card, IconButton, Stack, Typography, useTheme } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { AppDispatch } from '../../../../../store'
import { CurrentUserActions, CurrentUserSelector } from '../../../../../store/currentUser'
import { SnackbarActions } from '../../../../../store/snackbar'
import { formatPhoneNumber } from '../../../../../utils'
import { StyledTextField, UploadContainer } from '../../MyAccout.styles'

const UserProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('firstname is required'),
  phone: Yup.string()
    .max(15, 'phone cannot be more than 10 digits')
    .matches(
      /^(?:\+1\s)?\d{3}-\d{3}-\d{4}$/,
      'phone number format should be XXX-XXX-XXXX or 1XXX-XXX-XXXX.',
    ),
})

export const UserProfileForm = () => {
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme()

  const [editable, setEditable] = useState<boolean>(false)
  const [avatar, setAvatar] = useState<File>()

  const { firstName, lastName, phone } = useSelector(CurrentUserSelector.userDetail)
  const loading = useSelector(CurrentUserSelector.isLoading)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: firstName || '',
      lastName: lastName || '',
      phone: formatPhoneNumber(phone) || '',
    },
    validationSchema: UserProfileSchema,
    onSubmit: async ({ firstName, lastName, phone }, { setFieldValue, setTouched }) => {
      const resultAction = await dispatch(
        CurrentUserActions.updateCurrentUserProfileAsync({ firstName, lastName, phone }),
      )

      if (CurrentUserActions.updateCurrentUserProfileAsync.fulfilled.match(resultAction)) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: 'Changes saved successfully!',
            options: { variant: 'success' },
          }),
        )
        setEditable(false)
      }
    },
  })

  const handleAvatarChange = async (file: File) => {
    setAvatar(file)

    const formData = new FormData()

    formData.append('file', file)

    const resultAction = await dispatch(
      CurrentUserActions.updateCurrentUserAvatarAsync({ file: formData }),
    )

    if (CurrentUserActions.updateCurrentUserAvatarAsync.fulfilled.match(resultAction)) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: 'Avatar uploaded successfully!',
          options: { variant: 'success' },
        }),
      )
    }
  }

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik

  return (
    <Card sx={{ padding: '1.5rem', maxWidth: '480px', width: '100%', my: 3 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography
              fontSize="1rem"
              fontWeight={theme.typography.fontWeightBold}
              color="secondary.main"
            >
              User Info
            </Typography>
            {editable ? (
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  fontSize: '0.875rem',
                  textTransform: 'capitalize',
                  borderRadius: '0.8rem',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Save
              </Button>
            ) : (
              <IconButton onClick={() => setEditable(true)}>
                <BorderColorRounded color="primary" />
              </IconButton>
            )}
          </Stack>

          <Box my={1}>
            <Typography
              fontSize="0.875rem"
              fontWeight={theme.typography.fontWeightMedium}
              color="secondary.light"
              mb={1}
            >
              First name
            </Typography>
            <StyledTextField
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              autoComplete="off"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={(touched.firstName && errors.firstName) || ' '}
              InputProps={{
                readOnly: !editable,
              }}
              editable={editable}
            />
          </Box>

          <Box my={1}>
            <Typography
              fontSize="0.875rem"
              fontWeight={theme.typography.fontWeightMedium}
              color="secondary.light"
              mb={1}
            >
              Last name
            </Typography>
            <StyledTextField
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              autoComplete="off"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={(touched.lastName && errors.lastName) || ' '}
              InputProps={{
                readOnly: !editable,
              }}
              editable={editable}
            />
          </Box>

          <Box my={1}>
            <Typography
              fontSize="0.875rem"
              fontWeight={theme.typography.fontWeightMedium}
              color="secondary.light"
              mb={1}
            >
              Phone number
            </Typography>
            <StyledTextField
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              autoComplete="off"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={(touched.phone && errors.phone) || ' '}
              InputProps={{
                readOnly: !editable,
              }}
              editable={editable}
              onChange={(e) => setFieldValue('phone', formatPhoneNumber(e.target.value))}
            />
          </Box>

          {editable && (
            <Box my={1}>
              <Typography
                fontSize="0.875rem"
                fontWeight={theme.typography.fontWeightMedium}
                color="secondary.light"
                mb={1}
              >
                Upload avatar
              </Typography>

              <FileUploader
                multiple={false}
                name="avatar"
                handleChange={handleAvatarChange}
                types={['png', 'jpeg', 'jpg']}
              >
                <UploadContainer>
                  {avatar && avatar.name ? (
                    <Typography
                      textAlign="center"
                      color="secondary.light"
                      fontSize="0.875rem"
                      fontWeight={theme.typography.fontWeightMedium}
                    >
                      {avatar.name}
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        textAlign="center"
                        color="secondary.light"
                        fontSize="0.875rem"
                        fontWeight={theme.typography.fontWeightMedium}
                      >
                        Drop your avatar here,
                      </Typography>
                      <Typography
                        textAlign="center"
                        color="primary"
                        fontSize="0.875rem"
                        fontWeight={theme.typography.fontWeightMedium}
                      >
                        Browse
                      </Typography>
                    </>
                  )}
                </UploadContainer>
              </FileUploader>
            </Box>
          )}
        </Form>
      </FormikProvider>
    </Card>
  )
}
