import { Box, Stack, Typography } from '@mui/material'
import { forwardRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

interface RecaptchaFieldProps {
  helperText: string
  handleChange: (token: string | null) => void
}

export const RecaptchaField = forwardRef<ReCAPTCHA, RecaptchaFieldProps>(
  ({ helperText, handleChange }, ref) => {
    const recaptchaClientKey =
      (window.env &&
        window.env.RECAPTCHA_CLIENT_KEY !== '__RECAPTCHA_CLIENT_KEY__' &&
        window.env.RECAPTCHA_CLIENT_KEY) ||
      process.env.REACT_APP_RECAPTCHA_CLIENT_KEY

    return (
      <>
        {recaptchaClientKey && (
          <Stack alignItems="center" width="100%" mb={3}>
            <Box width="min-content">
              <ReCAPTCHA
                ref={ref}
                size="normal"
                onChange={handleChange}
                onExpired={() => {
                  console.log('timeout')
                }}
                onError={(e) => {
                  console.log('Recaptacha error', e)
                }}
                sitekey={recaptchaClientKey as string}
              />
              {helperText && (
                <Typography fontSize="0.8rem" color="error">
                  {helperText}
                </Typography>
              )}
            </Box>
          </Stack>
        )}
      </>
    )
  },
)
