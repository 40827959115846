import { Close } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  Modal as MuiModal,
  ModalProps,
  SxProps,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { FC, ReactNode } from 'react'

const closeButtonStyles: SxProps = {
  background: 'white',
  width: 30,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
}

const modalContainerStyles: SxProps = {
  width: 500,
  minHeight: 290,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

const modalContainerSmStyles: SxProps = {
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: 0,
}

interface CustomModalProps extends ModalProps {
  setOpen: Function
  title?: string
  footer?: ReactNode
  outerWrapperProps?: BoxProps
  containerProps?: BoxProps
  containerSx?: SxProps
  outerWrapperSx?: SxProps
  titleProps?: TypographyProps
  header?: ReactNode
  onCloseCallback?: Function
  closeButton?: ReactNode
  closeIcon?: ReactNode
  closeButtonProps?: BoxProps
  closeButtonSxProps?: SxProps
  closeButtonContainerProps?: BoxProps
  enableBackdropClick?: boolean
  hideDefaultCloseButton?: boolean
  closeButtonAction?: React.MouseEventHandler<HTMLDivElement>
}

export const CustomModal: FC<CustomModalProps> = ({
  open,
  header,
  title,
  footer,
  setOpen,
  children,
  closeIcon,
  titleProps,
  containerSx,
  outerWrapperSx,
  containerProps,
  onCloseCallback,
  closeButtonProps,
  outerWrapperProps,
  closeButtonAction,
  closeButtonSxProps,
  enableBackdropClick,
  hideDefaultCloseButton,
  closeButtonContainerProps,
}) => {
  const theme = useTheme()
  const isXs = useMediaQuery('(max-width:600px)')

  const sx = isXs ? modalContainerSmStyles : modalContainerStyles

  const handleModelClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (enableBackdropClick || hideDefaultCloseButton) {
      setOpen(false)
    }

    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false)
    }

    onCloseCallback && onCloseCallback()
  }

  return (
    <MuiModal open={open} onClose={handleModelClose} disableAutoFocus={true}>
      <Box sx={{ ...sx, ...(outerWrapperSx || {}) }} {...(outerWrapperProps && outerWrapperProps)}>
        {!hideDefaultCloseButton && (
          <Box
            mb={3}
            width="100%"
            display="flex"
            justifyContent="flex-end"
            {...(closeButtonContainerProps && closeButtonContainerProps)}
          >
            <Box
              component="div"
              onClick={closeButtonAction}
              sx={{ ...closeButtonStyles, ...(closeButtonSxProps || {}) }}
              {...(closeButtonProps || {})}
            >
              {closeIcon ? closeIcon : <Close sx={{ fontSize: 18 }} />}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            p: 3,
            background: 'white',
            borderRadius: '0.5rem',
            [theme.breakpoints.down('sm')]: {
              p: 4,
              borderRadius: '1rem 1rem 0 0',
            },
            ...(containerSx || {}),
          }}
          {...(containerProps && containerProps)}
        >
          {header && header}

          {title && (
            <Typography
              mb={2}
              color="secondary.main"
              fontWeight={700}
              fontSize="1.3rem"
              {...(titleProps || {})}
            >
              {title}
            </Typography>
          )}

          {children}

          {footer && footer}
        </Box>
      </Box>
    </MuiModal>
  )
}
