export const convertPhase = (phase: string[] | string) => {
  if (typeof phase === 'string') {
    return phase
  }
  let phaseValue = ''
  if (Array.isArray(phase)) {
    if (phase.includes('phaseI') && phase.includes('phaseII')) {
      phaseValue = 'BOTH'
    } else if (phase.includes('phaseI')) {
      phaseValue = 'Phase I'
    } else if (phase.includes('phaseII')) {
      phaseValue = 'Phase II'
    } else {
      phaseValue = 'N/A'
    }
  } else if (typeof phase === 'string') {
    phaseValue = phase
  } else {
    phaseValue = 'N/A'
  }
  return phaseValue
}
