import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'

import { FC, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { GoogleIcon, LinkedInIcon, MicrosoftIcon } from '../../../components/icons'
import { AppDispatch, RootState } from '../../../store'
import { AuthActions } from '../../../store/auth'
import { FormsSelector } from '../../../store/forms'
import { SnackbarActions } from '../../../store/snackbar'
import { PasswordRequirement } from '../subComponents'
import { RecaptchaField } from './subComponents'

interface SignUpFormProps {
  onSuccess: (value: boolean) => void
}

export const SignUpForm: FC<SignUpFormProps> = ({ onSuccess }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState<any>({
    confirmPassword: false,
    password: false,
  })
  const formId = 'signupform'
  const recaptchaRef = useRef<any>()
  const { email = '' } = useSelector((state: RootState) =>
    FormsSelector.getFormValues(state, formId),
  )

  const url =
    (window.env && window.env.API_URL !== '__API_URL__' && window.env.API_URL) ||
    process.env.REACT_APP_API_URL

  const handleGoogleLogin = () => {
    localStorage.setItem('authProvider', 'google')
    window.location.href = `${url}/v1/auth/google/signup/code`
  }

  const handleLinkedinLogin = () => {
    localStorage.setItem('authProvider', 'linkedin')
    window.location.href = `${url}/v1/auth/linkedin/signup/code`
  }

  const handleMicrosoftLogin = () => {
    localStorage.setItem('authProvider', 'microsoft')
    window.location.href = `${url}/v1/auth/microsoft/signup/code`
  }

  const handleShowPassword = (field: string) => {
    const set = { ...showPassword, [field]: !showPassword[field] }

    setShowPassword(set)
  }
  const iconStyle = {
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E2E8F0',
    borderRadius: 1,
    margin: '0 8px',
  }
  const resetFormFields = () => {
    onSuccess(false)
    setTouched({
      email: false,
      password: false,
      confirmPassword: false,
      recaptchaToken: false,
    })
    setValues({ email: '', password: '', confirmPassword: '', recaptchaToken: '' })
    recaptchaRef.current && recaptchaRef.current?.reset()
  }
  const handleRecaptchaChange = (_token: string | null) => {
    const token = _token ? _token : ''
    setFieldValue('recaptchaToken', token)
  }
  const RegistrationSchema = Yup.object({
    email: Yup.string().email('Email is not valid').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%*^?&+=-])[A-Za-z\d~!@#$%*^?&+=-]{8,}$/,
        'Password must include one uppercase letter, one lowercase letter, one number, and one special character (~!@#$%*^?&+=-)',
      ),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), ''], 'Password must match'),
    recaptchaToken: Yup.string().required('Please fill the reCAPTCHA'),
  })
  const formik = useFormik({
    initialValues: {
      email: email as string,
      password: '',
      confirmPassword: '',
      recaptchaToken: '',
    },
    validationSchema: RegistrationSchema,
    enableReinitialize: true,
    onSubmit: async ({ email, password, recaptchaToken }, _) => {
      try {
        const resultAction = await dispatch(
          AuthActions.userSignUpAsync({ email, password, recaptchaToken }) as any,
        )

        if (AuthActions.userSignUpAsync.fulfilled.match(resultAction)) {
          onSuccess(true)
        }
        if (AuthActions.userSignUpAsync.rejected.match(resultAction)) {
          onSuccess(false)
          resetFormFields()
        }
      } catch (error: any) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: error.message,
          }),
        )
      }
    },
  })

  const {
    errors,
    touched,
    values,
    setValues,
    setTouched,
    setFieldValue,
    getFieldProps,
    handleSubmit,
  } = formik

  return (
    <FormikProvider value={formik}>
      <Typography
        variant="h5"
        fontWeight={theme.typography.fontWeightMedium}
        mb={4}
        textAlign="center"
      >
        Let’s get you started.
      </Typography>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Input your email"
          variant="outlined"
          sx={{ mb: 1 }}
          InputProps={{ readOnly: !!email }}
          InputLabelProps={{ shrink: true }}
          {...getFieldProps('email')}
          error={Boolean(touched.email && errors.email)}
          helperText={(touched.email && errors.email) || ' '}
        />
        <TextField
          label="Create password"
          variant="outlined"
          type={showPassword.password ? 'text' : 'password'}
          fullWidth
          sx={{ mb: 1 }}
          InputLabelProps={{ shrink: true }}
          {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleShowPassword('password')} edge="end">
                  {showPassword.password ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={(touched.password && errors.password) || ' '}
        />
        <PasswordRequirement password={values.password} />
        <TextField
          label="Confirm password"
          variant="outlined"
          type={showPassword.confirmPassword ? 'text' : 'password'}
          fullWidth
          sx={{ mb: 1, mt: 2 }}
          InputLabelProps={{ shrink: true }}
          {...getFieldProps('confirmPassword')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleShowPassword('confirmPassword')} edge="end">
                  {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
          helperText={(touched.confirmPassword && errors.confirmPassword) || ' '}
        />

        <RecaptchaField
          ref={recaptchaRef}
          handleChange={handleRecaptchaChange}
          helperText={(touched.recaptchaToken && errors.recaptchaToken) || ' '}
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{
            mb: 2,
            height: '40px',
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '14px',
          }}
        >
          Get started
        </Button>
      </Form>
      <Typography textAlign="center" sx={{ mb: 2, fontWeight: theme.typography.fontWeightBold }}>
        Or
      </Typography>
      <Box display="flex" justifyContent="center" gap={1} mb={3}>
        <IconButton sx={iconStyle} aria-label="Google" onClick={handleGoogleLogin}>
          <GoogleIcon />
        </IconButton>
        <IconButton sx={iconStyle} aria-label="Microsoft" onClick={handleMicrosoftLogin}>
          <MicrosoftIcon />
        </IconButton>
        <IconButton sx={iconStyle} aria-label="LinkedIn" onClick={handleLinkedinLogin}>
          <LinkedInIcon />
        </IconButton>
      </Box>
      <Typography textAlign="center" fontWeight={theme.typography.fontWeightMedium}>
        Have an account?
        <Button
          variant="text"
          sx={{
            textTransform: 'none',
            fontSize: { xs: '14px', sm: '0.9375rem' },
            fontWeight: 700,
          }}
          onClick={() => navigate('/login')}
        >
          Sign in
        </Button>
      </Typography>
    </FormikProvider>
  )
}
