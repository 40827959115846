import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { GrantMatchApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { ICreateTechnologyFormState } from '../createTechnology.slice'

export const fetchKeywordsAsync = createAsyncThunk(
  'createTechnologyForm/fetchKeywordsAsync',
  async (body: { query: string }, { rejectWithValue }) => {
    try {
      const req = await GrantMatchApi.fetchKeywords(body)
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching keywords')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchKeywordsAsyncBuilder = (
  builder: ActionReducerMapBuilder<ICreateTechnologyFormState>,
) => {
  builder.addCase(fetchKeywordsAsync.pending, (state) => {
    state.submitting = true
    state.error = ''
  })
  builder.addCase(fetchKeywordsAsync.fulfilled, (state, action) => {
    state.keywords = action.payload.keywords || []
    state.submitting = false
  })
  builder.addCase(fetchKeywordsAsync.rejected, (state, action: PayloadAction<any>) => {
    state.submitting = false
    state.error = action.payload
  })
}
