import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GrantDetail } from '../../../../components/GrantDetail'
import { AppDispatch } from '../../../../store'
import { GrantMatchActions, GrantMatcherSelector } from '../../../../store/grantMatcher'
import { SnackbarActions } from '../../../../store/snackbar'

interface TechnologyGrantProps {
  technologyId: string
  matchedTopicId: string
}

export const TechnologyGrant: FC<TechnologyGrantProps> = ({ technologyId, matchedTopicId }) => {
  const dispatch = useDispatch<AppDispatch>()

  const grant = useSelector(GrantMatcherSelector.makeGrant)

  useEffect(() => {
    fetchTechnologyGrant()
  }, [technologyId, matchedTopicId])

  const fetchTechnologyGrant = async () => {
    try {
      if (!technologyId || !matchedTopicId) return

      await dispatch(
        GrantMatchActions.fetchMatchedGrantAsync({ technologyId, refId: matchedTopicId }),
      )
    } catch (error: any) {
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    }
  }

  return <GrantDetail grant={grant} />
}
