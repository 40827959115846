import { Grid } from '@mui/material'
import { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../store'
import { CurrentUserSelector } from '../../../store/currentUser'
import { SnackbarActions } from '../../../store/snackbar'
import { TechnologiesActions, TechnologiesSelectors } from '../../../store/technologies'
import { EmptyComponent } from './EmptyComponent'
import { TechnologyCard } from './TechnologyCard'

export const Technologies: FC = () => {
  const dispatch = useDispatch<AppDispatch>()

  const { _id } = useSelector(CurrentUserSelector.userDetail)
  const technologies = useSelector(TechnologiesSelectors.makeTechnologies)
  const technologyError = useSelector(TechnologiesSelectors.error)

  useEffect(() => {
    if (technologyError) {
      dispatch(SnackbarActions.showSnackbar({ message: technologyError }))
    }

    return () => {
      dispatch(TechnologiesActions.resetTechnologyError())
    }
  }, [technologyError])

  const fetchUserTechnologies = useCallback(async () => {
    if (!_id) return

    await dispatch(
      TechnologiesActions.fetchUserTechnologiesAsync({
        userId: _id,
        options: {
          params: { $counts: 'matchedGrants', $includes: 'matchedGrants', $limit: 100 },
        },
      }),
    )
  }, [_id, dispatch])

  useEffect(() => {
    fetchUserTechnologies()
  }, [fetchUserTechnologies])

  return (
    <Grid container spacing={3} pb={4}>
      {technologies && technologies.length ? (
        technologies.map((technology, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <TechnologyCard
              title={technology.title}
              technologyId={technology._id}
              status={technology.active}
              totalGrants={technology.matchedGrants_count || 0}
              cb={fetchUserTechnologies}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12} display="flex" justifyContent="center">
          <EmptyComponent />
        </Grid>
      )}
    </Grid>
  )
}
