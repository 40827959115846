import { CloseRounded } from '@mui/icons-material'
import { Box, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { FC } from 'react'
import { filterContainerStyles } from './searchFilters.styles'
import { CustomDatePicker } from './subComponents'

interface DateFilterProps {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  setStartDate: Function
  setEndDate: Function
}

//TODO: this function needs to be refactored, the props should be handled through redux
export const DateFilter: FC<DateFilterProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const theme = useTheme()

  const handleResetDate = () => {
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <Box
      component="div"
      sx={{
        ...filterContainerStyles,
        border: `1px solid ${theme.palette.grey[300]}`,
        background: startDate || endDate ? theme.palette.grey[300] : 'white',
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(0.5),
        },
      }}
    >
      <Stack direction="row" alignItems="center">
        <Typography
          color="secondary"
          fontWeight={theme.typography.fontWeightMedium}
          fontSize="1rem"
          ml={1}
        >
          Start Date : {startDate && startDate.format('MMM D, YYYY')}
        </Typography>
        <CustomDatePicker onSelect={setStartDate} value={startDate} />
      </Stack>

      <Divider orientation="horizontal" sx={{ width: '20px' }} />

      <Stack direction="row" alignItems="center">
        <Typography
          color="secondary"
          fontWeight={theme.typography.fontWeightMedium}
          fontSize="1rem"
          ml={1}
        >
          End Date : {endDate && endDate.format('MMM D, YYYY')}
        </Typography>
        <CustomDatePicker onSelect={setEndDate} value={endDate} minDate={startDate} />
      </Stack>

      {(startDate || endDate) && (
        <IconButton onClick={handleResetDate} sx={{ p: 0.1 }}>
          <CloseRounded sx={{ fontSize: 22, color: 'secondary.light' }} />
        </IconButton>
      )}
    </Box>
  )
}
