import { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { GrantDetail } from '../../components/GrantDetail'
import { GrantApi } from '../../services'
import { AppDispatch } from '../../store'
import { SnackbarActions } from '../../store/snackbar'
import { TechnologiesActions, TechnologiesSelectors } from '../../store/technologies'
import { AddToTechnologyButton, AddToTechnologyModal } from './subcomponents'

export const Grant = () => {
  const { id = '' } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const [grant, setGrant] = useState(null)
  const [openModal, setOpenModal] = useState(false)

  const technologyError = useSelector(TechnologiesSelectors.error)

  useEffect(() => {
    if (technologyError) {
      dispatch(SnackbarActions.showSnackbar({ message: technologyError }))
    }

    return () => {
      dispatch(TechnologiesActions.resetTechnologyError())
    }
  }, [technologyError])

  //TODO: move this to thunk
  const handleLoadGrant = useCallback(async () => {
    try {
      if (id) {
        const req = await GrantApi.fetchGrant(id, { params: { $includes: 'solicitation,agency' } })
        if (!(req as AxiosResponse<any>).data) {
          throw new Error('Invalid Id')
        }
        setGrant((req as AxiosResponse<any>).data)
      }
    } catch (err: any) {
      dispatch(SnackbarActions.showSnackbar({ message: err.message }))
    }
  }, [id])

  useEffect(() => {
    handleLoadGrant()
  }, [handleLoadGrant])

  return (
    <>
      <AddToTechnologyModal open={openModal} setOpen={setOpenModal} />
      <GrantDetail
        grant={grant}
        technologyModalHandler={<AddToTechnologyButton onClick={() => setOpenModal(true)} />}
      />
    </>
  )
}
