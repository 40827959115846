import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const GoogleIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Google">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.52 12.2729C23.52 11.422 23.4436 10.6038 23.3018 9.81836H12V14.4602H18.4582C18.18 15.9602 17.3345 17.2311 16.0636 18.082V21.0929H19.9418C22.2109 19.0038 23.52 15.9274 23.52 12.2729Z"
          fill="#4285F4"
        />
        <path
          id="Shape_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.999 23.9998C15.239 23.9998 17.9554 22.9252 19.9408 21.0925L16.0627 18.0816C14.9881 18.8016 13.6136 19.2271 11.999 19.2271C8.87357 19.2271 6.22812 17.1161 5.28448 14.2798H1.27539V17.3889C3.24994 21.3107 7.30812 23.9998 11.999 23.9998Z"
          fill="#34A853"
        />
        <path
          id="Shape_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.28545 14.2799C5.04545 13.5599 4.90909 12.7908 4.90909 11.9999C4.90909 11.209 5.04545 10.4399 5.28545 9.71993V6.61084H1.27636C0.463636 8.23084 0 10.0636 0 11.9999C0 13.9363 0.463636 15.769 1.27636 17.389L5.28545 14.2799Z"
          fill="#FBBC05"
        />
        <path
          id="Shape_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.999 4.77273C13.7608 4.77273 15.3427 5.37818 16.5863 6.56727L20.0281 3.12545C17.9499 1.18909 15.2336 0 11.999 0C7.30812 0 3.24994 2.68909 1.27539 6.61091L5.28448 9.72C6.22812 6.88364 8.87357 4.77273 11.999 4.77273Z"
          fill="#EA4335"
        />
      </g>
    </SvgIcon>
  )
}
