import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const ProfileIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.10671 7.24683C8.04004 7.24016 7.96004 7.24016 7.88671 7.24683C6.30004 7.1935 5.04004 5.8935 5.04004 4.2935C5.04004 2.66016 6.36004 1.3335 8.00004 1.3335C9.63337 1.3335 10.96 2.66016 10.96 4.2935C10.9534 5.8935 9.69337 7.1935 8.10671 7.24683Z"
        stroke="#334155"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.77348 9.7065C3.16014 10.7865 3.16014 12.5465 4.77348 13.6198C6.60681 14.8465 9.61348 14.8465 11.4468 13.6198C13.0601 12.5398 13.0601 10.7798 11.4468 9.7065C9.62014 8.4865 6.61348 8.4865 4.77348 9.7065Z"
        stroke="#475569"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
