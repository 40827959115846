import { Chip, Typography } from '@mui/material'
import { FC } from 'react'

interface AgencyTagProps {
  value: string
  textColor: string
  backgroundColor: string
}

export const AgencyTag: FC<AgencyTagProps> = ({ value, textColor, backgroundColor }) => {
  return (
    <Chip
      variant="outlined"
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: '24px',
        border: 'transparent',
        '& .MuiChip-label': {
          padding: 1,
        },
      }}
      label={
        <Typography variant="body1" sx={{ color: textColor, fontSize: '0.875rem' }}>
          {value}
        </Typography>
      }
    />
  )
}
