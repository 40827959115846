import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const getTechnology = (state: RootState) => state.technology.tech.data
const getTechnologyMembers = (state: RootState) => state.technology.tech.data.users || []
const loading = (state: RootState) => state.technology.tech.loading
const error = (state: RootState) => state.technology.tech.error
const getSelectedKeywords = (state: RootState) => state.technology.tech.data.selectedKeywords || []
const makeSelectedKeywords = createSelector(getSelectedKeywords, (keywords) => keywords)

const makeTechnologyMembers = createSelector(getTechnologyMembers, (members) => members)

const makeTechnologyMember = createSelector(
  getTechnologyMembers,
  (_: any, id: string) => id,
  (members, id) => {
    const member = members.find((g: any) => g._id === id)

    return (
      member && {
        memberId: member._id || '',
        firstName: member.firstName || '',
        lastName: member.lastName || '',
        email: member.email || '',
        phone: member.phone || '',
        avatar: member.avatar || '',
      }
    )
  },
)

export const TechnologySelectors = {
  loading,
  getTechnology,
  makeSelectedKeywords,
  makeTechnologyMembers,
  makeTechnologyMember,
  error,
}
