import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { TechnologiesApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { normalizeError } from '../../../utils'
import { TechnologyInitialState } from '../technology.slice'

export const addTechnologyMemberAsync = createAsyncThunk(
  'technology/addMembers',
  async (
    { id, body, options }: { id: string; body: any; options?: RequestConfig },
    { rejectWithValue },
  ) => {
    try {
      const res = await TechnologiesApi.createTechnologyMembers(id, body, options)
      return (res as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'adding members to technology')
      return rejectWithValue(normalized)
    }
  },
)

export const addTechnologyMemberAsyncBuilder = (
  builder: ActionReducerMapBuilder<TechnologyInitialState>,
) => {
  builder.addCase(addTechnologyMemberAsync.pending, (state, action) => {
    state.loading = true
    state.error = ''
  })
  builder.addCase(addTechnologyMemberAsync.fulfilled, (state, action) => {
    state.loading = false
  })
  builder.addCase(addTechnologyMemberAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.error = action.payload
  })
}
