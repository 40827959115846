import { createSlice } from '@reduxjs/toolkit'
import { IAgency } from '../../types'
import { fetchAgenciesAsync, fetchAgenciesAsyncBuilder } from './asyncRequests'

export interface AgenciesInitialState {
  data: IAgency[]
  loading: boolean
  error: string
  success: boolean
}

const initialState: AgenciesInitialState = {
  data: [] as IAgency[],
  loading: false,
  error: '',
  success: false,
}

export const AgenciesSlice = createSlice({
  name: 'agenciesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchAgenciesAsyncBuilder(builder)
  },
})

export const AgenciesActions = {
  ...AgenciesSlice.actions,
  fetchAgenciesAsync,
}

export const AgenciesReducer = AgenciesSlice.reducer
