import CustomError from './CustomError'

class ValidationError extends CustomError {
  constructor(message: string) {
    super(`ValidationError: ${message}`)
    this.name = 'ValidationError'
  }
}

export default ValidationError
