import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AuthApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { AuthInitialState } from '../userAuth.slice'

export const verifyEmailAsync = createAsyncThunk(
  'auth/verifyEmail',
  async ({ email, token }: { email: string; token: string }, { rejectWithValue }) => {
    try {
      const user = await AuthApi.verifyEmail({ email, token })

      const response = (user as AxiosResponse<any, any>).data
      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'reseting password')
      return rejectWithValue(normalized)
    }
  },
)

export const verifyEmailAsyncBuilder = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  builder.addCase(verifyEmailAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(verifyEmailAsync.fulfilled, (state, action: PayloadAction<string>) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(verifyEmailAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
