import { ArrowDropDownRounded, CloseRounded } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../store'
import { AgenciesActions, AgenciesSelectors } from '../../../../store/agencies'

interface AgencyFilterProps {
  handleFilteredAgencies: (agencues: string[]) => void
}

const filterContainerStyles = {
  minWidth: '100px',
  height: '44px',
  borderRadius: '44px',
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: '0 0.5rem',
}

const menuStyles = {
  '& .MuiPaper-root': {
    ml: 1,
    mt: 1,
    minWidth: 160,
    maxWidth: 400,
    borderRadius: '0.5rem',
    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
                rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
  },
}

export const AgencyFilter: FC<AgencyFilterProps> = ({ handleFilteredAgencies }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [filteredAgencies, setFilteredAgencies] = useState<string[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const agenciesAcronyms = useSelector(AgenciesSelectors.makeUniqueAgenciesAcronym)

  const open = Boolean(anchorEl)

  useEffect(() => {
    dispatch(AgenciesActions.fetchAgenciesAsync())
  }, [dispatch])

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)

    //apply filters only after agency menu has closed
    handleFilteredAgencies(filteredAgencies)
  }

  const handleFilterChange = (agency: string) => {
    const isSelected = filteredAgencies.includes(agency)

    if (!isSelected) {
      setFilteredAgencies([...filteredAgencies, agency])
    } else {
      setFilteredAgencies(filteredAgencies.filter((item) => item !== agency))
    }
  }

  const handleResetFilters = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setFilteredAgencies([])
    handleFilteredAgencies([])
  }

  const filterTitle = (
    <>
      {!filteredAgencies.length ? (
        <>
          <Typography color="secondary" fontWeight={700} fontSize="1rem" ml={1}>
            All Agencies
          </Typography>

          <ArrowDropDownRounded sx={{ fontSize: 28, color: 'secondary' }} />
        </>
      ) : (
        <>
          <Typography color="secondary" fontWeight={700} fontSize="1rem" mx={1}>
            {filteredAgencies.length}
            {filteredAgencies.length > 1 ? ' agencies' : ' agency'}
          </Typography>
          <IconButton onClick={handleResetFilters} sx={{ p: 0.1 }}>
            <CloseRounded sx={{ fontSize: 22, color: 'secondary.light' }} />
          </IconButton>
        </>
      )}
    </>
  )

  return (
    <Box>
      <Box
        component="div"
        sx={{
          ...filterContainerStyles,
          background: filteredAgencies.length ? theme.palette.grey[300] : 'white',
          border: `1px solid ${theme.palette.grey[300]}`,
        }}
        onClick={handleClick}
      >
        {filterTitle}
      </Box>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} elevation={0} sx={menuStyles}>
        {agenciesAcronyms.map((acronym) => (
          <Box
            component="div"
            key={acronym}
            sx={{
              py: 1,
              px: 2,
              '&:hover': {
                background: theme.palette.grey[100],
                cursor: 'pointer',
              },
            }}
          >
            <FormControlLabel
              control={<Checkbox />}
              checked={filteredAgencies.includes(acronym)}
              value={acronym}
              label={acronym}
              onChange={() => handleFilterChange(acronym)}
            />
          </Box>
        ))}
      </Menu>
    </Box>
  )
}
