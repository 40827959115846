import { Box, Paper, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { AgenciesSelectors } from '../../../../../store/agencies'
import { GrantsTableChip } from '../GrantsTableChip'

interface BoardCardProps {
  agency: string
  deadline: string
  title: string
}

export const KanbanViewCard: React.FC<BoardCardProps> = ({ agency, deadline, title }) => {
  const theme = useTheme()

  const agencyDetail: any = useSelector((state) =>
    AgenciesSelectors.makeAgenciesDetail(state, agency),
  )

  return (
    <Paper
      component="div"
      sx={{
        p: 1.5,
        borderRadius: 2,
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Stack gap={1}>
        <Typography
          variant="body1"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <Box
            component="span"
            fontSize="0.875rem"
            color="secondary.main"
            fontWeight={theme.typography.fontWeightBold}
          >
            {title}
          </Box>
        </Typography>

        <Typography
          color="secondary.light"
          fontWeight={theme.typography.fontWeightBold}
          fontSize="0.875rem"
        >
          {deadline}
        </Typography>

        <GrantsTableChip
          value={agencyDetail[agency]?.acronym}
          textColor={agencyDetail[agency]?.textColor}
          backgroundColor={agencyDetail[agency]?.backgroundColor}
        />
      </Stack>
    </Paper>
  )
}
