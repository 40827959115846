// BarChart.tsx
import { Box, Paper, Typography, useTheme } from '@mui/material'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Bar } from 'react-chartjs-2'

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement, BarController)

interface BarChartProps {
  data: any
  options: ChartOptions<'bar'>
  title: string
}

export const BarChart: React.FC<BarChartProps> = ({ data, options, title }) => {
  const theme = useTheme()

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '20px',
        width: '100%',
        border: `1px solid ${theme.palette.grey[200]}`,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        height="100%"
        p={2}
      >
        <Typography
          variant="h5"
          fontSize="20px"
          fontWeight={theme.typography.fontWeightBold}
          mb={2}
        >
          {title}
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: { xs: '600px', sm: '400px', md: '400px' },
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </Box>
    </Paper>
  )
}
