export const formatPhoneNumber = (_phone: string) => {
  if (!_phone) return ''

  const phone = _phone.replace(/[^0-9]/g, '')

  if (phone.length > 10 && phone.startsWith('1')) {
    return `+1 ${phone.substring(1, 4)}-${phone.substring(4, 7)}-${phone.substring(7, 11)}`
  }

  if (phone.length >= 10 && phone.charAt(0) !== '1') {
    return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`
  }

  if (phone.length > 6) {
    return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, phone.length)}`
  }

  if (phone.length > 3) {
    return `${phone.substring(0, 3)}-${phone.substring(3, phone.length)}`
  }
}
