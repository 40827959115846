import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const getCompanyInfo = (state: RootState) => state.company.data
const getTeamMembers = (state: RootState) => state.company.teamMembers
const getLoading = (state: RootState) => state.company.loading
const getError = (state: RootState) => state.company.error
const stepCount = (state: RootState) => state.company.step
const getStageStatuses = (state: RootState) => state.company.stageStatuses
export const getCompanyId = (state: RootState) => state.company.data?._id
const error = (state: RootState) => state.company.error

const getTeamMemberEmails = createSelector([getTeamMembers], (teamMembers) =>
  teamMembers.map((member: any) => member.email),
)

export const CompanySelectors = {
  getCompanyInfo,
  getTeamMembers,
  getLoading,
  getError,
  stepCount,
  getStageStatuses,
  getTeamMemberEmails,
  getCompanyId,
  error,
}
