import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { SubscriptionApi } from '../../../services'
import { IStripeProduct } from '../../../types'
import { normalizeError } from '../../../utils'
import { ISubscriptionInitialState } from '../subscription.slice'

export const fetchStripeProductsAsync = createAsyncThunk(
  'subscription/fetchStripeProducts',
  async (_, { rejectWithValue }) => {
    try {
      const products = await SubscriptionApi.fetchStripeProducts()
      const response = (products as AxiosResponse<any, any>).data
      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching stripe product')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchStripeProductsAsyncBuilder = (
  builder: ActionReducerMapBuilder<ISubscriptionInitialState>,
) => {
  builder.addCase(fetchStripeProductsAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(
    fetchStripeProductsAsync.fulfilled,
    (state, action: PayloadAction<IStripeProduct[]>) => {
      state.loading = false
      state.success = true
      state.data.products = action.payload
    },
  )
  builder.addCase(fetchStripeProductsAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
