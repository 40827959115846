import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { TechnologiesApi } from '../../../services'
import { IMatchedGrant } from '../../../types/Grants'
import { normalizeError } from '../../../utils'
import { GrantMatchInitialState } from '../grantMatcher.slice'

export const fetchMatchedGrantAsync = createAsyncThunk(
  'technology/fetchMatchedGrantAsync',
  async ({ technologyId, refId }: { technologyId: string; refId: string }, { rejectWithValue }) => {
    try {
      const req = await TechnologiesApi.fetchMatchedGrant(technologyId, refId, {
        params: { $includes: 'solicitation,grant,agency' },
      })

      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetch matched grant')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchMatchedGrantAsyncBuilder = (
  builder: ActionReducerMapBuilder<GrantMatchInitialState>,
) => {
  builder.addCase(fetchMatchedGrantAsync.pending, (state, action) => {
    state.grant.fetching = true
    state.grant.error = ''
  })
  builder.addCase(
    fetchMatchedGrantAsync.fulfilled,
    (state, action: PayloadAction<IMatchedGrant>) => {
      state.grant.fetching = false
      state.grant.data = action.payload
    },
  )
  builder.addCase(fetchMatchedGrantAsync.rejected, (state, action: PayloadAction<any>) => {
    state.grant.fetching = false
    state.grant.error = action.payload
  })
}
