import { Box } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EmptySavedStateIcon } from '../../../../components/icons'
import { RightSlider } from '../../../../components/sliders'
import { AppDispatch } from '../../../../store'
import { GrantMatchActions } from '../../../../store/grantMatcher'
import { TechGrantsActions, TechGrantSelectors } from '../../../../store/technology'
import { Outcome } from '../../../../types/Grants'
import { EmptyState } from './EmptyState'
import { GrantOutcomeConfirmationModal } from './GrantOutcome'
import { GrantsTable } from './GrantsTable'
import { KanbanView } from './KanbanView'
import { TechnologyGrant } from './TechnologyGrant'

interface SavedGrantsProps {
  technologyId: string
  view: string
  selectedTab: any
}

export const SavedGrants: React.FC<SavedGrantsProps> = ({ technologyId, view, selectedTab }) => {
  const dispatch = useDispatch<AppDispatch>()

  const [showGrantOutomeModal, setShowGrantOutcomeModal] = useState(false)
  const [showGrantDetail, setShowGrantDetail] = useState<boolean>(false)
  const [selectedGrant, setSelectedGrant] = useState({
    topicId: '',
    refId: '',
    grantTitle: '',
    outcome: 'N/A',
  })

  const paging = useSelector(TechGrantSelectors.makeSavedGrantsPaging)
  const grants = useSelector((state) => TechGrantSelectors.makeGrantsSummary(state, 'selected'))

  const fetchSavedGrants = useCallback(async () => {
    await dispatch(
      TechGrantsActions.fetchGrantsAsync({
        id: technologyId,
        status: 'selected',
        options: {
          params: {
            $includes: 'grant,solicitation',
            $limit: paging.limit,
            $skip: paging.skip,
            $sort: `createdAt -1`,
          },
        },
      }),
    )
  }, [paging, technologyId, dispatch])

  useEffect(() => {
    fetchSavedGrants()
  }, [fetchSavedGrants])

  const handlePageUp = async () => {
    dispatch(
      TechGrantsActions.setPaging({ skip: paging.skip + paging.limit, grantType: 'selected' }),
    )
  }

  const handlePageDown = async () => {
    const nextVal = paging.skip - paging.limit
    dispatch(
      TechGrantsActions.setPaging({ skip: nextVal < 0 ? 0 : nextVal, grantType: 'selected' }),
    )
  }

  const handleOpenDrawer = (topicId: string) => {
    setSelectedGrant({ ...selectedGrant, topicId })
    setShowGrantDetail(true)
  }

  const handleCloseDrawer = () => {
    setShowGrantDetail(false)
    fetchSavedGrants()
    dispatch(GrantMatchActions.resetGrantData())
  }

  const handleGrantOutcomeChange = (outcome: Outcome, refId: string, grantTitle: string) => {
    setSelectedGrant({ ...selectedGrant, outcome, refId, grantTitle })
    setShowGrantOutcomeModal(true)
  }

  return (
    <>
      <RightSlider
        open={showGrantDetail}
        setOpen={setShowGrantDetail}
        closeButtonAction={handleCloseDrawer}
      >
        <TechnologyGrant technologyId={technologyId} matchedTopicId={selectedGrant.topicId} />
      </RightSlider>

      <GrantOutcomeConfirmationModal
        cb={fetchSavedGrants}
        refId={selectedGrant.refId}
        open={showGrantOutomeModal}
        setOpen={setShowGrantOutcomeModal}
        grantTitle={selectedGrant.grantTitle}
        outcome={selectedGrant.outcome as Outcome}
      />

      {view === 'list' ? (
        <GrantsTable
          tableData={grants as any}
          pageUpAction={handlePageUp}
          pageDownAction={handlePageDown}
          handleSlider={handleOpenDrawer}
          fetchGrants={fetchSavedGrants}
          handleGrantOutcomeChange={handleGrantOutcomeChange}
          paging={paging}
          emptyComponent={
            <EmptyState
              message="You do not have any grants saved at the moment"
              icon={EmptySavedStateIcon}
            />
          }
          selectedTab={selectedTab}
        />
      ) : (
        <Box component="div" p={4} sx={{ backgroundColor: 'white' }}>
          <KanbanView cb={fetchSavedGrants} handleSlider={handleOpenDrawer} />
        </Box>
      )}
    </>
  )
}
