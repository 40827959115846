interface INavConfig {
  path: string
  title: string
}

export const navConfig: INavConfig[] = [
  {
    path: '/home',
    title: 'Home',
  },
  {
    path: '/browse',
    title: 'Browse',
  },
]

export const genHeader: INavConfig[] = []
