import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { UsersApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { CurrentUserInitialState } from '../currentUser.slice'

export const updateCurrentUserAvatarAsync = createAsyncThunk(
  'users/updateUserAvatar',
  async ({ file }: { file: FormData }, { rejectWithValue }) => {
    try {
      const user = await UsersApi.updateCurrentUserAvatar(file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      const response = (user as AxiosResponse<any, any>).data
      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'updating user profile picture')
      return rejectWithValue(normalized)
    }
  },
)

export const updateCurrentUserAvatarAsyncBuilder = (
  builder: ActionReducerMapBuilder<CurrentUserInitialState>,
) => {
  builder.addCase(updateCurrentUserAvatarAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(
    updateCurrentUserAvatarAsync.fulfilled,
    (state, action: PayloadAction<string>) => {
      state.loading = false
      state.success = true
    },
  )
  builder.addCase(updateCurrentUserAvatarAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
