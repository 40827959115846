import { createSlice } from '@reduxjs/toolkit'
import { IMatchedGrant } from '../../types/Grants'
import {
  fetchFilteredTechGrantsAsync,
  fetchFilteredTechGrantsAsyncBuilder,
  fetchMatchedGrantAsync,
  fetchMatchedGrantAsyncBuilder,
  updateMatchedTechGrantAsync,
  updateMatchedTechGrantAsyncBuilder,
} from './asyncRequests'

export interface IGrants {
  ID: string
  Agency: string
  Branch: string
  Program: string
  Solicitation: string
  'Topic Number': string
  Score: number
  Dates: string
  Description: string
}

export interface ISolicitaion {
  _id: string
  solicitation_title: string
  solicitation_number: string
  program: string
  phase: string
  agency: string
  branch: string
  solicitation_year: string
  release_date: string
  open_date: string
  close_date: string
  application_due_date: [string]
  occurrence_number: number | null
  sbir_solicitation_link: string
  solicitation_agency_url: string
  current_status: string
  grant_topics: string
}

export interface GrantMatchInitialState {
  grants: {
    fetching: boolean
    data: IMatchedGrant[]
    error: string
  }
  grant: {
    fetching: boolean
    data: IMatchedGrant
    error: string
  }
}

const initialState: GrantMatchInitialState = {
  grants: {
    fetching: false,
    data: [],
    error: '',
  },
  grant: {
    fetching: false,
    data: {} as IMatchedGrant,
    error: '',
  },
}

const GrantMatcherSlice = createSlice({
  name: 'grantMatcher',
  initialState,
  reducers: {
    updateMatchedGrant: (state, action) => {
      const { matchedGrantId = '', grant } = action.payload

      const grantIndex = state.grants.data.findIndex((grant) => grant._id === matchedGrantId)

      if (grantIndex !== -1) {
        const oldGrant = state.grants.data[grantIndex]
        state.grants.data[grantIndex] = { ...oldGrant, ...grant }
      }
    },
    resetGrantsError: (state) => {
      state.grants.error = ''
    },
    resetGrantError: (state) => {
      state.grant.error = ''
    },
    resetGrantData: (state) => {
      state.grant.data = {} as IMatchedGrant
    },
  },
  extraReducers: (builder) => {
    fetchFilteredTechGrantsAsyncBuilder(builder)
    updateMatchedTechGrantAsyncBuilder(builder)
    fetchMatchedGrantAsyncBuilder(builder)
  },
})

export const GrantMatchActions = {
  ...GrantMatcherSlice.actions,
  fetchFilteredTechGrantsAsync,
  updateMatchedTechGrantAsync,
  fetchMatchedGrantAsync,
}

export const GrantMatchReducer = GrantMatcherSlice.reducer
