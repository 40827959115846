import { Box, Paper, SxProps, Typography, useTheme } from '@mui/material'
import { FC, ReactElement } from 'react'

interface GrantAttributesCardProps {
  icon: ReactElement
  descriptions: string[]
  label: string
}

const cardStyles: SxProps = {
  flexGrow: 1,
  flexBasis: 0,
  minWidth: 200,
  padding: '1.5rem',
  borderRadius: '.5rem',
}

export const GrantAttributesCard: FC<GrantAttributesCardProps> = ({
  icon,
  label,
  descriptions,
}) => {
  const theme = useTheme()
  return (
    <Paper elevation={0} sx={cardStyles}>
      {icon}

      {descriptions &&
        descriptions.map((description, index) => (
          <Typography
            key={index}
            variant="body1"
            sx={{
              mb: 0.5,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            <Box component="span" fontWeight={theme.typography.fontWeightBold} color="secondary">
              {description}
            </Box>
          </Typography>
        ))}
      <Typography variant="body2" color="secondary.light">
        {label}
      </Typography>
    </Paper>
  )
}
