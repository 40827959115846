import jwtDecode from 'jwt-decode'
import { JwtPayload } from '../types/jwtType'

// Define the type of the decoded JWT payload
type DecodedToken = JwtPayload

function decodeToken(token: string): DecodedToken {
  try {
    return jwtDecode<DecodedToken>(token)
  } catch (error) {
    return {}
  }
}

export default decodeToken
