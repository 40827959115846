import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import { IconButton, Stack, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { GrantsActions, GrantsSelector } from '../../../../../../store'

export const GrantPaging = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const paging = useSelector(GrantsSelector.getPaging)

  const pages = Math.ceil(paging.totalRows / paging.limit)
  const currentPage = Math.ceil((paging.skip + 1) / paging.limit)

  const handlePageUp = async () => {
    dispatch(GrantsActions.setPaging({ skip: paging.skip + paging.limit }))
  }

  const handlePageDown = async () => {
    const nextVal = paging.skip - paging.limit
    dispatch(GrantsActions.setPaging({ skip: nextVal < 0 ? 0 : nextVal }))
  }

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="space-between">
      <Typography
        color="secondary"
        fontSize="0.875rem"
        fontWeight={theme.typography.fontWeightBold}
      >
        Page {currentPage} of {pages}
      </Typography>

      <Stack direction="row" alignItems="center" gap={1}>
        <IconButton onClick={handlePageDown} disabled={currentPage === 1}>
          <ArrowBackIosRounded sx={{ fontSize: '0.9rem' }} />
        </IconButton>

        <Typography
          color="secondary"
          fontSize="0.875rem"
          fontWeight={700}
          sx={{
            minWidth: '24px',
            padding: '4px 8px',
            borderRadius: '12px',
            textAlign: 'center',
            background: theme.palette.grey[200],
          }}
        >
          {currentPage}
        </Typography>

        <IconButton onClick={handlePageUp} disabled={currentPage === pages}>
          <ArrowForwardIosRounded sx={{ fontSize: '0.9rem' }} />
        </IconButton>
      </Stack>
    </Stack>
  )
}
