import { Button, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../store'
import { SnackbarActions } from '../../../store/snackbar'
import { SubscriptionActions, SubscriptionSelectors } from '../../../store/subscription'

export const AdminInvalidSub = () => {
  const dispatch = useDispatch<AppDispatch>()

  const loading = useSelector(SubscriptionSelectors.loading)
  const stripeSessionError = useSelector(SubscriptionSelectors.error)

  useEffect(() => {
    if (stripeSessionError) {
      dispatch(SnackbarActions.showSnackbar({ message: stripeSessionError }))
    }

    return () => {
      dispatch(SubscriptionActions.resetErrors())
    }
  }, [stripeSessionError])

  const handleManageSubscription = async () => {
    try {
      const resultAction = await dispatch(SubscriptionActions.createStripePortalSessionAsync())

      if (SubscriptionActions.createStripePortalSessionAsync.fulfilled.match(resultAction)) {
        const url = resultAction.payload as any
        window.open(url, '_blank')
      }
    } catch (error: any) {
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    }
  }

  return (
    <Stack alignItems="center" width="100%" gap={2}>
      <Typography fontSize="1.2rem" textAlign="center" mt={4}>
        Sorry, we are unable to process your subscription at this moment
      </Typography>
      <Button
        variant="contained"
        disabled={loading}
        disableElevation={true}
        sx={{ textTransform: 'capitalize' }}
        onClick={handleManageSubscription}
      >
        Manage Subscription
      </Button>
    </Stack>
  )
}
