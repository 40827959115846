import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { TechnologiesApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { GrantMatchInitialState } from '../grantMatcher.slice'

export interface IBody {
  like: number
  status: string
}

interface IParams {
  techId: string
  refId: string
}

export const updateMatchedTechGrantAsync = createAsyncThunk(
  'technology/updateMatchedTechGrant',
  async ({ params, body }: { params: IParams; body: any }, { rejectWithValue }) => {
    try {
      const req = await TechnologiesApi.updateTechnologyMatchedGrant(params, body)
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'updating matched grant')
      return rejectWithValue(normalized)
    }
  },
)

export const updateMatchedTechGrantAsyncBuilder = (
  builder: ActionReducerMapBuilder<GrantMatchInitialState>,
) => {
  builder.addCase(updateMatchedTechGrantAsync.pending, (state, action) => {
    state.grant.error = ''
    state.grant.fetching = true
  })
  builder.addCase(updateMatchedTechGrantAsync.fulfilled, (state, action) => {
    state.grant.fetching = false
  })
  builder.addCase(updateMatchedTechGrantAsync.rejected, (state, action: PayloadAction<any>) => {
    state.grant.error = action.payload
    state.grant.fetching = false
  })
}
