import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AuthApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { AuthInitialState } from '../userAuth.slice'

export const microsoftSignupTokenAsync = createAsyncThunk(
  'microsoft/signup/tokens',
  async ({ authCode }: { authCode: string }, { rejectWithValue }) => {
    try {
      const req = await AuthApi.microsoftSignupAccessToken({ authCode })
      return (req as AxiosResponse).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching microsoft tokens')
      return rejectWithValue(normalized)
    }
  },
)

export const microsoftSignupTokenAsyncBuilder = (
  builder: ActionReducerMapBuilder<AuthInitialState>,
) => {
  builder
    .addCase(microsoftSignupTokenAsync.pending, (state, action) => {
      state.loading = true
      state.success = false
      state.error = ''
    })
    .addCase(microsoftSignupTokenAsync.fulfilled, (state, action) => {
      state.loading = false
      state.success = true
    })
    .addCase(microsoftSignupTokenAsync.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
}
