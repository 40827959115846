import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../store'
import { AuthSelector } from '../../../store/auth'
import { CompanyActions } from '../../../store/company'
import { RegistrationProgressSelectors } from '../../../store/registrationProgress'
import { SubscriptionActions, SubscriptionSelectors } from '../../../store/subscription'
import { CheckoutForm, SubscriptionPlan } from './subComponents'

export const StripeCheckout = () => {
  const dispatch = useDispatch<AppDispatch>()

  const validSub = useSelector(AuthSelector.isSubscriptionValid)
  const planDetails = useSelector(SubscriptionSelectors.getSingleProduct)
  const { subscription = '' } = useSelector(RegistrationProgressSelectors.progressSteps)
  const { _id = '', name = '', amount = '0', recurringInterval = '' } = planDetails

  useEffect(() => {
    if (subscription === 'complete' || validSub) {
      dispatch(CompanyActions.updateStep(2))
    }
  }, [subscription, validSub])

  useEffect(() => {
    dispatch(SubscriptionActions.fetchStripeProductsAsync())
  }, [])

  return (
    <Grid container display="flex" justifyContent="center">
      {_id && (
        <Grid container item xs={10} spacing={4}>
          <Grid item xs={12} md={6}>
            <SubscriptionPlan
              name={name}
              amount={parseInt(amount) / 100}
              interval={recurringInterval}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CheckoutForm productId={_id} />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
