import { Box } from '@mui/material'
import { FC, useEffect, useRef } from 'react'

interface CustomDivElement extends HTMLDivElement {
  name?: string
  'data-id'?: string
}

interface TermlyEmbedProps {
  dataId: string
}

export const TermlyEmbed: FC<TermlyEmbedProps> = ({ dataId }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const d: CustomDivElement = document.createElement('div')
    d.setAttribute('name', 'termly-embed')
    d.setAttribute('data-id', dataId)

    if (ref.current) {
      ref.current.appendChild(d)
    }

    const ele = document.createElement('script')
    ele.type = 'text/javascript'
    ele.innerHTML =
      "(function(d, s, id) { var js, tjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = \"https://app.termly.io/embed-policy.min.js\"; tjs.parentNode.insertBefore(js, tjs); }(document, 'script', 'termly-jssdk'));"
    document.body.appendChild(ele)

    return () => {
      document.body.removeChild(ele)
    }
  }, [dataId])

  return <Box component="div" ref={ref} maxWidth="md" sx={{ ml: 'auto', mr: 'auto' }} />
}
