import { Grid, Paper } from '@mui/material'
import moment from 'moment/moment'
import { FC, ReactElement } from 'react'
import { LabeledText } from '../../../../../components/CustomTypography'
import { IAgency } from '../../../../../types'
import { Tag } from './Tag'

interface GrantCardProps {
  solicitationNumber: string
  grantTitle: string
  deadline: string
  phase: string
  addToTechBtn: ReactElement
  agency: IAgency
}

export const GrantCard: FC<GrantCardProps> = ({
  solicitationNumber,
  grantTitle,
  deadline,
  addToTechBtn,
  phase,
  agency,
}) => {
  return (
    <Paper elevation={0} sx={{ borderRadius: '.5rem', padding: '1.2rem', cursor: 'pointer' }}>
      <Grid container alignItems="flex-start" justifyContent="space-between">
        <Grid item xs={12} display="flex" flexDirection="column" gap={0.5}>
          <Grid container alignItems="flex-start" justifyItems="space-between">
            <Grid item xs={9} sm={8} md={7}>
              <LabeledText value={solicitationNumber} />
              <LabeledText value={grantTitle} />
            </Grid>
            <Grid item xs={3} sm={4} md={5} display="flex" justifyContent="flex-end" gap={0.5}>
              {addToTechBtn}
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={6}>
          <Tag
            value={phase}
            fontSize="15px"
            chipStyles={{
              py: 'auto',
              px: '10px',
              mt: '.8rem',
              display: { xs: 'none', sm: 'flex' },
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          gap={0.5}
          display="flex"
          flexDirection="column"
          alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
        >
          <Tag
            value={agency.acronym}
            textColor={agency.textColor}
            backgroundColor={agency.backgroundColor}
            chipStyles={{ p: 1, mt: '0.6rem' }}
          />
          <LabeledText
            label="Deadline"
            value={moment(deadline, 'MMMM DD, YYYY').format('MM/DD/YYYY')}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
