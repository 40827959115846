import { RootState } from '../store'

const getGrantFetching = (state: RootState) => state.public.grant.fetching
const getGrantError = (state: RootState) => state.public.grant.error
const getGrant = (state: RootState) => state.public.grant.data

export const PublicSelector = {
  getGrantFetching,
  getGrantError,
  getGrant,
}
