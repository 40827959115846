import { Button, ButtonProps } from '@mui/material'
import { ReactNode } from 'react'

interface CustomButtonProps extends ButtonProps {
  label?: string | ReactNode
  children?: ReactNode
}

export const CustomButton: React.FC<CustomButtonProps> = ({ label, children, ...props }) => {
  return (
    <Button {...props}>
      {!children && label}
      {children && children}
    </Button>
  )
}
