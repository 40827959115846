import { createSelector } from '@reduxjs/toolkit'
import { IStepsProgress } from '../../types'
import { RootState } from '../store'

const registrationProgress = (state: RootState) => state.registrationProgress.data
const error = (state: RootState) => state.registrationProgress.error
const fetching = (state: RootState) => state.registrationProgress.loading

const progressSteps = createSelector(registrationProgress, (rp) => {
  const { steps = {} as IStepsProgress } = rp
  return steps
})

export const RegistrationProgressSelectors = {
  registrationProgress,
  progressSteps,
  error,
  fetching,
}
