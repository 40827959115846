import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { AuthActions, AuthSelector } from '../../../store/auth'
import { SnackbarActions } from '../../../store/snackbar'
import { EmailAuthError, EmailAuthPendingResponse, EmailAuthSuccess } from './EmailResponsePages'

type PageState = 'pending' | 'success' | 'linkExpired'

export const EmailAuthPage = () => {
  const dispatch: any = useDispatch()

  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const token = searchParams.get('token')
  const [verificationState, setVerificationState] = useState<PageState | null>('pending')

  const authError = useSelector(AuthSelector.error)

  useEffect(() => {
    if (authError) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: authError,
        }),
      )
    }

    return () => {
      return dispatch(AuthActions.resetError())
    }
  }, [authError])

  useEffect(() => {
    const verifyEmail = async () => {
      if (email && token) {
        const result = await dispatch(AuthActions.verifyEmailAsync({ email, token }))

        if (AuthActions.verifyEmailAsync.fulfilled.match(result)) {
          setVerificationState('success')
        }
        if (AuthActions.verifyEmailAsync.rejected.match(result)) {
          setVerificationState('linkExpired')
        }
      }
    }
    verifyEmail()
  }, [email, token, dispatch])

  return (
    <Box>
      {verificationState === 'pending' && <EmailAuthPendingResponse />}

      {verificationState === 'success' && <EmailAuthSuccess />}

      {verificationState === 'linkExpired' && <EmailAuthError />}
    </Box>
  )
}
