import { Box, Chip, Menu, MenuItem, SxProps, Typography } from '@mui/material'
import { FC, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../../../../store'
import { companyPreferencesSelector } from '../../../../store/companyPreferences'
import { GrantMatchActions } from '../../../../store/grantMatcher'

const menuSx: SxProps = {
  '& .MuiPaper-root': {
    mt: 0.5,
    minWidth: 120,
    height: 'min-content',
    borderRadius: '0.5rem',
    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
  },
}

export const TrackGrantStatus: FC<{ progressStatus: string; refId: string; cb: Function }> = ({
  cb,
  refId,
  progressStatus,
}) => {
  const { id = '' } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [currentStatus, setCurrentStatus] = useState<string>('')

  const progressStatuses = useSelector(companyPreferencesSelector.makeGrantsProgressStatuses)

  const open = Boolean(anchorEl)

  useEffect(() => {
    setCurrentStatus(progressStatus)
  }, [progressStatus])

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    const currentTarget = event.currentTarget
    event.stopPropagation()
    setAnchorEl(() => currentTarget)
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleUpdateGrantStatus = async (event: MouseEvent<HTMLElement>, status: string) => {
    setCurrentStatus(status)
    handleClose(event)

    const resultAction = await dispatch(
      GrantMatchActions.updateMatchedTechGrantAsync({
        params: { techId: id, refId },
        body: { progressStatus: status },
      }),
    )

    if (GrantMatchActions.updateMatchedTechGrantAsync.rejected.match(resultAction)) {
      setCurrentStatus(status)
    }
    cb()
  }

  const getCurrentStatusColor = (currentStatus: string) => {
    return progressStatuses.find((value) => value.status === currentStatus)
  }

  const item = getCurrentStatusColor(currentStatus)
  const { textColor = '', backgroundColor = '' } = item || {}

  return (
    <Box>
      <Box onClick={handleClick}>
        {currentStatus && (
          <Chip
            size="small"
            sx={{
              minWidth: '100%',
              borderRadius: '5px',
              background: backgroundColor && backgroundColor,
            }}
            label={
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  color: textColor && textColor,
                }}
              >
                {currentStatus}
              </Typography>
            }
          />
        )}
      </Box>

      <Menu
        open={open}
        sx={menuSx}
        elevation={0}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {progressStatuses &&
          progressStatuses.map(({ status, index, textColor, backgroundColor }) => {
            return (
              status !== currentStatus && (
                <MenuItem
                  key={index}
                  disableRipple
                  onClick={(e) => {
                    handleUpdateGrantStatus(e, status)
                  }}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Chip
                    size="small"
                    sx={{
                      minWidth: '100px',
                      borderRadius: '5px',
                      background: backgroundColor,
                    }}
                    label={
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '0.875rem',
                          color: textColor,
                        }}
                      >
                        {status}
                      </Typography>
                    }
                  />
                </MenuItem>
              )
            )
          })}
      </Menu>
    </Box>
  )
}
