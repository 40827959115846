import { Grid, GridProps, Skeleton, SkeletonProps, useTheme } from '@mui/material'
import { FC } from 'react'

interface SkeletonLoaderProps {
  count: number
  skeletonProps?: SkeletonProps
  outerContainerProps?: GridProps
  innerContainerProps?: GridProps
}

export const SkeletonLoader: FC<SkeletonLoaderProps> = (props) => {
  const theme = useTheme()

  const { count, skeletonProps = {}, outerContainerProps = {}, innerContainerProps = {} } = props

  if (count < 1) {
    return null
  }

  return (
    <Grid container spacing={3} justifyContent="space-between" mt={3} {...outerContainerProps}>
      {Array.from({ length: count }).map((_, i) => (
        <Grid item key={i} xs={12} {...innerContainerProps}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={150}
            animation="wave"
            sx={{
              background: `linear-gradient(90deg, ${theme.palette.grey[200]} 0%,
                                                    ${theme.palette.grey[300]} 100%)`,
            }}
            {...skeletonProps}
          />
        </Grid>
      ))}
    </Grid>
  )
}
