import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const SolicitationTitleIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="title 1" clipPath="url(#clip0_3813_15364)">
        <g id="Group">
          <path
            id="Vector"
            d="M19.2519 28.7861L18.6009 31.1222L16.1255 40L12.8551 36.3556L8.17188 37.7822L10.8371 28.2232L11.412 26.1631L15.9669 26.6489L19.2519 28.7861Z"
            fill="#FF6F52"
          />
          <path
            id="Vector_2"
            d="M31.829 37.7822L27.1448 36.3556L23.8754 40L21.399 31.1222L20.748 28.7871L24.0331 26.6489L28.5889 26.1631L29.1638 28.2232L31.829 37.7822Z"
            fill="#FF6F52"
          />
        </g>
        <g id="Group_2">
          <path
            id="Vector_3"
            d="M15.9676 26.649L19.2527 28.7862L18.6017 31.1223C17.7023 30.5429 16.8716 29.6178 15.9677 29.3244C14.5686 28.8697 12.5259 29.6518 11.3614 28.8036C11.1496 28.6487 10.9791 28.4516 10.8379 28.2232L11.4127 26.1631L15.9676 26.649Z"
            fill="#E25749"
          />
          <path
            id="Vector_4"
            d="M29.1637 28.2232C29.0225 28.4515 28.852 28.6486 28.6402 28.8036C27.4748 29.6517 25.4321 28.8697 24.033 29.3244C23.1291 29.6178 22.2984 30.5429 21.399 31.1223L20.748 28.7871L24.0331 26.649L28.5889 26.1631L29.1637 28.2232Z"
            fill="#E25749"
          />
        </g>
        <path
          id="Vector_5"
          d="M33.0466 14.6966C33.0466 13.2002 34.4201 11.5043 33.9817 10.1541C33.5274 8.75469 31.4042 8.19336 30.5564 7.02867C29.7002 5.85219 29.8167 3.6625 28.6402 2.80625C27.4755 1.95852 25.4331 2.74023 24.0337 2.28586C22.6833 1.84742 21.4972 0 20.0008 0C18.5044 0 17.3183 1.84742 15.9681 2.28586C14.5688 2.74023 12.5263 1.95852 11.3615 2.80625C10.185 3.66242 10.3014 5.85219 9.44532 7.02867C8.59759 8.19344 6.47438 8.75469 6.02001 10.154C5.58157 11.5042 6.95509 13.2002 6.95509 14.6966C6.95509 16.193 5.58157 17.8888 6.01993 19.2391C6.47431 20.6384 8.59751 21.1998 9.44524 22.3645C10.3014 23.5409 10.185 25.7306 11.3614 26.5869C12.5262 27.4346 14.5686 26.6529 15.968 27.1073C17.3182 27.5457 18.5043 29.3931 20.0007 29.3931C21.4971 29.3931 22.6832 27.5457 24.0334 27.1073C25.4327 26.6529 27.4752 27.4346 28.64 26.5869C29.8165 25.7307 29.7001 23.5409 30.5562 22.3645C31.4039 21.1997 33.5271 20.6384 33.9815 19.2391C34.4201 17.8889 33.0466 16.1929 33.0466 14.6966Z"
          fill="#FCD577"
        />
        <path
          id="Vector_6"
          d="M20.0005 23.9523C25.1127 23.9523 29.257 19.808 29.257 14.6959C29.257 9.58369 25.1127 5.43945 20.0005 5.43945C14.8884 5.43945 10.7441 9.58369 10.7441 14.6959C10.7441 19.808 14.8884 23.9523 20.0005 23.9523Z"
          fill="#DCE8EF"
        />
        <g id="Group_3">
          <path
            id="Vector_7"
            d="M23.3432 10.4199H16.6562V11.7952H23.3432V10.4199Z"
            fill="#415E72"
          />
          <path
            id="Vector_8"
            d="M23.3432 17.5977H16.6562V18.9729H23.3432V17.5977Z"
            fill="#415E72"
          />
          <path id="Vector_9" d="M25.247 14.0088H14.752V15.384H25.247V14.0088Z" fill="#415E72" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3813_15364">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
