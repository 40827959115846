import { AxiosAPI } from './AxiosApi'

class SubscriptionApi extends AxiosAPI {
  public createStripeCheckoutSession = async (body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.subscription.checkoutSession(), body, options)
  }
  public fetchStripeCheckoutSessionStatus = async (options = {}) => {
    return this.GET(this.apiSchema.GET.subscription.checkoutSession(), options)
  }
  public fetchStripeProducts = async (options = {}) => {
    return this.GET(this.apiSchema.GET.subscription.plans(), options)
  }
  public createStripeProtalSession = async (options = {}) => {
    return this.GET(this.apiSchema.GET.subscription.stripePortalSession(), options)
  }
}

export default new SubscriptionApi()
