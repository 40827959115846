// DoughnutChart.tsx
import { Box, Paper, Typography, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

interface DoughnutChartProps {
  data: any
  options: ChartOptions<'doughnut'>
  title: string
}

export const DoughnutChart: React.FC<DoughnutChartProps> = ({ data, options, title }) => {
  const theme = useTheme()

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        border: `1px solid ${theme.palette.grey[200]}`,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        height="100%"
        p={2}
      >
        <Typography
          variant="h5"
          fontSize="20px"
          fontWeight={theme.typography.fontWeightBold}
          mb={2}
        >
          {title}
        </Typography>
        <Box width={300} height={200}>
          <Doughnut data={data} options={options} />
        </Box>
      </Box>
    </Paper>
  )
}
