import { createSlice } from '@reduxjs/toolkit'
import { requestResendEmailAsync, requestResendEmailAsyncBuilder } from './asyncRequests'

export interface IResendEmailVerificationInitialState {
  loading: boolean
  success: boolean
  error: string
}

const initialState: IResendEmailVerificationInitialState = {
  loading: false,
  success: true,
  error: '',
}

export const EmailVerificationSlice = createSlice({
  name: 'emailVerification',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState
    },
  },
  extraReducers: (builder) => {
    requestResendEmailAsyncBuilder(builder)
  },
})

export const EmailVerificationActions = {
  ...EmailVerificationSlice.actions,
  requestResendEmailAsync,
}

export const EmailVerificationReducer = EmailVerificationSlice.reducer
