import { Grid } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { CurrentUserSelector } from '../../../../../../../store/currentUser'
import { MemberActionMenu } from '../MemberActionMenu'
import { CompanyMembersListItem } from './CompanyMembersListItem'

interface CompnayMembersListProps {
  handleChangeRole: Function
  handleRemoveUser: Function
}

export const CompanyMembersList: FC<CompnayMembersListProps> = ({
  handleChangeRole,
  handleRemoveUser,
}) => {
  const companyMembers = useSelector(CurrentUserSelector.makeCompanyMembers)

  return (
    <Grid container spacing={3}>
      {companyMembers &&
        companyMembers.map((member) => {
          const { _id = '', email = '', name = '', isAdmin = false, technologyCount = 0 } = member

          const role = isAdmin ? 'Admin' : 'Member'

          const actionMenu = (
            <MemberActionMenu
              handleChangeRole={() => handleChangeRole(_id, role.toLowerCase())}
              handleRemoveUser={() => handleRemoveUser(_id, name, role.toLowerCase())}
            />
          )

          return (
            <Grid key={_id} item xs={12}>
              <CompanyMembersListItem
                name={name}
                role={role}
                email={email}
                technologyCount={technologyCount}
                actionMenu={actionMenu}
              />
            </Grid>
          )
        })}
    </Grid>
  )
}
