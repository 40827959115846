import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { AlertBox } from '../../../../../../../components/AlertBox'
import { AppDispatch } from '../../../../../../../store'
import { CurrentUserActions } from '../../../../../../../store/currentUser'
import { SnackbarActions } from '../../../../../../../store/snackbar'

interface MemberDeleteAlertModalProps {
  open: boolean
  setOpen: Function
  selectedMember: { memberId: string; memberName: string; currentRole: string }
}

export const MemberDeleteAlertModal: FC<MemberDeleteAlertModalProps> = ({
  open,
  setOpen,
  selectedMember,
}) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleRemoveMember = async () => {
    try {
      const roleAction = await dispatch(
        CurrentUserActions.removeCurrentUserCompanyMemberAsync({
          id: selectedMember.memberId,
          currentRole: selectedMember.currentRole,
        }),
      )

      if (CurrentUserActions.removeCurrentUserCompanyMemberAsync.fulfilled.match(roleAction)) {
        dispatch(CurrentUserActions.fetchCurrentUserCompanyMembersAsync())
        dispatch(
          SnackbarActions.showSnackbar({
            message: 'member has been removed',
            options: { variant: 'success' },
          }),
        )
        setOpen(false)
      }
    } catch (error: any) {
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    }
  }

  const description = (
    <Typography color="secondary.light" fontSize="1rem" my={2}>
      Are you sure you want to remove
      <Box component="b" sx={{ color: 'secondary', fontSize: '1rem', mx: 0.5 }}>
        {selectedMember.memberName}
      </Box>
      from company?
    </Typography>
  )

  return (
    <AlertBox
      open={open}
      setOpen={setOpen}
      title="Delete member?"
      description={description}
      confirmAction={handleRemoveMember}
      denyAction={() => setOpen(false)}
      confirmButtonProps={{ label: 'Delete member' }}
      denyButtonProps={{ label: 'Cancel' }}
    />
  )
}
