import { ThumbDownOffAlt, ThumbUpOffAlt } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../../../../store'
import { GrantMatchActions } from '../../../../store/grantMatcher'

export const LikeDislikeWidget: FC<{
  like: Number
  refId: string
}> = ({ like, refId }) => {
  const { id = '' } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const [currentLikedValue, setCurrentLikedValue] = useState(like)

  useEffect(() => {
    setCurrentLikedValue(like)
  }, [like])

  const likeDislikeAction = async (newLikedValue: number, status: string) => {
    const bodyPayload = {
      like: 0,
      status: 'recommended',
    }

    if (currentLikedValue !== newLikedValue) {
      bodyPayload.like = newLikedValue
      bodyPayload.status = status
    }

    setCurrentLikedValue(bodyPayload.like)

    const likedAction = await dispatch(
      GrantMatchActions.updateMatchedTechGrantAsync({
        params: { techId: id, refId },
        body: bodyPayload,
      }),
    )

    if (GrantMatchActions.updateMatchedTechGrantAsync.fulfilled.match(likedAction)) {
      const grant = likedAction.payload
      dispatch(GrantMatchActions.updateMatchedGrant({ matchedGrantId: refId, grant }))
    }

    if (GrantMatchActions.updateMatchedTechGrantAsync.rejected.match(likedAction)) {
      setCurrentLikedValue(like)
    }
  }

  return (
    <Box component="div" display={{ xs: 'none', sm: 'flex' }}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          likeDislikeAction(-1, 'rejected')
        }}
        sx={{ color: currentLikedValue === -1 ? 'error.main' : '' }}
      >
        <ThumbDownOffAlt />
      </IconButton>
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          likeDislikeAction(1, 'selected')
        }}
        sx={{ color: currentLikedValue === 1 ? 'primary.main' : '' }}
      >
        <ThumbUpOffAlt />
      </IconButton>
    </Box>
  )
}
