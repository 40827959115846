import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../../store'
import { SnackbarActions } from '../../store/snackbar'
import { SolicitationsActions } from '../../store/solicitations'
import { SolicitationDetail } from './subComponent'
import { Grants } from './subComponent/Grants'

export const Solicitation = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { id: solicitationId = '' } = useParams<{ id: string }>()

  useEffect(() => {
    const fetchSolicitation = async () => {
      try {
        await dispatch(SolicitationsActions.fetchSolicitationAsync({ id: solicitationId }))
      } catch (error: any) {
        dispatch(SnackbarActions.showSnackbar({ message: error.message }))
      }
    }

    if (solicitationId) {
      fetchSolicitation()
    }

    return () => {
      dispatch(SolicitationsActions.resetSolicitation())
      dispatch(SolicitationsActions.resetGrants())
    }
  }, [dispatch, solicitationId])

  return (
    <Grid container>
      <SolicitationDetail />
      <Grants />
    </Grid>
  )
}
