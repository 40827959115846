import { useSelector } from 'react-redux'
import { AuthSelector } from '../../store/auth'
import { AdminInvalidSub, MemberInvalidSub } from './subcomponents'

export const InvalidSubscription = () => {
  const userRole = useSelector(AuthSelector.userRole)

  return (
    <>
      {userRole === 'ORG_ADMIN' && <AdminInvalidSub />}
      {(userRole === 'ORG_MEMBER' || userRole === 'INVALID_ORG') && <MemberInvalidSub />}
    </>
  )
}
