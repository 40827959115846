import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'
import { convertPhase } from '../../utils'
import { RootState } from '../store'

const defaultAgency = {
  title: 'N/A',
  acronym: 'N/A',
  backgroundColor: '#e0e0e0',
  textColor: '#424242',
}

const SortKeyMapping = {
  Topics: 'grant_count',
  'Open date': 'open_date',
  'Close date': 'close_date',
}

const getSolicitations = (state: RootState) => state.solicitations.solicitations.data || []
const getSolicitationsError = (state: RootState) => state.solicitations.solicitations.error
const fetchingSolicitations = (state: RootState) => state.solicitations.solicitations.fetching
const getPaging = (state: RootState) => state.solicitations.solicitations.paging

const getSolicitation = (state: RootState) => state.solicitations.solicitation.data
const getSolicitationError = (state: RootState) => state.solicitations.solicitation.error
const fetchingSolicitation = (state: RootState) => state.solicitations.solicitation.fetching

const getGrants = (state: RootState) => state.solicitations.grants.data || []
const getGrantsError = (state: RootState) => state.solicitations.grants.error
const fetchingGrants = (state: RootState) => state.solicitations.grants.fetching
const getGrantsPaging = (state: RootState) => state.solicitations.grants.paging

const getFilters = (state: RootState) => state.solicitations.filters

const makeSolicitations = createSelector(getSolicitations, (solicitations) => {
  return (
    solicitations &&
    solicitations.map((solicitation) => {
      const agencyDetail =
        solicitation.agency && typeof solicitation.agency !== 'string'
          ? solicitation.agency
          : defaultAgency

      const openDate = solicitation.open_date
        ? moment.utc(solicitation.open_date).format('MMMM D, YYYY')
        : ''
      const closeDate = solicitation.close_date
        ? moment.utc(solicitation.close_date).format('MMMM D, YYYY')
        : ''

      const phase = convertPhase(solicitation.phase)

      return {
        _id: solicitation._id,
        solicitationTitle: solicitation.solicitation_title || '',
        solicitationNumber: solicitation.solicitation_number || '',
        topicCount: solicitation?.grant_count || 0,
        program: solicitation.program || '',
        agency: agencyDetail,
        agency_abbr: solicitation.agency_abbr || '',
        branch: solicitation.branch || '',
        phase,
        openDate,
        closeDate,
      }
    })
  )
})

const makeSolicitationNumber = createSelector(getSolicitation, (solicitation) => {
  const { solicitation_number = '' } = solicitation || {}

  return solicitation_number
})

const makeSolicitation = createSelector(getSolicitation, (solicitation) => {
  if (!solicitation) return {}

  const agencyDetail =
    solicitation.agency && typeof solicitation.agency !== 'string'
      ? solicitation.agency
      : defaultAgency

  const openDate = solicitation.open_date
    ? moment.utc(solicitation.open_date).format('MMMM D, YYYY')
    : ''
  const closeDate = solicitation.close_date
    ? moment.utc(solicitation.close_date).format('MMMM D, YYYY')
    : ''

  const phase = convertPhase(solicitation.phase)

  return {
    _id: solicitation._id || '',
    solicitationTitle: solicitation.solicitation_title || '',
    solicitationNumber: solicitation.solicitation_number || '',
    solicitationYear: solicitation.solicitation_year,
    branch: solicitation.branch || '',
    program: solicitation.program || '',
    phase,
    openDate,
    closeDate,
    agency: agencyDetail,
  }
})

const makeGrants = createSelector(getSolicitation, getGrants, (solicitation, grants) => {
  if (!grants) return []

  const agencyDetail =
    solicitation.agency && typeof solicitation.agency !== 'string'
      ? solicitation.agency
      : defaultAgency

  const closeDate = solicitation.close_date
    ? moment.utc(solicitation.close_date).format('MMMM D, YYYY')
    : ''

  const phase = convertPhase(solicitation.phase)

  const solicitationNumber = solicitation.solicitation_number || ''
  return grants.map((grantItem) => {
    const { _id = '', topic_title = '', subtopic_title = '' } = grantItem
    return {
      _id: _id,
      topicTitle: topic_title,
      subtopicTitle: subtopic_title,
      grantTitle: subtopic_title || topic_title,
      solicitationNumber,
      phase,
      closeDate,
      agency: agencyDetail,
    }
  })
})

const makeSolicitationSortFilter = createSelector(getFilters, (filters) => {
  const { sort } = filters

  const sortedColumn = Object.keys(sort)[0]
  const sortedOrder = sort[sortedColumn]
  const schemaKey = SortKeyMapping[sortedColumn as keyof typeof SortKeyMapping]

  return `${schemaKey} ${sortedOrder}`
})

export const SolicitationsSelector = {
  getGrants,
  getSolicitation,
  getSolicitations,
  getGrantsError,
  getSolicitationError,
  getSolicitationsError,
  fetchingGrants,
  fetchingSolicitation,
  fetchingSolicitations,
  getPaging,
  getGrantsPaging,
  getFilters,
  makeGrants,
  makeSolicitation,
  makeSolicitations,
  makeSolicitationNumber,
  makeSolicitationSortFilter,
}
