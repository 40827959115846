import { createSlice } from '@reduxjs/toolkit'
import { fetchTechnologyMetricsAsync, fetchTechnologyMetricsAsyncBuilder } from './asyncRequest'

export interface IMetrics {
  applied: number
  won: number
  loss: number
  agencies: Record<string, number>
}

export interface MetricsInitialState {
  data: IMetrics
  loading: boolean
  error: boolean
}

const initialState: MetricsInitialState = {
  data: {
    applied: 0,
    won: 0,
    loss: 0,
    agencies: {},
  },
  loading: false,
  error: false,
}

const MetricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchTechnologyMetricsAsyncBuilder(builder)
  },
})
export const MetricsActions = {
  ...MetricsSlice.actions,
  fetchTechnologyMetricsAsync,
}

export const MetricsReducer = MetricsSlice.reducer
