import { useDraggable, UseDraggableArguments } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { Box, SxProps } from '@mui/material'
import { FC, ReactNode } from 'react'

interface DraggableProps extends UseDraggableArguments {
  children: ReactNode
  draggableSx?: SxProps
}

export const Draggable: FC<DraggableProps> = ({ children, draggableSx, ...draggableArgs }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable(draggableArgs)

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    transition: isDragging ? 'none' : 'transform 0.5s ease, opacity 0.5s ease',
    ...(draggableSx && draggableSx),
  }

  return (
    <Box component="div" ref={setNodeRef} sx={style} {...attributes} {...listeners}>
      {children}
    </Box>
  )
}
