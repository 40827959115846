import { Grid, Typography, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { SolicitationsSelector } from '../../../store/solicitations'

export const SolicitationDetail = () => {
  const theme = useTheme()
  const solicitation = useSelector(SolicitationsSelector.makeSolicitation)

  if (!solicitation || Object.keys(solicitation).length === 0) {
    return null
  }

  return (
    <Grid container spacing={2} height="min-content">
      <Grid item xs={12}>
        <Typography
          variant="h5"
          gutterBottom
          fontSize="1.5rem"
          color="secondary"
          fontWeight={theme.typography.fontWeightMedium}
        >
          {solicitation.solicitationTitle}
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            fontSize="16px"
            color={theme.palette.grey[600]}
          >
            Agency:
          </Typography>
          <Typography variant="body1" fontWeight={theme.typography.fontWeightBold} mb={2}>
            {solicitation.agency?.title || ''}
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            fontSize="16px"
            color={theme.palette.grey[600]}
          >
            Branch:
          </Typography>
          <Typography variant="body1" fontWeight={theme.typography.fontWeightBold}>
            {solicitation.branch || ''}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            fontSize="16px"
            color={theme.palette.grey[600]}
          >
            Program / Phase / Year:
          </Typography>
          <Typography variant="body1" fontWeight={theme.typography.fontWeightBold} mb={2}>
            {solicitation.program || ''}/{solicitation.phase || ''}/
            {solicitation.solicitationYear || ''}
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            fontSize="16px"
            color={theme.palette.grey[600]}
          >
            Solicitation Number:
          </Typography>
          <Typography variant="body1" fontWeight={theme.typography.fontWeightBold}>
            {solicitation.solicitationNumber || ''}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            fontSize="16px"
            color={theme.palette.grey[600]}
          >
            Open date:
          </Typography>
          <Typography variant="body1" fontWeight={theme.typography.fontWeightBold} mb={2}>
            {solicitation.openDate || ''}
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            fontSize="16px"
            color={theme.palette.grey[600]}
          >
            Close date:
          </Typography>
          <Typography variant="body1" fontWeight={theme.typography.fontWeightBold}>
            {solicitation.closeDate || ''}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
