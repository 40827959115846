import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { AppDispatch } from '../../../../store'
import { AuthActions, AuthSelector } from '../../../../store/auth'
import { SnackbarActions } from '../../../../store/snackbar'
import { PasswordValidationSchema, ValidationError } from '../../../../utils/helper'
import { PasswordRequirement } from '../../subComponents'

interface ResetPasswordProps {
  setCurrentPage: Function
}

export const ResetPasswordForm: FC<ResetPasswordProps> = ({ setCurrentPage }) => {
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme()

  const [showPassword, setShowPassword] = useState<any>({
    confirmPassword: false,
    newPassword: false,
  })
  const [searchParams] = useSearchParams()

  const email = searchParams.get('email') || ''
  const token = searchParams.get('token') || ''

  const authError = useSelector(AuthSelector.error)

  useEffect(() => {
    if (authError) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: authError,
        }),
      )
    }

    return () => {
      dispatch(AuthActions.resetError())
    }
  }, [authError, dispatch])

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: PasswordValidationSchema,
    onSubmit: async ({ newPassword }) => {
      try {
        if (!email || !token) {
          throw new ValidationError('invalid email or token')
        }

        const resultAction = await dispatch(
          AuthActions.resetPasswordAsync({ email, token, newPassword }),
        )

        if (AuthActions.resetPasswordAsync.fulfilled.match(resultAction)) {
          setCurrentPage('success')
        }

        if (AuthActions.resetPasswordAsync.rejected.match(resultAction)) {
          if (resultAction.error.message !== 'invalid password structure') {
            setCurrentPage('linkExpired')
          }
        }
      } catch (error: any) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: error.message,
          }),
        )
      }
    },
  })

  const handleShowPassword = (field: string) => {
    const set = { ...showPassword, [field]: !showPassword[field] }
    setShowPassword(set)
  }

  const { errors, touched, handleSubmit, getFieldProps, values } = formik

  return (
    <FormikProvider value={formik}>
      <Stack alignItems="center" width="100%">
        <Card sx={{ padding: '2rem', maxWidth: '480px', width: '100%' }}>
          <Box mb={4}>
            <Typography
              variant="h5"
              fontWeight={theme.typography.fontWeightMedium}
              fontSize="1.5rem"
              mb={1}
            >
              Set new password
            </Typography>
            <Typography
              color="secondary.light"
              fontWeight={theme.typography.fontWeightMedium}
              fontSize="1rem"
            >
              Please enter your new password below to reset your account password.
            </Typography>
          </Box>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                required
                type={showPassword.newPassword ? 'text' : 'password'}
                label="New Password"
                {...getFieldProps('newPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleShowPassword('newPassword')} edge="end">
                        {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.newPassword && errors.newPassword)}
                helperText={(touched.newPassword && errors.newPassword) || ' '}
              />
              <TextField
                fullWidth
                required
                type={showPassword.confirmPassword ? 'text' : 'password'}
                label="Confirm Password"
                {...getFieldProps('confirmPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleShowPassword('confirmPassword')} edge="end">
                        {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                helperText={(touched.confirmPassword && errors.confirmPassword) || ' '}
              />

              <PasswordRequirement password={values.newPassword} />

              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={false}
                  sx={{
                    fontSize: '1rem',
                    textTransform: 'capitalize',
                    padding: '0.5rem 1.375rem',
                    fontWeight: 500,
                  }}
                >
                  Reset Password
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Card>
      </Stack>
    </FormikProvider>
  )
}
