import { Box, Button, Chip, Stack, SxProps, TextField, Typography, useTheme } from '@mui/material'
import { AxiosResponse } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CompanyApi } from '../../../../services'
import { AppDispatch } from '../../../../store'
import { AuthSelector } from '../../../../store/auth'
import { SnackbarActions } from '../../../../store/snackbar'
import { TechnologyActions } from '../../../../store/technology'
import { DropdownMenu } from './DropdownMenu'

const buttonSx: SxProps = {
  py: 1,
  px: 2,
  height: '40px',
  color: 'white',
  fontWeight: 500,
  fontSize: '0.875rem',
  borderRadius: '0.5rem',
  background: 'primary.main',
  textTransform: 'capitalize',
}

interface AddTechnologyMembersFormProps {
  showModal: Function
}

export const AddTechnologyMembersForm: FC<AddTechnologyMembersFormProps> = ({ showModal }) => {
  const theme = useTheme()
  const params = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const [emails, setEmails] = useState<string[]>([])
  const [emailToSearch, setEmailToSearch] = useState('')
  const [showEmailMenu, setShowEmailMenu] = useState(false)
  const [selectedEmails, setSelectedEmails] = useState<string[]>([])

  const { email: currentUserEmail = '' } = useSelector(AuthSelector.userData)
  const { _id = '' } = useSelector(AuthSelector.userCompany)

  const technologyId = params.id || ''

  useEffect(() => {
    const debounceId = setTimeout(async () => {
      try {
        if (emailToSearch && emailToSearch.length >= 3 && _id) {
          const options = {
            params: { email: encodeURIComponent(emailToSearch) },
          }

          const res = await CompanyApi.fetchCompanyMembers(_id, options)

          const data = res && (res as AxiosResponse<any, any>).data

          const emailsInDb = data
            .map((item: any) => item.email)
            .filter((email: string) => email !== currentUserEmail)

          setEmails(emailsInDb)
          setShowEmailMenu(true)
        }
      } catch (error: any) {
        dispatch(
          SnackbarActions.showSnackbar({
            message: error.message,
          }),
        )
      }
    }, 800)

    return () => {
      clearTimeout(debounceId)
    }
  }, [emailToSearch])

  useEffect(() => {
    resetValues()
  }, [])

  const fetchTechnology = () => {
    dispatch(
      TechnologyActions.fetchTechnologyAsync({
        id: technologyId,
        options: {
          params: {
            $includes: 'users',
          },
        },
      }),
    )
  }

  const handleMenuClose = () => {
    setShowEmailMenu(false)
  }

  const addEmailToList = (email: string) => {
    if (email.trim() !== '' && !selectedEmails.includes(email)) {
      setSelectedEmails([...selectedEmails, email])
      setEmailToSearch('')
    }
    handleMenuClose()
  }

  const removeEmailFomList = (email: string) => {
    const filteredEmails = selectedEmails.filter((_email) => _email !== email)
    setSelectedEmails(filteredEmails)
  }

  const resetValues = () => {
    setSelectedEmails([])
    setEmailToSearch('')
  }

  const onAddMemberSuccess = () => {
    fetchTechnology()
    dispatch(
      SnackbarActions.showSnackbar({
        message: 'users added to technology!!!',
        options: { variant: 'success' },
      }),
    )
    resetValues()
    showModal(false)
  }

  const handleAddToTechnology = async () => {
    const resultAction = await dispatch(
      TechnologyActions.addTechnologyMemberAsync({
        id: technologyId,
        body: selectedEmails,
      }),
    )

    if (TechnologyActions.addTechnologyMemberAsync.fulfilled.match(resultAction)) {
      onAddMemberSuccess()
    }
  }

  return (
    <Box>
      <Box mt={4} position="relative" component="div">
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          value={emailToSearch}
          onChange={(e) => setEmailToSearch(e.target.value)}
        />

        <DropdownMenu open={showEmailMenu} onClose={handleMenuClose}>
          {emails && emails.length ? (
            emails.map((email) => (
              <Box
                key={email}
                component="div"
                sx={{
                  py: 1,
                  px: 2,
                  '&:hover': {
                    background: theme.palette.grey[100],
                    cursor: 'pointer',
                  },
                }}
                onClick={() => addEmailToList(email)}
              >
                {email}
              </Box>
            ))
          ) : (
            <Typography py={1} px={2} fontWeight={500} fontSize="0.875rem" color="secondary.light">
              No user found with this email!!!
            </Typography>
          )}
        </DropdownMenu>
      </Box>

      <Box minHeight={40} my={2}>
        {selectedEmails.map((email, index) => (
          <Chip
            key={index}
            label={email}
            sx={{ m: 0.5 }}
            onDelete={() => removeEmailFomList(email)}
          />
        ))}
      </Box>

      <Stack justifyContent="flex-end" direction="row" gap="1rem">
        <Button
          sx={buttonSx}
          disableElevation
          variant="contained"
          onClick={handleAddToTechnology}
          disabled={!selectedEmails.length}
        >
          Send Invite
        </Button>
      </Stack>
    </Box>
  )
}
