import { Box } from '@mui/material'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AuthHeader } from '../headers'
import { Footer } from './Footer'

export const AuthorizationLayout = () => {
  const location = useLocation()
  const redirect = location.pathname === '/'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
      }}
    >
      <AuthHeader />
      <Box
        component="div"
        sx={{
          overflowY: 'auto',
          padding: '40px 24px 24px 24px',
          height: '100vh',
        }}
      >
        {redirect && <Navigate to={'/welcome'} />}
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}
