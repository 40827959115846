import { Box, Stack, Typography, useTheme } from '@mui/material'
import { FC, ReactElement } from 'react'

interface EmailAuthResponseLayoutProps {
  logo: ReactElement
  heading: string
  subHeading?: string
  additionalSubHead: string | ReactElement
  actionButtons: ReactElement | ReactElement[]
}

export const EmailAuthResponseLayout: FC<EmailAuthResponseLayoutProps> = ({
  logo,
  heading,
  subHeading,
  additionalSubHead,
  actionButtons,
}) => {
  const theme = useTheme()
  return (
    <Stack alignItems="center" py={4}>
      <Box
        sx={{
          maxWidth: '400px',
          display: 'flex',
          flexDirection: 'column',
          px: '10px',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          {logo}
        </Box>
        <Typography
          color="secondary"
          fontSize="1.5rem"
          fontWeight={theme.typography.fontWeightBold}
        >
          {heading}
        </Typography>
        <Typography
          color="secondary.light"
          fontSize="1rem"
          fontWeight={theme.typography.fontWeightMedium}
        >
          {subHeading}
        </Typography>
        <Typography
          color="secondary.light"
          fontSize="1rem"
          fontWeight={theme.typography.fontWeightMedium}
          textAlign="left"
        >
          {additionalSubHead}
        </Typography>
        <Box>{actionButtons}</Box>
      </Box>
    </Stack>
  )
}
