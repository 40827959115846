import { Box, BoxProps, Stack, SxProps, Typography, TypographyProps, useTheme } from '@mui/material'
import { FC } from 'react'

interface LabeledTextProps {
  label?: string
  labelProps?: TypographyProps
  value: string
  valueProps?: BoxProps
  valueContainerSx?: SxProps
}

export const LabeledText: FC<LabeledTextProps> = ({
  label,
  value,
  labelProps,
  valueProps,
  valueContainerSx,
}) => {
  const theme = useTheme()

  return (
    <Stack direction="row" alignItems="center" gap=".4rem" my=".2rem">
      {label && (
        <Typography variant="body1" color={theme.palette.grey[600]} {...(labelProps || {})}>
          {label}:
        </Typography>
      )}
      <Typography
        variant="body1"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...(valueContainerSx || {}),
        }}
      >
        <Box
          component="span"
          fontSize="1rem"
          color="secondary.main"
          fontWeight={theme.typography.fontWeightBold}
          {...(valueProps || {})}
        >
          {value}
        </Box>
      </Typography>
    </Stack>
  )
}
