import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IFormAction {
  id: string
  [key: string]: any
}
interface IInitialState {
  [key: string]: { [key: string]: any }
}

const initialState: IInitialState = {}
const FormsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    addValueToList: (state, action) => {},
    setValue: (state, action: PayloadAction<IFormAction>) => {
      const { id, ...rest } = action.payload
      if (!state[id]) {
        state[id] = {}
      }
      Object.keys(rest).forEach((k) => {
        const v = rest[k]
        state[id][k] = v
      })
    },
    resetFormWithId: (state, action: PayloadAction<string>) => {
      const formId = action.payload

      if (state[formId]) {
        delete state[formId]
      }
    },
  },
})

export const FormsActions = {
  ...FormsSlice.actions,
}

export const FormsReducer = FormsSlice.reducer
