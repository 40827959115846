import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { TechnologiesApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { TechnologiesInitialState } from '../technologies.slice'

export const updateTechnologiesAsync = createAsyncThunk(
  'technologies/updateTechnology',
  async (payload: { techId: string; body: any }, { rejectWithValue }) => {
    try {
      const { techId, body } = payload
      const req = await TechnologiesApi.updateTechnologies(techId, body)
      return (req as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'updating technology')
      return rejectWithValue(normalized)
    }
  },
)

export const updateTechnologiesAsyncBuilder = (
  builder: ActionReducerMapBuilder<TechnologiesInitialState>,
) => {
  builder.addCase(updateTechnologiesAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(updateTechnologiesAsync.fulfilled, (state, action: PayloadAction<string>) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(updateTechnologiesAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = true
    state.error = action.payload
  })
}
