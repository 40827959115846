import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { SubscriptionApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { ISubscriptionInitialState } from '../subscription.slice'

export const createStripePortalSessionAsync = createAsyncThunk(
  'subscription/fetchStripeLoginLink',
  async (_, { rejectWithValue }) => {
    try {
      const res = await SubscriptionApi.createStripeProtalSession()

      const data = (res as AxiosResponse<any, any>).data

      if (!data?.url) {
        throw new Error('link not found')
      }

      return data.url
    } catch (error: any) {
      const normalized = normalizeError(error, 'creating stripe protal session')
      return rejectWithValue(normalized)
    }
  },
)

export const createStripePortalSessionAsyncBuilder = (
  builder: ActionReducerMapBuilder<ISubscriptionInitialState>,
) => {
  builder.addCase(createStripePortalSessionAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(createStripePortalSessionAsync.fulfilled, (state, action) => {
    state.loading = false
    state.success = true
  })
  builder.addCase(createStripePortalSessionAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
