import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios/index'
import { GrantApi } from '../../../services'
import { normalizeError } from '../../../utils'
import { GrantsInitialState } from '../grants.slice'

export const createShareableGrantsAsync = createAsyncThunk(
  'grants/createShareableGrantsAsync',
  async ({ grantIds }: { grantIds: string[] }, { rejectWithValue }) => {
    try {
      const res = await GrantApi.createShareableGrants(grantIds)
      const data = (res as AxiosResponse<any, any>).data

      return data
    } catch (error: any) {
      const normalized = normalizeError(error, 'creating grants shareable URL')
      return rejectWithValue(normalized)
    }
  },
)

export const createShareableGrantsAsyncBuilder = (
  builder: ActionReducerMapBuilder<GrantsInitialState>,
) => {
  builder.addCase(createShareableGrantsAsync.pending, (state) => {
    state.submitting = true
    state.error = ''
  })
  builder.addCase(createShareableGrantsAsync.fulfilled, (state) => {
    state.submitting = false
  })
  builder.addCase(createShareableGrantsAsync.rejected, (state, action: PayloadAction<any>) => {
    state.error = action.payload
    state.submitting = false
  })
}
