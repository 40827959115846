import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const LightBulbGM: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="204"
      height="256"
      viewBox="0 0 204 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M175.782 150.778C179.495 137.189 179.94 122.915 177.078 109.122C174.216 95.329 168.13 82.4095 159.317 71.4201C150.504 60.4307 139.215 51.6838 126.373 45.8947C113.531 40.1056 99.5008 37.4389 85.4303 38.1126C71.3598 38.7864 57.6487 42.7815 45.4185 49.7712C33.1883 56.7609 22.7865 66.5466 15.064 78.3277C7.34149 90.1089 2.51764 103.551 0.987045 117.554C-0.543549 131.557 1.2626 145.724 6.25775 158.895C13.4823 180.769 39.6764 210.409 36.2677 222.931L32.3547 237.241C31.9804 238.612 32.1656 240.076 32.8696 241.311C33.5736 242.546 34.739 243.451 36.1098 243.827L49.9451 247.607L62.6563 251.088L76.4917 254.868C77.1707 255.054 77.8798 255.104 78.5784 255.016C79.2769 254.928 79.9513 254.704 80.563 254.355C81.1747 254.006 81.7117 253.54 82.1432 252.984C82.5748 252.428 82.8925 251.792 83.0782 251.113L86.9912 236.803C90.4181 224.299 128.06 212.146 145.413 196.956C160.187 185.183 170.825 169.007 175.782 150.778Z"
        fill="#FDD305"
      />
      <path
        d="M46.919 245.267C47.6056 243.134 58.5973 209.005 64.1144 177.962C67.6177 179.37 71.2007 180.571 74.8449 181.559C78.4849 182.566 82.1817 183.355 85.9156 183.922C74.8631 213.452 66.9581 248.427 66.472 250.614L68.0092 250.954C68.1308 250.42 76.3092 214.242 87.5804 184.159C114.078 187.586 129.463 176.261 135.248 163.16C138.632 155.511 138.079 146.828 133.935 142.502C131.42 139.883 127.889 138.528 123.721 138.582C117.165 138.661 109.953 142.319 104.43 148.365C98.0557 155.353 91.9127 168.161 86.4928 182.391C78.9188 181.244 71.5045 179.217 64.4 176.352C66.9824 161.325 68.2158 147.186 66.2775 137.914C64.6066 129.9 60.2561 123.082 54.6539 119.686C51.0933 117.523 47.3626 116.891 43.8688 117.863C38.1026 119.473 33.2113 126.667 32.227 134.973C30.5439 149.185 38.0175 166.776 62.5772 177.306C56.9689 208.938 45.5944 244.24 45.4243 244.751L46.919 245.267ZM50.1455 119.424C51.4427 119.793 52.6836 120.336 53.8336 121.041C59.0713 124.231 63.1423 130.665 64.7342 138.26C66.5935 147.162 65.3843 160.967 62.8506 175.72C39.3542 165.506 32.1783 148.724 33.7824 135.174C34.6817 127.597 39.1963 120.816 44.282 119.376C46.2045 118.858 48.2318 118.875 50.1455 119.424ZM127.731 140.642C129.656 141.15 131.406 142.173 132.793 143.601C136.439 147.411 136.882 155.547 133.802 162.522C128.284 175.009 113.568 185.8 88.1455 182.64C93.4682 168.665 99.4532 156.149 105.59 149.434C110.822 143.705 117.603 140.235 123.733 140.162C125.082 140.137 126.427 140.298 127.731 140.642Z"
        fill="white"
      />
      <path
        d="M16.5992 112.571C17.2475 112.749 17.9245 112.797 18.5916 112.713C19.2586 112.629 19.9025 112.415 20.4866 112.082C21.0706 111.749 21.5833 111.304 21.9954 110.773C22.4074 110.242 22.7107 109.635 22.888 108.986C26.9335 94.1214 35.8246 81.0323 48.152 71.7933C60.4795 62.5543 75.5379 57.6941 90.9406 57.9832C91.6248 58.0158 92.3087 57.9109 92.9517 57.6746C93.5947 57.4383 94.1838 57.0755 94.6841 56.6075C95.1844 56.1395 95.5857 55.576 95.8643 54.9501C96.1429 54.3243 96.2932 53.649 96.3062 52.964C96.3192 52.2791 96.1947 51.5986 95.94 50.9626C95.6854 50.3267 95.3057 49.7483 94.8236 49.2616C94.3414 48.775 93.7665 48.39 93.133 48.1295C92.4994 47.869 91.82 47.7382 91.135 47.7449C73.4506 47.4054 56.1591 52.9798 42.0027 63.5841C27.8464 74.1885 17.6357 89.2156 12.99 106.282C12.8134 106.932 12.7666 107.611 12.8525 108.279C12.9384 108.947 13.1551 109.591 13.4904 110.175C13.8256 110.759 14.2727 111.272 14.8062 111.683C15.3397 112.094 15.9489 112.396 16.5992 112.571Z"
        fill="#FFEE99"
      />
      <path
        d="M111.016 55.8261C110.749 56.8037 110.198 57.6801 109.433 58.3445C108.668 59.0088 107.722 59.4313 106.717 59.5584C105.712 59.6855 104.691 59.5115 103.785 59.0585C102.878 58.6055 102.127 57.8937 101.625 57.0133C101.123 56.1329 100.893 55.1235 100.965 54.1127C101.037 53.1019 101.408 52.1351 102.029 51.3348C102.651 50.5345 103.496 49.9365 104.457 49.6165C105.419 49.2965 106.454 49.269 107.431 49.5373C108.079 49.7146 108.687 50.0179 109.218 50.4299C109.749 50.842 110.194 51.3547 110.527 51.9387C110.86 52.5228 111.074 53.1667 111.158 53.8337C111.242 54.5008 111.194 55.1778 111.016 55.8261Z"
        fill="#FFEE99"
      />
      <g opacity="0.55">
        <path
          d="M2.3933 108.852C-2.51013 145.449 15.9917 189.786 68.6595 204.186C121.327 218.587 159.194 187.842 174.22 155.851C168.772 172.039 158.77 186.311 145.413 196.956C128.06 212.11 90.4181 224.298 86.9912 236.809L83.0781 251.118C82.8924 251.798 82.5747 252.433 82.1432 252.99C81.7116 253.546 81.1747 254.012 80.563 254.361C79.9513 254.709 79.2769 254.934 78.5783 255.022C77.8797 255.11 77.1707 255.06 76.4916 254.873L62.6563 251.094L49.945 247.612L36.1097 243.833C34.739 243.457 33.5736 242.552 32.8696 241.317C32.1655 240.082 31.9803 238.618 32.3547 237.247L36.2373 222.95C39.6642 210.427 13.4518 180.787 6.22733 158.913C0.155289 142.944 -1.17604 125.56 2.3933 108.852Z"
          fill="#FFC043"
        />
      </g>
      <path
        d="M123.235 4.62866L115.835 31.6796"
        stroke="#FFC043"
        strokeWidth="3.05629"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.5092 2.06421L75.6653 29.7956"
        stroke="#FFC043"
        strokeWidth="3.05629"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2075 20.7485L37.2095 44.4393"
        stroke="#FFC043"
        strokeWidth="3.05629"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.067 28.7444L151.374 50.503"
        stroke="#FFC043"
        strokeWidth="3.05629"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M202 69.9229L177.027 82.6827"
        stroke="#FFC043"
        strokeWidth="3.05629"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
