import { CheckCircleOutlineRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ResetPasswordResponseLayout } from './ResetPasswordResponseLayout'

export const ResetPasswordSuccess = () => {
  const navigate = useNavigate()

  return (
    <ResetPasswordResponseLayout
      heading="Password reset successfully!"
      subHeading="You’ve successfully reset your password. You can now log in to your account"
      logo={<CheckCircleOutlineRounded sx={{ color: 'primary.main', fontSize: '8rem' }} />}
      actionButton={
        <Button
          variant="contained"
          sx={{
            fontSize: '1rem',
            textTransform: 'capitalize',
            padding: '0.5rem 1.375rem',
            fontWeight: 500,
            minWidth: '100px',
            mt: 1,
          }}
          onClick={() => navigate('/login')}
          disableElevation
        >
          Login
        </Button>
      }
    />
  )
}
