import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../store'
import { SnackbarActions, SnackbarSelectors } from '../../store/snackbar'

export const CustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch<AppDispatch>()

  const snackbarQueue = useSelector(SnackbarSelectors.getSnackbarQueue)

  useEffect(() => {
    snackbarQueue.forEach(({ message, options }) => {
      const onExitedAction = !options?.persist
        ? () => dispatch(SnackbarActions.removeSnackbar(options.key))
        : undefined

      enqueueSnackbar(message, {
        ...options,
        onExited: onExitedAction,
      })
    })
  }, [snackbarQueue])

  return <></>
}
