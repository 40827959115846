import { AddCircleRounded } from '@mui/icons-material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Button, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GrantsSelector } from '../../../../../../store'
import { AddToTechnologyModal } from '../AddToTechnologyModal'
import { GrantCard } from '../GrantCard'
import { GrantPaging } from '../GrantPaging'

const addTechBtnStyles = {
  fontWeight: 700,
  fontSize: '0.8125rem',
  borderRadius: '0.5rem',
  textTransform: 'capitalize',
}

export const GrantsList = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))

  const [selectedGrantId, setSelectedGrantId] = useState('')
  const [showModel, setShowModel] = useState(false)

  const grantResponses = useSelector(GrantsSelector.makeGrants)

  const handleAddToTechnology = async (e: MouseEvent<HTMLButtonElement>, grantId: string) => {
    e.stopPropagation()
    setSelectedGrantId(grantId)
    setShowModel(true)
  }

  return (
    <>
      <AddToTechnologyModal open={showModel} setOpen={setShowModel} grantId={selectedGrantId} />

      <Grid container spacing={3} my={2}>
        {grantResponses.map((item) => {
          const { solicitationNumber, agency, dueDate, _id, phase, grantTitle } = item

          const AddToTechnologyButton = xs ? (
            <IconButton onClick={(e) => handleAddToTechnology(e, _id)}>
              <AddCircleRounded color="primary" />
            </IconButton>
          ) : (
            <Button
              sx={addTechBtnStyles}
              startIcon={<AddOutlinedIcon />}
              onClick={(e) => handleAddToTechnology(e, _id)}
            >
              Add To Project
            </Button>
          )

          return (
            <Grid item key={_id} xs={12} md={6} onClick={() => navigate(`/grants/${_id}`)}>
              <GrantCard
                phase={phase}
                agency={agency}
                deadline={dueDate}
                grantTitle={grantTitle}
                addToTechBtn={AddToTechnologyButton}
                solicitationNumber={solicitationNumber}
              />
            </Grid>
          )
        })}
      </Grid>

      {grantResponses && !!grantResponses.length && <GrantPaging />}
    </>
  )
}
