import { AxiosResponse } from 'axios'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GrantDetail } from '../../../../../components/GrantDetail'
import { GrantApi } from '../../../../../services'
import { AppDispatch } from '../../../../../store'
import { SnackbarActions } from '../../../../../store/snackbar'
import { AddToTechnologyButton } from '../AddToTechnologyButton'
import { AddToTechnologyModal } from '../AddToTechnologyModal'

export const Grant: FC<{ grantId: string }> = ({ grantId }) => {
  const dispatch = useDispatch<AppDispatch>()

  const [grant, setGrant] = useState(null)
  const [openModal, setOpenModal] = useState(false)

  const handleLoadGrant = useCallback(async () => {
    try {
      if (grantId) {
        const req = await GrantApi.fetchGrant(grantId, {
          params: { $includes: 'solicitation,agency' },
        })
        if (!(req as AxiosResponse<any>).data) {
          throw new Error('Invalid Id')
        }
        setGrant((req as AxiosResponse<any>).data)
      }
    } catch (err: any) {
      dispatch(SnackbarActions.showSnackbar({ message: err.message }))
    }
  }, [grantId])

  useEffect(() => {
    handleLoadGrant()
  }, [handleLoadGrant])

  return (
    <>
      <AddToTechnologyModal open={openModal} setOpen={setOpenModal} grantId={grantId} />
      <GrantDetail
        grant={grant}
        technologyModalHandler={<AddToTechnologyButton onClick={() => setOpenModal(true)} />}
      />
    </>
  )
}
