import { Card, Stack } from '@mui/material'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { AppDispatch } from '../../../store'
import { AuthActions, AuthSelector } from '../../../store/auth'
import { SnackbarActions } from '../../../store/snackbar'
import { AuthLoader } from '../subComponents'
import { LoginForm } from './LoginForm'

export const Login: FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const loading = useSelector(AuthSelector.isLoading)
  const [searchParams] = useSearchParams()
  const authCode: any = searchParams.get('code')
  const socialAccessToken = useSelector(AuthSelector.accessToken)
  const loginError = useSelector(AuthSelector.error)
  const provider = useSelector(AuthSelector.provider)
  const from = state?.from || '/home'

  const getGoogleAccessToken = async () => {
    try {
      const actionResult = await dispatch(AuthActions.googleAuthAsync({ authCode }))

      if (AuthActions.googleAuthAsync.fulfilled.match(actionResult)) {
        const accessToken = actionResult.payload.access_token
        await getJwtWithGoogle(accessToken)
      }
      if (AuthActions.googleAuthAsync.rejected.match(actionResult)) {
        dispatch(AuthActions.logout)
        dispatch(SnackbarActions.showSnackbar({ message: actionResult.payload as string }))
      }
    } catch (error: any) {
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    }
  }

  const getJwtWithGoogle = async (token: string) => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const loginAction = await dispatch(AuthActions.googleLoginAsync(options))

      if (AuthActions.googleLoginAsync.fulfilled.match(loginAction)) {
        navigate(from, { replace: true })
      }
      if (AuthActions.googleLoginAsync.rejected.match(loginAction)) {
        dispatch(AuthActions.logout)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getLinkedInAccessToken = async () => {
    try {
      const actionResult = await dispatch(AuthActions.linkedInAuthAsync({ authCode }))

      if (AuthActions.linkedInAuthAsync.fulfilled.match(actionResult)) {
        const accessToken = actionResult.payload.access_token

        await getJwtWithLinkedin(accessToken)
      }
    } catch (error: any) {
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    }
  }

  const getJwtWithLinkedin = async (token: string) => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const loginAction = await dispatch(AuthActions.linkedinLoginAsync(options))

      if (AuthActions.linkedinLoginAsync.fulfilled.match(loginAction)) {
        navigate(from, { replace: true })
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getMicrosoftAccessToken = async () => {
    try {
      const actionResult = await dispatch(AuthActions.microsoftAuthAsync({ authCode }))

      if (AuthActions.microsoftAuthAsync.fulfilled.match(actionResult)) {
        const accessToken = actionResult.payload.access_token

        await getJwtWithMicrosoft(accessToken)
      }
    } catch (error: any) {
      dispatch(SnackbarActions.showSnackbar({ message: error.message }))
    }
  }

  const getJwtWithMicrosoft = async (token: string) => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const loginAction = await dispatch(AuthActions.microsoftLoginAsync(options))

      if (AuthActions.microsoftLoginAsync.fulfilled.match(loginAction)) {
        navigate(from, { replace: true })
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const refreshToken = async () => {
    const resultAction = await dispatch(AuthActions.refreshJwtAsync())
    if (AuthActions.refreshJwtAsync.fulfilled.match(resultAction)) {
      onLogin()
    }
    if (AuthActions.refreshJwtAsync.rejected.match(resultAction)) {
      dispatch(AuthActions.logout)
    }
  }

  useEffect(() => {
    if (loginError) {
      dispatch(SnackbarActions.showSnackbar({ message: loginError }))
    }
  }, [loginError])

  useEffect(() => {
    const token = localStorage.getItem('authToken')
    if (!provider && token) {
      refreshToken()
    }
  }, [])
  useEffect(() => {
    if (provider && (authCode || socialAccessToken)) {
      switch (provider) {
        case 'google':
          authCode && getGoogleAccessToken()
          socialAccessToken && getJwtWithGoogle(socialAccessToken)
          break
        case 'linkedin':
          authCode && getLinkedInAccessToken()
          socialAccessToken && getJwtWithLinkedin(socialAccessToken)
          break
        case 'microsoft':
          authCode && getMicrosoftAccessToken()
          socialAccessToken && getJwtWithMicrosoft(socialAccessToken)
          break
        default:
          dispatch(SnackbarActions.showSnackbar({ message: 'invalid auth provider' }))
          break
      }
    }
  }, [authCode, socialAccessToken, dispatch, provider])

  const onLogin = () => {
    navigate(from, { replace: true })
  }
  return (
    <Stack alignItems="center" width="100%" padding={{ xs: '1rem', sm: '0.5rem' }}>
      {loading && <AuthLoader message=" Please be patient we are just letting you in" />}

      {!loading && (
        <Card sx={{ padding: '2.5rem', maxWidth: { xs: '100%', sm: '480px' } }}>
          <LoginForm onLogin={onLogin} />
        </Card>
      )}
    </Stack>
  )
}
