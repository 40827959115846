import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../store'
import { CurrentUserActions, CurrentUserSelector } from '../../../store/currentUser'
import { SnackbarActions } from '../../../store/snackbar'
import { CompanyInfo, CompanyMembers } from './subcomponents'

export const CompanyProfile = () => {
  const dispatch = useDispatch<AppDispatch>()

  const userError = useSelector(CurrentUserSelector.error)

  useEffect(() => {
    if (userError) {
      dispatch(SnackbarActions.showSnackbar({ message: userError }))
    }

    return () => {
      dispatch(CurrentUserActions.resetError())
    }
  }, [userError])

  return (
    <Stack direction="column" width="100%">
      <CompanyInfo />
      <CompanyMembers />
    </Stack>
  )
}
