import { AxiosAPI } from './AxiosApi'

class SolicitationsApi extends AxiosAPI {
  public fetchSolicitations = async (options = {}) => {
    return this.GET(this.apiSchema.GET.solicitations.solicitations(), options)
  }

  public fetchSolicitation = async (id: string, options = {}) => {
    return this.GET(this.apiSchema.GET.solicitations.solicitation(id), options)
  }

  public fetchGrants = async (solicitationNumber: string, options = {}) => {
    return this.GET(this.apiSchema.GET.solicitations.grants(solicitationNumber), options)
  }
}

export default new SolicitationsApi()
