import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createMatchedGrantsAsync } from './asyncRequests/createMatchedGrantsAsync'
import { fetchKeywordsAsync, fetchKeywordsAsyncBuilder } from './asyncRequests/fetchKeywordsAsync'

export enum CreateTechStages {
  technology,
  pendingKeywords,
  keywords,
  pendingResults,
  results,
}

export interface ICreateTechnologyFormState {
  submitting: boolean
  technology: { title: string; description: string }
  keywords: string[]
  selectedKeywords: string[]
  stage: CreateTechStages
  stageStatuses: { [key: string]: boolean }
  error: string
}

const initialState: ICreateTechnologyFormState = {
  submitting: false,
  technology: { title: '', description: '' },
  keywords: [],
  selectedKeywords: [],
  stage: CreateTechStages.technology,
  error: '',
  stageStatuses: {
    [CreateTechStages.technology]: false,
    [CreateTechStages.pendingKeywords]: false,
    [CreateTechStages.keywords]: false,
    [CreateTechStages.pendingResults]: false,
    [CreateTechStages.results]: false,
  },
}
const createTechnologySlice = createSlice({
  name: 'createTechnologyForm',
  initialState,
  reducers: {
    setSelectedKeyWords: (state, action) => {
      const kws = [...state.selectedKeywords]
      if (kws.includes(action.payload)) {
        kws.splice(kws.indexOf(action.payload), 1)
      } else {
        kws.push(action.payload)
      }
      state.selectedKeywords = kws
    },
    incrementStage: (state) => {
      const enumLength = Object.keys(CreateTechStages).filter((key) => isNaN(Number(key))).length
      if (state.stage < enumLength - 1) {
        state.stageStatuses[state.stage] = true
        state.stage = state.stage + 1
      }
    },
    decrementStage: (state) => {
      if (state.stage > 0) {
        state.stage = state.stage - 1
      }
    },

    updateStageStatus: (state, action) => {
      const { stage, status } = action.payload
      state.stageStatuses[stage] = status
    },

    resetState: (state) => {
      state.error = ''
      state.keywords = []
      state.submitting = false
      state.selectedKeywords = []
      state.stage = CreateTechStages.technology
      state.technology = { title: '', description: '' }
      state.stageStatuses = {
        [CreateTechStages.technology]: false,
        [CreateTechStages.pendingKeywords]: false,
        [CreateTechStages.keywords]: false,
        [CreateTechStages.pendingResults]: false,
        [CreateTechStages.results]: false,
      }
    },
    setState: (state, action: PayloadAction<CreateTechStages>) => {
      state.stage = action.payload
    },
    setTechnologyValues: (state, action: PayloadAction<{ title: string; description: string }>) => {
      state.technology = action.payload
    },
    setTitle: (state, action) => {
      state.technology.title = action.payload
    },
    setDescription: (state, action) => {
      state.technology.description = action.payload
    },
    resetError: (state) => {
      state.error = ''
    },
  },
  extraReducers: (builder) => {
    fetchKeywordsAsyncBuilder(builder)
  },
})

export const CreateTechnologyActions = {
  ...createTechnologySlice.actions,
  fetchKeywordsAsync,
  createMatchedGrantsAsync,
}

export const CreateTechnologyReducer = createTechnologySlice.reducer
