import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AgenciesApi } from '../../../services'
import { IAgency } from '../../../types'
import { normalizeError } from '../../../utils'
import { AgenciesInitialState } from '../agencies.slice'

export const fetchAgenciesAsync = createAsyncThunk(
  'agencies/fetchAgencies',
  async (_, { rejectWithValue }) => {
    try {
      const agencies = await AgenciesApi.fetchAgencies({
        params: {
          $paging: false,
          $limit: 100,
        },
      })

      return (agencies as AxiosResponse<any, any>).data
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching agencies')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchAgenciesAsyncBuilder = (
  builder: ActionReducerMapBuilder<AgenciesInitialState>,
) => {
  builder.addCase(fetchAgenciesAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(fetchAgenciesAsync.fulfilled, (state, action: PayloadAction<IAgency[]>) => {
    state.loading = false
    state.success = true
    state.data = action.payload
  })
  builder.addCase(fetchAgenciesAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
