import { Grid, Paper } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { AgenciesSelectors } from '../../../../store/agencies'
import { LabeledText } from './LabeledText'
import { LikeDislikeWidget } from './LikeDislikeWidget'
import { Tag } from './Tag'

interface GrantCardProps {
  phase: string
  agency: string
  deadline: string
  refId: string
  like: number
  status: string
  grant: any
}

export const GrantCard: FC<GrantCardProps> = ({
  like,
  refId,
  agency,
  deadline,
  phase,
  status,
  grant,
}) => {
  const agencyDetail: any = useSelector((state) =>
    AgenciesSelectors.makeAgenciesDetail(state, agency),
  )
  return (
    <Paper elevation={0} sx={{ borderRadius: '.5rem', padding: '1.5rem', cursor: 'pointer' }}>
      <Grid container alignItems="center">
        <Grid item xs={10} sm={8} md={7}>
          <LabeledText value={grant.solicitationNumber} />
          <LabeledText value={grant.title} />
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <Grid
            container
            display="flex"
            justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
            alignItems="center"
          >
            <Grid item>
              <Tag
                value={agencyDetail[agency]?.acronym}
                textColor={agencyDetail[agency]?.textColor}
                backgroundColor={agencyDetail[agency]?.backgroundColor}
                chipStyles={{ padding: 1 }}
              />
            </Grid>
            <Grid item>
              <LikeDislikeWidget refId={refId} like={like} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container display="flex" flexDirection="row" justifyContent="space-between">
        <Grid item sm={4} md={5}>
          <Tag
            value={phase}
            fontSize="15px"
            chipStyles={{ paddingY: '18px', paddingX: '10px', display: { xs: 'none', sm: 'flex' } }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={7}
          display="flex"
          flexDirection={{ xs: 'row', sm: 'row-reverse' }}
        >
          <LabeledText label="Deadline" value={deadline} />
        </Grid>
      </Grid>
    </Paper>
  )
}
