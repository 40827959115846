import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IMetrics } from './metrics.slice'

const getMetrics = (state: RootState) => state.metrics.data

const selectWon = createSelector(getMetrics, (metrics: IMetrics) => metrics.won)

const selectLoss = createSelector(getMetrics, (metrics: IMetrics) => metrics.loss)

const selectAgencies = createSelector(getMetrics, (metrics: IMetrics) => metrics.agencies)

const getAppliedGrants = createSelector(getMetrics, (metrics: IMetrics) => metrics.applied)

const selectTotalOutcomes = createSelector(selectWon, selectLoss, (won, loss) => won + loss)

const selectWinPercentage = createSelector(selectWon, selectTotalOutcomes, (won, total) =>
  total === 0 ? 0 : (won / total) * 100,
)

const selectLossPercentage = createSelector(selectLoss, selectTotalOutcomes, (loss, total) =>
  total === 0 ? 0 : (loss / total) * 100,
)

export const MetricsSelector = {
  selectWon,
  selectLoss,
  selectAgencies,
  getAppliedGrants,
  selectWinPercentage,
  selectLossPercentage,
}
