import { Chip, SxProps, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { convertPhase } from '../../../../utils'

interface TagProps {
  value: string | string[]
  tagStyles?: SxProps
}

export const PhaseTag: FC<TagProps> = ({ value, tagStyles }) => {
  const theme = useTheme()
  const phaseValue = convertPhase(value)

  const phaseColors: { [key: string]: { textColor: string; backgroundColor: string } } = {
    'Phase I': { textColor: '#4C3A55', backgroundColor: '#D2BFE7' },
    'Phase II': { textColor: '#056608', backgroundColor: '#CFF1B5' },
    BOTH: { textColor: '#222F5B', backgroundColor: '#A1BEF3' },
    'N/A': { textColor: 'black', backgroundColor: '#d1d1d1' },
  }

  const { textColor, backgroundColor } = phaseColors[phaseValue] || phaseColors['N/A']

  return (
    <Chip
      variant="outlined"
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: '24px',
        border: 'transparent',
        my: '.2rem',
        width: 'min-content',
        ...tagStyles,
        '& .MuiChip-label': {
          padding: 1,
        },
      }}
      label={
        <Typography
          variant="body1"
          sx={{ color: textColor, fontWeight: theme.typography.fontWeightMedium, fontSize: '13px' }}
        >
          {value}
        </Typography>
      }
    />
  )
}
