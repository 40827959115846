import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../../../store'
import { CompanyActions, CompanySelectors } from '../../../../store/company'
import {
  RegistrationProgressActions,
  RegistrationProgressSelectors,
} from '../../../../store/registrationProgress'
import { StripeCheckoutLoader } from './StripeCheckoutLoader'

export const StripeCheckoutSuccess = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const currentStep = useSelector(CompanySelectors.stepCount)

  const loading = useSelector(RegistrationProgressSelectors.fetching)

  useEffect(() => {
    dispatch(
      RegistrationProgressActions.updateRegistrationProgressAsync({
        steps: {
          subscription: 'complete',
        },
      }),
    )
  }, [])

  const handleContinue = () => {
    navigate('/steps')
    dispatch(CompanyActions.updateStep(currentStep + 1))
    dispatch(CompanyActions.incrementStage())
  }

  return (
    <>
      {loading ? (
        <StripeCheckoutLoader />
      ) : (
        <Box textAlign="center" mt={4}>
          <CheckCircleIcon sx={{ fontSize: 80, color: 'success.main' }} />
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold} mt={2}>
            You are all set up!
          </Typography>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            sx={{
              mt: 2,
              px: 2,
              textTransform: 'none',
              fontWeight: theme.typography.fontWeightBold,
              borderRadius: '12px',
            }}
            onClick={handleContinue}
          >
            Continue to App
          </Button>
        </Box>
      )}
    </>
  )
}
