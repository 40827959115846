import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { UsersApi } from '../../../services'
import { ICurrentUserCompanyMember } from '../../../types'
import { normalizeError } from '../../../utils'
import { CurrentUserInitialState } from '../currentUser.slice'

export const fetchCurrentUserCompanyMembersAsync = createAsyncThunk(
  'user/company/fetchMembers',
  async (_, { rejectWithValue }) => {
    try {
      const company = await UsersApi.fetchCompanyMembers()

      const response = (company as AxiosResponse<any, any>).data
      return response
    } catch (error: any) {
      const normalized = normalizeError(error, 'fetching company members')
      return rejectWithValue(normalized)
    }
  },
)

export const fetchCurrentUserCompanyMembersAsyncBuilder = (
  builder: ActionReducerMapBuilder<CurrentUserInitialState>,
) => {
  builder.addCase(fetchCurrentUserCompanyMembersAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(
    fetchCurrentUserCompanyMembersAsync.fulfilled,
    (state, action: PayloadAction<ICurrentUserCompanyMember[]>) => {
      state.loading = false
      state.success = true
      state.data.companyMembers = action.payload
    },
  )
  builder.addCase(
    fetchCurrentUserCompanyMembersAsync.rejected,
    (state, action: PayloadAction<any>) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    },
  )
}
