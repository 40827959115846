import { Badge, Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../store'
import { companyPreferencesActions } from '../../../../store/companyPreferences'
import { TechGrantsActions, TechGrantSelectors } from '../../../../store/technology'
import { MatchedGrantStatus } from '../../../../types/Grants'
import { ExportGrantsButton } from './ExportGrantsButton'
import { ListGridToggleButton } from './ListGridToggleButton'
import { MatchedGrants } from './MatchedGrants'
import { RejectedGrants } from './RejectedGrants'
import { SavedGrants } from './SavedGrants'

interface TechnologyGrantsProps {
  technologyId: string
}

export const TechnologyGrants: FC<TechnologyGrantsProps> = ({ technologyId }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  const [savedGrantView, setSavedGrantView] = useState<'list' | 'grid'>('list')
  const [selectedTab, setSelectedTab] = useState<MatchedGrantStatus>('selected')

  const matchedGrants = useSelector((state) =>
    TechGrantSelectors.makeGrantsSummary(state, 'recommended'),
  )

  useEffect(() => {
    dispatch(TechGrantsActions.resetData())
    dispatch(companyPreferencesActions.fetchCompanyPreferencesAsync())
  }, [dispatch])

  const handleTabChange = (event: SyntheticEvent, newTab: MatchedGrantStatus) => {
    setSelectedTab(newTab)
  }

  return (
    <>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
        >
          <Tab
            value="selected"
            label={
              <Typography
                fontSize="0.875rem"
                fontWeight={theme.typography.fontWeightBold}
                textTransform="capitalize"
              >
                Saved Grants
              </Typography>
            }
          />
          <Tab
            value="recommended"
            label={
              <Badge
                color="error"
                variant="dot"
                badgeContent=""
                invisible={matchedGrants.length === 0}
                sx={{ paddingTop: '3px' }}
              >
                <Typography
                  fontSize="0.875rem"
                  fontWeight={theme.typography.fontWeightBold}
                  textTransform="capitalize"
                >
                  Matched Grants
                </Typography>
              </Badge>
            }
          />
          <Tab
            value="rejected"
            label={
              <Typography
                fontSize="0.875rem"
                fontWeight={theme.typography.fontWeightBold}
                textTransform="capitalize"
              >
                {isSmallScreen ? 'Rejected' : 'Rejected Grants'}
              </Typography>
            }
          />
        </Tabs>
      </Box>

      <Box position="relative">
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
          <ExportGrantsButton selectedTab={selectedTab} />

          {selectedTab === 'selected' && (
            <ListGridToggleButton setActiveView={setSavedGrantView} activeView={savedGrantView} />
          )}
        </Box>

        {selectedTab === 'selected' && (
          <SavedGrants
            technologyId={technologyId}
            view={savedGrantView}
            selectedTab={selectedTab}
          />
        )}
        {selectedTab === 'recommended' && <MatchedGrants technologyId={technologyId} />}
        {selectedTab === 'rejected' && <RejectedGrants technologyId={technologyId} />}
      </Box>
    </>
  )
}
