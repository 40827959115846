import { createSelector } from '@reduxjs/toolkit'
import { IStripeProduct } from '../../types'
import { RootState } from '../store'

const loading = (state: RootState) => state.subscription.loading
const products = (state: RootState) => state.subscription.data.products
const error = (state: RootState) => state.subscription.error

const getSingleProduct = createSelector(
  products,
  (_: RootState) => {},
  (products) => {
    const filteredData = products.filter((product) => product.priceType === 'recurring')

    return filteredData.length ? filteredData[0] : ({} as IStripeProduct)
  },
)

export const SubscriptionSelectors = {
  loading,
  products,
  getSingleProduct,
  error,
}
