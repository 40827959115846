import axios, { AxiosError, AxiosResponse } from 'axios'
import merge from 'lodash.merge'
import { IApiPath, RequestConfig } from '../types'
import { ApiHttpPaths } from './ApiHttpPaths'

export class AxiosAPI {
  constructor() {
    this.apiSchema = ApiHttpPaths
    this.apiDomain = this.parseDomain()
  }

  apiSchema: typeof ApiHttpPaths

  apiDomain = ''

  private parseDomain = () => {
    const d =
      (window.env && window.env.API_URL !== '__API_URL__' && window.env.API_URL) ||
      process.env.REACT_APP_API_URL ||
      ''
    if (!d) {
      return window.location.origin
    }
    if (d.startsWith('/')) {
      return `${window.location.origin}${d}`
    }
    return d
  }
  private getAuthToken = () => {
    let token = localStorage.getItem('authToken')

    if (!token) {
      token = ''
    }

    return token
  }

  private setDefaultHeaders = (options: RequestConfig = {}, path: IApiPath): RequestConfig => {
    options.headers = {
      'Content-Type': 'application/json',
    }
    if (path.protected) {
      const token = this.getAuthToken()
      options.headers!.Authorization = `Bearer ${token}`
    }
    return options
  }
  public POST = (
    path: IApiPath,
    body: any,
    options: RequestConfig = {},
  ): Promise<AxiosResponse | AxiosError> => {
    const domain = this.apiDomain
    const defaultOptions: RequestConfig = this.setDefaultHeaders(
      {
        timeout: 600000,
      },
      path,
    )

    return axios.post(`${domain}${path.url}`, body, merge(defaultOptions, options))
  }

  public GET = (
    path: IApiPath,
    options: RequestConfig = {},
  ): Promise<AxiosResponse | AxiosError> => {
    const domain = this.apiDomain
    const defaultOptions: RequestConfig = this.setDefaultHeaders(
      {
        timeout: 600000,
      },
      path,
    )
    return axios.get(`${domain}${path.url}`, merge(defaultOptions, options))
  }
  public PUT = <T>(
    path: IApiPath,
    body: any,
    options: RequestConfig,
  ): Promise<AxiosResponse<T> | AxiosError> => {
    const domain = this.apiDomain
    const defaultOptions: RequestConfig = this.setDefaultHeaders(
      {
        timeout: 600000,
      },
      path,
    )

    return axios.put(`${domain}${path.url}`, body, merge(defaultOptions, options))
  }
  public DELETE = <T>(
    path: IApiPath,
    options: RequestConfig,
  ): Promise<AxiosResponse<T> | AxiosError> => {
    const domain = this.apiDomain
    const defaultOptions: RequestConfig = this.setDefaultHeaders(
      {
        timeout: 600000,
      },
      path,
    )

    return axios.delete(`${domain}${path.url}`, merge(defaultOptions, options))
  }
}
