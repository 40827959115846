import { TableCell, TableRow } from '@mui/material'
import { FC } from 'react'
import { ITableRowsProps } from './tableTypes'

export const TableRows: FC<ITableRowsProps> = ({ rows }) => {
  return (
    <>
      {rows &&
        rows.map(({ rowProps, cells }, index) => (
          <TableRow key={index} {...rowProps}>
            {cells.map(({ cellProps, content }, index) => (
              <TableCell key={index} {...cellProps}>
                {content}
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  )
}
