import { AddCircleRounded } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'
import { FC } from 'react'

export const AddToTechnologyButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      disableElevation={true}
      sx={{
        flexGrow: 1,
        flexBasis: 0,
        minWidth: 200,
        padding: '0.9rem 0',
        borderRadius: '8px',
        textTransform: 'none',
      }}
      startIcon={<AddCircleRounded />}
      {...props}
    >
      Add To Project
    </Button>
  )
}
