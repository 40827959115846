import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { CompanyApi } from '../../../services'
import { RequestConfig } from '../../../types'
import { normalizeError } from '../../../utils'
import { CompanyInitialState } from '../company.slice'

export const createCompanyMembersAsync = createAsyncThunk(
  'company/addMembers',
  async (
    { id, body, options }: { id: string; body: any; options?: RequestConfig },
    { rejectWithValue },
  ) => {
    try {
      const response = await CompanyApi.createCompanyMembers(id, body, options)
      const data = (response as AxiosResponse<any, any>).data
      return { data, ...data.company }
    } catch (error: any) {
      const normalized = normalizeError(error, 'adding company members')
      return rejectWithValue(normalized)
    }
  },
)

export const createCompanyMembersAsyncBuilder = (
  builder: ActionReducerMapBuilder<CompanyInitialState>,
) => {
  builder.addCase(createCompanyMembersAsync.pending, (state, action) => {
    state.loading = true
    state.success = false
    state.error = ''
  })
  builder.addCase(createCompanyMembersAsync.fulfilled, (state, action) => {
    state.loading = false
    state.success = false
  })
  builder.addCase(createCompanyMembersAsync.rejected, (state, action: PayloadAction<any>) => {
    state.loading = false
    state.success = false
    state.error = action.payload
  })
}
