import { Box } from '@mui/material'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { AxiosResponse } from 'axios'
import { FC, useEffect, useState } from 'react'
import { SubscriptionApi } from '../../../../services'

const key =
  (window.env &&
    window.env.STRIPE_PUBLIC_KEY !== '__STRIPE_PUBLIC_KEY__' &&
    window.env.STRIPE_PUBLIC_KEY) ||
  process.env.REACT_APP_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(key as string)

export const CheckoutForm: FC<{ productId: string }> = ({ productId }) => {
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    const getClientSecret = async () => {
      try {
        const res = await SubscriptionApi.createStripeCheckoutSession({
          productId,
        })
        const data = (res as AxiosResponse<any, any>).data
        setClientSecret(data.clientSecret)
      } catch (err) {}
    }
    if (productId) {
      getClientSecret()
    }
  }, [productId])

  return (
    <Box id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </Box>
  )
}
