export const normalizeError = (error: any, operation: string): string => {
  if (error.response) {
    const { status, data } = error.response
    const { message } = data

    if (status === 401 && message === 'USER_NOT_FOUND') {
      return 'invalid email or password'
    }

    return message
  }

  return error?.message || `error while ${operation}`
}
