import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { AppDispatch } from '../../../../../store'
import { CompanyActions } from '../../../../../store/company'
import { CurrentUserActions } from '../../../../../store/currentUser'
import { MetaSelectors } from '../../../../../store/meta/meta.selector'
import {
  RegistrationProgressActions,
  RegistrationProgressSelectors,
} from '../../../../../store/registrationProgress'
import { SnackbarActions } from '../../../../../store/snackbar'

interface CompanyInfoProps {
  handleNext: () => void
}

const CompanyValidationSchema = Yup.object().shape({
  name: Yup.string().required('Company name is required'),
  address: Yup.object().shape({
    address1: Yup.string().required('Address is required'),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string().required('Zip code is required'),
  }),
  description: Yup.string(),
})

export const CompanyInfo: React.FC<CompanyInfoProps> = ({ handleNext }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const { company = '' } = useSelector(RegistrationProgressSelectors.progressSteps)
  const usaStatesNameAbbreviation: any = useSelector(MetaSelectors.getUsaStatesNameAbbreviation)

  useEffect(() => {
    if (company === 'complete') {
      dispatch(CompanyActions.updateStep(3))
    }
  }, [company])

  const formik = useFormik({
    initialValues: {
      name: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      description: '',
    },
    validationSchema: CompanyValidationSchema,
    onSubmit: async (values) => {
      try {
        const resultAction = await dispatch(
          CurrentUserActions.updateCurrentUserCompanyAsync(values) as any,
        )

        if (CurrentUserActions.updateCurrentUserCompanyAsync.rejected.match(resultAction)) {
          const errorMessage = resultAction.payload as any
          dispatch(
            SnackbarActions.showSnackbar({
              message: errorMessage.message,
            }),
          )
        } else {
          dispatch(
            SnackbarActions.showSnackbar({
              message: 'Company information successfully added!',
              options: { variant: 'success' },
            }),
          )

          await dispatch(
            RegistrationProgressActions.updateRegistrationProgressAsync({
              steps: { company: 'complete' },
            }),
          )

          handleNext()
        }
      } catch (error: any) {
        if (error.message === 'There is an issue while collecting information !') {
          dispatch(
            SnackbarActions.showSnackbar({
              message: error.message,
            }),
          )
        } else {
          dispatch(
            SnackbarActions.showSnackbar({
              message: 'An error occurred. Please try again.',
            }),
          )
        }
      }
    },
  })

  const { errors, touched, handleSubmit, getFieldProps } = formik
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <FormikProvider value={formik}>
        <Typography
          component="h5"
          fontSize="24px"
          fontWeight={theme.typography.fontWeightMedium}
          mb={2}
        >
          Company information
        </Typography>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Company name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={(touched.name && errors.name) || ' '}
          />
          <TextField
            fullWidth
            label="Address1"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            {...getFieldProps('address.address1')}
            error={Boolean(touched.address?.address1 && errors.address?.address1)}
            helperText={(touched.address?.address1 && errors.address?.address1) || ' '}
          />
          <TextField
            fullWidth
            label="Address2"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            {...getFieldProps('address.address2')}
            error={Boolean(touched.address?.address2 && errors.address?.address2)}
            helperText={(touched.address?.address2 && errors.address?.address2) || ' '}
          />
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              label="State"
              {...getFieldProps('address.state')}
              error={Boolean(touched.address?.state && errors.address?.state)}
            >
              {usaStatesNameAbbreviation.map((state: any) => (
                <MenuItem key={state.abbreviation} value={state.abbreviation}>
                  {state.name} - {state.abbreviation}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="City"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            {...getFieldProps('address.city')}
            error={Boolean(touched.address?.city && errors.address?.city)}
            helperText={(touched.address?.city && errors.address?.city) || ' '}
          />
          <TextField
            fullWidth
            label="Zip Code"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            {...getFieldProps('address.zip')}
            error={Boolean(touched.address?.zip && errors.address?.zip)}
            helperText={(touched.address?.zip && errors.address?.zip) || ' '}
          />
          <Box>
            <Typography
              component="h5"
              fontSize="14px"
              fontWeight={theme.typography.fontWeightMedium}
            >
              Description
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter description here"
              InputProps={{
                sx: {
                  backgroundColor: theme.palette.grey[100],
                  textAlign: 'start',
                  fontSize: '14px',
                  p: 1,
                },
              }}
              multiline
              rows={3}
              InputLabelProps={{ shrink: true }}
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={(touched.description && errors.description) || ' '}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                height: '40px',
                textTransform: 'none',
                fontWeight: 700,
                fontSize: '14px',
              }}
            >
              Save and continue
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  )
}
