import { TextField } from '@mui/material'
import { FC } from 'react'

interface GrantOutcomeFeedbackFormProps {
  feedback: string
  setFeedback: Function
}

export const GrantOutcomeFeedbackForm: FC<GrantOutcomeFeedbackFormProps> = ({
  feedback,
  setFeedback,
}) => {
  return (
    <TextField
      fullWidth
      type="text"
      sx={{ mt: 1 }}
      value={feedback}
      variant="outlined"
      placeholder="Any reason for not getting it?"
      onChange={(e) => setFeedback(e.target.value)}
    />
  )
}
